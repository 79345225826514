export type UpdateDeviceDataResult = {
  deviceId: string;
  pushToken: string | null | undefined;
};

export type DeviceAction =  // Device Registration
  | { type: 'CLEAR_DEVICEDATA' }
  | { type: 'POST_DEVICEDATA_RETRY' }
  | { type: 'UPDATE_DEVICEDATA' } // Push Notification
  | { type: 'SET_PUSH_TOKEN'; pushToken: string }
  | { type: 'SET_PUSH_ALLOWED'; enabled: boolean }
  | { type: 'READY_FOR_PUSH_NOTIFICATION'; ready: boolean }
  | { type: 'PUSH_NOTIFICATION_RECEIVED'; resolve?: () => void }
  | { type: 'PUSH_NOTIFICATION_RECEIVED_BACKGROUND'; resolve: () => void } // Count app starts
  | { type: 'INCREMENT_APP_STARTS_COUNTER' }
  | { type: 'RESET_APP_STARTS_COUNTER' };

// Device Registration
export function clearDeviceData(): DeviceAction {
  return { type: 'CLEAR_DEVICEDATA' };
}

export function postDeviceDataRetry(): DeviceAction {
  return { type: 'POST_DEVICEDATA_RETRY' };
}

// Push Notification
export function setPushToken(pushToken: string): DeviceAction {
  return { type: 'SET_PUSH_TOKEN', pushToken };
}

export function setPushAllowed(enabled: boolean): DeviceAction {
  return { type: 'SET_PUSH_ALLOWED', enabled };
}

export function readyForPushNotification(ready: boolean): DeviceAction {
  return { type: 'READY_FOR_PUSH_NOTIFICATION', ready };
}

export function pushNotificationReceived(resolve?: () => void): DeviceAction {
  return { type: 'PUSH_NOTIFICATION_RECEIVED', resolve };
}

export function pushNotificationReceivedBackground(resolve: () => void): DeviceAction {
  return { type: 'PUSH_NOTIFICATION_RECEIVED_BACKGROUND', resolve };
}

// Count app starts
export function incrementAppStartsCounter(): DeviceAction {
  return { type: 'INCREMENT_APP_STARTS_COUNTER' };
}

export function resetAppStartsCounter(): DeviceAction {
  return { type: 'RESET_APP_STARTS_COUNTER' };
}
