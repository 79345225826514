import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import ModalDialog from '../../shared/modalDialog/ModalDialog';
import { CrowdTaskParticipation } from '../../api/types';
import RejectParticipationsReasonForm from '../participations/RejectParticipationsReasonForm';
import { getFullName } from '../../shared/utils/text';
import { WebReduxState } from '../reducers';

type OwnProps = {
  open: boolean;
  participations: CrowdTaskParticipation[];
  onReject: (participations: CrowdTaskParticipation[], reason: string) => void;
  onCancel: () => void;
};

type StoreProps = {
  reason: string;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    reason:
      state.form.rejectParticipationReason && state.form.rejectParticipationReason.values
        ? state.form.rejectParticipationReason.values.reason
        : '',
  };
}

class RejectParticipationReasonModal extends React.Component<Props> {
  render() {
    const { participations, open, onCancel } = this.props;

    const single = participations.length === 1;

    const actions = [
      <Button variant="text" key="cancel" onClick={onCancel}>
        <FormattedMessage id="RejectParticipationModal.cancel" defaultMessage="Cancel" />
      </Button>,
      <Button variant="text" key="ok" color="primary" onClick={this.handleReject}>
        <FormattedMessage id="RejectParticipationModal.ok" defaultMessage="OK" />
      </Button>,
    ];

    return (
      <ModalDialog
        title={
          single ? (
            <FormattedMessage id="RejectParticipationModal.title" defaultMessage="Reject participation?" />
          ) : (
            <FormattedMessage id="RejectParticipationModal.titleMultiple" defaultMessage="Reject participations?" />
          )
        }
        actions={actions}
        open={open}>
        {single ? (
          <FormattedMessage
            id="RejectParticipationModal.message"
            defaultMessage="Do you really want to reject the participation of {name}?"
            values={{
              name: getFullName(participations[0]),
            }}
          />
        ) : (
          <FormattedMessage
            id="RejectParticipationModal.messageMultiple"
            defaultMessage="Do you really want to reject {count} participations?"
            values={{ count: participations.length }}
          />
        )}
        <RejectParticipationsReasonForm />
      </ModalDialog>
    );
  }

  handleReject = () => {
    const { onReject, participations, reason } = this.props;
    onReject(participations, reason);
  };
}

export default connect(mapStateToProps)(RejectParticipationReasonModal);
