import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import useKeyPress from '../../shared/utils/useKeyPress';

export default function AnalysisPagination({ incidentId, position, maximum, handleLeftClick, handleRightClick }) {
  const onLeftClick = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.stopPropagation();

      handleLeftClick(incidentId, position);
    },
    [incidentId, position, handleLeftClick]
  );

  const onRightClick = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.stopPropagation();

      handleRightClick(incidentId, position);
    },
    [incidentId, position, handleRightClick]
  );

  const leftPress = useKeyPress('ArrowLeft');
  const rightPress = useKeyPress('ArrowRight');

  // Deliberately using the effect hook to "listen" to key presses, so "leftPress" is the only dependency.
  React.useEffect(() => {
    if (leftPress && position < maximum) {
      handleLeftClick(incidentId, position);
    }
  }, [leftPress]); // eslint-disable-line react-hooks/exhaustive-deps

  // Deliberately using the effect hook to "listen" to key presses, so "rightPress" is the only dependency.
  React.useEffect(() => {
    if (rightPress && position > 0) {
      handleRightClick(incidentId, position);
    }
  }, [rightPress]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Tooltip
        arrow
        title={
          <FormattedMessage id="AnalysisPagination.olderUpdates" defaultMessage="Older updates (left arrow key)" />
        }>
        <span>
          <IconButton size="small" disabled={position === maximum} onClick={onLeftClick}>
            <i className="icon-arrow_back" />
          </IconButton>
        </span>
      </Tooltip>
      <FormattedMessage
        id="AnalysisPagination.update"
        defaultMessage="Update {position} of {maximum}"
        values={{
          // Don't confuse the user with zero-based indexing.
          position: maximum - position + 1,
          maximum: maximum + 1,
        }}
      />
      <Tooltip
        arrow
        title={
          <FormattedMessage id="AnalysisPagination.newerUpdates" defaultMessage="Newer updates (right arrow key)" />
        }>
        <span>
          <IconButton size="small" disabled={position === 0} onClick={onRightClick}>
            <i className="icon-arrow_right" />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
