import isValid from 'date-fns/isValid';
import set from 'date-fns/set';

const millisecondsPerDay = 86400000.0; // 1000 * 60 * 60 * 24;

const setTimeToZero = (date: string) => set(new Date(date), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const setTimeToStartOfDay = (date: string) => setTimeToZero(date).toISOString();

export const setTimeToEndOfDay = (date: string) =>
  new Date(+setTimeToZero(date) + millisecondsPerDay - 1.0).toISOString();

export const getDateOrNow = (date: string | undefined) => (date ? new Date(date) : new Date());

export const isValidDate = (date: Date | string | null) => date instanceof Date && isValid(date);
