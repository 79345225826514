import { mapValues, values } from 'lodash-es';

import { Incident } from '../../api/incidentsTypes';
import { IncidentsMap } from '../reducers/incidents';
import { isIncidentClosedOrReplaced, isIncidentRead } from '../../common/utils/incidents';

export default function sortIncidents(incidentsById: IncidentsMap): Incident[] {
  const lastUpdatedDateById: {
    [incidentId: string]: Date;
  } = mapValues(incidentsById, (incident: Incident) => new Date(incident.lastUpdated));

  const incidents: Incident[] = values(incidentsById);

  return incidents.sort((a, b) => {
    const rankA = rankIncident(a);
    const rankB = rankIncident(b);

    if (rankA === rankB) {
      return lastUpdatedDateById[b.incidentId].valueOf() - lastUpdatedDateById[a.incidentId].valueOf();
    } else {
      return (rankA ? 1 : 0) - (rankB ? 1 : 0);
    }
  });
}

function rankIncident(i: Incident): boolean {
  // Sort closed/replaced incidents to the bottom when they have been read.
  return isIncidentClosedOrReplaced(i.currentData) && isIncidentRead(i);
}
