import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import browser from '../../shared/polyfills/browser';

import hasRoleSelector from '../../api/selectors/hasRole';
import { InformRoles, CrowdTaskRoles } from '../../api/roles';
import { ReduxState } from '../../common/reducers';
import OperationRoutes from '../operations/OperationRoutes';
import AdminRoutes from '../administration/AdminRoutes';
import TabBarAdmin from '../administration/TabBarAdmin';
import Incidents from '../incidents/Incidents';
import TabBarIncidents from '../incidents/TabBarIncidents';
import InformNavbar from './InformNavbar';
import Footer from './Footer';
import Modals from './Modals';
import styles from './App.module.css';

type RoleInfo = {
  isInformUser: boolean;
  isAdmin: boolean;
  isCrowdTaskCockpitUser: boolean;
};

type OwnProps = {};

type StoreProps = {
  roleInfo: RoleInfo;
};

type Props = OwnProps & StoreProps;

const roleInfoSelector: (state: ReduxState) => RoleInfo = createSelector(
  state => hasRoleSelector(state),
  hasRole => ({
    isInformUser: hasRole(InformRoles.USER),
    isCrowdTaskCockpitUser: hasRole(CrowdTaskRoles.COCKPIT),
    isAdmin:
      hasRole(InformRoles.ADMIN_TENANTS) || hasRole(InformRoles.ADMIN_INITIAL_LOAD) || hasRole(InformRoles.STATISTICS),
  })
);

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    roleInfo: roleInfoSelector(state),
  };
}

class App extends React.Component<Props> {
  scrollRef: HTMLElement | null = null;

  setScrollRef = (element: HTMLElement | null) => {
    this.scrollRef = element;
    this.updateDimensions();
  };

  updateDimensions = () => {
    // IE only
    if (!this.scrollRef || browser !== 'ie') {
      return;
    }

    /* need to add padding, so that the horizontal scrollbar does not cover up
     * the content, and cause an additional vertical scrollbar
     */
    const scrollbarHeight = this.scrollRef.offsetHeight - this.scrollRef.clientHeight;
    this.scrollRef.style.paddingBottom = `${scrollbarHeight}px`;
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { isInformUser, isAdmin, isCrowdTaskCockpitUser } = this.props.roleInfo;

    return (
      <div className={styles.app}>
        <Route component={InformNavbar} />
        <Route path="/incidents" component={TabBarIncidents} />
        <Route path="/admin" component={TabBarAdmin} />
        <div className={styles.content} ref={this.setScrollRef}>
          <Switch>
            {/* Routes dependent on role */}
            {isInformUser && <Route path="/incidents/:viewMode(all|favorites|filter)\?id=:id" component={Incidents} />}
            {isInformUser && <Route path="/incidents/:viewMode(all|favorites|filter)" component={Incidents} />}
            {isCrowdTaskCockpitUser && <Route path="/operations" component={OperationRoutes} />}
            {isAdmin && <Route path="/admin" component={AdminRoutes} />}

            {/* Redirects dependent on role.
            Either isCrowdTaskCockpitUser or isCrowdTaskCockpitUser must always be true,
            otherwise the user is already shown an error before. */}
            {isInformUser && <Redirect to="/incidents/all" />}
            {isCrowdTaskCockpitUser && <Redirect to="/operations" />}
          </Switch>
        </div>
        <Route component={Footer} />
        <Modals />
      </div>
    );
  }
}

export default connect(mapStateToProps)(App);
