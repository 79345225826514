import { take } from 'redux-saga/effects';

import { setApiCallConfig } from '../../api/apiCall';
import { ApplicationOptions } from '../../api/ApplicationOptions';
import { setPlatformSagas } from '../../common/sagas/platformSagas';
import startupSaga from '../../common/sagas/startup';
import { serverUrlSelector } from '../../api/reducers/appConfig';
import { authenticationEnabledSelector } from '../../api/reducers/config';
import { Dispatch } from '../actions';
import platformSagas from './platformSagas';

export default function* rootSaga(dispatch: Dispatch, options: ApplicationOptions): Generator<any, any, any> {
  if (options.isInternalClient) {
    // Wait for state restoration to complete.
    yield take('PERSIST_BOOTSTRAPPED');
  }

  // Register platform-specific sagas.
  initApiCallConfig();
  setPlatformSagas(platformSagas);

  // Invoke the common startup saga.
  yield* startupSaga(dispatch, options);
}

function initApiCallConfig() {
  const { refreshToken, logout } = platformSagas;

  setApiCallConfig({
    authenticationEnabledSelector,
    serverUrlSelector,
    refreshToken,
    logout,
  });
}
