import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  items: Set<string>;
};

export default function FilterText(props: Props) {
  const { items } = props;

  switch (items.size) {
    case 0:
      return (
        <span className="greyText">
          <FormattedMessage id="FilterText.None" defaultMessage="None" />
        </span>
      );
    case 1: {
      const iter = items.values();
      const value = iter.next().value;
      return <span className="tint">{value}</span>;
    }
    default:
      return (
        <span className="tint">
          <FormattedMessage id="FilterText.several" defaultMessage="Several" />
        </span>
      );
  }
}
