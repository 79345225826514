import { createSelector } from 'reselect';

import { ReduxState } from '../reducers';
import { IncidentStatus } from '../../api/types';

export type FilterWithSets = {
  lines: Set<string>;
  locations: Set<string>;
  regions: Set<string>;
  incidentTypes: Set<string>;
  incidentStatus: IncidentStatus | null | undefined;
  remID: string;
};

const filterWithSetsSelector: (state: ReduxState) => FilterWithSets = createSelector(
  state => state.inform.filter,
  filter => {
    return {
      incidentStatus: filter.incidentStatus,
      lines: new Set(filter.lines),
      locations: new Set(filter.locations),
      regions: new Set(filter.regions),
      incidentTypes: new Set(filter.incidentTypes),
      remID: filter.remID,
    };
  }
);

export default filterWithSetsSelector;
