import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { GetTenantsResponse, PutTenantRequest } from '../../api/types';
import { getTenants, setTenantId, setWizardModal, updateTenant, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import colorStyles from '../css/colors.module.css';
import CreateTenant from './CreateTenant';
import TenantRow from './TenantRow';
import styles from './Tenants.module.css';

type OwnProps = {};

type StoreProps = {
  tenants: GetTenantsResponse;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    tenants: state.admin.tenants.tenants,
  };
}

class Tenants extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(getTenants());
  }

  render() {
    const { tenants } = this.props;
    return (
      <div className={styles.container}>
        <table className={styles.tenantTable}>
          <thead className={colorStyles.toolbarBackground}>
            <tr>
              <th>
                <FormattedMessage id="Tenants.header.displayName" defaultMessage="Display Name" />
              </th>
              <th>
                <FormattedMessage id="Tenants.header.key" defaultMessage="Tenant Key" />
              </th>
              <th>
                <FormattedMessage id="Tenants.header.actions" defaultMessage="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {tenants &&
              tenants.length > 0 &&
              tenants.map(tenant => (
                <TenantRow
                  key={tenant.tenantId}
                  tenant={tenant}
                  onDataFilterButtonClick={this.handleDataFilterButtonClick}
                  onChangeDisplayNameButtonClick={this.handleChangeDisplayNameButtonClick}
                />
              ))}
            <CreateTenant />
          </tbody>
        </table>
      </div>
    );
  }

  handleDataFilterButtonClick = (tenantId: string) => {
    const { dispatch } = this.props;
    dispatch(setTenantId(tenantId));

    dispatch(setWizardModal('tenant'));
  };

  handleChangeDisplayNameButtonClick = (tenant: PutTenantRequest, tenantId: string) => {
    const { dispatch } = this.props;
    dispatch(updateTenant(tenant, tenantId));
  };
}

export default connect(mapStateToProps)(Tenants);
