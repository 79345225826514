import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Location, History } from 'history';
import { FormattedMessage } from 'react-intl';

import { Tabs, Tab } from '../../shared/tabs/Tabs';
import { InformRoles } from '../../api/roles';
import hasRoleSelector from '../../api/selectors/hasRole';
import incidentsByViewModeSelector, { IncidentsByViewMode } from '../../common/selectors/incidentsByViewMode';
import { getStatistics, getTenants, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import TabBarButton from '../navigation/TabBarButton';

type OwnProps = {
  location: Location;
  history: History;
};

type StoreProps = {
  filterBarOpen: boolean;
  incidentsByViewMode: IncidentsByViewMode;
  hasRole: (role: string) => boolean;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    filterBarOpen: state.webNav.filterBarOpen,
    incidentsByViewMode: incidentsByViewModeSelector(state),
    hasRole: hasRoleSelector(state),
  };
}

class TabBarIncidents extends React.Component<Props> {
  render() {
    const { location, history, hasRole } = this.props;
    const path = location.pathname;

    return (
      <Tabs buttons={<Route path="/admin/(tenants|statistics)" render={this.renderFetchButton} />}>
        {hasRole(InformRoles.ADMIN_TENANTS) && (
          <Tab
            path="/admin/tenants"
            currentPath={path}
            history={history}
            label={<FormattedMessage id="AdminTabs.tenants" defaultMessage="Tenants" />}
          />
        )}
        {hasRole(InformRoles.STATISTICS) && (
          <Tab
            path="/admin/statistics"
            currentPath={path}
            history={history}
            label={<FormattedMessage id="AdminTabs.statistics" defaultMessage="Statistics" />}
          />
        )}
        {hasRole(InformRoles.ADMIN_INITIAL_LOAD) && (
          <Tab
            path="/admin/initial-load"
            currentPath={path}
            history={history}
            label={<FormattedMessage id="TabBar.initialLoad" defaultMessage="Initial Load" />}
          />
        )}
        {hasRole(InformRoles.ANALYSIS) && (
          <Tab
            path="/admin/analysis"
            currentPath={path}
            history={history}
            label={<FormattedMessage id="TabBar.analysis" defaultMessage="Analysis" />}
          />
        )}
      </Tabs>
    );
  }

  renderFetchButton = _router => {
    return <TabBarButton onClick={this.handleFetchClick} icon="refresh" />;
  };

  handleSelect = (newPath: string) => {
    if (newPath) {
      const { location, history } = this.props;

      if (newPath !== location.pathname) {
        history.push(newPath);
      }
    }
  };

  handleFetchClick = () => {
    const {
      location: { pathname },
      dispatch,
    } = this.props;

    switch (pathname) {
      case '/admin/tenants':
        dispatch(getTenants());
        break;
      case '/admin/statistics':
        dispatch(getStatistics());
        break;
      default:
        break;
    }
  };
}

export default connect(mapStateToProps)(TabBarIncidents);
