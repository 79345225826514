import * as React from 'react';

import styles from './LinkButton.module.css';

type Props = {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
};

// Anchor-like button component. Based on
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md#case-i-understand-the-previous-cases-but-still-need-an-element-resembling-a-link-that-is-purely-clickable
export default function LinkButton({ className, onClick, children }: Props) {
  const fullClassName = className ? styles.linkButton + ' ' + className : styles.linkButton;

  return (
    <button type="button" className={fullClassName} onClick={onClick}>
      {children}
    </button>
  );
}
