// Polyfills first.
import '../shared/polyfills';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import log from '../shared/logging';
import getErrorMessage from '../shared/utils/getErrorMessage';
import getMuiThemeForTintColor from '../shared/utils/getMuiThemeForTintColor';
import { initKeycloak, setKeycloak } from '../api/keycloakWeb';
import { GetConfigResponse } from '../api';
import { getTenant, getConfiguration, GetConfigResult } from '../api/config';
import { ClientConfig } from '../api/reducers/config';
import { getCachedConfig, setCachedConfig, getTenantKey, setTenantKey } from '../api/utils/localStorage';
import ErrorScreen from '../shared/errorScreen/ErrorScreen';
import startApplication from './app';

const serverUrl = '';

async function loadConfiguration(): Promise<GetConfigResult> {
  if (window.location.hash === '#/reset') {
    setTenantKey(null);
    window.history.pushState('', document.title, window.location.pathname);
  }

  const cachedConfig = getCachedConfig();
  let config: GetConfigResult | null | undefined = null;

  // Only use the cachedConfig if the response already contains the "internalClient" field.
  const lastModified =
    cachedConfig && cachedConfig.response.internalClient !== undefined ? cachedConfig.lastModified : null;

  try {
    config = await getConfiguration(serverUrl, lastModified);
  } catch (error) {
    log.error('Error loading config', error);
  }

  if (config) {
    setCachedConfig(config);
    return config;
  } else if (cachedConfig) {
    // If we have no configResult, use the cached configuration if available.
    // This occurs in case of a fetch error and when the configuration is unchanged (HTTP status code 304).
    log.info('Using cached configuration');
    return cachedConfig;
  } else {
    throw new Error('Could not determine configuration');
  }
}

async function startup() {
  try {
    const configResult = await loadConfiguration();
    const response: GetConfigResponse = configResult.response;
    const clientConfig = response.config as ClientConfig;
    const isInternalClient = response.internalClient;

    const tenantKey = clientConfig.tenant || getTenantKey();
    if (!tenantKey) {
      log.info('tenantKey not set => no authentication');

      startApplication({ configResult, isInternalClient });
      return;
    }

    const tenant = await getTenant(serverUrl, tenantKey);

    log.info(`tenantKey=${tenantKey} => checking authentication`);
    const keycloak = await initKeycloak(tenant.keycloakConfiguration, response.authenticationEnabled);

    if (keycloak.authenticated) {
      // Set global Keycloak instance.
      setKeycloak(keycloak);

      // TODO: What if the user does not have the INFORM_USER role?

      const tenantInfo = { ...tenant, tenantKey };
      startApplication({ configResult, tenantInfo, isInternalClient });
    } else {
      log.info('Not authenticated');

      // Do not set global Keycloak instance or pass tenantInfo.
      startApplication({ configResult, isInternalClient });
    }
  } catch (error) {
    handleError(error);
  }
}

function handleError(error: any) {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    return;
  }

  const muiTheme = getMuiThemeForTintColor('#e2002a');
  const errorMessage = getErrorMessage(error);

  // We must not use <FormattedMessage/> nor anything else requiring the Redux Store here.
  ReactDOM.render(
    <MuiThemeProvider theme={muiTheme}>
      <ErrorScreen
        title="Error starting application"
        errorMessage={errorMessage}
        reloadButtonTitle="Reload App"
        reloadText="Reload the page to restart the application."
        resetButtonTitle="Reset App"
        resetText="Reset the application state and reload the page."
      />
    </MuiThemeProvider>,
    rootElement
  );
}

window.onload = startup;
