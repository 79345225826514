import { PostIncidentsQueryRequest, PostIncidentsQueryResponse } from '../../types';
import apiCall from '../../apiCall';

export function* getCrowdTaskIncidents(request: PostIncidentsQueryRequest): Generator<any, any, any> {
  const response: PostIncidentsQueryResponse = yield* apiCall('crowdtask/incidents', {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return response;
}
