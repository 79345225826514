import * as React from 'react';

import { RegionInfo } from '../../api/reducers/config';
import styles from './SVGRegionGroup.module.css';

export type Region = {
  name: string;
  isSelected: boolean;
};

type Props = {
  regionInfo: RegionInfo;
  region?: Region | null | undefined;
  onRegionClick?: (regionName: string) => void;
  onMouseOver?: (regionName: string) => void;
};

type State = {
  hoverActive: boolean;
};

export default class SVGRegionGroup extends React.PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      hoverActive: false,
    };
  }

  render() {
    const {
      regionInfo: { name, path, textTransform },
    } = this.props;
    const regionStyle = this.regionStyle();

    return (
      <g onClick={this.handleClick} onMouseOver={this.handleMouseOver} onMouseLeave={this.handleMouseLeave}>
        <path className={regionStyle} d={path} />
        <text transform={textTransform} className={styles.regionText}>
          {name}
        </text>
      </g>
    );
  }

  regionStyle() {
    const { region } = this.props;
    const { hoverActive } = this.state;

    if (!region) {
      return styles.regionUnknown;
    }

    const selectionStyle = region.isSelected ? styles.regionSelected : styles.regionUnselected;
    const hoverStyle = hoverActive ? styles.regionHoverActive : styles.regionHoverInactive;

    return `${selectionStyle} ${hoverStyle}`;
  }

  handleClick = () => {
    const { onRegionClick, region } = this.props;
    if (region && onRegionClick) {
      onRegionClick(region.name);
    }
  };

  handleMouseOver = () => {
    const { onMouseOver, region } = this.props;
    if (region && onMouseOver) {
      onMouseOver(region.name);
      this.setState({ hoverActive: true });
    }
  };

  handleMouseLeave = () => {
    const { onMouseOver, region } = this.props;
    if (region && onMouseOver) {
      this.setState({ hoverActive: false });
    }
  };
}
