import * as React from 'react';

import styles from './TabBarNew.module.css';

type Props = {
  children: React.ReactNode;
};

export default function TabBar({ children }: Props) {
  return <nav className={styles.tabBar}>{children}</nav>;
}
