import { WizardModal } from '../reducers/webNav';

export type WebNavAction =
  | { type: 'SET_COPYRIGHT_MODAL_OPEN'; open: boolean }
  | { type: 'SET_SUPPORT_MODAL_OPEN'; open: boolean }
  | { type: 'SET_SETTINGS_MODAL_OPEN'; open: boolean }
  | { type: 'SET_WIZARD_MODAL'; modal: WizardModal }
  | { type: 'TOGGLE_FILTER_BAR' }
  | { type: 'SET_WIZARD_PAGE'; page: number }
  | { type: 'CHANGE_FEEDBACK_PHONE_NUMBER'; phoneNumber: string };

// Modals
export function setCopyrightModalOpen(open: boolean): WebNavAction {
  return { type: 'SET_COPYRIGHT_MODAL_OPEN', open };
}

export function setSupportModalOpen(open: boolean): WebNavAction {
  return { type: 'SET_SUPPORT_MODAL_OPEN', open };
}

export function setSettingsModalOpen(open: boolean): WebNavAction {
  return { type: 'SET_SETTINGS_MODAL_OPEN', open };
}

export function setWizardModal(modal: WizardModal): WebNavAction {
  return { type: 'SET_WIZARD_MODAL', modal };
}

export function toggleFilterBar(): WebNavAction {
  return { type: 'TOGGLE_FILTER_BAR' };
}

export function setWizardPage(page: number): WebNavAction {
  return { type: 'SET_WIZARD_PAGE', page };
}

export function changeFeedbackPhoneNumber(phoneNumber: string): WebNavAction {
  return { type: 'CHANGE_FEEDBACK_PHONE_NUMBER', phoneNumber };
}
