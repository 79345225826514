import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { ChatMessage } from '../../api/types';
import Chat from '../../api/components/Chat';
import { ApiDispatch, submitOperationChatMessages, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import FullScreenMessage from '../navigation/FullScreenMessage';

type OwnProps = {
  match: match<{ operationId?: string }>;
};

type StoreProps = {
  operationId: string | null | undefined;
  chatThreadId: string | null | undefined;
};

type Props = OwnProps & WebDispatchProps & StoreProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { operationId } = ownProps.match.params;
  const chatThreadId = operationId ? state.operations.byId[operationId].chatThreadId : null;

  return {
    operationId,
    chatThreadId,
  };
}

class OperationDetailsChat extends React.Component<Props> {
  render() {
    const { chatThreadId } = this.props;
    if (!chatThreadId) {
      return <NoOperationChatMessage />;
    }

    return (
      <Chat chatThreadId={chatThreadId} hasWritePermission={true} onSubmitChatMessage={this.handleSubmitChatMessage} />
    );
  }

  handleSubmitChatMessage = (dispatch: ApiDispatch, message: ChatMessage) => {
    const { operationId, chatThreadId } = this.props;

    if (!operationId || !chatThreadId) {
      return;
    }

    dispatch(submitOperationChatMessages(operationId, chatThreadId, [message]));
  };
}

export default connect(mapStateToProps)(OperationDetailsChat);

function NoOperationChatMessage() {
  return (
    <FullScreenMessage
      icon={<i className="icon-chat" />}
      title={<FormattedMessage id="OperationDetailsChat.noChat" defaultMessage="No chat" />}
      message={
        <FormattedMessage
          id="OperationDetailsChat.releaseCallOutToStartChat"
          defaultMessage="Release a call out to start the chat."
        />
      }
    />
  );
}
