import { GetIncidentsSearchRequest } from '../../api/admin';

export type GetIncidentsSearchChatAction = { type: 'GET_INCIDENTS_SEARCH_CHAT'; incidentId: string };

export type AnalysisAction =
  | GetIncidentsSearchChatAction
  | { type: 'GET_INCIDENTS_SEARCH' }
  | { type: 'GET_INCIDENTS_SEARCH_CSV' }
  | { type: 'SET_INCIDENTS_SEARCH'; search: GetIncidentsSearchRequest }
  | { type: 'SELECT_INCIDENTS_SEARCH_ID'; incidentId: string | null }
  | { type: 'SET_INCIDENTS_SEARCH_HISTORY'; incidentId: string; historyNumber: number };

export function getIncidentsSearch(): AnalysisAction {
  return { type: 'GET_INCIDENTS_SEARCH' };
}

export function getIncidentsSearchChat(incidentId: string): AnalysisAction {
  return { type: 'GET_INCIDENTS_SEARCH_CHAT', incidentId };
}

export function getIncidentsSearchCsv(): AnalysisAction {
  return { type: 'GET_INCIDENTS_SEARCH_CSV' };
}

export function setIncidentsSearch(search: GetIncidentsSearchRequest): AnalysisAction {
  return { type: 'SET_INCIDENTS_SEARCH', search };
}

export function selectIncidentsSearchId(incidentId: string | null): AnalysisAction {
  return { type: 'SELECT_INCIDENTS_SEARCH_ID', incidentId };
}

export function setIncidentsSearchHistory(incidentId: string, historyNumber: number): AnalysisAction {
  return { type: 'SET_INCIDENTS_SEARCH_HISTORY', incidentId, historyNumber };
}
