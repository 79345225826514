export type License = {
  name: string;
  version: string;
  licenseType: string;
  url: string;
};

export const licenses: License[] = require('./licenseList.json');

export function isValidCopyrightUrl(url: string): boolean {
  return Boolean(url && url !== 'Unknown' && url.startsWith('http'));
}
