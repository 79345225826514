import * as React from 'react';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';

import validatePhoneNumber, { validateDigitsOnly } from '../../common/utils/validatePhoneNumber';
import InputField from '../navigation/InputField';

type Props = {
  intl: IntlShape;
  onChange: (phoneNumber: string) => void;
  onEnterKeyPress?: (phoneNumber: string) => void;
  feedbackPhoneNumber: string;
  dark?: boolean;
};

const msg = defineMessages({
  wizardPhoneNumberPlaceholder: {
    id: 'WizardFeedbackPhoneNumberPage.phoneNumber.placeholder',
    defaultMessage: '00431234567890',
  },
});

export default class WizardFeedbackPhoneNumberPage extends React.Component<Props> {
  render() {
    const { feedbackPhoneNumber, intl, dark } = this.props;
    return (
      <>
        <p>
          <FormattedMessage
            id="WizardFeedbackPhoneNumberPage.phoneNumber"
            defaultMessage="Insert a callback phone number for the feedback chat. Only digits are allowed."
          />
        </p>
        <InputField
          controlId="enterFeedbackPhoneNumber"
          formType="text"
          placeholder={intl.formatMessage(msg.wizardPhoneNumberPlaceholder)}
          value={feedbackPhoneNumber}
          onChange={this.handleOnChange}
          onConfirm={this.handleOnConfirm}
          maxLength={128}
          validate={validatePhoneNumber}
          dark={dark}
        />
      </>
    );
  }

  handleOnChange = (value: string) => {
    if (validateDigitsOnly(value)) {
      this.props.onChange(value);
    }
  };

  handleOnConfirm = (value: string) => {
    const { onEnterKeyPress } = this.props;
    if (onEnterKeyPress) {
      onEnterKeyPress(value);
    }
  };
}
