import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { Location } from 'history';

import SharedFooter from '../../shared/footer/Footer';
import { clientConfigSelector } from '../../api/reducers/config';
import connectionStatusTextSelector from '../../api/selectors/connectionStatusText';
import incidentStatusTextByViewModeSelector from '../../common/selectors/incidentStatusTextByViewMode';
import getViewModeByPath from '../utils/getViewModeByPath';
import { useSelector, WebReduxState } from '../reducers';
import styles from './Footer.module.css';

type Props = {
  location: Location;
};

function Footer({ location }: Props) {
  const dataSelector = (state: WebReduxState) => {
    const viewMode = getViewModeByPath(location.pathname) || 'all';

    return {
      logoData: clientConfigSelector(state).theme.logo,
      connectionStatus: connectionStatusTextSelector(state),
      incidentStatus: incidentStatusTextByViewModeSelector(state)[viewMode],
    };
  };

  const { logoData, connectionStatus, incidentStatus } = useSelector(dataSelector, shallowEqual);
  const logoBase64 = logoData && logoData.logoBase64;
  const imageUri = logoBase64 ? `data:image/png;base64,${logoBase64}` : null;

  const text = incidentStatus ? [connectionStatus, incidentStatus].join('\n') : connectionStatus;

  return (
    <SharedFooter
      logo={imageUri ? <img src={imageUri} alt="Logo" className={styles.logo} /> : null}
      statusText={text}
    />
  );
}

export default React.memo(Footer);
