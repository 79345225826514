import apiCall from '../../apiCall';
import {
  PostCrowdTaskCreateOperationRequest,
  PostCrowdTaskUpdateOperationRequest,
  CrowdTaskOperation,
  CrowdTaskOperationWithStats,
  PostCrowdTaskSendOperationCodeResponse,
} from '../../types';

const operationsPath = 'crowdtask/admin/operations';

export function* createOperation(request: PostCrowdTaskCreateOperationRequest): Generator<any, any, any> {
  const result: CrowdTaskOperation = yield* apiCall(`${operationsPath}/create`, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return result;
}

export function* updateOperation(
  operationId: string,
  version: number,
  request: PostCrowdTaskUpdateOperationRequest
): Generator<any, any, any> {
  const result: CrowdTaskOperation = yield* apiCall(`${operationsPath}/${operationId}/${version}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });

  return result;
}

export function* terminateOperation(operationId: string, version: number): Generator<any, any, any> {
  const result: CrowdTaskOperation = yield* apiCall(`${operationsPath}/${operationId}/${version}/close`, {
    method: 'POST',
  });

  return result;
}

export function* getOperations(): Generator<any, any, any> {
  const result: CrowdTaskOperationWithStats[] = yield* apiCall(operationsPath);
  return result;
}

export function* deleteOperation(operationId: string, version: number): Generator<any, any, any> {
  const result: CrowdTaskOperation = yield* apiCall(`${operationsPath}/${operationId}/${version}`, {
    method: 'DELETE',
  });

  return result;
}

export function* sendCode(operationId: string): Generator<any, any, any> {
  const result: PostCrowdTaskSendOperationCodeResponse = yield* apiCall(`${operationsPath}/${operationId}/sendCode`, {
    method: 'POST',
  });

  return result;
}
