import { take } from 'redux-saga/effects';
import log from '../../shared/logging';

import { Action } from '../actions';

export default function* logActionsSaga(): Generator<any, any, any> {
  while (true) {
    // Trigger on any action.
    const action: Action = yield take();

    switch (action.type) {
      case 'SET_USER_IDLE':
      case 'REFRESH_TIMER':
        // Ignore
        break;
      case 'API_CALL_STARTED':
        log.info('started', action.name.toLowerCase());
        break;
      case 'API_CALL_SUCCESS':
        log.info('success', action.name.toLowerCase());
        break;
      case 'API_CALL_ERROR':
        log.info('error', action.name.toLowerCase());
        break;
      case 'SET_APPLICATION_STATE':
        log.info(action.type.toLowerCase(), action.applicationState);
        break;
      case 'SET_STARTUP_STATE':
        log.info(action.type.toLowerCase(), action.startupState);
        break;
      default:
        log.info(action.type.toLowerCase());
        break;
    }
  }
}
