import { buffers } from 'redux-saga';
import { actionChannel, take, select, put } from 'redux-saga/effects';
import { values, sortBy } from 'lodash-es';

import log from '../../shared/logging';
import {
  PostChatSyncRequest,
  PostIncidentFeedbackRequest,
  PostCrowdTaskOperationChatMessageRequest,
  ChatMessage,
} from '../../api/types';
import { Incident } from '../../api/incidentsTypes';
import { ChatThread } from '../../api/reducers/chat';
import { ChatPendingState } from '../../api/reducers/chatPending';
import { App, appSelector } from '../../api/reducers/appConfig';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { syncMessages, postOperationChatMessage, postIncidentChatMessage } from '../../api';
import { ApplicationState } from '../../common/reducers/app';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';
import { IncidentsMap } from '../reducers/incidents';
import { apiCallStarted, apiCallSuccess, apiCallError, PostChatMessageResult } from '../actions';
import { ReduxState } from '../reducers';
import { getOperationChatThreads, OperationChatThread } from './platformSagas';

export function* chatApiCallsSaga(): Generator<any, any, any> {
  // Queue processing requests for syncing pending chat messages.
  const channel = yield actionChannel(
    ['SUBMIT_INCIDENT_CHAT_MESSAGES', 'SUBMIT_OPERATION_CHAT_MESSAGES', 'SYNC_PENDING_CHAT_MESSAGES'],
    buffers.sliding(1)
  );

  try {
    while (true) {
      yield* syncPendingIncidentChatMessages();
      yield* syncPendingOperationChatMessages();
      yield take(channel);
    }
  } finally {
    channel.close();
  }
}

function* syncPendingIncidentChatMessages() {
  const chatPendingState: ChatPendingState = yield select((state: ReduxState) => state.chatPending);
  const incidentsById: IncidentsMap = yield select((state: ReduxState) => state.inform.incidents.byId);

  const incidents: Incident[] = values(incidentsById);

  for (let i = 0; i < incidents.length; i++) {
    const { incidentId, threadId } = incidents[i];
    const thread = chatPendingState[threadId];

    if (thread) {
      const { byId, ids } = thread.pendingMessages;

      for (let j = 0; j < ids.length; j++) {
        const chatMessage = byId[ids[j]];
        yield* attemptPostPendingIncidentChatMessage(chatMessage, incidentId);
      }
    }
  }
}

function* attemptPostPendingIncidentChatMessage(chatMessage, incidentId) {
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  if (!device) {
    return;
  }

  const callbackNumber: string = yield select((state: ReduxState) => state.settings.feedbackPhoneNumber);

  const request: PostIncidentFeedbackRequest = {
    clientMessageId: chatMessage.messageId,
    message: chatMessage.message,
    callbackNumber,
  };

  yield put(apiCallStarted('POST_INCIDENT_CHAT_MESSAGE', true));

  try {
    const postedChatMessage: ChatMessage = yield* postIncidentChatMessage(device.deviceId, incidentId, request);
    const result = getPostChatMessageResult(chatMessage, postedChatMessage);

    yield put(apiCallSuccess('POST_INCIDENT_CHAT_MESSAGE', result));
  } catch (error) {
    // Special case: If the UUID is already used, try again and generate a different one.
    if (error.status === 409) {
      yield* attemptPostPendingIncidentChatMessage(chatMessage, incidentId);
    }
    yield put(apiCallError('POST_INCIDENT_CHAT_MESSAGE', error));
  }
}

function* syncPendingOperationChatMessages() {
  const operationChatThreads: OperationChatThread[] = yield* getOperationChatThreads();
  const chatPendingState: ChatPendingState = yield select((state: ReduxState) => state.chatPending);

  for (let i = 0; i < operationChatThreads.length; i++) {
    const { operationId, chatThreadId } = operationChatThreads[i];
    const thread = chatPendingState[chatThreadId];

    if (thread) {
      const { byId, ids } = thread.pendingMessages;

      for (let j = 0; j < ids.length; j++) {
        const chatMessage = byId[ids[j]];
        yield* attemptPostPendingOperationChatMessage(chatMessage, operationId);
      }
    }
  }
}

function* attemptPostPendingOperationChatMessage(chatMessage, operationId) {
  const request: PostCrowdTaskOperationChatMessageRequest = {
    clientMessageId: chatMessage.messageId,
    message: chatMessage.message,
  };

  // Coordinator app needs to include device id in request.
  const app: App = yield select(appSelector);
  if (app === ('coordinator' as App)) {
    const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
    if (device) {
      request.coordinatorDeviceId = device.deviceId;
    } else {
      // Should not happen.
      return;
    }
  }

  yield put(apiCallStarted('POST_OPERATION_CHAT_MESSAGE', true));

  try {
    const postedChatMessage: ChatMessage = yield* postOperationChatMessage(operationId, request);
    const result = getPostChatMessageResult(chatMessage, postedChatMessage);

    yield put(apiCallSuccess('POST_OPERATION_CHAT_MESSAGE', result));
  } catch (error) {
    // Special case: If the UUID is already used, try again and generate a different one.
    if (error.status === 409) {
      yield* attemptPostPendingOperationChatMessage(chatMessage, operationId);
    }
    yield put(apiCallError('POST_OPERATION_CHAT_MESSAGE', error));
  }
}

function getPostChatMessageResult(pendingChatMessage, postedChatMessage): PostChatMessageResult {
  return {
    pendingMessageId: pendingChatMessage.messageId,
    chatMessage: postedChatMessage,
  };
}

type PostChatSyncRequestThread = {
  threadId: string;
  lastMessageId?: string;
};

export function* performSyncChatMessages(threadIds: string[]): Generator<any, any, any> {
  const threads: PostChatSyncRequestThread[] = [];

  for (let i = 0; i < threadIds.length; i++) {
    const threadId = threadIds[i];
    let lastMessageId: string | null = null;

    const thread: ChatThread | null | undefined = yield select((state: ReduxState) => state.chat[threadId]);

    if (thread) {
      const { ids, byId } = thread.messages;

      const sortedMessageIds = sortBy(ids, id => byId[id].submitted);
      lastMessageId = sortedMessageIds.length ? sortedMessageIds[sortedMessageIds.length - 1] : null;
    }

    if (lastMessageId) {
      threads.push({ threadId, lastMessageId });
    } else {
      threads.push({ threadId });
    }
  }

  if (threads.length === 0) {
    return;
  }

  const applicationState: ApplicationState = yield select((state: ReduxState) => state.app.applicationState);
  const foreground = applicationState === ('active' as ApplicationState);
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  const app: App = yield select(appSelector);
  if (!device) {
    return;
  }

  const request = getChatSyncRequest(threads, foreground, device, app);
  yield* wrapApiCallActions('SYNC_CHAT_MESSAGES', syncMessages(request));
}

function getChatSyncRequest(
  threads: PostChatSyncRequestThread[],
  pushOnNextChatMessage: boolean,
  device: DeviceRegistration,
  app: App
) {
  const request: PostChatSyncRequest = {
    threads,
    pushOnNextChatMessage,
  };

  // Device id needs to be included in request.
  const { deviceId } = device;

  switch (app) {
    case 'inform':
      request.informDeviceId = parseInt(deviceId, 10);
      break;
    case 'staffMember':
      request.staffMemberDeviceId = deviceId;
      break;
    case 'coordinator':
      request.coordinatorDeviceId = deviceId;
      break;
    default:
      break;
  }

  return request;
}

export function* performResetPushFlagChatSync(): Generator<any, any, any> {
  const app: App = yield select(appSelector);
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  if (!device) {
    return;
  }

  const request = getChatSyncRequest([], true, device, app);
  log.info('Sending chat sync request', request);
  yield* syncMessages(request);
}
