import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import log from '../../shared/logging';
import ErrorScreen from '../errorScreen/ErrorScreen';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: any;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: null };

  componentDidCatch(error: any, info: any) {
    log.error('Error boundary caught error', { error, info });
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <ErrorScreen
          title={<FormattedMessage id="shared.ErrorBoundary.internalError" defaultMessage="Internal Error" />}
          resetButtonTitle={<FormattedMessage id="shared.ErrorBoundary.resetApp" defaultMessage="Reset App" />}
          reloadButtonTitle={<FormattedMessage id="shared.ErrorBoundary.reloadApp" defaultMessage="Reload App" />}
          resetText={
            <FormattedMessage
              id="shared.ErrorBoundary.pressReset"
              defaultMessage={'Press the "Reset App" button below to reset the app state and reload the page.'}
            />
          }
          reloadText={
            <FormattedMessage
              id="shared.ErrorBoundary.pressReload"
              defaultMessage={'Press the "Reload App" button below to reload the page.'}
            />
          }
          errorMessage={
            <FormattedMessage
              id="shared.ErrorBoundary.anErrorOccurred"
              defaultMessage="An internal application error has occurred."
            />
          }
        />
      );
    } else {
      return React.Children.only(this.props.children);
    }
  }

  handleResetApp = () => {
    localStorage.clear();
    window.location.href = '/';
  };
}
