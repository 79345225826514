import { groupBy, mapValues, keyBy } from 'lodash-es';

import { createCollection, emptyCollection, addValue, addValues } from '../../shared/normalizedCollection';
import { ChatMessage, ChatMessageIdAndThreadId, PostChatSyncResponse } from '../../api/types';
import applyLongPollingUpdate from '../../api/utils/applyLongPollingUpdate';
import { ApiAction, PostChatMessageResult } from '../actions';
import { ChatThread, makeChatThread } from '../utils/chat';

export type { ChatThread } from '../utils/chat';

export type ChatState = {
  [threadId: string]: ChatThread;
};

const emptyThread = {
  messages: emptyCollection,
  lastMessageId: null,
};

const idSelector = (m: ChatMessage) => m.messageId;

const initialState = {};

// For pending messages, messageId is the clientMessageId that will be sent to the server to avoid duplicates.
// For messages received from the server, this is the actual message id.
export default function chat(state: ChatState = initialState, action: ApiAction): ChatState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_OPERATION_CHAT_MESSAGES':
        case 'GET_FORUM_MESSAGES':
          return handleGetChatMessagesResult(state, action.result);
        case 'SYNC_CHAT_MESSAGES':
          return handleChatSync(state, action.result);
        case 'POST_INCIDENT_CHAT_MESSAGE':
        case 'POST_OPERATION_CHAT_MESSAGE':
        case 'POST_FORUM_CHAT_MESSAGE':
          return handlePostMessageResult(state, action.result);
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

function getThread(state: ChatState, threadId: string) {
  return state[threadId] || emptyThread;
}

function handleGetChatMessagesResult(state: ChatState, messages: ChatMessage[]) {
  const messagesByThreadId = groupBy(messages, m => m.threadId);
  const newMessages = mapValues(messagesByThreadId, msgs => makeChatThread(createCollection(msgs, idSelector)));

  return { ...state, ...newMessages };
}

type ResponsePerThread = {
  threadId: string;
  error?: string;
  messages: Array<ChatMessage>;
};

// Note: all threads not in result will be lost.
function handleChatSync(state: ChatState, result: PostChatSyncResponse) {
  const threadsByKey = keyBy(result.threads, t => t.threadId);

  return mapValues(threadsByKey, (response: ResponsePerThread, threadId: string) => {
    const thread = getThread(state, threadId);
    const messages = addValues(thread.messages, response.messages, idSelector);

    return makeChatThread(messages);
  });
}

function handlePostMessageResult(state: ChatState, result: PostChatMessageResult) {
  const { chatMessage } = result;
  const { threadId } = chatMessage;

  const thread = getThread(state, threadId);
  const messages = addValue(thread.messages, chatMessage, idSelector);

  return {
    ...state,
    [threadId]: makeChatThread(messages),
  };
}

function handleLongPollingMessage(state, message): ChatState {
  if (message.type !== 'ChatMessage') {
    return state;
  }

  if (message.action === 'DELETE') {
    const { action, payload, type } = message;
    const { threadId, messageId } = payload as ChatMessageIdAndThreadId;
    const thread = getThread(state, threadId);

    return {
      ...state,
      [threadId]: makeChatThread(
        applyLongPollingUpdate<ChatMessage>(thread.messages, { action, payload: messageId, type }, idSelector)
      ),
    };
  }

  if (message.action === 'UPSERT') {
    const { threadId } = message.payload as ChatMessage;
    const thread = getThread(state, threadId);

    return {
      ...state,
      [threadId]: makeChatThread(applyLongPollingUpdate(thread.messages, message, idSelector)),
    };
  }

  return state;
}

export function getChatMessageIds(state: ChatState, threadId: string) {
  const thread: ChatThread = getThread(state, threadId);
  return thread.messages.ids;
}
