import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import versionInfo from '../../shared/version/versionInfo';
import { supportPhoneNumberSelector, supportEmailSelector, ConfigState } from '../reducers/config';
import { AppConfig } from '../reducers/appConfig';

import styles from './Support.module.css';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
};

type OwnProps = {
  linkClassName?: string;
};

type StoreProps = {
  supportPhoneNumber: string | null | undefined;
  supportEmail: string | null | undefined;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: PartialReduxState): StoreProps {
  return {
    supportPhoneNumber: supportPhoneNumberSelector(state),
    supportEmail: supportEmailSelector(state),
  };
}

class Support extends React.Component<Props> {
  render() {
    const { supportPhoneNumber, supportEmail, linkClassName } = this.props;
    const telLink = supportPhoneNumber && supportPhoneNumber.replace(/\s+/g, ''); // don't use replace on possible null
    const linkClassNames = linkClassName ? `${styles.underline} ${linkClassName}` : styles.underline;
    const supportTelLink = supportPhoneNumber && telLink && (
      <a className={linkClassNames} title={supportPhoneNumber} href={`tel:${telLink}`}>
        {supportPhoneNumber}
      </a>
    );
    const supportEmailLink = supportEmail && (
      <a className={linkClassNames} title={supportEmail} href={`mailto:${supportEmail}`}>
        {supportEmail}
      </a>
    );

    return (
      <>
        <SubSection
          title={<FormattedMessage id="api.Support.hotlineTitle" defaultMessage="Support Hotline" />}
          body={
            <>
              {supportTelLink && (
                <div>
                  <FormattedMessage
                    id="Support.hotlineText"
                    values={{ supportTelLink }}
                    defaultMessage={'Our support hotline can be reached at: {supportTelLink}'}
                  />
                </div>
              )}
              {supportEmailLink && (
                <div>
                  <FormattedMessage
                    id="Support.emailText"
                    values={{ supportEmailLink }}
                    defaultMessage="Our support email can be reached at: {supportEmailLink}"
                  />
                </div>
              )}
            </>
          }
        />
        <SubSection
          title={<FormattedMessage id="Support.appVersion" defaultMessage="App version" />}
          body={
            <>
              <div>
                <FormattedMessage id="Support.version" defaultMessage="Version:" /> {versionInfo.version}
              </div>
              <div>
                <FormattedMessage id="Support.build" defaultMessage="Build:" /> {versionInfo.build}
              </div>
            </>
          }
        />
        <SubSection
          title={<FormattedMessage id="Support.browserInformation" defaultMessage="Browser information" />}
          body={navigator.userAgent}
        />
      </>
    );
  }
}

function SubSection({ title, body }) {
  return (
    <div className={styles.subSection}>
      <h4 className={styles.subHeading}>{title}</h4>
      {body}
    </div>
  );
}

export default connect(mapStateToProps)(Support);
