import { PostIncidentFeedbackRequest, ChatMessage } from '../types';
import apiCall from '../apiCall';

export function* postIncidentChatMessage(
  deviceId: string,
  incidentId: string,
  request: PostIncidentFeedbackRequest
): Generator<any, any, any> {
  const response: ChatMessage = yield* apiCall(`devices/${deviceId}/incident/${incidentId}/feedback`, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return response;
}
