import * as React from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { StateWithLocationCount } from '../reducers/locations';

const msg = defineMessages({
  all: { id: 'NewOperation.all', defaultMessage: 'All Stations' },
});

type Props = {
  intl: IntlShape;
  stateFilter: string;
  sortedLocationCodes: string[];
  statesWithLocationCount: StateWithLocationCount[];
  onStateChange: (event: any, child: React.ReactNode) => void;
};

const selectStyle = {
  width: 230,
};

export default function NewOperationStatesDropDown({
  intl,
  stateFilter,
  sortedLocationCodes,
  statesWithLocationCount,
  onStateChange,
}: Props) {
  const allMessage = intl.formatMessage(msg.all);

  return (
    <Select value={stateFilter} style={selectStyle} onChange={onStateChange}>
      <MenuItem value={'__all__'}>
        {allMessage} ({sortedLocationCodes.length})
      </MenuItem>
      {statesWithLocationCount.map(({ state, locationCount }) => (
        <MenuItem key={state} value={state}>
          {state} ({locationCount})
        </MenuItem>
      ))}
    </Select>
  );
}
