import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  open: boolean;
  onClose?: () => void;
  title: React.ReactNode;
  actions: React.ReactNode;
  children: React.ReactNode;
};

export default function ModalDialog({ open, onClose, title, actions, children }: Props) {
  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick={true} disableEscapeKeyDown={true} fullWidth={true}>
      <DialogTitle disableTypography={true}>
        <h4>{title}</h4>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
