import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import ModalDialog from '../../shared/modalDialog/ModalDialog';
import { getFullName } from '../../shared/utils/text';
import { CrowdTaskParticipation } from '../../api/types';

type Props = {
  open: boolean;
  participations: CrowdTaskParticipation[];
  onConfirm: (participations: CrowdTaskParticipation[]) => void;
  onCancel: () => void;
};

export default class ConfirmParticipationsModal extends React.PureComponent<Props> {
  render() {
    const { participations, open, onCancel } = this.props;

    const actions = [
      <Button variant="text" key="cancel" onClick={onCancel}>
        <FormattedMessage id="ConfirmationDialog.cancel" defaultMessage="Cancel" />
      </Button>,
      <Button variant="text" key="ok" color="primary" onClick={this.handleOkClicked}>
        <FormattedMessage id="ConfirmationDialog.ok" defaultMessage="OK" />
      </Button>,
    ];

    const single = participations.length === 1;

    return (
      <ModalDialog
        title={
          single ? (
            <FormattedMessage id="ConfirmationDialog.title" defaultMessage="Confirm participation?" />
          ) : (
            <FormattedMessage id="ConfirmationDialog.titleMultiple" defaultMessage="Reject participations?" />
          )
        }
        actions={actions}
        open={open}>
        {single ? (
          <FormattedMessage
            id="ConfirmationDialog.message"
            defaultMessage="Do you really want to confirm the participation of {name}?"
            values={{
              name: getFullName(participations[0]),
            }}
          />
        ) : (
          <FormattedMessage
            id="ConfirmationDialog.messageMultiple"
            defaultMessage="Do you really want to confirm {count} participations?"
            values={{ count: participations.length }}
          />
        )}
      </ModalDialog>
    );
  }
  handleOkClicked = () => {
    const { onConfirm, participations } = this.props;
    onConfirm(participations);
  };
}
