import * as React from 'react';
import { connect } from 'react-redux';
import { union } from 'lodash-es';

import { RegionInfo } from '../../api/reducers/config';
import FilterText from '../filter/FilterText';
import FilterCheckbox from '../filter/FilterCheckbox';
import SVGRegionMap from '../filter/SVGRegionMap';
import { WebDispatch, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import styles from './wizard.module.css';

type OwnProps = {
  HeaderComponent: React.ComponentType<any>;
  DescriptionComponent?: React.ComponentType<any>;
  optionsSelector: (state: WebReduxState) => string[];
  selectedOptionsSelector: (state: WebReduxState) => string[];
  onSelectionChanged: (dispatch: WebDispatch, newSelectedOptions: string[]) => void;
};

type StoreProps = {
  options: string[];
  selectedOptions: string[];
  regionInfos?: RegionInfo[];
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { optionsSelector, selectedOptionsSelector } = ownProps;

  return {
    options: optionsSelector(state),
    selectedOptions: selectedOptionsSelector(state),
  };
}

class WizardFilterPage extends React.Component<Props> {
  render() {
    const { HeaderComponent, DescriptionComponent, options, selectedOptions, regionInfos } = this.props;

    // No need to put this into a selector.
    // This component is pure, as it is bound to the Redux Store,
    // and therefore does not re-render unnecessarily anyway.
    const selectedOptionsSet = new Set(selectedOptions);
    const allOptions = union(options, selectedOptions).sort();
    const items = allOptions.map(s => ({ name: s, isSelected: selectedOptionsSet.has(s) }));

    const iconColor = selectedOptions.length > 0 ? 'tint' : 'greyText';

    return (
      <div>
        {DescriptionComponent && (
          <p>
            <DescriptionComponent />
          </p>
        )}
        <div className={styles.boxHeader}>
          <div className="alignLeft">
            <i className={`icon-line ${styles.iconFilter} ${iconColor}`} />
            <span>
              <HeaderComponent />
            </span>
          </div>
          <div className="alignRight">
            <FilterText items={selectedOptionsSet} />
          </div>
          <div className="clearBoth" />
        </div>
        {regionInfos ? (
          <SVGRegionMap regionInfos={regionInfos} regions={items} onChange={this.handleChange} />
        ) : (
          <div className={styles.scrollBox}>
            <form>
              {items.map(c => (
                <FilterCheckbox
                  key={c.name}
                  criterion={c}
                  filterPrefix="data"
                  filterType="regions"
                  onChange={this.handleChange}
                />
              ))}
            </form>
          </div>
        )}
      </div>
    );
  }

  handleChange = (name: string) => {
    const { dispatch, selectedOptions, onSelectionChanged } = this.props;

    const selectedOptionsSet = new Set(selectedOptions);

    if (selectedOptionsSet.has(name)) {
      selectedOptionsSet.delete(name);
    } else {
      selectedOptionsSet.add(name);
    }

    onSelectionChanged(dispatch, Array.from(selectedOptionsSet));
  };
}

export default connect(mapStateToProps)(WizardFilterPage);
