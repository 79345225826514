import handleError from '../../shared/apiCalls/handleError';
import simpleFetch from '../../shared/apiCalls/simpleFetch';
import apiCall from '../apiCall';
import { CallOutArea } from '../types';

export type GetMasterDataResponse = {
  organizations: string[];
  specialSkills: string[];
  healthRestrictions: string[];
  languages: string[];
  qualifications: string[];
  phoneNumberPrefixes: string[];
  workPlaces: string[];
};

export function getMasterData(): Promise<GetMasterDataResponse> {
  return Promise.all([
    getMasterDataEntity('organizations'),
    getMasterDataEntity('specialSkills'),
    getMasterDataEntity('healthRestrictions'),
    getMasterDataEntity('languages'),
    getMasterDataEntity('qualifications'),
    getMasterDataEntity('phoneNumberPrefixes'),
    getMasterDataEntity('workPlaces'),
    getMasterDataEntity('jacketSizes'),
  ]).then(results => {
    const [
      organizations,
      specialSkills,
      healthRestrictions,
      languages,
      qualifications,
      phoneNumberPrefixes,
      workPlaces,
      jacketSizes,
    ] = results;
    return {
      organizations,
      specialSkills,
      healthRestrictions,
      languages,
      qualifications,
      phoneNumberPrefixes,
      workPlaces,
      jacketSizes,
    };
  });
}

function getMasterDataEntity(type: string): Promise<string[]> {
  return simpleFetch(`/api/v1/crowdtask/${type}`)
    .then(handleError)
    .then(response => response.json());
}

export function* getCallOutAreas(): Generator<any, any, any> {
  const response: CallOutArea[] = yield* apiCall('crowdtask/callOutAreas');
  return response;
}

export function* getLocations(): Generator<any, any, any> {
  return yield* apiCall('crowdtask/locations');
}
