import { Dispatch } from '../actions';

export type PlatformSagas = {
  authenticate: () => Generator<any, any, any>;
  logout: () => Generator<any, any, any>;
  refreshToken: (force: boolean) => Generator<any, any, any>;
  startNavigationSagas: () => Generator<any, any, any>;
  checkAppVersion: () => Generator<any, any, any>;
  startApiSagas: () => Generator<any, any, any>;
  startPeriodicSagas: (dispatch: Dispatch) => Generator<any, any, any>;
  getOperationChatThreads: () => Generator<any, any, any>;
  performInitialFetch: () => Generator<any, any, any>;
  ensureWizardCompleted: () => Generator<any, any, any>;
  checkPermissions: () => Generator<any, any, any>;
};

export type OperationChatThread = {
  operationId: string;
  chatThreadId: string;
};

// Claim that this is not null. It *must* be initialized on startup!
let platformSagas: PlatformSagas = null as any;

// The platform sagas (native/web) must be sent during the respective startup process.
export function setPlatformSagas(sagas: PlatformSagas) {
  platformSagas = sagas;
}

export function* authenticate(): Generator<any, any, any> {
  return yield* platformSagas.authenticate();
}

export function* logout(): Generator<any, any, any> {
  return yield* platformSagas.logout();
}

export function* refreshToken(force: boolean): Generator<any, any, any> {
  return yield* platformSagas.refreshToken(force);
}

export function* startNavigationSagas(): Generator<any, any, any> {
  return yield* platformSagas.startNavigationSagas();
}

export function* checkAppVersion(): Generator<any, any, any> {
  return yield* platformSagas.checkAppVersion();
}

export function* startApiSagas(): Generator<any, any, any> {
  return yield* platformSagas.startApiSagas();
}

export function* startPeriodicSagas(dispatch: Dispatch): Generator<any, any, any> {
  return yield* platformSagas.startPeriodicSagas(dispatch);
}

export function* getOperationChatThreads(): Generator<any, any, any> {
  return yield* platformSagas.getOperationChatThreads();
}

export function* performInitialFetch(): Generator<any, any, any> {
  return yield* platformSagas.performInitialFetch();
}

export function* ensureWizardCompleted(): Generator<any, any, any> {
  return yield* platformSagas.ensureWizardCompleted();
}

export function* checkPermissions(): Generator<any, any, any> {
  return yield* platformSagas.checkPermissions();
}
