import { Action } from '../actions';

export type MasterDataState = {
  specialSkills: string[];
  languages: string[];
};

const initialState: MasterDataState = {
  specialSkills: [],
  languages: [],
};

export default function masterData(state: MasterDataState = initialState, action: Action) {
  if (action.type === 'API_CALL_SUCCESS' && action.name === 'GET_MASTER_DATA') {
    return {
      languages: action.result.languages,
      specialSkills: action.result.specialSkills,
      jacketSizes: action.result.jacketSizes,
    };
  } else {
    return state;
  }
}
