import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history';
import EditCallOutComponent, { EditCallOutComponentMode } from './EditCallOutComponent';

type Props = {
  match: match<{ operationId?: string }>;
  history: History;
};

const mode: EditCallOutComponentMode = { type: 'new' };

// eslint-disable-next-line no-shadow
export default function NewCallOutScreen({ match, history }: Props) {
  const operationId = match.params.operationId;

  return <EditCallOutComponent mode={mode} operationId={operationId} history={history} />;
}
