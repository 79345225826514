export type ApiCallStatus = {
  readonly inProgress: boolean;
  readonly error: any;
};

// Use this in componentWillReceiveProps to determine if an API call has finished successfully
// in order to e.g. redirect to the result.
export function apiCallFinishedSuccessfully(
  newStatus: ApiCallStatus | null | undefined,
  oldStatus: ApiCallStatus | null | undefined
) {
  return oldStatus && oldStatus.inProgress && newStatus && !newStatus.inProgress && !newStatus.error;
}
