import { select } from 'redux-saga/effects';

import { fetchIncidents } from '../../api';
import { PostIncidentsQueryRequest, PostIncidentsQueryResponse } from '../../api/types';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { ReduxState } from '../reducers';
import { IncidentsState } from '../reducers/incidents';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';

export type IncidentsEndpoint = 'inform' | 'crowdTaskStaffMember' | 'crowdTaskCockpit';

export default function* fetchIncidentsSaga(): Generator<any, any, any> {
  yield* wrapApiCallActions('FETCH_INCIDENTS', performFetchIncidents(), true);
}

// TODO: Is this still needed?
// Trigger cleanup of leftover incidents if last sync happened more than 24 hours ago.
// const cleanupInterval = 24; // hours

// if (syncTimestamp && differenceInHours(new Date(), syncTimestamp) >= cleanupInterval) {
//   yield put(resetTransactionId());
// }

// Important: This must come after the possible transaction id reset above.
// const lastTransactionId: number = yield select((state: ReduxState) => state.inform.incidents.transactionId);

export function buildIncidentsQueryRequest(incidentsState: IncidentsState): PostIncidentsQueryRequest {
  const { transactionId, filterHash, byId } = incidentsState;

  return {
    lastTransactionId: transactionId,
    lastFilterHash: filterHash,
    currentIncidentIds: Object.keys(byId),
  };
}

function* performFetchIncidents() {
  const deviceRegistration: DeviceRegistration = yield select(deviceRegistrationSelector);

  const incidentsState: IncidentsState = yield select((state: ReduxState) => state.inform.incidents);
  const request = buildIncidentsQueryRequest(incidentsState);

  const response: PostIncidentsQueryResponse = yield* fetchIncidents(deviceRegistration.deviceId, request);
  return response;
}
