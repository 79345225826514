import { AvailableFilterCriteria } from '../../api/types';
import { DataFilterType } from '../../api/reducers/config';

export type FetchDataFilterCriteriaResult = {
  availableOptions: AvailableFilterCriteria;
  criteria: AvailableFilterCriteria;
};

export type DataFilterAction =
  | { type: 'SET_DATAFILTER_CRITERIA'; dataFilterType: DataFilterType; criteria: string[] }
  | { type: 'FETCH_DATAFILTER_CRITERIA' }
  | { type: 'POST_DATAFILTER_CRITERIA' }
  | { type: 'APPLY_DATAFILTER'; criteria: AvailableFilterCriteria };

export function setDataFilterCriteria(dataFilterType: DataFilterType, criteria: string[]): DataFilterAction {
  return { type: 'SET_DATAFILTER_CRITERIA', dataFilterType, criteria };
}

export function fetchDataFilterCriteria(): DataFilterAction {
  return { type: 'FETCH_DATAFILTER_CRITERIA' };
}

export function postDataFilterCriteria(): DataFilterAction {
  return { type: 'POST_DATAFILTER_CRITERIA' };
}

export function applyDataFilter(criteria: AvailableFilterCriteria): DataFilterAction {
  return { type: 'APPLY_DATAFILTER', criteria };
}
