import { createSelector } from 'reselect';

import { ApiState } from '../../api/reducers/api';

type PartialReduxState = {
  api: ApiState;
};

const actions = [
  // Entity create & update
  'POST_CALL_OUT',
  'POST_FOLLOWUP_CALL_OUT',
  'UPDATE_CALL_OUT',
  'POST_OPERATION',
  'UPDATE_OPERATION', // Action Buttons
  'RELEASE_ALL_CALL_OUTS',
  'CLOSE_CALL_OUT',
  'DELETE_CALL_OUT',
  'TERMINATE_OPERATION',
  'DELETE_OPERATION',
  'RELEASE_CALL_OUT',
  'RELEASE_ALL_CALL_OUTS',
  'CONFIRM_PARTICIPATIONS',
  'REJECT_PARTICIPATIONS',
  'TERMINATE_PARTICIPATIONS', // Getters
  // 'GET_CALL_OUTS',
  // 'GET_PARTICIPATIONS',
  // Other
  'SEND_CODE',
];

const apiActions = [...actions];

export const apiActionInProgressSelector: (state: PartialReduxState) => boolean = createSelector(
  state => state.api.apiCalls,
  apiCalls =>
    apiActions.some(action => {
      const status = apiCalls[action];
      return status && status.inProgress;
    })
);
