import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../api/components/Button';

type Props = {
  onLogoutClick: () => void;
};

export default class MenuHeader extends React.PureComponent<Props> {
  render() {
    const { onLogoutClick } = this.props;
    return (
      <Button onClick={onLogoutClick}>
        <FormattedMessage id="Menu.logout" defaultMessage="Logout" />
      </Button>
    );
  }
}
