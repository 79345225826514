import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './ProgressSpinner.module.css';

export default function ProgressSpinner() {
  return (
    <div className={styles.spinner}>
      <CircularProgress size={70} thickness={7} />
    </div>
  );
}
