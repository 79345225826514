import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css';

type Props = {
  supportPhoneNumber: string;
  linkClass: string;
};

export default class MenuFooter extends React.PureComponent<Props> {
  render() {
    const { supportPhoneNumber, linkClass } = this.props;
    const phoneNumber = supportPhoneNumber.replace(/\s+/g, '');
    const telLink = `tel:${phoneNumber}`;

    const hotline = (
      <a className={`${linkClass} ${styles.hotlineLink}`} href={telLink}>
        {supportPhoneNumber}
      </a>
    );

    return (
      <p className={styles.problems}>
        <FormattedMessage id="Menu.problems" defaultMessage="Problems with the application?" />
        <br />
        <FormattedMessage
          id="Menu.callSupport"
          defaultMessage="Call our support hotline: {hotline}"
          values={{ hotline }}
        />
      </p>
    );
  }
}
