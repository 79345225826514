import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import ApiCallStatusHandler from '../../api/components/ApiCallStatusHandler';
import styles from './CockpitApiStatus.module.css';

type Props = {
  actions: string[];
  onSuccess?: () => void;
};

export default function CockpitApiErrors({ actions, onSuccess }: Props) {
  return <ApiCallStatusHandler apiCallNames={actions} onSuccess={onSuccess} renderErrorMessage={renderErrorMessage} />;
}

export function renderErrorMessage(errorMessage: React.ReactNode) {
  return <div className={styles.container}>{errorMessage}</div>;
}

export function renderProgress() {
  return <CircularProgress size={32} thickness={3} />;
}
