import * as React from 'react';

import styles from './TabBarButton.module.css';

type Props = {
  icon: string;
  onClick: () => void;
  toggle?: boolean;
  disabled?: boolean;
};

export default function TabBarButton({ disabled, icon, onClick, toggle }: Props) {
  const buttonClass = disabled ? styles.buttonDisabled : toggle ? styles.buttonActive : styles.buttonEnabled;

  return (
    <span className={buttonClass} onClick={onClick}>
      <i className={`icon-${icon}`} />
    </span>
  );
}
