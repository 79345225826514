import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { some } from 'lodash-es';

import { TextField } from '../../shared/formComponents';
import { CrowdTaskParticipationStatus, CrowdTaskParticipation } from '../../api/types';
import validatorsSelector, { Validators } from '../../api/selectors/validators';
import { WebReduxState } from '../reducers';
import styles from './EditOperationForm.module.css';

type OwnProps = {
  operationId: string | null | undefined;
};

type StoreProps = {
  initialValues: Object;
  contactInformationRequired: boolean;
  validators: Validators;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const operationId = ownProps.operationId;

  // TODO: Loading initial values does not work when navigating here directly. Same as in call out...

  return {
    initialValues: state.operations && operationId ? state.operations.byId[operationId] : {},
    contactInformationRequired: contactInformationRequiredSelector(state),
    validators: validatorsSelector(state),
  };
}

const confirmedStates: CrowdTaskParticipationStatus[] = ['CONFIRMED', 'ARRIVED', 'READY'];
const contactInformationRequiredSelector: (state: WebReduxState) => boolean = createSelector(
  state => state.participations.participations.byId,
  participationsById => {
    return some(participationsById, (p: CrowdTaskParticipation) => confirmedStates.includes(p.status));
  }
);

class EditOperationForm extends React.Component<Props> {
  render() {
    const {
      contactInformationRequired,
      validators: { required, phone },
    } = this.props;

    return (
      <div>
        <form className={styles.grid}>
          <div className={styles.gridElement}>
            <Field
              name="meetingPoint"
              component={TextField}
              label={
                <div>
                  <FormattedMessage id="NewOperationForm.meetingPoint" defaultMessage="Meeting Point" />*
                </div>
              }
              validate={[required]}
              fullWidth={true}
              margin="normal"
            />
            <Field
              name="additionalInformation"
              component={TextField}
              multiline={true}
              fullWidth={true}
              label={
                <FormattedMessage id="NewOperationForm.additionalInformation" defaultMessage="Additional Information" />
              }
              margin="normal"
            />
          </div>
          <div className={styles.gridElement}>
            <Field
              name="contactName"
              component={TextField}
              label={
                <div>
                  <FormattedMessage id="NewOperationForm.contactName" defaultMessage="Contact Name" />
                  {contactInformationRequired && '*'}
                </div>
              }
              fullWidth={true}
              validate={contactInformationRequired ? [required] : []}
              margin="normal"
            />
            <Field
              name="contactPhoneNumber"
              component={TextField}
              validate={contactInformationRequired ? [required, phone] : [phone]}
              label={
                <div>
                  <FormattedMessage id="PersonalDataForm.phoneNumber" defaultMessage="Mobile Number" />
                  {contactInformationRequired && '*'}
                </div>
              }
              fullWidth={true}
              margin="normal"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'newOperation',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })(EditOperationForm)
);
