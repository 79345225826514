import { StatisticsDaily, StatisticsIncidentType, StatisticsLine, StatisticsRegion } from '../types';
import apiCall from '../apiCall';

export type GetStatisticsResult = {
  readonly dailyStatistic: StatisticsDaily[];
  readonly incidentTypeStatistic: StatisticsIncidentType[];
  readonly lineStatistic: StatisticsLine[];
  readonly regionStatistic: StatisticsRegion[];
};

// Returns GetStatisticsResult.
export function* getAllStatistics(): Generator<any, any, any> {
  return {
    dailyStatistic: yield* getStatistics('daily'),
    incidentTypeStatistic: yield* getStatistics('incidentTypes'),
    regionStatistic: yield* getStatistics('regions'),
    lineStatistic: yield* getStatistics('lines'),
  };
}

export function* postAppStartStatistics(deviceId: string, appStarts: number): Generator<any, any, any> {
  const body = JSON.stringify({ day: new Date(), appStarts: appStarts });

  return yield* apiCall(`devices/${deviceId}/statistics`, {
    method: 'POST',
    body,
  });
}

export function* getStatistics(statisticName: string): Generator<any, any, any> {
  return yield* apiCall(`statistics/${statisticName}`);
}
