import * as React from 'react';
import { FormattedDate } from 'react-intl';
import isSameDay from 'date-fns/isSameDay';

import { nowSelector } from '../reducers/refreshTimer';
import { useSelector } from '../reducers';

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'numeric',
};

const fullDateFormatOptions: Intl.DateTimeFormatOptions = {
  ...timeFormatOptions,
  ...dateFormatOptions,
  year: 'numeric',
};

type Props = {
  value: Date | string;
  hourAndMinutesOnPastDays?: boolean;
  fullDate?: boolean;
};

export default function FormattedTimestamp({ value, hourAndMinutesOnPastDays, fullDate }: Props) {
  const now = useSelector(nowSelector);
  return (
    <UndecoratedFormattedTimestamp
      value={new Date(value)}
      now={now}
      hourAndMinutesOnPastDays={hourAndMinutesOnPastDays}
      fullDate={fullDate}
    />
  );
}

type UndecoratedFormattedTimestampProps = {
  value: Date;
  now: Date;
  hourAndMinutesOnPastDays?: boolean;
  fullDate?: boolean;
};

export function UndecoratedFormattedTimestamp({
  value,
  hourAndMinutesOnPastDays,
  fullDate,
  now,
}: UndecoratedFormattedTimestampProps) {
  let options = dateFormatOptions;

  if (fullDate) {
    options = fullDateFormatOptions;
  } else {
    if (hourAndMinutesOnPastDays) {
      options = { ...timeFormatOptions, ...dateFormatOptions };
    }

    if (isSameDay(value, now)) {
      options = timeFormatOptions;
    }
  }

  return <FormattedDate value={value} {...options} />;
}
