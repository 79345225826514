import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldInfo } from '../../common/utils/compareIncidentData';

type Props = {
  title: React.ReactNode;
  value: string | null | undefined;
  fieldInfo: FieldInfo | null | undefined;
};

export default class Field extends React.Component<Props> {
  render() {
    const { fieldInfo, title, value } = this.props;
    const highlight = fieldInfo && fieldInfo.changed ? 'highlight' : '';

    if (!value) {
      if (fieldInfo && fieldInfo.changedToEmpty) {
        return (
          <span>
            <h5 className="highlight">{title}</h5>
            <p className="highlight">
              <FormattedMessage id="Field.empty" defaultMessage="(empty)" />
            </p>
          </span>
        );
      } else {
        return null;
      }
    }

    return (
      <span>
        <h5 className={highlight}>{title}</h5>
        <p className={highlight}>{value}</p>
      </span>
    );
  }
}
