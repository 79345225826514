import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './FilterableTableColumn.module.css';

type Props<T> = {
  activeClassName: string;
  selectedItem: T;
  items: Array<T>;
  renderItem: (item: T) => React.ReactNode;
  message: React.ReactNode;
  onChange: (value: T) => void;
};

type State = {
  open: boolean;
  anchorEl: any;
};

class ClickableMenuItem extends React.PureComponent<any> {
  render() {
    const { value, className, key, children } = this.props;
    return (
      <MenuItem onClick={this.handleClick} value={value} className={className} key={key}>
        {children}
      </MenuItem>
    );
  }

  handleClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { onClick, value } = this.props;
    onClick(value);
  };
}

export default class FilterableTableColumn<T> extends React.Component<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  render() {
    const { items, selectedItem, message, renderItem, activeClassName } = this.props;

    return (
      <div>
        {message}
        <span
          className={`${styles.filterButton} ${selectedItem === null ? '' : activeClassName}`}
          onClick={this.handleFilterButtonClicked}>
          <i className="icon-filter" />
        </span>
        <Menu
          open={this.state.open}
          // @ts-ignore
          onChange={this.handleChange}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              maxHeight: 300,
            },
          }}
          onClose={this.handleRequestClose}>
          {items.map(item => (
            <ClickableMenuItem
              onClick={this.handleChange}
              key={keySelector(item)}
              className={selectedItem === item ? activeClassName : ''}
              value={item}>
              {renderItem(item)}
            </ClickableMenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  handleFilterButtonClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleChange = (value: T) => {
    this.props.onChange(value);
    this.handleRequestClose();
  };
}

function keySelector(item) {
  if (typeof item === 'string') {
    return item;
  }
  return 'ALL';
}
