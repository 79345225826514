import { put, select } from 'redux-saga/effects';

import { switchLocale } from '../../shared/i18n/actions';
import { ConfigState, ClientConfig, clientConfigSelector } from '../reducers/config';
import { AppConfig } from '../reducers/appConfig';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
};

export function* overrideUILanguageIfNeeded(): Generator<any, any, any> {
  const clientConfig: ClientConfig = yield select((state: PartialReduxState) => clientConfigSelector(state));
  const { forcedLanguageCode } = clientConfig;

  if (forcedLanguageCode) {
    yield put(switchLocale(forcedLanguageCode));
  }
}
