import ifvisible from 'ifvisible.js';

import { Dispatch } from '../common/actions';
import { setApplicationState, setUserIdle } from './actions';

export default function initAppStateHandler(dispatch: Dispatch) {
  ifvisible.setIdleDuration(10);

  ifvisible.blur(() => dispatch(setApplicationState('background')));
  ifvisible.focus(() => dispatch(setApplicationState('active')));

  ifvisible.idle(() => dispatch(setUserIdle(true)));
  ifvisible.wakeup(() => dispatch(setUserIdle(false)));

  // Trigger focus event to dispatch initial state.
  ifvisible.focus();
}
