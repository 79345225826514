import * as React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';

export default function Button(props: ButtonProps) {
  return (
    <MuiButton color="primary" variant="contained" {...props}>
      {props.children}
    </MuiButton>
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <MuiButton variant="contained" {...props}>
      {props.children}
    </MuiButton>
  );
}
