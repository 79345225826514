import { createSelector } from 'reselect';

import { ReduxState } from '../reducers';
import { Incident } from '../../api/incidentsTypes';
import filterWithSetsSelector, { FilterWithSets } from './filterWithSets';
import sortedIncidentsSelector from './sortedIncidents';

const filteredIncidentsSelector: (state: ReduxState) => Incident[] = createSelector(
  state => sortedIncidentsSelector(state),
  state => filterWithSetsSelector(state),
  (sortedIncidents, filterWithSets) => {
    return sortedIncidents.filter(incident => {
      return incidentMatchesFilter(incident, filterWithSets);
    });
  }
);

export default filteredIncidentsSelector;

export function incidentMatchesFilter(incident: Incident, filter: FilterWithSets) {
  // REM ID
  const remID = filter.remID.trim();
  if (remID.length) {
    // If remID is set, all other filter criteria are ignored.
    return incident.incidentId.includes(remID);
  }

  // Status
  const { status } = incident.currentData;
  if (filter.incidentStatus && filter.incidentStatus !== status) {
    return false;
  }

  const { regions: filterRegions, lines: filterLines, locations: filterLocations } = filter;

  const incidentTypeFilterSet = filter.incidentTypes.size > 0;
  const positionalFilterSet = filterRegions.size > 0 || filterLines.size > 0 || filterLocations.size > 0;

  // Special case: When neither filter is set, no incident matches.
  if (!incidentTypeFilterSet && !positionalFilterSet) {
    return false;
  }

  // Incident Type (if set)
  const { incidentType } = incident.currentData;
  if (incidentTypeFilterSet && incidentType && !filter.incidentTypes.has(incidentType)) {
    return false;
  }

  // Positional filter (if set): regions, lines, locations
  const { lines, regions, locationTo, locationFrom } = incident.currentData;
  if (positionalFilterSet) {
    const regionMatches = filterRegions.size && regions.some(r => filterRegions.has(r));
    const lineMatches = filterLines.size && lines.some(l => filterLines.has(l));
    const locationFromMatches = filterLocations.has(locationFrom);
    const locationToMatches = locationTo && filterLocations.has(locationTo);

    if (!regionMatches && !lineMatches && !locationFromMatches && !locationToMatches) {
      return false;
    }
  }

  return true;
}
