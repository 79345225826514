import { put, delay } from 'redux-saga/effects';

import { refreshTimer } from '../actions';

// Trigger refreshTimer every 10 seconds.
export default function* refreshTimerSaga(): Generator<any, any, any> {
  while (true) {
    // Dispatch refresh timer action *before* the delay so that the GUI is refreshed
    // when reopening the app.
    yield put(refreshTimer());
    yield delay(10 * 1000);
  }
}
