import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import ModalDialog from '../../shared/modalDialog/ModalDialog';

type Props = {
  show: boolean;
  onOkClicked: () => void;
};

export default function OperationMissingDataDialog({ show, onOkClicked }: Props) {
  return (
    <ModalDialog
      open={show}
      actions={
        <Button variant="text" color="primary" key="ok" onClick={onOkClicked}>
          <FormattedMessage id="OperationMissingDataDialog.ok" defaultMessage="OK" />
        </Button>
      }
      title={<FormattedMessage id="OperationMissingDataDialog.title" defaultMessage="Contact data missing" />}>
      <FormattedMessage
        id="OperationMissingDataDialog.message"
        defaultMessage="You need to fill out the contact information in this operation prior to confirming any participations."
      />
    </ModalDialog>
  );
}
