import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { History } from 'history';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isNil } from 'lodash-es';
import { destroy } from 'redux-form';

import { confirmParticipations, rejectParticipations, getParticipations, WebDispatchProps } from '../actions';
import ApiCallStatusHandler from '../../api/components/ApiCallStatusHandler';
import { CrowdTaskCallOut, CrowdTaskParticipation, CrowdTaskOperation } from '../../api/types';
import { WebReduxState } from '../reducers';
import Button from '../../api/components/Button';
import CockpitApiErrors, { renderErrorMessage, renderProgress } from '../navigation/CockpitApiStatus';
import ParticipationsList from '../participations/ParticipationsList';
import RejectParticipationReasonModal from '../participations/RejectParticipationsReasonModal';
import ConfirmParticipationsModal from '../participations/ConfirmParticipationsModal';
import Timeline from './Timeline';
import styles from './OperationDetailsCallOuts.module.css';
import SelectedCallOutInfo from './SelectedCallOutInfo';
import SelectedCallOutButtons from './SelectedCallOutButtons';
import SelectedCallOutDialogs from './SelectedCallOutDialogs';
import OperationMissingDataDialog from './OperationMissingDataDialog';

type State = {
  showOperationMissingDataDialog: boolean;
  showReleaseDialog: boolean;
  showDeleteDialog: boolean;
  showCloseDialog: boolean;
  showTerminateDialog: boolean;
  confirmationDialog: { show: boolean; participations: CrowdTaskParticipation[] };
  rejectionDialog: { show: boolean; participations: CrowdTaskParticipation[] };
};

type OwnProps = {
  match: match<{ operationId?: string; callOutId?: string }>;
  history: History;
};

type StoreProps = {
  operationId: string;
  operation: CrowdTaskOperation | null | undefined;
  selectedCallOut: CrowdTaskCallOut | null | undefined;
  locale: string;
};

type Props = OwnProps & WebDispatchProps & StoreProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const {
    match: {
      params: { operationId, callOutId },
    },
  } = ownProps;

  if (!operationId) {
    throw new Error('OperationDetailsCallOuts: operationId is not set. This should not happen.');
  }

  return {
    operationId,
    operation: state.operations.byId[operationId],
    selectedCallOut: callOutId ? state.callOuts.callOuts.byId[callOutId] : null,
    locale: state.i18n.currentLocale,
  };
}

const actions = ['RELEASE_CALL_OUT', 'CLOSE_CALL_OUT', 'DELETE_CALL_OUT'];
class OperationDetailsCallOuts extends React.Component<Props, State> {
  state = {
    showOperationMissingDataDialog: false,
    showReleaseDialog: false,
    showDeleteDialog: false,
    showCloseDialog: false,
    showTerminateDialog: false,
    confirmationDialog: { show: false, participations: [] },
    rejectionDialog: { show: false, participations: [] },
  };

  componentDidMount() {
    const { operationId, selectedCallOut, dispatch } = this.props;
    const taskId = selectedCallOut ? selectedCallOut.taskId : null;

    if (selectedCallOut && taskId) {
      dispatch(getParticipations({ operationId, taskId: taskId, callOutId: selectedCallOut.callOutId }));
    }
  }

  componentWillUpdate(nextProps: Props) {
    const { dispatch, operationId, selectedCallOut } = this.props;
    const curId = selectedCallOut ? selectedCallOut.callOutId : null;

    const newId = nextProps.selectedCallOut ? nextProps.selectedCallOut.callOutId : null;
    const newTaskId = nextProps.selectedCallOut ? nextProps.selectedCallOut.taskId : null;

    if (newId && newTaskId && curId !== newId) {
      dispatch(getParticipations({ operationId, taskId: newTaskId, callOutId: newId }));
    }
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { selectedCallOut, history, match, dispatch } = this.props;
    const {
      showReleaseDialog,
      showDeleteDialog,
      showTerminateDialog,
      showCloseDialog,
      confirmationDialog,
      rejectionDialog,
      showOperationMissingDataDialog,
    } = this.state;

    let dialogs: JSX.Element | null = null;

    if (selectedCallOut) {
      const { operationId } = this.props;
      const { taskId, callOutId } = selectedCallOut;
      const version = selectedCallOut.version;
      const callOutKey = { operationId, taskId, callOutId };

      dialogs = (
        <SelectedCallOutDialogs
          showDeleteDialog={showDeleteDialog}
          showReleaseDialog={showReleaseDialog}
          showCloseDialog={showCloseDialog}
          showTerminateDialog={showTerminateDialog}
          dispatch={dispatch}
          callOutKey={callOutKey}
          version={version}
          onChangeDeleteDialogVisibility={this.handleChangeDeleteDialogVisibility}
          onChangeReleaseDialogVisibility={this.handleChangeReleaseDialogVisibility}
          onChangeCloseDialogVisibility={this.handleChangeCloseDialogVisibility}
          onChangeTerminateDialogVisibility={this.handleChangeTerminateDialogVisibility}
        />
      );
    }

    return (
      <ApiCallStatusHandler
        apiCallNames={['GET_CALL_OUTS']}
        renderProgress={this.renderFullScreenProgress}
        renderErrorMessage={renderErrorMessage}>
        <div className={styles.container}>
          {/* TODO: Not working yet! Why?! */}
          {/* <CockpitApiErrors actions={['GET_CALL_OUTS']} /> */}
          <Timeline history={history} match={match} />
          <div>
            <Button className={styles.button} onClick={this.handleCreateCallOutClicked}>
              <FormattedMessage id="OperationDetailsCallOuts.editCallOut" defaultMessage="New Call Out" />
            </Button>
            {selectedCallOut && (
              <Button className={styles.button} onClick={this.handleFollowupClicked}>
                <FormattedMessage id="OperationDetailsCallOuts.followup" defaultMessage="New Followup" />
              </Button>
            )}
          </div>
          {selectedCallOut && (
            <div>
              <div className={styles.infoAndButtonContainer}>
                <SelectedCallOutInfo selectedCallOut={selectedCallOut} />
                <div className="rbyjsdahfasjdkfhkjhasdkf">
                  <SelectedCallOutButtons
                    selectedCallOut={selectedCallOut}
                    onReleaseClicked={this.handleChangeReleaseDialogVisibility}
                    onEditClicked={this.handleEditCallOutClicked}
                    onDeleteClicked={this.handleChangeDeleteDialogVisibility}
                    onCloseClicked={this.handleChangeCloseDialogVisibility}
                    onCloseParticipationsClicked={this.handleChangeTerminateDialogVisibility}
                  />
                </div>
              </div>
              <CockpitApiErrors actions={actions} />
              <ApiCallStatusHandler
                apiCallNames={['GET_PARTICIPATIONS']}
                renderProgress={renderProgress}
                renderErrorMessage={renderErrorMessage}>
                <ParticipationsList
                  onConfirm={this.handleConfirmParticipationsClicked}
                  onReject={this.handleRejectParticipationsClicked}
                />
              </ApiCallStatusHandler>
            </div>
          )}
          {dialogs}
          <ConfirmParticipationsModal
            open={confirmationDialog.show}
            participations={confirmationDialog.participations}
            onConfirm={this.handleConfirm}
            onCancel={this.handleConfirmCancelClicked}
          />
          <RejectParticipationReasonModal
            open={rejectionDialog.show}
            participations={rejectionDialog.participations}
            onCancel={this.handleRejectionCancelClicked}
            onReject={this.handleReject}
          />
          <OperationMissingDataDialog
            show={showOperationMissingDataDialog}
            onOkClicked={this.handleOperationMissingDataOkClicked}
          />
        </div>
      </ApiCallStatusHandler>
    );
  }

  renderFullScreenProgress = () => {
    return (
      <div className={styles.centeredFullscreenSpinnerWrapper}>
        <div className={styles.centeredFullscreenSpinner}>
          <CircularProgress className={styles.spinner} size={32} thickness={3} />
          <FormattedMessage id="CallOutsLoading.title" defaultMessage="Fetching Call Outs" />
        </div>
      </div>
    );
  };

  handleConfirm = (participations: CrowdTaskParticipation[]) => {
    if (!participations.length) {
      return;
    }

    const { selectedCallOut, operationId, dispatch } = this.props;

    this.setState({
      confirmationDialog: {
        show: false,
        participations: [],
      },
    });

    if (selectedCallOut) {
      const { taskId, callOutId } = selectedCallOut;
      dispatch(
        confirmParticipations(
          { operationId, taskId, callOutId },
          { staffMembers: participations.map(p => ({ staffMemberId: p.staffMemberId, version: p.version })) }
        )
      );
    }
  };

  handleReject = (participations: CrowdTaskParticipation[], reason: string) => {
    const { selectedCallOut, operationId, dispatch } = this.props;

    this.setState({
      rejectionDialog: {
        show: false,
        participations: [],
      },
    });

    if (selectedCallOut) {
      const { taskId, callOutId } = selectedCallOut;

      dispatch(
        rejectParticipations(
          { operationId, taskId, callOutId },
          {
            rejectionReason: reason,
            staffMembers: participations.map(p => ({ staffMemberId: p.staffMemberId, version: p.version })),
          }
        )
      );
    }
  };

  handleCreateCallOutClicked = () => {
    const { history, operationId, dispatch } = this.props;
    dispatch(destroy('editCallOut') as any);
    history.push(`/operations/${operationId}/callOuts/new`);
  };

  handleEditCallOutClicked = () => {
    const { selectedCallOut, operationId, history, dispatch } = this.props;

    if (selectedCallOut) {
      const { callOutId } = selectedCallOut;
      dispatch(destroy('editCallOut') as any);
      history.push(`/operations/${operationId}/callOuts/${callOutId}/edit`);
    }
  };

  handleFollowupClicked = () => {
    const { selectedCallOut, operationId, history, dispatch } = this.props;

    if (selectedCallOut) {
      const { callOutId } = selectedCallOut;
      dispatch(destroy('editCallOut') as any);
      history.push(`/operations/${operationId}/callOuts/${callOutId}/newFollowup`);
    }
  };

  handleConfirmParticipationsClicked = (participations: CrowdTaskParticipation[]) => {
    if (!participations.length) {
      return;
    }

    const { selectedCallOut, operation, dispatch } = this.props;

    let contactName: string | null | undefined = null;
    let contactPhoneNumber: string | null | undefined = null;

    if (operation) {
      contactName = operation.contactName;
      contactPhoneNumber = operation.contactPhoneNumber;
    }

    if (contactName && contactPhoneNumber) {
      dispatch(destroy('rejectParticipationReason') as any);
      if (selectedCallOut) {
        this.setState({
          confirmationDialog: {
            show: true,
            participations,
          },
        });
      }
    } else {
      this.setState({
        showOperationMissingDataDialog: true,
      });
    }
  };

  handleRejectParticipationsClicked = (participations: CrowdTaskParticipation[]) => {
    if (!participations.length) {
      return;
    }

    const { selectedCallOut, dispatch } = this.props;

    dispatch(destroy('rejectParticipationReason') as any);
    if (selectedCallOut) {
      this.setState({
        rejectionDialog: {
          show: true,
          participations,
        },
      });
    }
  };

  handleOperationMissingDataOkClicked = () => {
    this.setState({
      showOperationMissingDataDialog: false,
    });
  };

  handleRejectionCancelClicked = () => {
    this.setState({
      rejectionDialog: {
        show: false,
        participations: [],
      },
    });
  };

  handleConfirmCancelClicked = () => {
    this.setState({
      confirmationDialog: {
        show: false,
        participations: [],
      },
    });
  };

  handleChangeReleaseDialogVisibility = (show: boolean) => {
    const { showReleaseDialog } = this.state;
    this.setState({ showReleaseDialog: isNil(show) ? !showReleaseDialog : show });
  };

  handleChangeCloseDialogVisibility = (show?: boolean) => {
    const { showCloseDialog } = this.state;
    this.setState({ showCloseDialog: isNil(show) ? !showCloseDialog : show });
  };

  handleChangeDeleteDialogVisibility = (show?: boolean) => {
    const { showDeleteDialog } = this.state;
    this.setState({ showDeleteDialog: isNil(show) ? !showDeleteDialog : show });
  };

  handleChangeTerminateDialogVisibility = (show?: boolean) => {
    const { showTerminateDialog } = this.state;
    this.setState({ showTerminateDialog: isNil(show) ? !showTerminateDialog : show });
  };
}

export default connect(mapStateToProps)(OperationDetailsCallOuts);
