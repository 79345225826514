import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { licenses, isValidCopyrightUrl, License } from './thirdPartyLicenses';
import styles from './Copyright.module.css';

type Props = {
  linkClassName?: string;
};

export default class Copyright extends React.Component<Props> {
  render() {
    const { linkClassName } = this.props;
    const ccaUrl = 'https://www.cca.io/';
    return (
      <div className={styles.container}>
        <div>
          <FormattedMessage id="Copyright.ccaCopyright" defaultMessage="Copyright" />{' '}
          <a className={linkClassName} href={ccaUrl} target="_blank" rel="noopener noreferrer">
            Control Center Apps GmbH.
          </a>
        </div>
        <div>
          <FormattedMessage id="Copyright.ccaAllRights" defaultMessage="All rights reserved." />
        </div>
        <div>
          <FormattedMessage
            id="Copyright.registeredAt"
            defaultMessage="Registered at the Commercial Court Vienna, Reg. No. FN 397984 x"
          />
        </div>
        <h4 className={styles.header}>
          <FormattedMessage id="Copyright.mobileServices" defaultMessage="CCA Mobile Services Platform" />
        </h4>
        <div>
          <FormattedMessage id="Copyright.mobileServicesCopyright" defaultMessage="Copyright" />{' '}
          <a className={linkClassName} href={ccaUrl} target="_blank" rel="noopener noreferrer">
            Control Center Apps GmbH.
          </a>
        </div>
        <div>
          <FormattedMessage id="Copyright.mobileServicesAllRights" defaultMessage="All rights reserved." />
        </div>
        <h4 className={styles.header}>
          <FormattedMessage id="Copyright.openSource.shared" defaultMessage="Open source licenses" />
        </h4>
        <div>
          <FormattedMessage
            id="Copyright.thirdParty"
            defaultMessage="This application uses the following third party libraries:"
          />
        </div>
        {licenses.map(license => (
          <LicenseRow key={license.name} license={license} linkClassName={linkClassName} />
        ))}
      </div>
    );
  }
}

type LicenseRowProps = {
  license: License;
  linkClassName?: string;
};

class LicenseRow extends React.Component<LicenseRowProps> {
  render() {
    const { license, linkClassName } = this.props;
    const { name, licenseType, version, url } = license;

    return (
      <div>
        <h4 className={styles.header}>{name}</h4>
        <div>
          <FormattedMessage
            id="Copyright.licenseVersion.shared"
            defaultMessage="Version: {version}"
            values={{ version }}
          />
        </div>
        <div>
          <FormattedMessage
            id="Copyright.licenseType.shared"
            defaultMessage="License: {licenseType}"
            values={{ licenseType }}
          />
        </div>
        {isValidCopyrightUrl(url) && (
          <a className={linkClassName} href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        )}
      </div>
    );
  }
}
