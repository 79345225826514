import { AvailableFilterCriteria } from '../types';
import apiCall from '../apiCall';

// Returns AvailableFilterCriteria.
export function* fetchAvailableFilterCriteria(): Generator<any, any, any> {
  return yield* apiCall('availableFilterCriteria');
}

// Returns GetDeviceFilterResponse
export function* getDeviceDataFilter(deviceId: string): Generator<any, any, any> {
  return yield* apiCall(`devices/${deviceId}/serverFilter`);
}

export function* updateDeviceDataFilter(deviceId: string, criteria: AvailableFilterCriteria): Generator<any, any, any> {
  return yield* apiCall(`devices/${deviceId}/serverFilter`, {
    method: 'PUT',
    body: JSON.stringify(criteria),
  });
}
