import getHorizontalScrollbarHeight from '../../shared/utils/getHorizontalScrollbarHeight';

export default function scrollTo(containerRef: HTMLElement, ref: HTMLElement, hasHorizontalScrollbar?: boolean) {
  const verticalScrollbarOffset = hasHorizontalScrollbar ? getHorizontalScrollbarHeight(containerRef) : 0;

  const scrollContainerOffset = containerRef.getBoundingClientRect().top;
  const nodeOffsetTop = ref.offsetTop - scrollContainerOffset;
  const nodeOffsetHeight = ref.offsetHeight;
  const containerScrollTop = containerRef.scrollTop;
  const containerOffsetHeight = containerRef.offsetHeight;

  const insideUp = nodeOffsetTop >= containerScrollTop;
  const insideDown =
    nodeOffsetTop + nodeOffsetHeight <= containerScrollTop + containerOffsetHeight - verticalScrollbarOffset;

  if (!insideUp || !insideDown) {
    containerRef.scrollTop = insideUp
      ? nodeOffsetTop - containerOffsetHeight + nodeOffsetHeight + verticalScrollbarOffset
      : nodeOffsetTop;
  }
}
