import { some } from 'lodash-es';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import { ApiCallAction, ConnectionStatus } from '../../api/actions';

export type ApiCallStatusExtended = {
  readonly inProgress: boolean;
  readonly error: any;
  readonly pauseLongPollingMessages: boolean;
};

export type ApiCallDict = {
  [name: string]: ApiCallStatusExtended;
};

export type SyncState = {
  readonly status: ConnectionStatus;
  readonly firstSyncSucceeded: boolean;
  readonly error: any;
};
export type ApiState = {
  readonly apiCalls: ApiCallDict;
  readonly sync: SyncState;
};

const initialApiCallState = {
  inProgress: false,
  error: null,
};

// This reducer contains transient api calls progress state.
const initialState: ApiState = {
  apiCalls: {},
  sync: {
    status: 'disconnected',
    firstSyncSucceeded: false,
    error: null,
  },
};

type PartialReduxState = {
  api: ApiState;
};

export default function api(state: ApiState = initialState, action: ApiCallAction): ApiState {
  switch (action.type) {
    case 'API_CALL_STARTED':
    case 'API_CALL_SUCCESS':
    case 'API_CALL_ERROR':
      return { ...state, apiCalls: apiCallsReducer(state.apiCalls, action) };
    case 'SYNC_CONNECTED':
    case 'SYNC_DISCONNECTED':
    case 'SYNC_IN_PROGRESS':
    case 'SYNC_ERROR':
      return { ...state, sync: syncReducer(state.sync, action) };
    case 'AUTH_LOGOUT' as any:
      return initialState;
    default:
      return state;
  }
}

function apiCallsReducer(state: ApiCallDict, action: ApiCallAction): ApiCallDict {
  switch (action.type) {
    case 'API_CALL_STARTED':
      return {
        ...state,
        [action.name]: { inProgress: true, error: null, pauseLongPollingMessages: action.pauseLongPollingMessages },
      };
    case 'API_CALL_SUCCESS':
      return { ...state, [action.name]: { inProgress: false, error: null, pauseLongPollingMessages: false } };
    case 'API_CALL_ERROR':
      return { ...state, [action.name]: { inProgress: false, error: action.error, pauseLongPollingMessages: false } };
    default:
      return state;
  }
}

function syncReducer(state: SyncState, action: ApiCallAction): SyncState {
  switch (action.type) {
    case 'SYNC_CONNECTED':
      return { ...state, status: 'connected', firstSyncSucceeded: true };
    case 'SYNC_IN_PROGRESS':
      return { ...state, status: 'syncing' };
    case 'SYNC_DISCONNECTED':
      return { ...state, status: 'disconnected', error: null };
    case 'SYNC_ERROR':
      return { ...state, status: 'disconnected', error: action.error };
    default:
      return state;
  }
}

export function getApiCallStatus(state: PartialReduxState, name: string): ApiCallStatus {
  return state.api.apiCalls[name] || initialApiCallState;
}

export function longPollingMessagesPausedSelector(state: PartialReduxState) {
  return some(state.api.apiCalls, (status: ApiCallStatusExtended) => status.pauseLongPollingMessages);
}
