import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavbarLinkItem.module.css';

type NavbarInternalLinkItemProps = {
  title: React.ReactNode;
  path: string;
};

export function NavbarInternalLinkItem({ title, path }: NavbarInternalLinkItemProps) {
  return (
    <NavLink to={path} className={styles.item} activeClassName={styles.active}>
      {title}
    </NavLink>
  );
}

type NavbarExternalLinkItemProps = {
  title: React.ReactNode;
  url: string;
};

export function NavbarExternalLinkItem({ title, url }: NavbarExternalLinkItemProps) {
  return (
    <a href={url} className={styles.item}>
      {title}
    </a>
  );
}
