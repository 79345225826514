import { uniq } from 'lodash-es';

import { ApiCallAction, UserInfoAction } from '../../api/actions';
import { AdminAction } from '../actions';
import { GetTenantsResponse, GetDeviceFilterResponse } from '../../api/types';
import { WebReduxState } from '.';

export type Tenant = {
  tenantId: string;
  tenantKey: string;
  displayName: string;
  version: number;
  created: any;
  lastUpdated: any;
};

export type AdminTenantsState = {
  readonly tenants: GetTenantsResponse;
  readonly selectedTenantId: string;
  readonly tenantFilter: GetDeviceFilterResponse;
  readonly availableOptions: {
    regions: string[];
    lines: string[];
    incidentTypes: string[];
  };
};

const initialState = {
  tenants: [],
  tenantFilter: {
    regions: [],
    lines: [],
    incidentTypes: [],
  },
  availableOptions: {
    regions: [],
    lines: [],
    incidentTypes: [],
  },
  selectedTenantId: '',
};

export default function admin(
  state: AdminTenantsState = initialState,
  action: AdminAction | ApiCallAction | UserInfoAction
): AdminTenantsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_TENANTS':
          return { ...state, tenants: action.result };
        case 'GET_TENANT_FILTER':
          return { ...state, tenantFilter: action.result };
        case 'FETCH_AVAILABLE_DATAFILTER_CRITERIA':
          return {
            ...state,
            availableOptions: {
              lines: uniq([...state.availableOptions.lines, ...action.result.lines]),
              regions: uniq([...state.availableOptions.regions, ...action.result.regions]),
              incidentTypes: uniq([...state.availableOptions.incidentTypes, ...action.result.incidentTypes]),
            },
          };
        default:
          return state;
      }

    case 'SET_TENANT_ID':
      return { ...state, selectedTenantId: action.tenantId };
    case 'SET_TENANT_DATA_FILTER_CRITERIA': {
      return { ...state, tenantFilter: { ...state.tenantFilter, [action.dataFilterType]: action.criteria } };
    }
    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    default:
      return state;
  }
}

export function tenantIdSelector(state: WebReduxState): string {
  return state.admin.tenants.selectedTenantId;
}

export function tenantAdminSelector(state: WebReduxState): Tenant | null | undefined {
  const { selectedTenantId, tenants } = state.admin.tenants;
  tenants.find(tenant => tenant.tenantId === selectedTenantId);

  return tenants.find(tenant => tenant.tenantId === selectedTenantId);
}
