import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../api/components/Button';

import { canSeeIncidentTypeSelector } from '../../api/reducers/config';
import hasChatSelector from '../../api/selectors/hasChat';
import hasRoleSelector from '../../api/selectors/hasRole';
import { InformRoles } from '../../api/roles';
import { ChatMessage } from '../../api/types';
import { Incident } from '../../api/incidentsTypes';
import Chat from '../../api/components/Chat';
import { ReduxState } from '../../common/reducers';
import replacesIncidentsSelector from '../../common/selectors/replacesIncidents';
import { compareIncidentData } from '../../common/utils/compareIncidentData';
import { ApiDispatch, submitIncidentChatMessages } from '../actions';
import IncidentDetailsLeft from './IncidentDetailsLeft';
import IncidentDetailsRight from './IncidentDetailsRight';
import styles from './IncidentDetail.module.css';

type OwnProps = {
  incident: Incident;
  onLinkedIncidentPressed: (id: string) => void;
  replacedByIncident: Incident | null;
};

type StoreProps = {
  replacesIncidents: Incident[] | null;
  hasChat: boolean;
  hasRole: (role: string) => boolean;
  canSeeIncidentType: boolean;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: ReduxState, ownProps: OwnProps): StoreProps {
  const { incident } = ownProps;

  return {
    replacesIncidents: replacesIncidentsSelector(state.inform.incidents.byId)[incident.incidentId],
    hasChat: hasChatSelector(state)(incident.threadId),
    hasRole: hasRoleSelector(state),
    canSeeIncidentType: canSeeIncidentTypeSelector(state),
  };
}

function IncidentDetail({
  incident,
  hasRole,
  replacesIncidents,
  replacedByIncident,
  onLinkedIncidentPressed,
  canSeeIncidentType,
  hasChat,
}: Props) {
  const leftDivRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [maxChatWindowHeight, setMaxChatWindowHeight] = React.useState(0);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  React.useEffect(() => {
    setMaxChatWindowHeight(leftDivRef.current?.clientHeight ?? 0);
    setFeedbackOpen(hasChat);
  }, [hasChat]);

  const handleOpenFeedback = () => setFeedbackOpen(true);

  const handleSubmitChatMessage = (dispatch: ApiDispatch, message: ChatMessage) => {
    const { incidentId, threadId } = incident;
    dispatch(submitIncidentChatMessages(incidentId, threadId, [message]));
  };

  const data = replacedByIncident ? replacedByIncident.currentData : incident.currentData;
  const fieldInfos = compareIncidentData(data, incident.lastReadData);

  return (
    <tr className={styles.incidentDetailRow}>
      <td className={styles.incidentDetail} colSpan={10}>
        <div className={styles.flexRow}>
          <div className={styles.col1}>
            <div ref={leftDivRef}>
              <IncidentDetailsLeft
                incidentId={incident.incidentId}
                created={incident.created}
                lastUpdated={incident.lastUpdated}
                incidentDate={incident.incidentDate}
                incidentData={data}
                fieldInfos={fieldInfos}
                replacedById={incident.currentData.replacedById}
                replacedByIncident={replacedByIncident}
                replacesIncidents={replacesIncidents}
                onLinkedIncidentPressed={onLinkedIncidentPressed}
                canSeeIncidentType={canSeeIncidentType}
              />
            </div>
          </div>
          <div className={feedbackOpen ? styles.col2 : styles.col2Big}>
            {!feedbackOpen && hasRole(InformRoles.FEEDBACK_WRITE) && (
              <div className={styles.openFeedback}>
                <Button onClick={handleOpenFeedback}>
                  <FormattedMessage id="IncidentDetail.openFeedback" defaultMessage="Start Chat" />
                </Button>
              </div>
            )}
            <IncidentDetailsRight incidentData={data} fieldInfos={fieldInfos} />
          </div>
          <div className={styles.colBreak} />
          {feedbackOpen && (
            <div className={styles.col3}>
              <Chat
                chatThreadId={incident.threadId}
                maxHeight={maxChatWindowHeight}
                hasWritePermission={hasRole(InformRoles.FEEDBACK_WRITE)}
                onSubmitChatMessage={handleSubmitChatMessage}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default connect(mapStateToProps)(IncidentDetail);

export { IncidentDetail as UnconnectedIncidentDetail };
