import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './NavbarAppItem.module.css';

type Props = {
  appName: string;
  appIconUrl: string;
};

export default function NavbarAppItem({ appName, appIconUrl }: Props) {
  return (
    <>
      <Link to="/">
        <img src={appIconUrl} alt="app-icon" />
      </Link>
      <Link to="/" className={styles.appName}>
        {appName}
      </Link>
    </>
  );
}
