import { CallOutKey } from '../../api/crowdTask/cockpit/callOuts';
import { PostCrowdTaskConfirmParticipationsRequest, PostCrowdTaskRejectParticipationsRequest } from '../../api/types';

export type ParticipationsAction =
  | { type: 'GET_PARTICIPATIONS'; callOutKey: CallOutKey }
  | {
      type: 'CONFIRM_PARTICIPATIONS';
      callOutKey: CallOutKey;
      request: PostCrowdTaskConfirmParticipationsRequest;
    }
  | {
      type: 'REJECT_PARTICIPATIONS';
      callOutKey: CallOutKey;
      request: PostCrowdTaskRejectParticipationsRequest;
    }
  | { type: 'TERMINATE_PARTICIPATIONS'; callOutKey: CallOutKey }
  | { type: 'SET_SELECTED_CALL_OUT_ID'; callOutId: string | null | undefined };

export function getParticipations(callOutKey: CallOutKey): ParticipationsAction {
  return { type: 'GET_PARTICIPATIONS', callOutKey };
}

export function confirmParticipations(
  callOutKey: CallOutKey,
  request: PostCrowdTaskConfirmParticipationsRequest
): ParticipationsAction {
  return { type: 'CONFIRM_PARTICIPATIONS', callOutKey, request };
}

export function rejectParticipations(
  callOutKey: CallOutKey,
  request: PostCrowdTaskRejectParticipationsRequest
): ParticipationsAction {
  return { type: 'REJECT_PARTICIPATIONS', callOutKey, request };
}

export function terminateOpenParticipations(callOutKey: CallOutKey): ParticipationsAction {
  return { type: 'TERMINATE_PARTICIPATIONS', callOutKey };
}

export function setSelectedCallOutId(callOutId: string | null | undefined): ParticipationsAction {
  return { type: 'SET_SELECTED_CALL_OUT_ID', callOutId };
}
