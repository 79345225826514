import { keyBy, omit } from 'lodash-es';

import { Action } from '../actions';
import { CrowdTaskOperationStats, CrowdTaskOperationWithStats } from '../../api/types';

export type OperationStatsState = {
  [operationId: string]: CrowdTaskOperationStats;
};

export default function operationStats(state: OperationStatsState = {}, action: Action): OperationStatsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_OPERATIONS': {
          const results: CrowdTaskOperationWithStats[] = action.result;
          const statsArray = results.map(os => os.stats);

          return keyBy(statsArray, stats => stats.operationId);
        }
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'CLEANUP':
      return {};
    default:
      return state;
  }
}

function handleLongPollingMessage(state, message) {
  if (message.type !== 'CrowdTaskOperationStats') {
    return state;
  }

  const { action, payload } = message;

  switch (action) {
    case 'UPSERT': {
      const stats: CrowdTaskOperationStats = payload;
      return { ...state, [stats.operationId]: stats };
    }
    case 'DELETE': {
      const id: string = payload;
      return omit(state, [id]);
    }
    default:
      return state;
  }
}
