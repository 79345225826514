import { reduce } from 'lodash-es';

import generateUuid from '../../shared/utils/generateUuid';
import { NormalizedCollection } from '../../shared/normalizedCollection';
import { ChatMessage } from '../../api/types';

type CreateNewChatMessageParams = {
  threadId: string;
  message: string;
  author: string;
  authorPhoneNumber?: string;
  submitted?: Date;
};

export function createNewChatMessage({
  threadId,
  author,
  authorPhoneNumber,
  message,
  submitted,
}: CreateNewChatMessageParams): ChatMessage {
  return {
    // This will be sent to the server as the clientMessageId. It must be a UUID.
    messageId: generateUuid(),
    threadId,
    submitted: (submitted ?? new Date()).toISOString(),
    author: author,
    authorId: author,
    authorType: 'USER', // will be filled out correctly later by the server
    authorPhoneNumber,
    you: true,
    message: message,
  };
}

export type ChatThread = {
  messages: NormalizedCollection<ChatMessage>;
  lastMessageId: string | null | undefined;
};

export function makeChatThread(messages: NormalizedCollection<ChatMessage>): ChatThread {
  return {
    // Don't order here, we do it in the UI components, as we need to
    // sort differently for web and mobile, and also across two reducers
    // (chat and chatPending).
    // messages: orderCollection(messages, (m: ChatMessage) => m.submitted, 'desc'),
    messages,
    lastMessageId: findLastMessageId(messages),
  };
}

function findLastMessageId(messages: NormalizedCollection<ChatMessage>) {
  const { ids, byId } = messages;
  return reduce(
    ids.filter(id => !byId[id].you),
    (a, b) => {
      return new Date(byId[a].submitted) > new Date(byId[b].submitted) ? a : b;
    }
  );
}
