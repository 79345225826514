import colorStyle from '../css/colors.module.css';

export default function applyTintColor(tintColor: string) {
  const styleTag = document.createElement('style');
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(styleTag);

  const { sheet } = styleTag;

  if (sheet instanceof CSSStyleSheet) {
    // color
    sheet.insertRule(
      `.tint, .redLink, .redLink:link, .redLink:visited, .redLink:hover, .redLink:active, .settingsRow h4,
      .tabBarButton, h4 > a, h4 > a:hover, .outlineRed, .outlineRed:focus, .outlineRedSmall,
      input[type=checkbox]:checked + label:after, input[type=radio]:checked + label:after {
        color: ${tintColor};
      }`,
      0
    );
    // border-color
    sheet.insertRule(
      `.outlineRed, .outlineRedBig, .filterAlertBox, .alertBox {
        border-color: ${tintColor} !important;
      }`,
      0
    );
    // background-color
    sheet.insertRule(
      `.tintIcon, .outlineRed:hover,
      .filterAlertBox, .alertBox, .tabBarButton:hover, .tabBarButton.active {
        background-color: ${tintColor} !important;
      }`,
      0
    );
    // special rules
    sheet.insertRule(
      `textarea:focus, input:focus, input[type]:focus, .form-control:focus {
        border-color: ${tintColor};
        box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px ${tintColor};
      }`,
      0
    );
    sheet.insertRule(
      `.outlineRedSmall:hover {
        color: ${tintColor} !important;
        border-bottom: ${tintColor} solid 1px;
      }`,
      0
    );
    sheet.insertRule(
      `input[type=checkbox] + label:hover, input[type=checkbox]:checked + label,
      input[type=radio] + label:hover, input[type=radio]:checked + label {
        border: solid 1px ${tintColor};
        box-shadow: 0 0 1px ${tintColor};
      }`,
      0
    );
    sheet.insertRule(
      `.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        border-top-color: ${tintColor};
      }`,
      0
    );
    // Overwrite CSS module colors.
    // TODO: Only use the rules below and get rid of the ones above completely.
    sheet.insertRule(`.${colorStyle.tint} { color: ${tintColor}; }`, 0);
    sheet.insertRule(`.${colorStyle.tintBackground} { background-color: ${tintColor}; }`, 0);
    sheet.insertRule(`.${colorStyle.tintFill} { fill: ${tintColor}; }`, 0);
    sheet.insertRule(`.${colorStyle.tintBorder} { border-color: ${tintColor}; }`, 0);
    sheet.insertRule(
      `.${colorStyle.tintShadow} {
      box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px ${tintColor};
    }`,
      0
    );
  }
}
