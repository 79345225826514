import { WebAction } from '../actions';

export type NewOperationState = {
  readonly lastCreatedOperationId: string | null | undefined;
  readonly stateFilter: string;
  readonly selectedIncidentId: string | null | undefined;
  readonly selectedLocationCode: string | null | undefined;
};

const initialState = {
  lastCreatedOperationId: null,
  stateFilter: '__all__',
  selectedIncidentId: null,
  selectedLocationCode: null,
};

export default function newOperation(state: NewOperationState = initialState, action: WebAction): NewOperationState {
  switch (action.type) {
    case 'SET_NEW_OPERATION_LOCATION_FILTER':
      return { ...state, stateFilter: action.stateFilter };
    case 'SELECT_NEW_OPERATION_LOCATION':
      return { ...state, selectedLocationCode: action.locationCode };
    case 'SELECT_NEW_OPERATION_INCIDENT':
      return { ...state, selectedIncidentId: action.incidentId };
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'POST_OPERATION':
          return {
            ...initialState,
            lastCreatedOperationId: action.result.operationId,
          };
        default:
          return state;
      }

    case '@@redux-form/DESTROY':
      if (action.meta && action.meta.form && action.meta.form.includes('newOperation')) {
        return initialState;
      }

      return state;
    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    default:
      return state;
  }
}
