import { DefaultPushSound } from '../sounds';
import { Action } from '../actions';

export type PushSettings = {
  readonly notificationsEnabled: boolean; // SideMenu - Enable/Disable Notifications
  readonly vibrationEnabled: boolean;
  readonly soundFileName: string;
};

export type SettingsState = {
  readonly feedbackPhoneNumber: string;
  readonly push: PushSettings;
};

const initialState: SettingsState = {
  feedbackPhoneNumber: '',
  push: {
    notificationsEnabled: true, // SideMenu - Enable/Disable Notifications
    vibrationEnabled: true,
    soundFileName: DefaultPushSound.fileName,
  },
};

export default function settings(state: SettingsState = initialState, action: Action): SettingsState {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_ENABLED':
      return { ...state, push: { ...state.push, notificationsEnabled: action.enabled } };
    case 'SET_VIBRATION_ENABLED':
      return { ...state, push: { ...state.push, vibrationEnabled: action.enabled } };
    case 'SET_PUSH_SOUND':
      return { ...state, push: { ...state.push, soundFileName: action.fileName } };
    case 'SET_FEEDBACK_PHONE_NUMBER':
      return { ...state, feedbackPhoneNumber: action.phoneNumber };
    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    default:
      return state;
  }
}
