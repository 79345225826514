import { createSelector } from 'reselect';

import { WebReduxState } from '../reducers';

const tenantDataFilterIncidentTypeEmptySelector: (state: WebReduxState) => boolean = createSelector(
  state => state.admin.tenants.tenantFilter,
  criteria => criteria.incidentTypes.length === 0
);

export default tenantDataFilterIncidentTypeEmptySelector;
