import { Action } from '../actions';
import { IncidentStatus } from '../../api/types';

// Removed covariance annotation from lines, locations, regions and incidentTypes to prevent flow
// from assuming that we change the filterType in SET_FILTER_CRITERIA
export type FilterState = {
  lines: string[];
  locations: string[];
  regions: string[];
  incidentTypes: string[];
  readonly incidentStatus: IncidentStatus | null | undefined; // null means all states allowed
  readonly remID: string;
};

const initialState: FilterState = {
  lines: [],
  locations: [],
  regions: [],
  incidentTypes: [],
  incidentStatus: null, // null means all states allowed
  remID: '',
};

export default function filter(state: FilterState = initialState, action: Action): FilterState {
  switch (action.type) {
    case 'SET_FILTER_INCIDENT_STATUS':
      return { ...state, incidentStatus: action.status };
    case 'SET_FILTER_CRITERIA':
      return { ...state, [action.filterType]: action.criteria };
    case 'SET_FILTER_REMID':
      return { ...state, remID: action.remID };
    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    case 'RESET_FILTER':
      return initialState;
    default:
      return state;
  }
}
