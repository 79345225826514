import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';

import Menu, { MenuComponentRenderFunction } from './Menu';
import styles from './Navbar.module.css';

type Props = {
  renderNavbarItems: (routerContext: RouteComponentProps) => React.ReactNode;
  renderMenuButton: (
    menuOpen: boolean,
    toggleMenu: (event: React.MouseEvent<HTMLDivElement>) => void
  ) => React.ReactNode;
  renderMenuHeader?: MenuComponentRenderFunction;
  renderMenuBody?: MenuComponentRenderFunction;
  renderMenuFooter?: MenuComponentRenderFunction;
};

type State = {
  menuOpen: boolean;
  anchorElement: EventTarget | null;
};

export default class Navbar extends React.Component<Props, State> {
  state = {
    menuOpen: false,
    anchorElement: null,
  };

  render() {
    return <Route render={this.renderContent} />;
  }

  renderContent = (routerContext: RouteComponentProps) => {
    const { renderNavbarItems, renderMenuHeader, renderMenuButton, renderMenuBody, renderMenuFooter } = this.props;
    const { menuOpen, anchorElement } = this.state;

    return (
      <div className={styles.navbar}>
        <div className={styles.left}>{renderNavbarItems(routerContext)}</div>
        <div className={styles.right}>
          {renderMenuButton(menuOpen, this.handleMenuButtonClicked)}
          <Menu
            anchorElement={anchorElement}
            open={menuOpen}
            onRequestClose={this.handleMenuClose}
            renderMenuHeader={renderMenuHeader}
            renderMenuBody={renderMenuBody}
            renderMenuFooter={renderMenuFooter}
          />
        </div>
      </div>
    );
  };

  handleMenuButtonClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    const anchorElement = event.currentTarget;

    this.setState(state => ({
      menuOpen: !state.menuOpen,
      anchorElement,
    }));
  };

  handleMenuClose = () => {
    this.setState({ menuOpen: false });
  };
}
