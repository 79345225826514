import { GetChatMessagesOptions } from '../../api/crowdTask';
import { ChatMessage } from '../../api/types';

export type ChatAction =
  | { type: 'GET_OPERATION_CHAT_MESSAGES'; operationId: string; options?: GetChatMessagesOptions }
  | { type: 'SUBMIT_INCIDENT_CHAT_MESSAGES'; incidentId: string; threadId: string; messages: ChatMessage[] }
  | { type: 'SUBMIT_OPERATION_CHAT_MESSAGES'; operationId: string; threadId: string; messages: ChatMessage[] }
  | { type: 'SUBMIT_FORUM_CHAT_MESSAGES'; incidentId: string; threadId: string; messages: ChatMessage[] }
  | { type: 'EDIT_CHAT_TEXT'; threadId: string; value: string }
  | { type: 'SET_CHAT_THREAD_READ'; threadId: string; lastReadMessageId: string }
  | { type: 'SYNC_CHAT_MESSAGES'; threadIds: string[] };

// API call result type augmented with pendingMessageId for the reducers.
export type PostChatMessageResult = {
  pendingMessageId: string;
  chatMessage: ChatMessage;
};

export function getOperationChatMessages(operationId: string, options?: GetChatMessagesOptions): ChatAction {
  return { type: 'GET_OPERATION_CHAT_MESSAGES', operationId, options };
}

export function submitIncidentChatMessages(incidentId: string, threadId: string, messages: ChatMessage[]): ChatAction {
  return { type: 'SUBMIT_INCIDENT_CHAT_MESSAGES', incidentId, threadId, messages };
}

export function submitOperationChatMessages(
  operationId: string,
  threadId: string,
  messages: ChatMessage[]
): ChatAction {
  return { type: 'SUBMIT_OPERATION_CHAT_MESSAGES', operationId, threadId, messages };
}

export function submitForumChatMessages(incidentId: string, threadId: string, messages: ChatMessage[]): ChatAction {
  return { type: 'SUBMIT_FORUM_CHAT_MESSAGES', incidentId, threadId, messages };
}

export function editChatText(threadId: string, value: string): ChatAction {
  return { type: 'EDIT_CHAT_TEXT', threadId, value };
}

export function setChatThreadRead(threadId: string, lastReadMessageId: string): ChatAction {
  return { type: 'SET_CHAT_THREAD_READ', threadId, lastReadMessageId };
}

export function syncChatMessages(threadIds: string[]): ChatAction {
  return { type: 'SYNC_CHAT_MESSAGES', threadIds };
}
