export type App = 'inform' | 'staffMember' | 'coordinator';

export type AppConfig = {
  readonly serverUrl: string;
  readonly app: App; // relevant for mobile app only
  readonly isTablet: boolean; // relevant for mobile app only
};

// The serverUrl defaults to an empty string which is correct for the web app.
// It is overridden on startup for the native apps.
const initialState: AppConfig = {
  serverUrl: '',
  app: 'inform',
  isTablet: false,
};

type PartialReduxState = {
  appConfig: AppConfig;
};

export default function appConfigReducer(state: AppConfig = initialState, _action: any): AppConfig {
  return state;
}

export function appSelector(state: PartialReduxState): App {
  return state.appConfig.app;
}

export function serverUrlSelector(state: PartialReduxState): string {
  return state.appConfig.serverUrl;
}
