import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { ApplicationOptions } from '../../api/ApplicationOptions';
import { notifyPersistorBootstrapped } from '../../shared/utils/persist';
import { onSagaError } from '../../shared/utils/sagaErrorHandler';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware({ onError: onSagaError });

const storeEnhancer = applyMiddleware(sagaMiddleware);

export default function configureStore(initialState: Object, shouldPersist: boolean, options: ApplicationOptions) {
  const store = createStore(rootReducer, initialState, storeEnhancer);
  sagaMiddleware.run(rootSaga, store.dispatch, options);

  if (shouldPersist) {
    // Persist store using redux-persist.
    const persistor = persistStore(store);
    notifyPersistorBootstrapped(persistor, store.dispatch);
  }

  return store;
}
