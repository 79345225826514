import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';

import getMuiThemeForTintColor from '../../shared/utils/getMuiThemeForTintColor';
import { clientConfigSelector } from '../../api/reducers/config';
import { messagesSelector } from '../../api/selectors/i18n';
import ErrorBoundary from '../../shared/errorBoundary/ErrorBoundary';
import { WebReduxState, useSelector } from '../reducers';
import Root from './Root';
import NotificationAudio from './NotificationAudio';
import { shallowEqual } from 'react-redux';

function dataSelector(state: WebReduxState) {
  return {
    currentLocale: state.i18n.currentLocale,
    messages: messagesSelector(state),
    tintColor: clientConfigSelector(state).theme.tintColor,
  };
}

function ignore() {}

export default function RootWrapper() {
  const { currentLocale, messages, tintColor } = useSelector(dataSelector, shallowEqual);
  const muiTheme = getMuiThemeForTintColor(tintColor);

  return (
    <IntlProvider key={currentLocale} locale={currentLocale} messages={messages} onError={ignore}>
      <MuiThemeProvider theme={muiTheme}>
        <ErrorBoundary>
          <>
            <NotificationAudio />
            <Root />
          </>
        </ErrorBoundary>
      </MuiThemeProvider>
    </IntlProvider>
  );
}
