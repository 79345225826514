import { NormalizedCollection, emptyCollection, createCollection, addValues } from '../../shared/normalizedCollection';
import applyLongPollingUpdate from '../../api/utils/applyLongPollingUpdate';

import { WebAction } from '../actions';
import { CrowdTaskCallOut, CrowdTaskCallOutWithStats } from '../../api/types';

export type CallOutsState = {
  synced: boolean;
  selectedOperationId: string | null | undefined; // Memorize to know which callOuts need to be stored here
  callOuts: NormalizedCollection<CrowdTaskCallOut>;
};

const initialState = {
  synced: false,
  selectedOperationId: null,
  callOuts: emptyCollection,
};

const idSelector = (o: CrowdTaskCallOut) => o.callOutId;

export default function callOuts(state: CallOutsState = initialState, action: WebAction) {
  switch (action.type) {
    case 'API_CALL_SUCCESS': {
      switch (action.name) {
        case 'GET_CALL_OUTS': {
          const results: CrowdTaskCallOutWithStats[] = action.result;
          const theCallOuts = results.map(r => r.callOut);

          if (theCallOuts.length > 0 && theCallOuts[0].operationId !== state.selectedOperationId) {
            return state;
          }

          return { ...state, callOuts: createCollection(theCallOuts, idSelector), synced: true };
        }
        case 'POST_CALL_OUT':
          return {
            ...state,
            callOuts: addValues(state.callOuts, [action.result], idSelector),
            lastCreatedCallOutId: action.result.callOutId,
          };
        case 'RELEASE_CALL_OUT':
          return {
            ...state,
            callOuts: {
              ...state.callOuts,
              byId: { ...state.callOuts.byId, [action.result.callOutId]: action.result },
            },
          };
        default:
          return state;
      }
    }
    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'GET_CALL_OUTS':
      return { callOuts: emptyCollection, selectedOperationId: action.operationId, synced: false };
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

function handleLongPollingMessage(state, message) {
  if (message.type !== 'CrowdTaskCallOut') {
    return state;
  }

  // Don't accept UPSERTs for other operation ids.
  const { action, payload } = message;
  if (action === 'UPSERT' && (payload as CrowdTaskCallOut).operationId !== state.selectedOperationId) {
    return state;
  }

  return {
    ...state,
    callOuts: applyLongPollingUpdate(state.callOuts, message, idSelector),
  };
}
