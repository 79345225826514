import {
  NormalizedCollection,
  createCollection,
  emptyCollection,
  addValues,
  removeId,
} from '../../shared/normalizedCollection';
import applyLongPollingUpdate from '../../api/utils/applyLongPollingUpdate';
import { Action } from '../actions';
import { CrowdTaskOperation, CrowdTaskOperationWithStats } from '../../api/types';

export type OperationsState = NormalizedCollection<CrowdTaskOperation>;

const idSelector = (o: CrowdTaskOperation) => o.operationId;

export default function operations(state: OperationsState = emptyCollection, action: Action): OperationsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_OPERATIONS': {
          const results: CrowdTaskOperationWithStats[] = action.result;
          const theOperations = results.map(r => r.operation);

          return createCollection(theOperations, idSelector);
        }
        case 'POST_OPERATION':
          return addValues(state, [action.result], idSelector);
        case 'DELETE_OPERATION':
        case 'TERMINATE_OPERATION':
          return removeId(state, action.result.operationId);
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'CLEANUP':
      return emptyCollection;
    default:
      return state;
  }
}

function handleLongPollingMessage(state, message) {
  if (message.type !== 'CrowdTaskOperation') {
    return state;
  }

  return applyLongPollingUpdate(state, message, idSelector);
}
