import { IncidentId } from '../../api/incidentsTypes';

export type IncidentsAction =
  | { type: 'FETCH_INCIDENTS' }
  | { type: 'TOGGLE_FAVORITE'; incidentId: IncidentId }
  | { type: 'INCIDENT_READ'; incidentId: IncidentId }
  | { type: 'SET_INCIDENT_SELECTED'; incidentId: IncidentId; selected: boolean }
  | { type: 'RESET_INCIDENTS_STATE' } // Reset
  | { type: 'RESET_OFFLINE_CACHE' }
  | { type: 'RESET_TRANSACTION_ID' };

export function fetchIncidents(): IncidentsAction {
  return { type: 'FETCH_INCIDENTS' };
}

export function toggleFavorite(incidentId: IncidentId): IncidentsAction {
  return { type: 'TOGGLE_FAVORITE', incidentId };
}

export function incidentRead(incidentId: IncidentId): IncidentsAction {
  return { type: 'INCIDENT_READ', incidentId };
}

export function setIncidentSelected(incidentId: IncidentId, selected: boolean): IncidentsAction {
  return { type: 'SET_INCIDENT_SELECTED', incidentId, selected };
}

export function resetIncidentsState(): IncidentsAction {
  return { type: 'RESET_INCIDENTS_STATE' };
}

// Reset
export function resetOfflineCache(): IncidentsAction {
  return { type: 'RESET_OFFLINE_CACHE' };
}

export function resetTransactionId(): IncidentsAction {
  return { type: 'RESET_TRANSACTION_ID' };
}
