import * as React from 'react';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { History, Location, Action as HistoryAction } from 'history';
import { Prompt } from 'react-router-dom';
import { isInvalid, isDirty } from 'redux-form';
import { createSelector } from 'reselect';
import { values } from 'lodash-es';

import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { CrowdTaskOperation } from '../../api/types';
import { intlSelector } from '../../api/selectors/i18n';
import {
  setNewOperationLocationFilter,
  selectNewOperationLocation,
  selectNewOperationIncident,
  postOperation,
  WebDispatchProps,
} from '../actions';
import { Incident, IncidentData } from '../../api/incidentsTypes';
import { NewOperationState } from '../reducers/newOperation';
import { WebReduxState } from '../reducers';
import iconNameSelector from '../../common/selectors/iconName';
import {
  statesWithLocationCountsSelector,
  sortedLocationCodesSelector,
  StateWithLocationCount,
  LocationsMap,
} from '../reducers/locations';
import sortedCrowdTaskIncidentsSelector from '../selectors/sortedCrowdTaskIncidents';
import styles from './NewOperation.module.css';
import NewOperationStatesDropDown from './NewOperationStatesDropDown';
import NewOperationLeftPanel from './NewOperationLeftPanel';
import NewOperationRightPanel from './NewOperationRightPanel';

type OwnProps = {
  history: History;
};

type StoreProps = {
  incidents: Incident[];
  locationsByCode: LocationsMap;
  statesWithLocationCount: StateWithLocationCount[];
  sortedLocationCodes: string[];
  usedLocationCodes: Set<string>;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  getIconName: (data: IncidentData) => string;
  newOperationState: NewOperationState;
  formInvalid: boolean;
  formDirty: boolean;
  lastCreatedOperationId: string | null | undefined;
  intl: IntlShape;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    incidents: sortedCrowdTaskIncidentsSelector(state),
    locationsByCode: state.locations.byId,
    sortedLocationCodes: sortedLocationCodesSelector(state),
    usedLocationCodes: usedLocationCodesSelector(state),
    statesWithLocationCount: statesWithLocationCountsSelector(state),
    dataFilterTypes: dataFilterTypesSelector(state),
    getIconName: iconNameSelector(state),
    newOperationState: state.ui.newOperation,
    formInvalid: isInvalid('newOperation')(state),
    formDirty: isDirty('newOperation')(state),
    lastCreatedOperationId: state.ui.newOperation.lastCreatedOperationId,
    intl: intlSelector(state),
  };
}

const usedLocationCodesSelector: (state: WebReduxState) => Set<string> = createSelector(
  state => state.operations,
  operations => {
    return new Set(
      operations
        ? values(operations.byId)
            .filter((o: CrowdTaskOperation) => o.status !== 'CLOSED')
            .map((o: CrowdTaskOperation) => o.locationCode)
        : []
    );
  }
);

class NewOperation extends React.Component<Props> {
  isDirty() {
    const { formDirty, newOperationState } = this.props;
    const { selectedLocationCode, selectedIncidentId } = newOperationState;

    return formDirty || selectedLocationCode !== null || selectedIncidentId !== null;
  }

  render() {
    const {
      sortedLocationCodes,
      usedLocationCodes,
      statesWithLocationCount,
      incidents,
      dataFilterTypes,
      getIconName,
      newOperationState,
      formInvalid,
      intl,
      locationsByCode,
    } = this.props;
    const { selectedLocationCode, selectedIncidentId, stateFilter } = newOperationState;

    return (
      <>
        <Prompt when={this.isDirty()} message={this.getCancelPrompt} />
        <div className={styles.header}>
          <h3>
            <FormattedMessage id="NewOperation.newOperation" defaultMessage="New Operation" />
          </h3>
          <NewOperationStatesDropDown
            intl={intl}
            stateFilter={stateFilter}
            sortedLocationCodes={sortedLocationCodes}
            statesWithLocationCount={statesWithLocationCount}
            onStateChange={this.handleStateChange}
          />
        </div>
        <div className={styles.container}>
          <NewOperationLeftPanel
            newOperationState={newOperationState}
            sortedLocationCodes={sortedLocationCodes}
            usedLocationCodes={usedLocationCodes}
            locationsByCode={locationsByCode}
            onLocationChange={this.handleLocationChange}
          />
          <NewOperationRightPanel
            incidents={incidents}
            selectedIncidentId={selectedIncidentId}
            dataFilterTypes={dataFilterTypes}
            getIconName={getIconName}
            saveDisabled={formInvalid || !selectedLocationCode || !selectedIncidentId}
            onIncidentChange={this.handleIncidentChange}
            onSaveClicked={this.handleSave}
            onCancelClicked={this.handleCancel}
            onApiCallSuccess={this.handleSuccess}
          />
        </div>
      </>
    );
  }

  handleStateChange = (event: React.ChangeEvent<HTMLInputElement>, _child: React.ReactNode) => {
    this.props.dispatch(setNewOperationLocationFilter(event.target.value));
  };

  handleLocationChange = (locationCode: string) => {
    this.props.dispatch(selectNewOperationLocation(locationCode));
  };

  handleIncidentChange = (incident: Incident) => {
    this.props.dispatch(selectNewOperationIncident(incident.incidentId));
  };

  handleSave = () => {
    this.props.dispatch(postOperation());
  };

  handleSuccess = () => {
    const { history, lastCreatedOperationId } = this.props;

    if (lastCreatedOperationId) {
      history.replace(`/operations/${lastCreatedOperationId}`);
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.replace('/operations');
  };

  getCancelPrompt = (_location: Location, _action: HistoryAction) => {
    const { intl } = this.props;
    const msg = defineMessages({
      cancelPrompt: {
        id: 'NewOperation.cancelPrompt',
        defaultMessage: 'You have unsaved changes. Are you sure you want to cancel creating a new operation?',
      },
    });

    return intl.formatMessage(msg.cancelPrompt);
  };
}

export default connect(mapStateToProps)(NewOperation);
