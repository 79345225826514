import { TenantInfo } from '../ApplicationOptions';

export type CleanupType = 'CACHE' | 'FULL';

export type User = {
  readonly username: string;
  readonly email: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly roles: string[];
};

export type UserInfoAction =
  | { type: 'SET_TENANT'; tenantInfo: TenantInfo }
  | { type: 'SET_USER_INFO'; user: User }
  | { type: 'CLEANUP'; cleanupType: CleanupType };

export function setTenant(tenantInfo: TenantInfo): UserInfoAction {
  return { type: 'SET_TENANT', tenantInfo };
}

export function setUserInfo(user: User): UserInfoAction {
  return { type: 'SET_USER_INFO', user };
}

export function cleanup(cleanupType: CleanupType): UserInfoAction {
  return { type: 'CLEANUP', cleanupType };
}
