import * as React from 'react';

const pushSound = require('../../common/sounds/push.mp3');

// This component is rendered as a sibling to Root,
// therefore the globalAudio element will always be and remain set
// after the first render of the application.
let globalAudio: HTMLAudioElement | null | undefined = null;

export default class NotificationAudio extends React.PureComponent<{}> {
  render() {
    return <audio ref={this.setAudioRef} src={pushSound} preload="auto" />;
  }

  // Provide audio element to saga that handles notification sound playing.
  setAudioRef = (audio: HTMLAudioElement | null | undefined) => {
    globalAudio = audio;
  };
}

export function getGlobalAudioElement(): HTMLAudioElement | null | undefined {
  return globalAudio;
}
