import { createSelector } from 'reselect';

import { dataFilterTypesSelector } from '../../api/reducers/config';
import { WebReduxState } from '../reducers';

const tenantDataFilterLinesAndRegionsEmptySelector: (state: WebReduxState) => boolean = createSelector(
  state => state.admin.tenants.tenantFilter,
  state => dataFilterTypesSelector(state),
  (criteria, dataFilterTypes) => {
    if (dataFilterTypes.includes('regions') && criteria.regions.length > 0) {
      return false;
    }

    if (dataFilterTypes.includes('lines') && criteria.lines.length > 0) {
      return false;
    }

    return true;
  }
);

export default tenantDataFilterLinesAndRegionsEmptySelector;
