import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../api/components/Button';
import { Tenant } from '../reducers/adminTenants';
import { PutTenantRequest } from '../../api/types';
import InputField from '../navigation/InputField';
import styles from './TenantRow.module.css';

type Props = {
  tenant: Tenant;
  onDataFilterButtonClick: (tenantId: string) => void;
  onChangeDisplayNameButtonClick: (tenant: PutTenantRequest, tenantId: string) => void;
};

type State = {
  showChangeNameField: boolean;
  name: string;
};

export default class TenantRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showChangeNameField: false,
      name: props.tenant.displayName,
    };
  }

  render() {
    const { tenant } = this.props;

    return (
      <tr className={styles.tenantRow}>
        <td>
          {this.state.showChangeNameField ? (
            <InputField
              controlId="editTenantKey"
              formType="text"
              value={this.state.name}
              onChange={this.handleTenantNameChange}
              onCancel={this.handleCancel}
              onConfirm={this.handleTenantNameChangeConfirm}
              maxLength={128}
            />
          ) : (
            <span className={styles.changeLink} title="Edit" onClick={this.handleToggleEditDisplayName}>
              {tenant.displayName}
            </span>
          )}
        </td>
        <td>{tenant.tenantKey}</td>
        <td>
          <Button onClick={this.handleDataFilterButtonClick}>
            <FormattedMessage id="TenantRow.manageDataFilter" defaultMessage="Manage Data Filter" />
          </Button>
        </td>
      </tr>
    );
  }

  handleTenantNameChange = (value: string) => {
    this.setState({ name: value });
  };

  handleTenantNameChangeConfirm = () => {
    const { tenant, onChangeDisplayNameButtonClick } = this.props;
    const request = {
      tenantKey: tenant.tenantKey,
      displayName: this.state.name,
      version: tenant.version,
    };
    onChangeDisplayNameButtonClick(request, tenant.tenantId);
    this.setState({ showChangeNameField: false });
  };

  handleDataFilterButtonClick = () => {
    const { tenant, onDataFilterButtonClick } = this.props;
    onDataFilterButtonClick(tenant.tenantId);
  };

  handleToggleEditDisplayName = () => {
    this.setState({ showChangeNameField: !this.state.showChangeNameField });
  };

  handleCancel = () => {
    this.setState({ showChangeNameField: false, name: this.props.tenant.displayName });
  };
}
