import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { siteNameSelector, supportPhoneNumberSelector } from '../../api/reducers/config';
import {
  userDisplayNameSelector,
  tenantDisplayNameSelector,
  hasManagedServerFiltersSelector,
} from '../../api/reducers/userInfo';
import Navbar from '../../api/components/Navbar';
import NavbarAppItem from '../../api/components/NavbarAppItem';
import NavbarConfigItems from '../../api/components/NavbarConfigItems';
import MenuHeader from '../../api/components/MenuHeader';
import MenuButton from '../../api/components/MenuButton';
import MenuFooter from '../../api/components/MenuFooter';
import handleLogout from '../../api/utils/handleLogout';
import { WebReduxState } from '../reducers';
import { WebDispatchProps } from '../actions';
import colorStyles from '../css/colors.module.css';
import InformMenuBody from './InformMenuBody';

const appIconUrl: any = require('../../shared/assets/inform.png');

type OwnProps = {};

type StoreProps = {
  siteName: string;
  tenantDisplayName: string | null | undefined;
  userDisplayName: string | null | undefined;
  hasManagedDataFilter: boolean;
  supportPhoneNumber: string | null | undefined;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    siteName: siteNameSelector(state),
    tenantDisplayName: tenantDisplayNameSelector(state),
    userDisplayName: userDisplayNameSelector(state),
    hasManagedDataFilter: hasManagedServerFiltersSelector(state),
    supportPhoneNumber: supportPhoneNumberSelector(state),
  };
}

type State = {
  copyrightModalOpen: boolean;
};

class AppNavbar extends React.Component<Props, State> {
  render() {
    return (
      <Navbar
        renderNavbarItems={this.renderNavbarItems}
        renderMenuButton={this.renderMenuButton}
        renderMenuHeader={this.renderMenuHeader}
        renderMenuBody={this.renderMenuBody}
        renderMenuFooter={this.renderMenuFooter}
      />
    );
  }

  renderNavbarItems = (routerContext: RouteComponentProps) => {
    const { siteName } = this.props;
    return (
      <>
        <NavbarAppItem appName={siteName} appIconUrl={appIconUrl} />
        <NavbarConfigItems routerContext={routerContext} />
      </>
    );
  };

  renderMenuButton = (menuOpen: boolean, toggleMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) => {
    const { tenantDisplayName, userDisplayName } = this.props;
    return (
      <MenuButton
        tenantDisplayName={tenantDisplayName}
        userDisplayName={userDisplayName}
        menuOpen={menuOpen}
        onClick={toggleMenu}
      />
    );
  };

  renderMenuHeader = () => {
    const { userDisplayName } = this.props;
    return userDisplayName && <MenuHeader onLogoutClick={handleLogout} />;
  };

  renderMenuBody = (requestClose: () => void) => {
    return <InformMenuBody requestClose={requestClose} />;
  };

  renderMenuFooter = () => {
    const { supportPhoneNumber } = this.props;
    return (
      supportPhoneNumber && <MenuFooter supportPhoneNumber={supportPhoneNumber} linkClass={colorStyles.tintLink} />
    );
  };
}

export default connect(mapStateToProps)(AppNavbar);
