import { NormalizedCollection, emptyCollection, createCollection } from '../../shared/normalizedCollection';
import { WebAction } from '../actions';
import { CrowdTaskParticipation } from '../../api/types';
import applyLongPollingUpdate from '../../api/utils/applyLongPollingUpdate';

export type StaffMemberParticipationsState = {
  readonly selectedCallOutId: string | null | undefined;
  readonly participations: NormalizedCollection<CrowdTaskParticipation>;
};

const initialState = {
  selectedCallOutId: null,
  participations: emptyCollection,
};

const idSelector = (o: CrowdTaskParticipation) => o.staffMemberId;

export default function participations(state: StaffMemberParticipationsState = initialState, action: WebAction) {
  switch (action.type) {
    case 'API_CALL_SUCCESS': {
      switch (action.name) {
        case 'GET_PARTICIPATIONS': {
          const results: CrowdTaskParticipation[] = action.result;
          return {
            ...state,
            participations: createCollection(results, idSelector),
          };
        }
        default:
          return state;
      }
    }
    case 'GET_PARTICIPATIONS':
      return { participations: emptyCollection, selectedCallOutId: action.callOutKey.callOutId };
    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'SET_SELECTED_CALL_OUT_ID':
      return { ...state, selectedCallOutId: action.callOutId };
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}
function handleLongPollingMessage(state, message) {
  if (message.type !== 'CrowdTaskParticipation') {
    return state;
  }

  // Don't accept UPSERTs for other call out ids.
  const { action, payload } = message;
  if (action === 'UPSERT' && (payload as CrowdTaskParticipation).callOutId !== state.selectedCallOutId) {
    return state;
  }

  return {
    ...state,
    participations: applyLongPollingUpdate(state.participations, message, idSelector),
  };
}
