import { I18NAction } from '../actions';

export type MessageDictionary = {
  [messageId: string]: string;
};

export type I18NState = {
  currentLocale: string;
  messages: {
    [lang: string]: MessageDictionary;
  };
};

const initialState: I18NState = {
  currentLocale: 'en',
  messages: {},
};

export default function i18n(state: I18NState = initialState, action: I18NAction): I18NState {
  switch (action.type) {
    case 'SWITCH_LOCALE':
      return { ...state, currentLocale: action.locale };
    default:
      return state;
  }
}
