import * as React from 'react';
import { FormattedMessage, FormattedTime, FormattedDate } from 'react-intl';

import { CrowdTaskCallOut } from '../../api/types';
import CallOutStatusText from './CallOutStatusText';
import styles from './SelectedCallOutInfo.module.css';

type Props = {
  selectedCallOut: CrowdTaskCallOut;
};

export default class SelectedCallOutInfo extends React.PureComponent<Props> {
  render() {
    const { status, task, startTime, endTime, requiredTeamSize, specialSkills, languages } = this.props.selectedCallOut;
    return (
      <div className={styles.infoContainer}>
        <div className={styles.col1}>
          <Field
            title={<FormattedMessage id="OperationDetailsCallOuts.timeSpan" defaultMessage="Timespan" />}
            value={
              <span>
                <FormattedDate value={startTime} day="numeric" month="numeric" year="numeric" />{' '}
                <FormattedTime value={startTime} /> -{' '}
                <FormattedDate value={endTime} day="numeric" month="numeric" year="numeric" />{' '}
                <FormattedTime value={endTime} />
              </span>
            }
          />
          <Field title={<FormattedMessage id="OperationDetailsCallOuts.task" defaultMessage="Task" />} value={task} />
          <Field
            title={<FormattedMessage id="OperationDetailsCallOuts.teamSize" defaultMessage="Required Teamsize" />}
            value={requiredTeamSize}
          />
        </div>
        <div className={styles.col2}>
          <Field
            title={<FormattedMessage id="OperationDetailsCallOuts.status" defaultMessage="Status" />}
            value={<CallOutStatusText status={status} />}
          />
          <Field
            title={<FormattedMessage id="OperationDetailsCallOuts.specialSkills" defaultMessage="Special Skills" />}
            value={specialSkills.length ? specialSkills.join(', ') : '-'}
          />
          <Field
            title={<FormattedMessage id="OperationDetailsCallOuts.language" defaultMessage="Language" />}
            value={languages.length ? languages.join(', ') : '-'}
          />
        </div>
      </div>
    );
  }
}

function Field({ title, value }) {
  return (
    <div className={styles.field}>
      <div className={styles.label}>{title}</div>
      <p>{value}</p>
    </div>
  );
}
