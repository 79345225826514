import apiCall from '../apiCall';
import { PostCrowdTaskOperationChatMessageRequest, PostChatSyncRequest, ChatMessage } from '../types';

const operationPath = 'crowdtask/operations';

export type GetChatMessagesOptions = {
  startTime?: string | null | undefined;
  limit?: number | null | undefined;
};

export function* postOperationChatMessage(
  operationId: string,
  request: PostCrowdTaskOperationChatMessageRequest
): Generator<any, any, any> {
  const response: ChatMessage = yield* apiCall(`${operationPath}/${operationId}/chat`, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return response;
}

export function* getMessages(operationId: string, options?: GetChatMessagesOptions): Generator<any, any, any> {
  let uriParameters = '';

  if (options) {
    const { startTime, limit } = options;
    if (startTime && limit) {
      uriParameters = `?startFrom=${startTime}&limit=${limit}`;
    } else if (startTime) {
      uriParameters = `?startFrom=${startTime}`;
    } else if (limit) {
      uriParameters = `?limit=${limit}`;
    }
  }

  return yield* apiCall(`${operationPath}/${operationId}/chat${uriParameters}`);
}

export function* syncMessages(request: PostChatSyncRequest): Generator<any, any, any> {
  return yield* apiCall('chats/sync', {
    method: 'POST',
    body: JSON.stringify(request),
  });
}
