import simpleFetch from '../../shared/apiCalls/simpleFetch';
import handleError from '../../shared/apiCalls/handleError';
import { GetConfigResponse } from '../types';

export type GetConfigResult = {
  response: GetConfigResponse;
  lastModified: string;
};

export function getConfiguration(
  serverUrl: string,
  lastModified: string | null | undefined
): Promise<GetConfigResult | null | undefined> {
  const additionalHeaders = {};
  if (lastModified) {
    additionalHeaders['If-Modified-Since'] = lastModified;
  }

  return simpleFetch(`${serverUrl}/api/v1/config`, {
    headers: {
      Accept: 'application/json',
      ...additionalHeaders,
    },
  })
    .then(response => {
      if (response.status === 304) {
        // Not modified
        return null;
      } else {
        return handleError(response);
      }
    })
    .then(response => {
      if (!response) {
        // Not modified, handling the return null from above
        return null;
      }

      return response.json().then(json => {
        return {
          response: json,
          lastModified: response.headers.get('last-modified'),
        };
      });
    });
}
