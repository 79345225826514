import { values, mapValues, pick, omit, omitBy } from 'lodash-es';

import {
  Incident as ApiIncident,
  AvailableFilterCriteria,
  PostIncidentsQueryResponse,
  LongPollingMessage,
  Role,
} from '../../api/types';
import { Incident } from '../../api/incidentsTypes';
import { incidentDataEqual } from '../utils/compareIncidentData';
import { isIncidentNew, isIncidentStrictNew, isIncidentRead, incidentToIncidentData } from '../utils/incidents';
import replacesIncidentsSelector from '../selectors/replacesIncidents';
import { Action } from '../actions';
import { ReduxState } from '.';

export type IncidentsMap = {
  [id: string]: Incident;
};

export type IncidentsState = {
  readonly transactionId: number;
  readonly filterHash: string;
  readonly syncTimestamp: string | null | undefined;
  readonly syncId: number;
  readonly byId: IncidentsMap;
  readonly maxNumberOfIncidentsExceeded: boolean;
};

const initialState: IncidentsState = {
  transactionId: 0,
  filterHash: '',
  syncTimestamp: null,
  syncId: 0,
  byId: {},
  maxNumberOfIncidentsExceeded: false,
};

const updateIncident = (
  state: IncidentsState,
  incidentId: string,
  updateFn: (_: Incident) => Incident
): IncidentsState => {
  const incident = state.byId[incidentId];
  if (!incident) {
    return state;
  }

  return { ...state, byId: { ...state.byId, [incidentId]: updateFn(incident) } };
};

const toggleFavorite = (state: IncidentsState, incidentId: string) =>
  updateIncident(state, incidentId, incident => ({ ...incident, isFavorite: !incident.isFavorite }));

const setIncidentRead = (state: IncidentsState, incidentId: string) =>
  updateIncident(state, incidentId, incident => ({
    ...incident,
    lastReadData: incident.currentData,
    changeStatus: 'read',
  }));

export default function incidents(state: IncidentsState = initialState, action: Action): IncidentsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'FETCH_INCIDENTS':
          return handleFetchIncidentsSuccess(state, action.result);
        case 'POST_DATAFILTER_CRITERIA': {
          const criteria: AvailableFilterCriteria = action.result;
          return applyDataFilter(state, criteria);
        }
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, 'INFORM_USER', action.message);
    case 'TOGGLE_FAVORITE':
      return toggleFavorite(state, action.incidentId);
    case 'INCIDENT_READ':
      return setIncidentRead(state, action.incidentId);
    case 'SET_INCIDENT_SELECTED':
      return { ...state, byId: setIncidentSelected(state.byId, action) };
    case 'RESET_INCIDENTS_STATE':
      return { ...state, byId: resetUnreadAndFavorites(state.byId) };
    case 'APPLY_DATAFILTER':
      return applyDataFilter(state, action.criteria);
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

export function crowdTaskIncidents(state: IncidentsState = initialState, action: Action): IncidentsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_CROWD_TASK_INCIDENTS':
          return handleFetchIncidentsSuccess(state, action.result);
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, 'CROWD_TASK_COCKPIT', action.message);
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

export function coordinatorIncidents(state: IncidentsState = initialState, action: Action): IncidentsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_COORDINATOR_INCIDENTS':
          return handleFetchIncidentsSuccess(state, action.result);
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, 'CROWD_TASK_COORDINATOR', action.message);
    case 'INCIDENT_READ':
      return setIncidentRead(state, action.incidentId);
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

export function staffMemberIncidents(state: IncidentsState = initialState, action: Action): IncidentsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_STAFF_MEMBER_INCIDENTS':
          return handleFetchIncidentsSuccess(state, action.result);
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, 'CROWD_TASK_STAFF_MEMBER', action.message);
    case 'INCIDENT_READ':
      return setIncidentRead(state, action.incidentId);
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

function handleLongPollingMessage(state: IncidentsState, role: Role, message: LongPollingMessage): IncidentsState {
  if (message.type !== 'Incident' || message.role !== role) {
    return state;
  }

  // Fake a successful fetch incidents API call
  const lastTransactionId = state.transactionId;
  const lastFilterHash = state.filterHash;

  const updates = message.action === 'UPSERT' ? [message.payload as ApiIncident] : [];
  const deletes = message.action === 'DELETE' ? [message.payload as string] : [];

  const response: PostIncidentsQueryResponse = {
    lastTransactionId,
    lastFilterHash,
    updates,
    deletes,
    maxNumberOfIncidentsExceeded: false,
  };
  return handleFetchIncidentsSuccess(state, response);
}

function handleFetchIncidentsSuccess(state: IncidentsState, response: PostIncidentsQueryResponse): IncidentsState {
  const { byId: initialIncidents } = state;

  // 1. Merge new or updated incidents.
  const newOrUpdatedIncidents = mergeIncidents(state, response);
  let mergedIncidents = { ...state.byId, ...newOrUpdatedIncidents };

  // 2. Cleanup incidents
  if (response.lastTransactionId === 0) {
    // After transactionId is reset to 0, we receive all incidents in response.updates.
    // Therefore all other incidents are obsolete and can be removed.
    const serverIncidentIds = response.updates.map(incident => incident.incidentId);
    mergedIncidents = pick(mergedIncidents, serverIncidentIds);
  } else {
    // Remove incidents to be deleted according to the server.
    mergedIncidents = omit(mergedIncidents, response.deletes);
  }

  // 3. Propagate favs from replaced to replacement incidents
  mergedIncidents = propagateFavorites(initialIncidents, newOrUpdatedIncidents, mergedIncidents);

  return {
    transactionId: response.lastTransactionId,
    filterHash: response.lastFilterHash,
    maxNumberOfIncidentsExceeded: response.maxNumberOfIncidentsExceeded,
    byId: mergedIncidents,
    syncTimestamp: new Date().toISOString(),
    syncId: state.syncId + 1,
  };
}

// Returns a map of the new/updated incidents.
function mergeIncidents(state: IncidentsState, response: PostIncidentsQueryResponse): IncidentsMap {
  const mergedIncidents = {};
  const currentIncidents = state.byId;
  const syncId = state.syncId + 1;

  response.updates.forEach(d => {
    const incidentData = incidentToIncidentData(d);

    const incident = currentIncidents[d.incidentId];
    if (incident) {
      // Existing incident => update only if data changed.
      if (!incidentDataEqual(incidentData, incident.currentData)) {
        mergedIncidents[d.incidentId] = {
          ...incident,
          lastUpdated: d.lastUpdated,
          currentData: incidentData,
          // For a new incident, lastReadData must remain equal to currentData, so that no changes are highlighted.
          // While an incident is new (= freshly added and unopened), no changes shall be indicated, i.e. lastReadData shall be kept identical to currentData.
          lastReadData: isIncidentStrictNew(incident) ? incidentData : incident.lastReadData,
          changeStatus: isIncidentRead(incident) ? 'updated' : incident.changeStatus,
          transactionId: response.lastTransactionId,
          syncId,
        };
      }
    } else {
      // New incident
      // Fill lastReadData with the currentData to be consistent until incident is read
      mergedIncidents[d.incidentId] = {
        incidentId: d.incidentId,
        incidentDate: d.incidentDate,
        created: d.created,
        lastUpdated: d.lastUpdated,
        currentData: incidentData,
        // Set lastReadData equal to currentData so that field comparisons work,
        // but no changes are highlighted.
        lastReadData: incidentData,
        isFavorite: false,
        isSelected: false,
        changeStatus: 'new',
        transactionId: response.lastTransactionId,
        syncId,
        threadId: d.threadId,
      };
    }
  });

  return mergedIncidents;
}

function propagateFavorites(initialIncidents, changedIncidents, mergedIncidents) {
  const result = mergedIncidents;

  values(changedIncidents).forEach((changedIncident: Incident) => {
    const replacedById = changedIncident.currentData.replacedById;

    if (!replacedById) {
      return;
    }

    const initialIncident = initialIncidents[changedIncident.incidentId];
    const isReplacedByChange = !initialIncident || initialIncident.currentData.replacedById !== replacedById;

    if (!isReplacedByChange) {
      return;
    }

    const replacesExisting = replacesIncidentsSelector(mergedIncidents)[replacedById];

    if (replacesExisting && replacesExisting.length) {
      const fav = replacesExisting.some(inc => inc && inc.isFavorite);
      const replacedIncident = mergedIncidents[replacedById];

      if (!replacedIncident) {
        return;
      }

      mergedIncidents[replacedById] = { ...replacedIncident, isFavorite: replacedIncident.isFavorite || fav };
    }
  });

  return result;
}

function applyDataFilter(state: IncidentsState, criteria: AvailableFilterCriteria): IncidentsState {
  return {
    ...state,
    byId: omitBy(state.byId, incident => !matchesDataFilterCriteria(incident, criteria)),
    transactionId: 0,
  };
}

function resetUnreadAndFavorites(incidentsById: IncidentsMap): IncidentsMap {
  return mapValues(incidentsById, (incident: Incident) => ({
    ...incident,
    isFavorite: false,
    isSelected: false,
    changeStatus: 'new',
  }));
}

// Set an incident as selected/unselected. Only one incident can be selected at a time.
// Incidents that are deselected are also set read.
function setIncidentSelected(incidentsById: IncidentsMap, action): IncidentsMap {
  const deselectAndSetRead = (incident: Incident): Incident => {
    return {
      ...incident,
      isSelected: false,
      lastReadData: incident.currentData,
      changeStatus: 'read',
    };
  };

  return mapValues(
    incidentsById,
    (incident: Incident): Incident => {
      if (incident.incidentId === action.incidentId) {
        if (action.selected && !incident.isSelected) {
          // Select incident.
          // #2085: Mark new incidents as read when selecting them.
          return {
            ...incident,
            changeStatus: isIncidentNew(incident) ? 'newOpened' : incident.changeStatus,
            isSelected: true,
          };
        } else if (!action.selected && incident.isSelected) {
          // Deselect incident and set read.
          return deselectAndSetRead(incident);
        }
      } else if (incident.isSelected && action.selected) {
        // This incident is automatically deselected and set read because another incident was selected.
        return deselectAndSetRead(incident);
      }

      // Fallthrough: Incident is unchanged.
      return incident;
    }
  );
}

function matchesDataFilterCriteria(incident: Incident, criteria: AvailableFilterCriteria): boolean {
  const d = incident.currentData;
  const lines = new Set(criteria.lines);
  const regions = new Set(criteria.regions);
  const incidentTypes = new Set(criteria.incidentTypes);

  return (
    d.lines.some(l => lines.has(l)) ||
    d.regions.some(r => regions.has(r)) ||
    (d.incidentType ? incidentTypes.has(d.incidentType) : false)
  );
}

export function filterHashSelector(state: ReduxState): string {
  return state.inform.incidents.filterHash;
}
