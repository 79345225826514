import { createSelector } from 'reselect';

import { Incident } from '../../api/incidentsTypes';
import { ReduxState } from '../reducers';
import sortIncidents from '../utils/sortIncidents';

const sortedIncidentsSelector: (state: ReduxState) => Incident[] = createSelector(
  state => state.inform.incidents.byId,
  incidentsById => sortIncidents(incidentsById)
);

export default sortedIncidentsSelector;
