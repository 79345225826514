import { forIn } from 'lodash-es';
import { createSelector } from 'reselect';

import { isIncidentNew, isIncidentUpdated } from '../utils/incidents';
import { ReduxState } from '../reducers';
import { Incident } from '../../api/incidentsTypes';
import { incidentMatchesFilter } from './filteredIncidents';
import filterWithSetsSelector from './filterWithSets';

export type IncidentStatusInfo = {
  hasNonFavoriteChangedIncidents: boolean;
  hasNonFilteredChangedIncidents: boolean;
  newIncidentsCounter: number;
  updatedIncidentsCounter: number;
  maxNumberOfIncidentsExceeded: boolean;
};

const incidentStatusInfoSelector: (state: ReduxState) => IncidentStatusInfo = createSelector(
  state => filterWithSetsSelector(state),
  state => state.inform.incidents,
  (filterWithSets, incidents) => {
    let newIncidentsCounter = 0;
    let updatedIncidentsCounter = 0;
    const changedIncidents: Incident[] = [];

    forIn(incidents.byId, (incident: Incident) => {
      if (incident.syncId === incidents.syncId) {
        changedIncidents.push(incident);

        if (isIncidentNew(incident)) {
          newIncidentsCounter++;
        } else if (isIncidentUpdated(incident)) {
          updatedIncidentsCounter++;
        }
      }
    });

    const hasNonFavoriteChangedIncidents = changedIncidents.some(incident => !incident.isFavorite);
    const hasNonFilteredChangedIncidents = changedIncidents.some(
      incident => !incidentMatchesFilter(incident, filterWithSets)
    );

    const incidentStatusInfo: IncidentStatusInfo = {
      hasNonFavoriteChangedIncidents,
      hasNonFilteredChangedIncidents,
      newIncidentsCounter,
      updatedIncidentsCounter,
      maxNumberOfIncidentsExceeded: incidents.maxNumberOfIncidentsExceeded,
    };

    return incidentStatusInfo;
  }
);

export default incidentStatusInfoSelector;
