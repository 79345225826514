import { DeviceType } from '../../api/types';

// This reducer provides device- and platform-specific information.
// Its state is initialized on store creation.
export type DeviceInfoState = {
  readonly deviceName: string;
  readonly deviceType: DeviceType;
};

const initialState: DeviceInfoState = {
  deviceName: 'unknown',
  deviceType: 'WEB',
};

export default function deviceInfo(state: DeviceInfoState = initialState): DeviceInfoState {
  return state;
}
