import { createSelector } from 'reselect';
import { defineMessages } from 'react-intl';

import { intlSelector } from '../../api/selectors/i18n';
import incidentStatusInfoSelector from '../../common/selectors/incidentStatusInfo';
import { IncidentsViewMode } from '../../common/selectors/incidentsByViewMode';
import { ReduxState } from '../reducers';

const msg = defineMessages({
  tooManyIncidents: {
    id: 'IncidentStatusText.tooManyIncidents',
    defaultMessage: 'Too many messages. Please subscribe less.',
  },
  newIncidentInAll: {
    id: 'IncidentStatusText.newIncidentInAll',
    defaultMessage: 'Attention: New messages in view "All"',
  },
  updatedIncidents: {
    id: 'IncidentStatusText.updatedIncidents',
    defaultMessage: '{updatedIncidentsCounter, plural, one {One updated message} other {# updated messages}}',
  },
  newIncidents: {
    id: 'IncidentStatusText.newIncidents',
    defaultMessage: '{newIncidentsCounter, plural, one {One new message} other {# new messages}}',
  },
  newAndUpdatedIncidents: {
    id: 'IncidentStatusText.newAndUpdatedIncidents',
    defaultMessage:
      '{newIncidentsCounter, plural, one {One new} other {# new}} and {updatedIncidentsCounter, plural, one {one updated message} other {# updated messages}}',
  },
});

// Note: Do not make a React component out of this,
// otherwise concatenating it with other strings is troublesome.
const incidentStatusTextByViewModeSelector: (
  state: ReduxState
) => {
  [viewMode in IncidentsViewMode]: string | null | undefined;
} = createSelector(
  state => intlSelector(state),
  state => incidentStatusInfoSelector(state),
  (intl, incidentStatusInfo) => {
    return {
      all: getStatusText(intl, incidentStatusInfo, 'all'),
      filter: getStatusText(intl, incidentStatusInfo, 'filter'),
      favorites: getStatusText(intl, incidentStatusInfo, 'favorites'),
    };
  }
);

function getStatusText(intl, incidentStatusInfo, viewMode): string | null | undefined {
  const {
    hasNonFavoriteChangedIncidents,
    hasNonFilteredChangedIncidents,
    maxNumberOfIncidentsExceeded,
    newIncidentsCounter,
    updatedIncidentsCounter,
  } = incidentStatusInfo;

  let hasNonVisibleChangedIncidents = false;

  switch (viewMode) {
    case 'favorites' as IncidentsViewMode:
      hasNonVisibleChangedIncidents = hasNonFavoriteChangedIncidents;
      break;
    case 'filter' as IncidentsViewMode:
      hasNonVisibleChangedIncidents = hasNonFilteredChangedIncidents;
      break;
    default:
      break;
  }

  if (maxNumberOfIncidentsExceeded) {
    return intl.formatMessage(msg.tooManyIncidents);
  }

  if (hasNonVisibleChangedIncidents) {
    return intl.formatMessage(msg.newIncidentInAll);
  }

  if (newIncidentsCounter > 0 || updatedIncidentsCounter > 0) {
    if (newIncidentsCounter === 0) {
      return intl.formatMessage(msg.updatedIncidents, { updatedIncidentsCounter });
    } else if (updatedIncidentsCounter === 0) {
      return intl.formatMessage(msg.newIncidents, { newIncidentsCounter });
    } else {
      return intl.formatMessage(msg.newAndUpdatedIncidents, { newIncidentsCounter, updatedIncidentsCounter });
    }
  }

  return null;
}

export default incidentStatusTextByViewModeSelector;
