export type PushSound = {
  title: string;
  fileName: string;
};

export const PushSounds: PushSound[] = [
  { title: 'Standard', fileName: 'default_jingle.wav' },
  { title: 'Gong', fileName: 'cca_jingle.wav' },
  { title: 'Alarm', fileName: 'callout.wav' },
  { title: 'Update', fileName: 'update.wav' },
];

export const DefaultPushSound = PushSounds[0];

export function getPushSound(fileName: string): PushSound | null | undefined {
  return PushSounds.find(sound => sound.fileName === fileName);
}
