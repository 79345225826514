import { select } from 'redux-saga/effects';

import { postAppStartStatistics } from '../../api';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';

export default function* postStatisticsSaga(): Generator<any, any, any> {
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  if (!device) {
    return false;
  }

  const { deviceId, numAppStarts } = device;
  return yield* wrapApiCallActions('POST_STATISTICS', postAppStartStatistics(deviceId, numAppStarts));
}
