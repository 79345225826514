import * as React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { ChatMessage } from '../../api/types';
import styles from './ChatMessageRow.module.css';

type Props = {
  chatMessage: ChatMessage;
  isAdmin?: boolean;
  onBlock?: (messageId: string) => void;
};

export default class ChatMessageRow extends React.PureComponent<Props> {
  render() {
    const { chatMessage, isAdmin } = this.props;
    const { submitted, author, you, message, messageId, blocked, authorPhoneNumber } = chatMessage;
    const ownMessage = messageId.startsWith('pending') ? styles.pendingMessage : styles.ownMessage;
    const messageClass = you ? ownMessage : styles.otherMessage;
    const messageWrapperClass = you ? styles.ownWrapper : styles.messageWrapper;

    return (
      <div className={messageWrapperClass}>
        <div className={`${messageClass} ${blocked ? styles.blockedMessage : ''}`}>
          <div className={styles.userInfo}>
            <span className={styles.user}>{author}</span>
            {authorPhoneNumber && !you && (
              <a className={styles.phoneNumberLink} href={`tel:${authorPhoneNumber}`}>
                {authorPhoneNumber}
              </a>
            )}
          </div>
          <div className={styles.messageBody}>
            {message}
            <span className={styles.timestamp}>{<FormattedTime value={submitted} />}</span>
          </div>
        </div>
        {!you &&
          isAdmin &&
          (blocked ? (
            <div className={styles.blocked}>
              <i className={`${styles.blockIcon} icon-blocked`} />
              <FormattedMessage id="ChatMessageRow.blocked" defaultMessage="Blocked" />
            </div>
          ) : (
            <div className={styles.block} onClick={this.handleBlockClicked}>
              <i className={`${styles.blockIcon} icon-blocked`} />
              <FormattedMessage id="ChatMessageRow.block" defaultMessage="Block" />
            </div>
          ))}
      </div>
    );
  }

  handleBlockClicked = () => {
    const {
      onBlock,
      chatMessage: { messageId },
    } = this.props;
    if (onBlock) {
      onBlock(messageId);
    }
  };
}
