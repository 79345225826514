import * as React from 'react';

import { IncidentData } from '../../api/incidentsTypes';
import ReadOnlyChat from '../../api/components/ReadOnlyChat';
import { compareIncidentData } from '../../common/utils/compareIncidentData';
import IncidentDetailsLeft from './IncidentDetailsLeft';
import IncidentDetailsRight from './IncidentDetailsRight';
import styles from './IncidentDetail.module.css';
import { useDispatch, getIncidentsSearchChat } from '../actions';
import { useSelector, WebReduxState } from '../reducers';
import { shallowEqual } from 'react-redux';

type Props = {
  incidentId: string;
  created: string;
  lastUpdated: string;
  incidentDate: string;
  incidentData: IncidentData;
  previousIncidentData: IncidentData | null;
  canSeeIncidentType: boolean;
  longDates?: boolean;
  hasChat: boolean;
};

function dataSelector(state: WebReduxState) {
  return {
    chatThread: state.admin.analysis.chat,
  };
}

export default function AnalysisIncidentDetail({
  incidentId,
  created,
  lastUpdated,
  incidentDate,
  incidentData,
  previousIncidentData,
  canSeeIncidentType,
  longDates,
  hasChat,
}: Props) {
  const { chatThread } = useSelector(dataSelector, shallowEqual);
  const leftDivRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [maxChatWindowHeight, setMaxChatWindowHeight] = React.useState(0);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setMaxChatWindowHeight(leftDivRef.current?.clientHeight ?? 0);
    dispatch(getIncidentsSearchChat(incidentId));
  }, [dispatch, incidentId]);

  const fieldInfos = previousIncidentData ? compareIncidentData(incidentData, previousIncidentData) : undefined;

  return (
    <tr className={styles.incidentDetailRow}>
      <td className={styles.incidentDetail} colSpan={8}>
        <div className={styles.flexRow}>
          <div className={styles.col1}>
            <div ref={leftDivRef}>
              <IncidentDetailsLeft
                incidentId={incidentId}
                created={created}
                lastUpdated={lastUpdated}
                incidentDate={incidentDate}
                incidentData={incidentData}
                fieldInfos={fieldInfos}
                canSeeIncidentType={canSeeIncidentType}
                showStatus={true}
                longDates={longDates}
              />
            </div>
          </div>
          <div className={hasChat ? styles.col2 : styles.col2Big}>
            <IncidentDetailsRight incidentData={incidentData} fieldInfos={fieldInfos} />
          </div>
          <div className={styles.colBreak} />
          {hasChat && (
            <div className={styles.col3}>
              <ReadOnlyChat height={maxChatWindowHeight} thread={chatThread} />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
