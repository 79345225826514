import apiCallShared, { ApiCallConfig } from '../shared/apiCalls/apiCall';
import { SimpleRequestOptions } from '../shared/apiCalls/simpleFetch';

let apiCallConfig: ApiCallConfig<any> | null | undefined = null;

export function setApiCallConfig(config: ApiCallConfig<any>) {
  apiCallConfig = config;
}

export default function* apiCall(path: string, options: SimpleRequestOptions = {}): Generator<any, any, any> {
  if (!apiCallConfig) {
    throw new Error('Api call config not set');
  }

  return yield* apiCallShared(apiCallConfig, path, options);
}
