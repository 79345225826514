import * as React from 'react';
import { Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import { getApiCallStatus, SyncState } from '../../api/reducers/api';
import { authenticationEnabledSelector } from '../../api/reducers/config';
import { User } from '../../api/reducers/userInfo';
import hasRoleSelector from '../../api/selectors/hasRole';
import { InformRoles, CrowdTaskRoles } from '../../api/roles';
import handleLogout from '../../api/utils/handleLogout';
import { loadConfigurationRetry, postDeviceDataRetry, WebDispatchProps, fetchDataFilterCriteria } from '../actions';
import { WebReduxState } from '../reducers';
import { StartupState } from '../../common/reducers/app';
import { isDataFilterSetSelector } from '../../common/reducers/device';
import LoadingScreen from './LoadingScreen';
import SimpleErrorScreen from './SimpleErrorScreen';
import App from './App';

type OwnProps = {};

type StoreProps = {
  startupState: StartupState;
  user: User | null | undefined;
  authenticationEnabled: boolean;
  hasRole: (role: string) => boolean;
  isDataFilterSet: boolean;
  loadConfiguration: ApiCallStatus;
  postDeviceData: ApiCallStatus;
  loadDataFilter: ApiCallStatus;
  syncState: SyncState;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    startupState: state.app.startupState,
    user: state.userInfo.user,
    authenticationEnabled: authenticationEnabledSelector(state),
    hasRole: hasRoleSelector(state),
    isDataFilterSet: isDataFilterSetSelector(state),
    loadConfiguration: getApiCallStatus(state, 'LOAD_CONFIGURATION'),
    postDeviceData: getApiCallStatus(state, 'POST_DEVICEDATA'),
    loadDataFilter: getApiCallStatus(state, 'FETCH_DATAFILTER_CRITERIA'),
    syncState: state.api.sync,
  };
}

class Root extends React.Component<Props> {
  render() {
    const { authenticationEnabled, user, hasRole, isDataFilterSet, loadDataFilter } = this.props;

    if (authenticationEnabled && user && !hasRole(InformRoles.USER) && !hasRole(CrowdTaskRoles.COCKPIT)) {
      return <MissingRoleError onLogoutButtonClicked={handleLogout} />;
    }

    const { startupState, loadConfiguration, postDeviceData, syncState } = this.props;

    switch (startupState) {
      case 'LoadConfiguration':
        return (
          <LoadingScreen
            text={<FormattedMessage id="Root.loadConfiguration" defaultMessage="Loading Configuration" />}
            status={loadConfiguration}
            onButtonClicked={this.handleLoadConfigurationRetry}
          />
        );
      case 'Rehydrate':
      case 'StartApiSagas':
        return <LoadingScreen />;
      case 'DeviceRegistration':
        return (
          <LoadingScreen
            text={<FormattedMessage id="Root.postDeviceData" defaultMessage="Device Registration" />}
            status={postDeviceData}
            onButtonClicked={this.handlePostDeviceDataRetry}
          />
        );
      case 'LoadDataFilter':
        return (
          <LoadingScreen
            text={<FormattedMessage id="Root.loadDataFilter" defaultMessage="Data Filter" />}
            status={loadDataFilter}
            onButtonClicked={this.handleLoadDataFilterRetry}
          />
        );
      case 'Authenticate':
      case 'DataFilter':
      case 'StartPeriodicSagas':
      case 'Complete':
        return !isDataFilterSet || syncState.firstSyncSucceeded ? (
          <HashRouter>
            <Route path="/" component={App} />
          </HashRouter>
        ) : (
          <InitialSyncProgress syncState={syncState} />
        );
      // this should never happen
      default:
        return null;
    }
  }

  handleLoadConfigurationRetry = () => {
    this.props.dispatch(loadConfigurationRetry());
  };

  handlePostDeviceDataRetry = () => {
    this.props.dispatch(postDeviceDataRetry());
  };

  handleLoadDataFilterRetry = () => {
    const { dispatch } = this.props;
    dispatch(fetchDataFilterCriteria());
  };
}

export default connect(mapStateToProps)(Root);

function MissingRoleError({ onLogoutButtonClicked }) {
  return (
    <SimpleErrorScreen
      errorMessage={
        <FormattedMessage
          id="Root.missingRole"
          defaultMessage="Missing role; expected {user} or {cockpit}."
          values={{ user: InformRoles.USER, cockpit: CrowdTaskRoles.COCKPIT }}
        />
      }
      buttonTitle={<FormattedMessage id="Root.logout" defaultMessage="Logout" />}
      onButtonClicked={onLogoutButtonClicked}
    />
  );
}

function InitialSyncProgress({ syncState }) {
  const status: ApiCallStatus = {
    inProgress: syncState.status === 'syncing',
    error: syncState.error,
  };

  return (
    <LoadingScreen
      text={<FormattedMessage id="Root.initialSync" defaultMessage="Loading initial data" />}
      status={status}
      onButtonClicked={handleReload}
    />
  );
}

function handleReload() {
  window.location.reload();
}
