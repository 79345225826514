import { createSelector } from 'reselect';
import { omit } from 'lodash-es';

import { Action, UpdateDeviceDataResult } from '../actions';

export type DeviceRegistration = {
  readonly deviceId: string;
  readonly pushToken: string | null | undefined;
  readonly isDataFilterSet: boolean;
  readonly numAppStarts: number;
};

type CurrentUserInfo = {
  tenantKey: string | null;
  username: string;
};

export type DeviceState = {
  // We store the current user info here as we need <tenantKey/username> (aka "fullUsername")
  // to index the device registrations which we keep per user.
  readonly currentUserInfo: CurrentUserInfo;
  readonly registrations: {
    [fullUsername: string]: DeviceRegistration;
  };
  readonly pushToken: string | null | undefined;
  readonly pushAllowed: boolean;
};

const initialState: DeviceState = {
  currentUserInfo: {
    tenantKey: null,
    username: 'anonymous', // Required for operation without authentication
  },
  registrations: {},
  pushToken: null,
  pushAllowed: false,
};

type PartialReduxState = {
  device: DeviceState;
};

function updateCurrentUserInfo(
  state: DeviceState,
  updateFn: (userInfo: CurrentUserInfo) => CurrentUserInfo
): DeviceState {
  return { ...state, currentUserInfo: updateFn(state.currentUserInfo) };
}

function updateRegistration(
  state: DeviceState,
  updateFn: (registration: DeviceRegistration) => DeviceRegistration
): DeviceState {
  const username = fullUsernameSelector(state);
  const registration = state.registrations[username];

  if (!registration) {
    return state;
  }

  return {
    ...state,
    registrations: {
      ...state.registrations,
      [username]: updateFn(registration),
    },
  };
}

export default function device(state: DeviceState = initialState, action: Action): DeviceState {
  switch (action.type) {
    case 'SET_TENANT':
      return updateCurrentUserInfo(state, userInfo => ({ ...userInfo, tenantKey: action.tenantInfo.tenantKey }));
    case 'SET_USER_INFO':
      return updateCurrentUserInfo(state, userInfo => ({ ...userInfo, username: action.user.username }));
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'LOAD_TENANT':
          return updateCurrentUserInfo(state, userInfo => ({ ...userInfo, tenantKey: action.result.tenantKey }));
        case 'POST_DEVICEDATA': {
          const deviceId: string = action.result;
          const username = fullUsernameSelector(state);

          return {
            ...state,
            registrations: {
              ...state.registrations,
              [username]: {
                deviceId,
                pushToken: null,
                isDataFilterSet: false,
                numAppStarts: 0,
              },
            },
          };
        }
        case 'UPDATE_DEVICEDATA': {
          const { pushToken }: UpdateDeviceDataResult = action.result;
          return updateRegistration(state, registration => ({ ...registration, pushToken }));
        }
        case 'POST_DATAFILTER_CRITERIA':
        case 'POST_DATAFILTER_CALLOUT_AREAS':
          return updateRegistration(state, registration => ({ ...registration, isDataFilterSet: true }));
        case 'FETCH_DATAFILTER_CALLOUT_AREAS': {
          const isDataFilterSet = action.result.criteria.callOutAreas.length > 0;
          return updateRegistration(state, registration => ({ ...registration, isDataFilterSet }));
        }
        case 'FETCH_DATAFILTER_CRITERIA': {
          const isDataFilterSet = action.result.criteria.regions.length > 0;
          return updateRegistration(state, registration => ({ ...registration, isDataFilterSet }));
        }
        default:
          return state;
      }

    case 'SET_PUSH_TOKEN':
      return { ...state, pushToken: action.pushToken };
    case 'SET_PUSH_ALLOWED':
      return { ...state, pushAllowed: action.enabled };
    case 'INCREMENT_APP_STARTS_COUNTER':
      return updateRegistration(state, registration => ({
        ...registration,
        numAppStarts: registration.numAppStarts + 1,
      }));
    case 'RESET_APP_STARTS_COUNTER':
      return updateRegistration(state, registration => ({ ...registration, numAppStarts: 0 }));
    case 'CLEANUP':
      return updateRegistration(state, registration => ({ ...registration, isDataFilterSet: false }));
    case 'CLEAR_DEVICEDATA': {
      const username = fullUsernameSelector(state);
      return { ...state, registrations: omit(state.registrations, username) };
    }
    default:
      return state;
  }
}

const fullUsernameSelector: (state: DeviceState) => string = createSelector(
  deviceState => deviceState.currentUserInfo,
  ({ tenantKey, username }) => [tenantKey, username].filter(s => s).join('/')
);

export const deviceRegistrationSelector: (
  state: PartialReduxState
) => DeviceRegistration | null | undefined = createSelector(
  state => fullUsernameSelector(state.device),
  state => state.device.registrations,
  (fullUsername, deviceRegistrations) => deviceRegistrations[fullUsername]
);

export const isDataFilterSetSelector: (state: PartialReduxState) => boolean = createSelector(
  state => deviceRegistrationSelector(state),
  registration => (registration ? registration.isDataFilterSet : false)
);
