import browser from './browser';
import './intl';

if (browser === 'ie') {
  require('core-js/features/object/assign');
  require('core-js/features/object/values');
  require('core-js/features/array/from');
  require('core-js/features/array/includes');
  require('core-js/features/array/find');
  require('core-js/features/set');
  require('core-js/features/string/starts-with');
  require('core-js/features/string/ends-with');
  require('core-js/features/string/includes');
  require('core-js/features/math/trunc');

  require('core-js/features/promise');
  require('whatwg-fetch');
}
