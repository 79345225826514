import { WebAction } from '../actions';

export type EditCallOutState = {
  lastCreatedCallOutId: string | null | undefined;
  editCallOutError: string | null | undefined;
};

const initialState = {
  lastCreatedCallOutId: null,
  editCallOutError: null,
};

export default function newCallOut(state: EditCallOutState = initialState, action: WebAction) {
  switch (action.type) {
    case 'API_CALL_SUCCESS': {
      switch (action.name) {
        case 'POST_CALL_OUT':
        case 'POST_FOLLOWUP_CALL_OUT':
          return {
            lastCreatedCallOutId: action.result.callOutId,
            editCallOutError: null,
          };
        default:
          return state;
      }
    }
    case 'SET_EDIT_CALL_OUT_ERROR':
      return { ...state, editCallOutError: action.error };
    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    default:
      return state;
  }
}
