import { CallOutKey } from '../../api/crowdTask/cockpit/callOuts';

export type CallOutsAction =
  | { type: 'GET_CALL_OUTS'; operationId: string }
  | { type: 'POST_CALL_OUT'; operationId: string }
  | { type: 'POST_FOLLOWUP_CALL_OUT'; callOutKey: CallOutKey }
  | { type: 'RELEASE_CALL_OUT'; callOutKey: CallOutKey }
  | { type: 'CLOSE_CALL_OUT'; callOutKey: CallOutKey; version: number }
  | { type: 'DELETE_CALL_OUT'; callOutKey: CallOutKey; version: number }
  | { type: 'UPDATE_CALL_OUT'; callOutKey: CallOutKey; version: number; taskVersion: number }
  | { type: 'RELEASE_ALL_CALL_OUTS' }
  | { type: 'SET_EDIT_CALL_OUT_ERROR'; error: string | null | undefined };

export function getCallOuts(operationId: string): CallOutsAction {
  return { type: 'GET_CALL_OUTS', operationId };
}

export function postCallOut(operationId: string): CallOutsAction {
  return { type: 'POST_CALL_OUT', operationId };
}

export function postFollowupCallOut(callOutKey: CallOutKey): CallOutsAction {
  return { type: 'POST_FOLLOWUP_CALL_OUT', callOutKey };
}

export function releaseCallOut(callOutKey: CallOutKey): CallOutsAction {
  return { type: 'RELEASE_CALL_OUT', callOutKey };
}

export function closeCallOut(callOutKey: CallOutKey, version: number): CallOutsAction {
  return { type: 'CLOSE_CALL_OUT', callOutKey, version };
}

export function deleteCallOut(callOutKey: CallOutKey, version: number): CallOutsAction {
  return { type: 'DELETE_CALL_OUT', callOutKey, version };
}

export function updateCallOut(callOutKey: CallOutKey, version: number, taskVersion: number): CallOutsAction {
  return { type: 'UPDATE_CALL_OUT', callOutKey, version, taskVersion };
}

export function releaseAllCallOuts(): CallOutsAction {
  return { type: 'RELEASE_ALL_CALL_OUTS' };
}

export function setEditCallOutError(error: string | null | undefined): CallOutsAction {
  return { type: 'SET_EDIT_CALL_OUT_ERROR', error };
}
