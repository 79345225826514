export type SettingsAction =
  | { type: 'SET_NOTIFICATIONS_ENABLED'; enabled: boolean }
  | { type: 'SET_VIBRATION_ENABLED'; enabled: boolean }
  | { type: 'SET_PUSH_SOUND'; fileName: string }
  | { type: 'SET_FEEDBACK_PHONE_NUMBER'; phoneNumber: string };

export function setNotificationsEnabled(enabled: boolean): SettingsAction {
  return { type: 'SET_NOTIFICATIONS_ENABLED', enabled };
}

export function setVibrationEnabled(enabled: boolean): SettingsAction {
  return { type: 'SET_VIBRATION_ENABLED', enabled };
}

export function setPushSound(fileName: string): SettingsAction {
  return { type: 'SET_PUSH_SOUND', fileName };
}

export function setFeedbackPhoneNumber(phoneNumber: string): SettingsAction {
  return { type: 'SET_FEEDBACK_PHONE_NUMBER', phoneNumber };
}
