import { WebAction } from '../actions';

export type AdminPersistedState = {
  readonly initialLoadTriggeredAt: string;
  readonly statisticsFetchedAt: string;
};

const initialState = {
  initialLoadTriggeredAt: '',
  statisticsFetchedAt: '',
};

export default function adminPersisted(
  state: AdminPersistedState = initialState,
  action: WebAction
): AdminPersistedState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_STATISTICS':
          return { ...state, statisticsFetchedAt: new Date().toISOString() };
        case 'SUBMIT_INITIAL_LOAD':
          return { ...state, initialLoadTriggeredAt: new Date().toISOString() };
        default:
          return state;
      }

    case 'CLEANUP':
      return action.cleanupType === 'FULL' ? initialState : state;
    default:
      return state;
  }
}
