import apiCall from '../../apiCall';
import {
  CrowdTaskCallOutWithStats,
  PostCrowdTaskCreateCallOutRequest,
  PostCrowdTaskUpdateCallOutRequest,
  PostCrowdTaskUpdateTaskRequest,
  PostCrowdTaskCreateFollowupCallOutRequest,
} from '../../types';

const operationsPath = 'crowdtask/admin/operations';

export type TaskKey = {
  operationId: string;
  taskId: string;
};

export type CallOutKey = TaskKey & {
  callOutId: string;
};

export function* createTaskAndCallOut(
  operationId: string,
  request: PostCrowdTaskCreateCallOutRequest
): Generator<any, any, any> {
  return yield* apiCall(`${operationsPath}/${operationId}/tasks`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function* createFollowupCallOut(
  operationId: string,
  taskId: string,
  request: PostCrowdTaskCreateFollowupCallOutRequest
): Generator<any, any, any> {
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/callouts`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function* getCallOuts(operationId: string): Generator<any, any, any> {
  const result: CrowdTaskCallOutWithStats = yield* apiCall(`${operationsPath}/${operationId}/callouts`);
  return result;
}

export function* releaseCallOut(callOutKey: CallOutKey): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/release`, {
    method: 'POST',
  });
}

export function* closeCallOut(callOutKey: CallOutKey, version: number): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/${version}/close`, {
    method: 'POST',
  });
}

export function* deleteCallOut(callOutKey: CallOutKey, version: number): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/${version}`, {
    method: 'DELETE',
  });
}

export function* updateCallOut(
  callOutKey: CallOutKey,
  version: number,
  request: PostCrowdTaskUpdateCallOutRequest
): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/${version}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}

export function* updateTask(
  taskKey: TaskKey,
  version: number,
  request: PostCrowdTaskUpdateTaskRequest
): Generator<any, any, any> {
  const { operationId, taskId } = taskKey;
  return yield* apiCall(`${operationsPath}/${operationId}/tasks/${taskId}/${version}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}

export function* releaseAllCallOuts(): Generator<any, any, any> {
  return yield* apiCall(`${operationsPath}/releaseAll`, {
    method: 'POST',
  });
}
