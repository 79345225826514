import * as React from 'react';
import styles from './FullScreenMessage.module.css';

type Props = {
  icon: React.ReactNode;
  title: React.ReactNode;
  message: React.ReactNode;
  children?: React.ReactNode;
};

export default function FullScreenMessage({ icon, title, message, children }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.message}>{message}</p>
      {children && <div className={styles.childrenContainer}>{children}</div>}
    </div>
  );
}
