import { TypedUseSelectorHook, useSelector as useSelectorGeneric } from 'react-redux';

import i18n, { I18NState } from '../../shared/i18n/reducers/i18n';
import api, { ApiState } from '../../api/reducers/api';
import appConfig, { AppConfig } from '../../api/reducers/appConfig';
import chat, { ChatState } from '../../api/reducers/chat';
import chatPending, { ChatPendingState } from '../../api/reducers/chatPending';
import config, { ConfigState } from '../../api/reducers/config';
import userInfo, { UserInfoState } from '../../api/reducers/userInfo';
import app, { AppState } from './app';
import dataFilter, { DataFilterState } from './dataFilter';
import device, { DeviceState } from './device';
import deviceInfo, { DeviceInfoState } from './deviceInfo';
import filter, { FilterState } from './filter';
import incidents, { IncidentsState } from './incidents';
import refreshTimer, { RefreshTimerState } from './refreshTimer';
import settings, { SettingsState } from './settings';
import faq, { FaqState } from './faq';

export type ReduxState = {
  api: ApiState;
  app: AppState;
  appConfig: AppConfig;
  chat: ChatState;
  chatPending: ChatPendingState;
  config: ConfigState;
  device: DeviceState;
  deviceInfo: DeviceInfoState;
  i18n: I18NState;
  refreshTimer: RefreshTimerState;
  settings: SettingsState;
  userInfo: UserInfoState;
  faq: FaqState;
  inform: {
    incidents: IncidentsState;
    filter: FilterState;
    dataFilter: DataFilterState;
  };
};

export default {
  api,
  app,
  appConfig,
  chat,
  chatPending,
  config,
  dataFilter,
  device,
  deviceInfo,
  filter,
  i18n,
  incidents,
  refreshTimer,
  faq,
  settings,
  userInfo,
};

export const useSelector: TypedUseSelectorHook<ReduxState> = useSelectorGeneric;
