import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, IntlShape } from 'react-intl';

import { I18NState } from '../../shared/i18n/reducers/i18n';
import { UserInfoState, userDisplayNameSelector } from '../../api/reducers/userInfo';
import { ChatState, ChatThread } from '../../api/reducers/chat';
import { ChatPendingState, ChatThreadInfo } from '../../api/reducers/chatPending';
import { createNewChatMessage } from '../../api/utils/chat';
import { AppConfig } from '../../api/reducers/appConfig';
import { ConfigState } from '../../api/reducers/config';
import { ChatMessage } from '../../api/types';
import { intlSelector } from '../selectors/i18n';
import { ApiDispatch, editChatText, setChatThreadRead } from '../actions';
import ChatTextField from '../../api/components/ChatTextField';
import ChatList from '../../api/components/ChatList';
import styles from './Chat.module.css';

type PartialReduxState = {
  chat: ChatState;
  chatPending: ChatPendingState;
  appConfig: AppConfig;
  config: ConfigState;
  userInfo: UserInfoState;
  i18n: I18NState;
};

type OwnProps = {
  chatThreadId: string;
  hasWritePermission: boolean;
  maxHeight?: number;
  onSubmitChatMessage: (dispatch: ApiDispatch, message: ChatMessage) => void;
};

type StoreProps = {
  author: string | null | undefined;
  thread: ChatThread | null | undefined;
  threadInfo: ChatThreadInfo | null | undefined;
  currentMessage: string | null | undefined;
  maxChatMessageSize: number;
  intl: IntlShape;
};

type Props = OwnProps & StoreProps & { dispatch: ApiDispatch };

function mapStateToProps(state: PartialReduxState, ownProps: OwnProps): StoreProps {
  const { chatThreadId } = ownProps;

  return {
    author: userDisplayNameSelector(state),
    thread: chatThreadId ? state.chat[chatThreadId] : null,
    threadInfo: chatThreadId ? state.chatPending[chatThreadId] : null,
    currentMessage:
      chatThreadId && state.chatPending[chatThreadId] ? state.chatPending[chatThreadId].currentMessage : null,
    maxChatMessageSize: state.config.loaded.maxChatMessageSize,
    intl: intlSelector(state),
  };
}

class Chat extends React.Component<Props> {
  render() {
    const { thread, threadInfo, currentMessage, maxChatMessageSize, hasWritePermission, maxHeight, intl } = this.props;
    // The textfield has a height of 50 pixels.
    const height = maxHeight ? maxHeight - 50 : 0;

    return (
      <div className={styles.chatView}>
        <ChatList thread={thread} threadInfo={threadInfo} onSetRead={this.handleSetRead} height={height} />
        {hasWritePermission ? (
          <ChatTextField
            maxChatMessageSize={maxChatMessageSize}
            value={currentMessage || ''}
            onChange={this.handleValueChange}
            onSubmitClick={this.handleChatTextSubmitClicked}
            intl={intl}
          />
        ) : (
          <NoWritePermission />
        )}
      </div>
    );
  }

  handleChatTextSubmitClicked = () => {
    const { author, chatThreadId, currentMessage, onSubmitChatMessage, dispatch } = this.props;

    if (currentMessage) {
      const chatMessage = createNewChatMessage({
        threadId: chatThreadId,
        author: author || '',
        message: currentMessage,
      });

      onSubmitChatMessage(dispatch, chatMessage);
    }
  };

  handleValueChange = (value: string) => {
    const { chatThreadId, dispatch } = this.props;
    if (chatThreadId) {
      dispatch(editChatText(chatThreadId, value));
    }
  };

  handleSetRead = (messageId: string) => {
    const { chatThreadId, dispatch } = this.props;
    if (chatThreadId) {
      dispatch(setChatThreadRead(chatThreadId, messageId));
    }
  };
}

function NoWritePermission() {
  return (
    <span className={styles.noWritePermission}>
      <FormattedMessage id="api.Chat.noWritePermission" defaultMessage="You are not allowed to create new messages." />
    </span>
  );
}

export default connect(mapStateToProps)(Chat);
