import * as React from 'react';

import LinkButton from './LinkButton';
import styles from './Menu.module.css';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  linkClass: string;
  onClick: () => void;
};

export default class MenuItem extends React.PureComponent<Props> {
  render() {
    const { title, subtitle, linkClass } = this.props;

    return (
      <div className={styles.menuItem}>
        <LinkButton className={`${linkClass} ${styles.menuLink}`} onClick={this.handleClick}>
          {title}
        </LinkButton>
        <br />
        {subtitle}
      </div>
    );
  }

  handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.onClick();
  };
}
