import { IncidentStatus } from '../../api/types';
import { DataFilterType } from '../../api/reducers/config';

export type DisplayFilterType = DataFilterType | 'locations';

export type FilterAction =
  | { type: 'SET_FILTER_INCIDENT_STATUS'; status: IncidentStatus | null | undefined }
  | { type: 'SET_FILTER_CRITERIA'; filterType: DisplayFilterType; criteria: string[] }
  | { type: 'RESET_FILTER' }
  | { type: 'SET_FILTER_REMID'; remID: string };

export function setFilterIncidentStatus(status: IncidentStatus | null | undefined): FilterAction {
  return { type: 'SET_FILTER_INCIDENT_STATUS', status };
}

export function setFilterCriteria(filterType: DisplayFilterType, criteria: string[]): FilterAction {
  return { type: 'SET_FILTER_CRITERIA', filterType, criteria };
}

export function resetFilter(): FilterAction {
  return { type: 'RESET_FILTER' };
}

export function setRemId(remID: string): FilterAction {
  return { type: 'SET_FILTER_REMID', remID };
}
