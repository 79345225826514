import { select, takeLatest } from 'redux-saga/effects';

import wrapApiCallActions from '../../api/wrapApiCallActions';
import { fetchAvailableFilterCriteria, getDeviceDataFilter } from '../../api';
import { AvailableFilterCriteria } from '../../api/types';
import { FetchDataFilterCriteriaResult } from '../actions/dataFilter';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';

export default function* fetchDataFilterCriteriaSaga(): Generator<any, any, any> {
  yield takeLatest('FETCH_DATAFILTER_CRITERIA', performFetchDataFilterCriteria);
}

function* performFetchDataFilterCriteria() {
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);

  if (device) {
    yield* wrapApiCallActions('FETCH_DATAFILTER_CRITERIA', doFetchDataFilterCriteria(device.deviceId));
  }
}

function* doFetchDataFilterCriteria(deviceId) {
  const availableOptions: AvailableFilterCriteria = yield* fetchAvailableFilterCriteria();
  const criteria: AvailableFilterCriteria = yield* getDeviceDataFilter(deviceId);

  const result: FetchDataFilterCriteriaResult = { availableOptions, criteria };
  return result;
}
