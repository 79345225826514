import { select, call, take } from 'redux-saga/effects';

import { getConfiguration } from '../../api';
import { GetConfigResult } from '../../api/config';
import { serverUrlSelector } from '../../api/reducers/appConfig';
import { clientConfigSelector, lastModifiedSelector } from '../../api/reducers/config';
import wrapApiCallActions from '../../api/wrapApiCallActions';

export default function* loadConfigurationSaga(): Generator<any, any, any> {
  while (true) {
    const serverUrl: string = yield select(serverUrlSelector);
    const lastModified: string | null | undefined = yield select(lastModifiedSelector);

    yield* wrapApiCallActions('LOAD_CONFIGURATION', performGetConfiguration(serverUrl, lastModified));
    const clientConfig = yield select(clientConfigSelector);

    if (clientConfig) {
      // Configuration was either loaded successfully or still cached.
      break;
    } else {
      yield take('LOAD_CONFIGURATION_RETRY');
    }
  }
}

function* performGetConfiguration(serverUrl, lastModified) {
  const result: GetConfigResult = yield call(getConfiguration, serverUrl, lastModified);
  return result;
}
