import simpleFetch from '../../shared/apiCalls/simpleFetch';
import handleError from '../../shared/apiCalls/handleError';
import { GetTenantResponse } from '../types';

export function getTenant(serverUrl: string, tenantKey: string): Promise<GetTenantResponse> {
  return simpleFetch(`${serverUrl}/api/v1/tenant/${tenantKey}`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(handleError)
    .then(response => response.json());
}
