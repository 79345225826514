import { IncidentsViewMode } from '../../common/selectors/incidentsByViewMode';

export default function getViewModeByPath(path: string): IncidentsViewMode | null | undefined {
  switch (path) {
    case '/incidents/all':
      return 'all';
    case '/incidents/favorites':
      return 'favorites';
    case '/incidents/filter':
      return 'filter';
    default:
      return null;
  }
}
