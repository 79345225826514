import * as React from 'react';
import { connect } from 'react-redux';
import { History, Location } from 'history';

import { IncidentData, IncidentId } from '../../api/incidentsTypes';
import { dataFilterTypesSelector, DataFilterType, canSeeIncidentTypeSelector } from '../../api/reducers/config';
import { DispatchProps } from '../../common/actions';
import { ReduxState } from '../../common/reducers';
import { IncidentsMap } from '../../common/reducers/incidents';
import incidentsByViewModeSelector, {
  IncidentsViewMode,
  IncidentsByViewMode,
} from '../../common/selectors/incidentsByViewMode';
import { replacedByIncidentSelector } from '../../common/selectors/replacesIncidents';
import iconNameSelector from '../../common/selectors/iconName';
import { incidentRead, setIncidentSelected, toggleFavorite } from '../actions';
import NoIncidents from './NoIncidents';
import IncidentRow from './IncidentRow';
import IncidentHeader from './IncidentHeader';
import IncidentDetail from './IncidentDetail';
import IncidentListContent from './IncidentListContent';
import { InvisibleRow } from './IncidentListTable';

type OwnProps = {
  viewMode: IncidentsViewMode;
  history: History;
  location: Location;
};

type StoreProps = {
  incidentsByViewMode: IncidentsByViewMode;
  replacedByMap: IncidentsMap;
  getIconName: (data: IncidentData) => string;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  canSeeIncidentType: boolean;
};

type Props = OwnProps & StoreProps & DispatchProps;

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    incidentsByViewMode: incidentsByViewModeSelector(state),
    replacedByMap: replacedByIncidentSelector(state),
    getIconName: iconNameSelector(state),
    dataFilterTypes: dataFilterTypesSelector(state),
    canSeeIncidentType: canSeeIncidentTypeSelector(state),
  };
}

class IncidentsList extends React.Component<Props> {
  scrollRef: HTMLElement | null = null;
  incidentRefs: {
    [id: string]: HTMLElement | null;
  } = {};

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (location.search && location.search !== prevProps.location.search) {
      const id = this.getParameterByName('id', location.search);

      if (id) {
        this.scrollToId(id);
      }
    }
  }

  render() {
    const { getIconName, viewMode, dataFilterTypes, replacedByMap, canSeeIncidentType } = this.props;
    const incidents = this.props.incidentsByViewMode[viewMode];

    if (incidents.length === 0) {
      return <NoIncidents viewMode={viewMode} />;
    }

    // Workaround for issue #1646
    const rows = [
      <InvisibleRow
        key="invisibleRow"
        showChatColumn={true}
        showFavoriteColumn={true}
        canSeeIncidentType={canSeeIncidentType}
      />,
    ];

    incidents.forEach(incident => {
      const replacedByIncident = replacedByMap[incident.incidentId];
      const iconName = getIconName(incident.currentData);
      const detailsOpen = incident.isSelected || viewMode === ('favorites' as IncidentsViewMode);

      if (detailsOpen) {
        const detailKey = `details${incident.incidentId}`;
        rows.push(
          <IncidentHeader
            key={incident.incidentId}
            iconName={iconName}
            incident={incident}
            viewMode={viewMode}
            onFavoriteButtonPressed={this.handleFavoriteButtonPressed}
            onRowClicked={this.handleRowClicked}
            onReadButtonClicked={this.handleReadButtonClicked}
            setIncidentRef={this.setIncidentRef}
            replacedByIncident={replacedByIncident}
            canSeeIncidentType={canSeeIncidentType}
          />
        );
        rows.push(
          <IncidentDetail
            key={detailKey}
            incident={incident}
            onLinkedIncidentPressed={this.handleLinkedIncidentPressed}
            replacedByIncident={replacedByIncident}
          />
        );
      } else {
        rows.push(
          <IncidentRow
            key={incident.incidentId}
            iconName={iconName}
            incident={incident}
            replacedByIncident={replacedByIncident}
            dataFilterTypes={dataFilterTypes}
            onFavoriteButtonPressed={this.handleFavoriteButtonPressed}
            onRowClicked={this.handleRowClicked}
            setIncidentRef={this.setIncidentRef}
            canSeeIncidentType={canSeeIncidentType}
          />
        );
      }
    });

    return (
      <IncidentListContent
        showChatColumn={true}
        showFavoriteColumn={true}
        showTableHeader={viewMode !== ('favorites' as IncidentsViewMode)}
        canSeeIncidentType={canSeeIncidentType}
        dataFilterTypes={dataFilterTypes}
        setScrollRef={this.setScrollRef}>
        {rows}
      </IncidentListContent>
    );
  }

  handleFavoriteButtonPressed = incident => {
    this.props.dispatch(toggleFavorite(incident.incidentId));
  };

  handleRowClicked = incident => {
    const { dispatch } = this.props;
    dispatch(setIncidentSelected(incident.incidentId, !incident.isSelected));
  };

  handleLinkedIncidentPressed = (id: IncidentId) => {
    const { history, dispatch } = this.props;
    history.push(`/incidents/all?id=${id}`);
    dispatch(setIncidentSelected(id, true));
  };

  handleReadButtonClicked = incident => {
    const { dispatch } = this.props;
    dispatch(incidentRead(incident.incidentId));
  };

  getParameterByName(name: string, search: string) {
    const result = search.match(new RegExp(`(\\?|&)${name}(\\[\\])?=([^&]*)`));
    return result ? result[3] : false;
  }

  setScrollRef = (ref: HTMLElement | null) => {
    this.scrollRef = ref;
  };

  setIncidentRef = (id: IncidentId, ref: HTMLElement) => {
    if (!this.incidentRefs) {
      this.incidentRefs = {};
    }

    this.incidentRefs[id] = ref;
  };

  scrollToId(id: IncidentId) {
    const { dispatch } = this.props;

    setTimeout(() => {
      const target = this.incidentRefs[id];
      if (target) {
        dispatch(setIncidentSelected(id, true));
        if (this.scrollRef) {
          this.scrollRef.scrollTop = target.offsetTop;
        }
      }
    }, 250);
  }
}

export default connect(mapStateToProps)(IncidentsList);
