import * as React from 'react';
import { connect } from 'react-redux';

import hasChatSelector from '../../api/selectors/hasChat';
import hasNewMessagesSelector from '../../api/selectors/hasNewMessages';
import { DispatchProps } from '../../common/actions';
import { ReduxState } from '../../common/reducers';
import styles from './ChatIndicator.module.css';

type OwnProps = {
  chatThreadId: string;
  closed?: boolean;
};

type StoreProps = {
  hasChat: boolean;
  hasNewMessages: boolean;
};

type Props = OwnProps & StoreProps & DispatchProps;

function mapStateToProps(state: ReduxState, ownProps: OwnProps): StoreProps {
  const { chatThreadId } = ownProps;
  return {
    hasChat: hasChatSelector(state)(chatThreadId),
    hasNewMessages: hasNewMessagesSelector(state)(chatThreadId),
  };
}

class ChatIndicator extends React.Component<Props> {
  render() {
    const { hasChat, hasNewMessages, closed } = this.props;
    if (!hasChat) {
      return null;
    }

    const highlightStyle = hasNewMessages ? styles.highlight : '';
    const iconStyle = closed ? styles.greyChatIcon : styles.tintChatIcon;

    return <span className={`icon-chat ${iconStyle} ${highlightStyle}`} />;
  }
}

export default connect(mapStateToProps)(ChatIndicator);
