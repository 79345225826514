import { CrowdTaskParticipationStatus } from '../types';

export function isClosed(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'TERMINATED':
    case 'CANCELLED':
      return true;
    default:
      return false;
  }
}

export function isOffered(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'OFFERED':
      return true;
    default:
      return false;
  }
}

export function isReadyArrived(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'READY':
    case 'ARRIVED':
      return true;
    default:
      return false;
  }
}

export function isOfferedConfirmedReadyArrived(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'OFFERED':
    case 'CONFIRMED':
    case 'READY':
    case 'ARRIVED':
      return true;
    default:
      return false;
  }
}

export function isActive(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'READY':
    case 'ARRIVED':
      return true;
    default:
      return false;
  }
}

export function isOpenTerminated(status: CrowdTaskParticipationStatus): boolean {
  switch (status) {
    case 'OPEN':
    case 'TERMINATED':
      return true;
    default:
      return false;
  }
}

// If this is used for anything else than meeting point visibility -> rename!
export function isMeetingPointVisible(status: CrowdTaskParticipationStatus) {
  switch (status) {
    case 'CONFIRMED':
    case 'READY':
    case 'ARRIVED':
    case 'CANCELLED':
      return true;
    default:
      return false;
  }
}

export function convertForGrouping(status: CrowdTaskParticipationStatus) {
  switch (status) {
    case 'READY':
      return 'CONFIRMED';
    default:
      return status;
  }
}
