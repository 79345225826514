import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import styles from './PopupModal.module.css';

type Props = {
  title: React.ReactNode;
  body: React.ReactNode;
  submitText?: React.ReactNode;
  show: boolean;
  closeButton: boolean;
  dark?: boolean;
  nextDisabled?: boolean;
  onHide?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
};

class PopupModal extends React.Component<Props> {
  render() {
    const {
      title,
      body,
      closeButton,
      submitText,
      show,
      dark,
      onBack,
      onHide,
      onNext,
      onSubmit,
      nextDisabled,
    } = this.props;

    const leftButtonStyle = dark ? styles.darkLeftButton : '';
    const rightButtonStyle = dark ? styles.darkRightButton : '';
    const bodyStyle = dark ? styles.darkBody : '';
    const fontStyle = dark ? { color: '#fff' } : {};
    const style = dark ? { backgroundColor: '#333' } : {};

    let leftButton: JSX.Element | null = null;
    let rightButton: JSX.Element | null = null;
    const cancel = <FormattedMessage id="PopupModal.cancel" defaultMessage="Cancel" />;
    const close = <FormattedMessage id="PopupModal.close" defaultMessage="Close" />;

    if (onBack) {
      leftButton = (
        <Button key="Back" className={leftButtonStyle} onClick={onBack}>
          <FormattedMessage id="PopupModal.back" defaultMessage="Back" />
        </Button>
      );
    } else if (onHide) {
      leftButton = (
        <Button key="Cancel" className={leftButtonStyle} onClick={onHide}>
          {submitText ? cancel : close}
        </Button>
      );
    }

    if (onNext) {
      rightButton = (
        <Button className={rightButtonStyle} key="Next" disabled={nextDisabled} onClick={onNext}>
          <FormattedMessage id="PopupModal.next" defaultMessage="Next" />
        </Button>
      );
    } else if (dark) {
      rightButton = (
        <Button key="Submit" className={rightButtonStyle} disabled={!onSubmit || nextDisabled} onClick={onSubmit}>
          {submitText}
        </Button>
      );
    }

    const actions = [leftButton, rightButton];

    return (
      <Dialog
        open={show}
        onClose={this.handleHide}
        fullWidth={true}
        PaperProps={{ style }}
        disableBackdropClick={!closeButton}
        disableEscapeKeyDown={!closeButton}>
        <DialogTitle disableTypography={true} style={fontStyle}>
          {<h4>{title}</h4>}
        </DialogTitle>
        <DialogContent>
          <div className={bodyStyle}>{body}</div>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }

  handleHide = () => {
    const { onHide } = this.props;
    if (onHide) {
      onHide();
    }
  };
}

export default PopupModal;
