import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Tab.module.css';

type Props = {
  label: React.ReactNode;
  path: string;
};

export default function Tab({ label, path }: Props) {
  return (
    <NavLink to={path} className={styles.tabLabel} activeClassName={styles.tabLabelActive}>
      {label}
    </NavLink>
  );
}
