import {
  CreateCrowdTaskDeviceRequest,
  CreateCrowdTaskDeviceResponse,
  UpdateCrowdTaskDeviceRequest,
  UpdateCrowdTaskDeviceResponse,
  GetDeviceResponse,
} from '../../types';
import apiCall from '../../apiCall';

const staffMemberDevicePath = 'crowdtask/staffmember/devices';

export function* createStaffMemberDevice(request: CreateCrowdTaskDeviceRequest): Generator<any, any, any> {
  const result: CreateCrowdTaskDeviceResponse = yield* apiCall(staffMemberDevicePath, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return result;
}

export function* updateStaffMemberDevice(
  deviceId: string,
  request: UpdateCrowdTaskDeviceRequest
): Generator<any, any, any> {
  const result: UpdateCrowdTaskDeviceResponse = yield* apiCall(`${staffMemberDevicePath}/${deviceId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });

  return result;
}

export function* getStaffMemberDevice(deviceId: string): Generator<any, any, any> {
  const result: GetDeviceResponse = yield* apiCall(`${staffMemberDevicePath}/${deviceId}`);
  return result;
}
