import { performFetch } from '../shared/apiCalls/apiCall';
import {
  AppType,
  PostLongPollingCreateSessionRequest,
  PostLongPollingCreateSessionResponse,
  GetLongPollingResponse,
} from './types';
import apiCall from './apiCall';

export function* createSession(appType: AppType, deviceId?: string | null | undefined): Generator<any, any, any> {
  const request: PostLongPollingCreateSessionRequest = deviceId ? { appType, deviceId } : { appType };

  const response: PostLongPollingCreateSessionResponse = yield* apiCall('xhr/create', {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return response.sessionId;
}

// Polling does not need OAuth2 authentication, just the sessionId.
// Therefore, do not use the apiCall saga here.
export function poll(serverUrl: string, sessionId: string): Promise<GetLongPollingResponse> {
  return performFetch(`${serverUrl}/api/v1/xhr/${sessionId}?t=${Date.now()}`, {
    headers: {
      Accept: 'application/json',
    },
    timeout: 30000,
  });
}
