import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { values, groupBy, intersection } from 'lodash-es';
import Select from '@material-ui/core/Select';
import MuiButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import ModalDialog from '../../shared/modalDialog/ModalDialog';
import Button, { SecondaryButton } from '../../api/components/Button';
import FilterableTableColumn from '../../shared/filterableTableColumn/FilterableTableColumn';
import { getFullName } from '../../shared/utils/text';
import {
  isClosed,
  isOffered,
  isOfferedConfirmedReadyArrived,
  isOpenTerminated,
  convertForGrouping,
} from '../../api/utils/participationStatus';
import { CrowdTaskParticipation, CrowdTaskParticipationStatus } from '../../api/types';
import { WebReduxState } from '../reducers';
import colorStyles from '../css/colors.module.css';
import styles from './ParticipationsList.module.css';

type OwnProps = {
  onConfirm: (p: CrowdTaskParticipation[]) => void;
  onReject: (p: CrowdTaskParticipation[]) => void;
};

type StoreProps = {
  groupedParticipations:
    | {
        [status in CrowdTaskParticipationStatus | 'all']: CrowdTaskParticipation[];
      }
    | null;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    groupedParticipations: groupedParticipationsSelector(state),
  };
}

type GroupedParticipations = {
  [status in CrowdTaskParticipationStatus | 'all']: CrowdTaskParticipation[];
};

const groupedParticipationsSelector: (state: WebReduxState) => GroupedParticipations | null = createSelector(
  state => state.participations.participations.byId,
  state => state.participations.selectedCallOutId,
  state => state.callOuts.callOuts.byId,
  (participationsById, selectedCallOutId, callOutsById) => {
    if (!selectedCallOutId) {
      return null;
    }

    const callOut = callOutsById[selectedCallOutId];
    if (!callOut) {
      return null;
    }

    const compare = (a: CrowdTaskParticipation, b: CrowdTaskParticipation) => {
      const s1 = rankParticipationStatus(a.status);
      const s2 = rankParticipationStatus(b.status);

      if (s2 - s1 !== 0) {
        return s2 - s1;
      }

      const d1 =
        intersection(a.languages, callOut.languages).length +
        intersection(a.specialSkills, callOut.specialSkills).length;
      const d2 =
        intersection(b.languages, callOut.languages).length +
        intersection(b.specialSkills, callOut.specialSkills).length;

      if (d2 - d1 !== 0) {
        return d2 - d1;
      }

      const n1 = getFullName({ firstName: a.firstName, lastName: a.lastName });
      const n2 = getFullName({ firstName: b.firstName, lastName: b.lastName });

      return n1.localeCompare(n2, [], { sensitivity: 'accent' });
    };

    const sorted = values(participationsById).sort(compare);
    const grouped = groupBy(sorted, (p: CrowdTaskParticipation) => convertForGrouping(p.status));

    return {
      ...grouped,
      all: sorted.filter(p => !isOpenTerminated(p.status)),
    } as GroupedParticipations;
  }
);

function rankParticipationStatus(status: CrowdTaskParticipationStatus) {
  switch (status) {
    case 'OFFERED':
      return 5;
    case 'CONFIRMED':
    case 'READY':
      return 4;
    case 'ARRIVED':
      return 3;
    case 'CANCELLED':
      return 2;
    case 'TERMINATED':
      return 1;
    case 'OPEN':
    default:
      return 0;
  }
}

type State = {
  filter: CrowdTaskParticipationStatus | null | undefined;
  staffMemberInfoParticipation: CrowdTaskParticipation | null | undefined;
};

function getCellStyles(anyOffered: boolean, anyOfferedConfirmedReadyArrived: boolean) {
  if (anyOffered && anyOfferedConfirmedReadyArrived) {
    return {
      confirmationHeader: styles.confirmationHeader2Buttons,
      textCell: styles.textCell2Buttons,
      confirmationButtonCellStyle: styles.confirmationButtonCell,
      rejectionButtonCellStyle: styles.confirmationButtonCell,
    };
  } else if (anyOffered || anyOfferedConfirmedReadyArrived) {
    return {
      confirmationHeader: styles.confirmationHeader1Button,
      textCell: styles.textCell1Button,
      confirmationButtonCellStyle: anyOffered ? styles.confirmationButtonCell : styles.noConfirmationButtonCell,
      rejectionButtonCellStyle: anyOfferedConfirmedReadyArrived
        ? styles.confirmationButtonCell
        : styles.noConfirmationButtonCell,
    };
  }
  return {
    confirmationHeader: styles.emptyHeader,
    textCell: styles.textCellNoButtons,
    confirmationButtonCellStyle: styles.noConfirmationButtonCell,
    rejectionButtonCellStyle: styles.noConfirmationButtonCell,
  };
}

class ParticipationsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filter: null,
      staffMemberInfoParticipation: null,
    };
  }

  render() {
    const { onConfirm, onReject, groupedParticipations } = this.props;
    const { filter, staffMemberInfoParticipation } = this.state;

    if (!groupedParticipations) {
      return null;
    }
    const filteredParticipations = filter ? groupedParticipations[filter] || [] : groupedParticipations.all;

    const anyOffered = filteredParticipations.some(p => isOffered(p.status));
    const anyOfferedConfirmedReadyArrived = filteredParticipations.some(p => isOfferedConfirmedReadyArrived(p.status));
    const { confirmationHeader, textCell, confirmationButtonCellStyle, rejectionButtonCellStyle } = getCellStyles(
      anyOffered,
      anyOfferedConfirmedReadyArrived
    );

    return (
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.iconCell} />
              <th className={styles.iconCell} />
              <th className={textCell}>
                <FormattedMessage id="ParticipationsList.participant" defaultMessage="Participant" />
              </th>
              <th className={textCell}>
                <FormattedMessage id="ParticipationsList.skills" defaultMessage="Special Skills" />
              </th>
              <th className={textCell}>
                <FormattedMessage id="ParticipationsList.language" defaultMessage="Languages" />
              </th>
              <th className={styles.statusCell}>
                <FilterableTableColumn
                  activeClassName={colorStyles.active}
                  message={<FormattedMessage id="ParticipationsList.status" defaultMessage="Status" />}
                  items={[null, 'OFFERED', 'CONFIRMED', 'ARRIVED', 'CANCELLED', 'OPEN', 'TERMINATED']}
                  selectedItem={filter}
                  onChange={this.handleStatusFilterChanged}
                  renderItem={this.renderItem}
                />
              </th>
              <th className={styles.phoneCallCell} />
              <th colSpan={2} className={confirmationHeader}>
                <BulkActionMenu participations={filteredParticipations} onConfirm={onConfirm} onReject={onReject} />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredParticipations.length > 0 ? (
              filteredParticipations.map(p => (
                <ParticipationRow
                  textCellStyle={textCell}
                  participation={p}
                  handleInfo={this.onInfoClicked}
                  handleConfirm={onConfirm}
                  handleReject={onReject}
                  key={p.staffMemberId}
                  confirmationButtonCellStyle={confirmationButtonCellStyle}
                  rejectionButtonCellStyle={rejectionButtonCellStyle}
                />
              ))
            ) : (
              <tr>
                <td className={styles.noParticipations} colSpan={9}>
                  <FormattedMessage id="ParticipationsList.noParticipations" defaultMessage="No Participations" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <InfoModal onClose={this.handleStaffMemberInfoModalClose} participation={staffMemberInfoParticipation} />
      </div>
    );
  }

  onInfoClicked = (p: CrowdTaskParticipation) => {
    this.setState({ staffMemberInfoParticipation: p });
  };

  handleStaffMemberInfoModalClose = () => {
    this.setState({ staffMemberInfoParticipation: null });
  };

  handleStatusFilterChanged = (value: CrowdTaskParticipationStatus | null | undefined) => {
    this.setState({ filter: value });
  };

  renderItem = (value: CrowdTaskParticipationStatus | null | undefined) => {
    const { groupedParticipations } = this.props;
    if (!groupedParticipations) {
      return null;
    }

    if (!value) {
      return (
        <div>
          <FormattedMessage id="ParticipationsList.all" defaultMessage="All" />
          {` (${groupedParticipations.all.length})`}
        </div>
      );
    } else if (value === 'OPEN') {
      return (
        <div>
          <Divider />
          <ParticipationStatusMessage status={value} />
          {` (${groupedParticipations[value] ? groupedParticipations[value].length : '0'})`}
        </div>
      );
    } else {
      return (
        <div>
          <ParticipationStatusMessage status={value} />
          {` (${groupedParticipations[value] ? groupedParticipations[value].length : '0'})`}
        </div>
      );
    }
  };
}

type ParticipationRowProps = {
  handleInfo: (p: CrowdTaskParticipation) => void;
  handleConfirm: (p: CrowdTaskParticipation[]) => void;
  handleReject: (p: CrowdTaskParticipation[]) => void;
  participation: CrowdTaskParticipation;
  confirmationButtonCellStyle: string;
  rejectionButtonCellStyle: string;
  textCellStyle: string;
};

class ParticipationRow extends React.PureComponent<ParticipationRowProps> {
  render() {
    const {
      participation: { firstName, lastName, specialSkills, languages, status },
      textCellStyle,
      confirmationButtonCellStyle,
      rejectionButtonCellStyle,
    } = this.props;

    const rowStyle = isClosed(status) ? colorStyles.grey : '';

    return (
      <tr className={rowStyle}>
        <td className={styles.iconCell}>
          <span className={`icon-user ${styles.userIcon}`} />
        </td>
        <td className={styles.iconCell}>
          <span className={`icon-info_nofill ${styles.infoIcon}`} onClick={this.handleInfoClicked} />
        </td>
        <td className={textCellStyle}>{`${firstName} ${lastName}`}</td>
        <td className={textCellStyle}>{specialSkills.join(', ')}</td>
        <td className={textCellStyle}>{languages.join(', ')}</td>
        <td className={styles.statusCell}>{<ParticipationStatusMessage status={status} />}</td>
        <td className={styles.phoneCallCell}>
          <SecondaryButton className={styles.phoneButton} fullWidth={true} onClick={this.handlePhoneClicked}>
            <span className={`icon-phone ${styles.phoneActionIcon}`} />
          </SecondaryButton>
        </td>
        <td className={confirmationButtonCellStyle}>
          {isOffered(status) && (
            <SecondaryButton className={styles.confirmationButton} fullWidth={true} onClick={this.handleConfirmClicked}>
              <span className={`icon-accept ${styles.actionIcon}`} />
            </SecondaryButton>
          )}
        </td>
        <td className={rejectionButtonCellStyle}>
          {isOfferedConfirmedReadyArrived(status) && (
            <Button className={styles.actionButton} fullWidth={true} onClick={this.handleRejectClicked}>
              <span className={`icon-reject ${styles.actionIcon}`} />
            </Button>
          )}
        </td>
      </tr>
    );
  }
  handleInfoClicked = () => {
    const { participation, handleInfo } = this.props;
    handleInfo(participation);
  };

  handleConfirmClicked = () => {
    const { participation, handleConfirm } = this.props;
    handleConfirm([participation]);
  };

  handleRejectClicked = () => {
    const { participation, handleReject } = this.props;
    handleReject([participation]);
  };

  handlePhoneClicked = () => {
    const {
      participation: { phone },
    } = this.props;
    window.location.href = `tel:${phone}`;
  };
}

export default connect(mapStateToProps)(ParticipationsList);

function ParticipationStatusMessage({ status }) {
  switch (status) {
    case 'OPEN':
      return <FormattedMessage id="ParticipationsList.open" defaultMessage="Open" />;
    case 'OFFERED':
      return <FormattedMessage id="ParticipationsList.offered" defaultMessage="Offered" />;
    case 'CONFIRMED':
    case 'READY':
      return <FormattedMessage id="ParticipationsList.confirmed" defaultMessage="Confirmed" />;
    case 'ARRIVED':
      return <FormattedMessage id="ParticipationsList.arrived" defaultMessage="Arrived" />;
    case 'TERMINATED':
      return <FormattedMessage id="ParticipationsList.terminated" defaultMessage="Terminated" />;
    case 'CANCELLED':
      return <FormattedMessage id="ParticipationsList.cancelled" defaultMessage="Cancelled" />;
    default:
      break;
  }
  return <FormattedMessage id="ParticipationsList.unknown" defaultMessage="Unknown" />;
}

type BulkActionMenuProps = {
  participations: CrowdTaskParticipation[];
  onConfirm: (participations: CrowdTaskParticipation[]) => void;
  onReject: (participations: CrowdTaskParticipation[]) => void;
};

type BulkActionMenuState = {
  actionableParticipations: CrowdTaskParticipation[];
};

class BulkActionMenu extends React.PureComponent<BulkActionMenuProps, BulkActionMenuState> {
  state: BulkActionMenuState;

  constructor(props: BulkActionMenuProps) {
    super(props);
    this.state = this.getUpdatedState(props.participations);
  }

  componentWillReceiveProps(nextProps: BulkActionMenuProps) {
    this.setState(this.getUpdatedState(nextProps.participations));
  }

  getUpdatedState(participations: CrowdTaskParticipation[]) {
    return {
      actionableParticipations: participations.filter(p => isOffered(p.status)),
    };
  }

  render() {
    const { actionableParticipations } = this.state;
    const anyActionableParticipations = actionableParticipations.length;

    if (!anyActionableParticipations) {
      return null;
    }

    return (
      <Select
        onChange={this.handleChange}
        className={styles.participationDropDown}
        disableUnderline={true}
        value="ACTIONS">
        <MenuItem disabled={true} value="ACTIONS">
          {<FormattedMessage id="ParticipationsList.actions" defaultMessage="Actions" />}
        </MenuItem>
        <Divider />
        <MenuItem value="CONFIRM">
          {<FormattedMessage id="ParticipationsList.confirm" defaultMessage="Confirm" />}
        </MenuItem>
        <MenuItem value="REJECT">
          {<FormattedMessage id="ParticipationsList.reject" defaultMessage="Reject" />}
        </MenuItem>
      </Select>
    );
  }

  handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const { onReject, onConfirm } = this.props;
    const { actionableParticipations } = this.state;

    switch (event.target.value) {
      case 'CONFIRM':
        onConfirm(actionableParticipations);
        break;
      case 'REJECT':
        onReject(actionableParticipations);
        break;
      default:
        break;
    }
  };
}

function InfoModal({ participation, onClose }) {
  if (!participation) {
    return null;
  }

  const body = (
    <div>
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.firstName" defaultMessage="First name" />}
        value={participation.firstName}
      />
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.lastName" defaultMessage="Last name" />}
        value={participation.lastName}
      />
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.phoneNumber" defaultMessage="Phone number" />}
        value={participation.phone}
      />
      <ItemRow
        displayKey={
          <FormattedMessage
            id="ParticipationsList.InfoModal.identificationNumber"
            defaultMessage="Identification number"
          />
        }
        value={participation.identificationNumber}
      />
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.organization" defaultMessage="Organization" />}
        value={participation.organization}
      />
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.placeOfWork" defaultMessage="Place of work" />}
        value={participation.placeOfWork}
      />
      <ItemRow
        displayKey={
          <FormattedMessage id="ParticipationsList.InfoModal.specialSkills" defaultMessage="Special skills" />
        }
        value={participation.specialSkills.join(', ')}
      />
      <ItemRow
        displayKey={
          <FormattedMessage id="ParticipationsList.InfoModal.qualifications" defaultMessage="Qualifications" />
        }
        value={participation.qualifications.join(', ')}
      />
      <ItemRow
        displayKey={<FormattedMessage id="ParticipationsList.InfoModal.languages" defaultMessage="Languages" />}
        value={participation.languages.join(', ')}
      />
      <ItemRow
        displayKey={
          <FormattedMessage id="ParticipationsList.InfoModal.healthRestrictions" defaultMessage="Health restrictions" />
        }
        value={participation.healthRestrictions.join(', ')}
      />
    </div>
  );

  const actions = [
    <MuiButton variant="text" key="close" color="primary" onClick={onClose}>
      <FormattedMessage id="InfoModal.close" defaultMessage="Close" />
    </MuiButton>,
  ];

  return (
    <ModalDialog
      title={<FormattedMessage id="InfoModal.title" defaultMessage="Staff member info" />}
      actions={actions}
      open={Boolean(participation)}>
      {body}
    </ModalDialog>
  );
}

type ItemRowProps = {
  displayKey: React.ReactNode;
  value: React.ReactNode;
};

function ItemRow({ displayKey, value }: ItemRowProps) {
  return (
    <div className={styles.itemRow}>
      <span className={styles.itemRowKey}>{displayKey}</span>
      <span className={styles.itemRowValue}>{value || '-'}</span>
    </div>
  );
}
