import * as React from 'react';

import { FilterCriterion } from '../../common/selectors/filterCriteria';
import inputStyles from './input.module.css';

type Props<T extends string> = {
  criterion: FilterCriterion;
  filterPrefix: string;
  filterType: T;
  onChange?: (criterionName: string, type: T) => void;
};

// Using a type parameter because the filterType is either a DataFilterType or a DisplayFilterType.
// (The latter includes "locations", too.)
export default class FilterCheckbox<T extends string> extends React.Component<Props<T>> {
  render() {
    const { criterion, filterPrefix, filterType } = this.props;
    const id = `${filterPrefix}-${filterType}-${criterion.name}`;

    return (
      <div className={inputStyles.input}>
        <input type="checkbox" id={id} checked={criterion.isSelected} onChange={this.handleChange} />
        <label htmlFor={id}>
          <span className="labelText greyText">{criterion.name}</span>
        </label>
      </div>
    );
  }

  handleChange = () => {
    const { criterion, onChange, filterType } = this.props;
    if (onChange) {
      onChange(criterion.name, filterType);
    }
  };
}
