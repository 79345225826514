import apiCall from '../../apiCall';
import {
  CrowdTaskParticipation,
  PostCrowdTaskConfirmParticipationsRequest,
  PostCrowdTaskConfirmParticipationsResponse,
  PostCrowdTaskRejectParticipationsRequest,
  PostCrowdTaskRejectParticipationsResponse,
} from '../../types';
import { CallOutKey } from './callOuts';

const operationsPath = 'crowdtask/admin/operations';

export function* getParticipations(callOutKey: CallOutKey): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  const result: CrowdTaskParticipation[] = yield* apiCall(
    `${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/participations`
  );
  return result;
}

export function* confirmParticipations(
  callOutKey: CallOutKey,
  request: PostCrowdTaskConfirmParticipationsRequest
): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  const result: PostCrowdTaskConfirmParticipationsResponse = yield* apiCall(
    `${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/participations/confirm`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    }
  );

  return result;
}

export function* rejectParticipations(
  callOutKey: CallOutKey,
  request: PostCrowdTaskRejectParticipationsRequest
): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  const result: PostCrowdTaskRejectParticipationsResponse = yield* apiCall(
    `${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/participations/reject`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    }
  );

  return result;
}

export function* terminateOpenParticipations(callOutKey: CallOutKey): Generator<any, any, any> {
  const { operationId, taskId, callOutId } = callOutKey;
  const result: PostCrowdTaskRejectParticipationsResponse = yield* apiCall(
    `${operationsPath}/${operationId}/tasks/${taskId}/callouts/${callOutId}/terminateOpenParticipations`,
    {
      method: 'POST',
    }
  );

  return result;
}
