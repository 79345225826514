import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history';
import { Redirect } from 'react-router-dom';
import EditCallOutComponent, { EditCallOutComponentMode } from './EditCallOutComponent';

type Props = {
  match: match<{ operationId?: string; callOutId?: string }>;
  history: History;
};

export default class EditCallOutScreen extends React.PureComponent<Props> {
  mode: EditCallOutComponentMode | null | undefined;

  render() {
    const {
      match: {
        params: { operationId, callOutId },
      },
      history,
    } = this.props;

    if (!callOutId) {
      if (operationId) {
        return <Redirect to={`/operations/${operationId}/callOuts`} />;
      } else {
        return <Redirect to="/operations" />;
      }
    }

    if (!this.mode) {
      // Cache so that we don't create a new object each time.
      this.mode = { callOutId, type: 'edit' };
    }

    return <EditCallOutComponent mode={this.mode} operationId={operationId} history={history} />;
  }
}
