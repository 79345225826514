import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import styles from './InputField.module.css';

export type InputFieldProps = {
  controlId: string;
  formType: string;
  maxLength?: number;
  placeholder?: string;
  value: string;
  dark?: boolean;
  onChange: (value: string) => void;
  onCancel?: () => void;
  onConfirm?: (value: string) => void;
  validate?: (value: string) => boolean;
};

type ValidationState = 'success' | 'warning' | 'error';

type InputFieldState = {
  validation: ValidationState | null | undefined;
};

export default class InputField extends React.Component<InputFieldProps, InputFieldState> {
  constructor(props: InputFieldProps) {
    super(props);
    const { value } = props;

    this.state = {
      validation: this.validateInput(value) ? 'success' : null,
    };
  }

  render() {
    const { controlId, formType, maxLength, placeholder, value, dark, onCancel, onConfirm } = this.props;
    const validationSuccessful = this.state.validation === 'success';
    const validationClass = validationSuccessful ? 'validationSuccessful' : '';

    const className = dark ? styles.dark : '';

    return (
      <FormGroup>
        {onCancel || onConfirm ? (
          <TextField
            id={controlId}
            type={formType}
            onChange={this.handleChange}
            autoFocus={true}
            placeholder={placeholder}
            value={value}
            inputProps={{
              className: className,
              maxLength: maxLength,
              onKeyPress: this.handleEnterKeyPress,
              onKeyDown: this.handleEscKeyPress,
            }}
            InputProps={{
              endAdornment: (
                <div>
                  {onCancel && (
                    <IconButton onClick={onCancel}>
                      <i className={styles.bigX}>×</i>
                    </IconButton>
                  )}
                  {onConfirm && (
                    <IconButton onClick={this.handleConfirm}>
                      <i className={`icon-check ${validationClass}`} />
                    </IconButton>
                  )}
                </div>
              ),
            }}
          />
        ) : (
          <TextField
            id={controlId}
            fullWidth
            type={formType}
            onChange={this.handleChange}
            placeholder={placeholder}
            value={value}
            inputProps={{
              className: className,
              maxLength: maxLength,
            }}
          />
        )}
      </FormGroup>
    );
  }

  handleChange = (e: any) => {
    const { onChange } = this.props;

    this.setState({ validation: this.validateInput(e.target.value) ? 'success' : null });
    onChange(e.target.value);
  };

  handleConfirm = () => {
    const { onConfirm, value } = this.props;
    if (this.state.validation === 'success' && this.validateInput(value) && onConfirm) {
      onConfirm(value);
    }
  };

  handleEnterKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const { onConfirm } = this.props;
      if (onConfirm) {
        this.handleConfirm();
      }
    }
  };

  handleEscKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.key === 'ESC') {
      e.preventDefault();

      const { onCancel } = this.props;
      if (onCancel) {
        onCancel();
      }
    }
  };

  validateInput(value: string) {
    const { validate } = this.props;

    return validate ? validate(value) : this.validateDefault(value);
  }

  validateDefault(value: string) {
    return value.trim().length > 0;
  }
}
