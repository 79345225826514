import * as React from 'react';
import { Field } from 'redux-form';

import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

type SelectProps = {
  name: string;
  className?: string;
  fullWidth?: boolean;
  label?: React.ReactNode;
  validate?: Array<any>;
  disabled?: boolean;
  margin?: 'dense' | 'none';
  children: React.ReactNode;
};

const SelectInput = ({
  className,
  id,
  children,
  labelId,
  defaultValue,
  onChange,
  value,
  disabled,
  input,
  margin,
  meta: { touched, error },
}) => {
  return (
    <MuiSelect
      className={className}
      error={touched && error}
      id={id}
      labelId={labelId}
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue}
      margin={margin}
      inputProps={input}>
      {children}
    </MuiSelect>
  );
};

export function Select({
  name,
  className,
  fullWidth = true,
  label,
  validate,
  disabled,
  margin,
  children,
}: SelectProps) {
  return (
    <FormControl fullWidth={fullWidth} disabled={disabled}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Field name={name} id={name} className={className} margin={margin} component={SelectInput} validate={validate}>
        {children}
      </Field>
    </FormControl>
  );
}

class DeletableChip extends React.Component<{ value: string; onDelete: (value: string) => void }> {
  render() {
    const { value } = this.props;
    return <Chip color="primary" label={value} onMouseDown={this.handleMouseDown} onDelete={this.handleDelete} />;
  }

  // Prevent dropdown from opening on click / mouseDown events.
  handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  handleDelete = () => {
    const { onDelete, value } = this.props;
    onDelete(value);
  };
}

class MultiSelectInput extends React.PureComponent<any> {
  render() {
    const {
      className,
      id,
      labelId,
      children,
      disabled,
      defaultValue,
      input,
      value,
      onChange,
      meta: { touched, error },
    } = this.props;

    return (
      <MuiSelect
        id={id}
        labelId={labelId}
        className={className}
        multiple={true}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        error={touched && error}
        onChange={onChange}
        renderValue={this.renderValue}
        inputProps={input}>
        {children}
      </MuiSelect>
    );
  }

  renderValue = (selected: any) => (
    <div>
      {selected.map((value: string) => (
        <DeletableChip key={value} value={value} onDelete={this.handleDelete} />
      ))}
    </div>
  );

  handleDelete = valueToDelete => {
    const {
      name,
      input: { value, onChange },
    } = this.props;
    const remainingValues = value.filter(val => val !== valueToDelete);
    onChange(remainingValues, value, name);
  };
}

export class MultiSelect extends React.PureComponent<SelectProps> {
  render() {
    const { name, className, fullWidth = true, label, validate, disabled, margin, children } = this.props;
    return (
      <FormControl fullWidth={fullWidth} disabled={disabled}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <Field
          name={name}
          id={name}
          className={className}
          margin={margin}
          component={MultiSelectInput}
          validate={validate}
          format={handleFormat}>
          {children}
        </Field>
      </FormControl>
    );
  }
}

const handleFormat = value => (Array.isArray(value) ? value : []);
