import {
  CreateCrowdTaskDeviceRequest,
  CreateCrowdTaskDeviceResponse,
  UpdateCrowdTaskDeviceRequest,
  UpdateCrowdTaskDeviceResponse,
  GetDeviceResponse,
} from '../../types';
import apiCall from '../../apiCall';

const coordinatorDevicePath = 'crowdtask/coordinator/devices';

export function* createCoordinatorDevice(request: CreateCrowdTaskDeviceRequest): Generator<any, any, any> {
  const result: CreateCrowdTaskDeviceResponse = yield* apiCall(coordinatorDevicePath, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return result;
}

export function* updateCoordinatorDevice(
  deviceId: string,
  request: UpdateCrowdTaskDeviceRequest
): Generator<any, any, any> {
  const result: UpdateCrowdTaskDeviceResponse = yield* apiCall(`${coordinatorDevicePath}/${deviceId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });

  return result;
}

export function* getCoordinatorDevice(deviceId: string): Generator<any, any, any> {
  const result: GetDeviceResponse = yield* apiCall(`${coordinatorDevicePath}/${deviceId}`);
  return result;
}
