import { createSelector } from 'reselect';

import { ReduxState } from '../reducers';

const dataFilterIncidentTypeEmptySelector: (state: ReduxState) => boolean = createSelector(
  state => state.inform.dataFilter.criteria,
  criteria => criteria.incidentTypes.length === 0
);

export default dataFilterIncidentTypeEmptySelector;
