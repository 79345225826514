import * as React from 'react';

import styles from './Buttons.module.css';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export default function Buttons({ className, children }: Props) {
  const completeClassName = className ? `${styles.buttons} ${className}` : styles.buttons;
  return <div className={completeClassName}>{children}</div>;
}
