import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, IntlShape } from 'react-intl';

import { intlSelector } from '../../api/selectors/i18n';
import { SecondaryButton } from '../../api/components/Button';
import {
  changeFeedbackPhoneNumber,
  resetOfflineCache,
  resetFilter,
  cleanup,
  setSupportModalOpen,
  WebDispatchProps,
} from '../actions';
import { WebReduxState } from '../reducers';
import WizardFeedbackPhoneNumberPage from '../wizard/WizardFeedbackPhoneNumberPage';
import textStyles from '../css/text.module.css';
import styles from './Settings.module.css';
import ModalSubSection from './ModalSubSection';

type OwnProps = {};

type StoreProps = {
  intl: IntlShape;
  feedbackPhoneNumber: string;
  phoneNumberToChange: string;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

type State = {
  notificationOpen: boolean;
  isReset: boolean;
};

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    feedbackPhoneNumber: state.settings.feedbackPhoneNumber,
    phoneNumberToChange: state.webNav.wizard.phoneNumber,
    intl: intlSelector(state),
  };
}

class Settings extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      notificationOpen: false,
      isReset: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(changeFeedbackPhoneNumber(this.props.feedbackPhoneNumber));
  }

  render() {
    const { phoneNumberToChange, intl } = this.props;

    const resetSuccessText = (
      <FormattedMessage id="Settings.resetSuccessText" defaultMessage="App reset successfully!" />
    );
    const resetInfoText = (
      <FormattedMessage
        id="Settings.resetInfoText"
        defaultMessage={'This reloads all data from the server!\nFavorites and read status will be lost!'}
      />
    );

    const resetText = this.state.isReset ? (
      resetSuccessText
    ) : (
      <span className={textStyles.preserveLinebreaks}>{resetInfoText}</span>
    );
    const resetNotification = this.state.notificationOpen ? (
      <div className={styles.alertBox}>
        <p>{resetText}</p>
        {this.state.isReset ? null : (
          <div>
            <SecondaryButton onClick={this.handleResetConfirmClick}>
              <FormattedMessage id="Settings.ok" defaultMessage="OK" />
            </SecondaryButton>
            <i className="spacer" />
            <SecondaryButton onClick={this.handleResetCloseClick}>
              <FormattedMessage id="Settings.cancel" defaultMessage="Cancel" />
            </SecondaryButton>
            <p />
          </div>
        )}
      </div>
    ) : null;

    return (
      <div>
        <ModalSubSection
          title={<FormattedMessage id="Settings.feedbackPhoneNumber" defaultMessage="Feedback Phone Number" />}
          body={
            <WizardFeedbackPhoneNumberPage
              intl={intl}
              onChange={this.handleChangeCallbackNumber}
              feedbackPhoneNumber={phoneNumberToChange}
            />
          }
        />
        <ModalSubSection
          title={<FormattedMessage id="Settings.resetWebApp" defaultMessage="Reset WebApp" />}
          body={resetNotification}
          onClick={this.handleResetOpenClick}
        />
      </div>
    );
  }

  handleResetOpenClick = () => {
    this.setState({ notificationOpen: true });
  };

  handleResetCloseClick = () => {
    this.setState({ notificationOpen: false });
  };

  handleResetConfirmClick = () => {
    this.props.dispatch(resetOfflineCache());
    this.props.dispatch(cleanup('CACHE'));
    this.props.dispatch(resetFilter());
    this.setState({ isReset: true });
    this.props.dispatch(setSupportModalOpen(false));
  };

  handleChangeCallbackNumber = phoneNumber => {
    this.props.dispatch(changeFeedbackPhoneNumber(phoneNumber));
  };
}

export default connect(mapStateToProps)(Settings);
