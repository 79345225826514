import * as React from 'react';

import styles from './Footer.module.css';

type Props = {
  logo: React.ReactNode;
  statusText: React.ReactNode;
};

export default function Footer({ logo, statusText }: Props) {
  return (
    <div className={styles.footer}>
      {logo && <div className={styles.logoWrapper}>{logo}</div>}
      <div className={styles.statusText}>{statusText}</div>
      <div className={styles.byCcaIO}>
        <a target="_blank" href="https://www.cca.io" rel="noopener noreferrer">
          by <span className={styles.underline}>cca.io</span>
        </a>
      </div>
    </div>
  );
}
