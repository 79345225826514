import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { I18NState } from '../../shared/i18n/reducers/i18n';
import { clientConfigSelector, NavbarItemSpec, ConfigState } from '../reducers/config';
import { AppConfig } from '../reducers/appConfig';
import { UserInfoState } from '../reducers/userInfo';
import hasRoleSelector from '../selectors/hasRole';
import { NavbarInternalLinkItem, NavbarExternalLinkItem } from '../components/NavbarLinkItem';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
  i18n: I18NState;
  userInfo: UserInfoState;
};

type OwnProps = {
  // Need to pass router context so that we rerender if route refreshes
  routerContext: RouteComponentProps;
};

type StoreProps = {
  navbarItems: ReadonlyArray<NavbarItemSpec>;
  currentLocale: string;
  hasRole: (...roles: string[]) => boolean;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: PartialReduxState): StoreProps {
  return {
    navbarItems: clientConfigSelector(state).navbarItems,
    currentLocale: state.i18n.currentLocale,
    hasRole: hasRoleSelector(state),
  };
}

function NavbarConfigItems({ navbarItems, currentLocale, hasRole }: Props) {
  const { origin, pathname } = window.location;
  const locationRootUrl = `${origin}${pathname}`;

  return (
    <>
      {navbarItems.map(item => {
        if (!hasRole(...item.roles)) {
          return null;
        }

        const { title, rootUrl, path } = item;
        const url = `${rootUrl}#${path}`;
        const titleText = title[currentLocale] || title.en;

        return rootUrl === locationRootUrl ? (
          <NavbarInternalLinkItem key={url} title={titleText} path={path} />
        ) : (
          <NavbarExternalLinkItem key={url} title={titleText} url={url} />
        );
      })}
    </>
  );
}

export default connect(mapStateToProps)(NavbarConfigItems);
