import * as React from 'react';

import LinkButton from '../../api/components/LinkButton';
import styles from './ModalSubSection.module.css';

type Props = {
  title: React.ReactNode;
  body: React.ReactNode;
  onClick?: () => void | null | undefined;
};

export default class ModalSubSection extends React.Component<Props> {
  render() {
    const { title, body, onClick } = this.props;
    return (
      <div className={styles.modalSubSection}>
        <h4 className={styles.modalSubHeading}>
          {onClick ? (
            <LinkButton className="pointerCursor underline" onClick={onClick}>
              {title}
            </LinkButton>
          ) : (
            title
          )}
        </h4>
        {body}
      </div>
    );
  }
}
