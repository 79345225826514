import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldInfo } from '../../common/utils/compareIncidentData';
import styles from './DetailRow.module.css';

type Props = {
  name: any;
  data: any;
  fieldInfo?: FieldInfo;
  icon?: string;
  showSeparator: boolean;
};

export default class DetailRow extends React.Component<Props> {
  static defaultProps = {
    showSeparator: true,
  };

  render() {
    const { data, fieldInfo, icon, name, showSeparator } = this.props;
    const detailIcon = icon ? <i className={`icon-${icon} alignLeft ${styles.iconDetail} greyText`} /> : null;
    const highlight = fieldInfo && fieldInfo.changed ? 'highlight' : '';
    const rowStyle = icon ? styles.iconRow : styles.noIconRow;

    const value =
      fieldInfo && fieldInfo.changedToEmpty ? <FormattedMessage id="DetailRow.empty" defaultMessage="(empty)" /> : data;
    if (!value) {
      return null;
    }

    return (
      <li>
        {detailIcon}
        <div className={`${highlight} ${rowStyle}`}>
          <div className="alignLeft greyText">{name}</div>
          <div className={styles.detailRowData}>{value}</div>
          <div className="clearBoth" />
        </div>
        {showSeparator && <hr />}
      </li>
    );
  }
}
