import { createSelector } from 'reselect';
import { values, groupBy } from 'lodash-es';

import { CrowdTaskCallOut, CrowdTaskCallOutStatus } from '../../api/types';
import { WebReduxState } from '../reducers';

type groupedCallOuts = {
  [status in CrowdTaskCallOutStatus | 'all']: CrowdTaskCallOut[];
};

const sortedGroupedCallOutsSelector: (state: WebReduxState) => groupedCallOuts = createSelector(
  state => state.callOuts.callOuts.byId,
  callOutsById => {
    const callOuts = values(callOutsById).sort(compare);
    const grouped = groupBy(callOuts, c => c.status);
    return { ...grouped, all: callOuts } as groupedCallOuts;
  }
);

const compare = (a: CrowdTaskCallOut, b: CrowdTaskCallOut) => {
  const s1 = rankCallOutStatus(a.status);
  const s2 = rankCallOutStatus(b.status);

  if (s1 - s2 !== 0) {
    return s1 - s2;
  }

  const d1 = new Date(a.startTime).getTime();
  const d2 = new Date(b.startTime).getTime();
  if (d1 - d2 !== 0) {
    return d1 - d2;
  }

  const dd1 = new Date(a.endTime).getTime();
  const dd2 = new Date(b.endTime).getTime();
  if (dd1 - dd2 !== 0) {
    return dd1 - dd2;
  }

  return 0;
};

function rankCallOutStatus(status: CrowdTaskCallOutStatus) {
  switch (status) {
    case 'DELETED':
    case 'CLOSED':
      return 2;
    case 'RELEASED':
      return 1;
    case 'DRAFT':
    default:
      return 0;
  }
}

export default sortedGroupedCallOutsSelector;
