import log from '../shared/logging';

import {
  CreateDeviceRequest,
  CreateDeviceResponse,
  UpdateDeviceRequest,
  UpdateDeviceResponse,
  GetDeviceResponse,
} from './types';
import apiCall from './apiCall';

let nextRequestId = 1;

export function* createDevice(request: CreateDeviceRequest): Generator<any, any, any> {
  const requestId = nextRequestId++;
  log.info(`createDevice request ${requestId}`, request);

  const result: CreateDeviceResponse = yield* apiCall('devices', {
    method: 'POST',
    body: JSON.stringify(request),
  });

  log.info(`createDevice result ${requestId}`, result);

  return result;
}

export function* updateDevice(deviceId: string, request: UpdateDeviceRequest): Generator<any, any, any> {
  const requestId = nextRequestId++;
  log.info(`updateDevice request ${requestId}`, request);

  const result: UpdateDeviceResponse = yield* apiCall(`devices/${deviceId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });

  log.info(`updateDevice result ${requestId}`, result);

  return result;
}

export function* getDevice(deviceId: string): Generator<any, any, any> {
  const result: GetDeviceResponse = yield* apiCall(`devices/${deviceId}`);
  return result;
}
