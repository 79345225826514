import { createSelector } from 'reselect';
import { sortBy } from 'lodash-es';

import { LocationsState } from '../reducers/locations';
import { OperationsState } from '../reducers/operations';

type PartialReduxState = {
  operations: OperationsState;
  locations: LocationsState;
};

const sortedOperationIdsSelector: (state: PartialReduxState) => string[] = createSelector(
  state => state.operations.ids,
  state => state.operations.byId,
  state => state.locations.byId,
  (operationIds, operationsById, locationsById) => {
    return sortBy(operationIds, id => {
      const operation = operationsById[id];
      const location = locationsById[operation.locationCode];

      return location ? location.name : operation.locationCode;
    }).filter(id => operationsById[id].status === 'DRAFT' || operationsById[id].status === 'RELEASED');
  }
);

export default sortedOperationIdsSelector;
