// Simplified fetch API that
// 1.) supports a timeout options
// 2.) can be implemented natively on Android to avoid Citrix app wrapping issues

import timeout from '../utils/promiseTimeout';

export const defaultTimeout = 20000;

// We mostly just pass the request options through.
// So we may use the standard (fetch) RequestOptions type.
// But we augment it with a timeout option that we use explicitly.
export type SimpleRequestOptions = RequestInit & {
  timeout?: number;
  noauth?: boolean;
};

// Simple (fetch) response. This is a very simplified version of the standard (fetch) Response type.
export type SimpleResponse = {
  ok: boolean;
  status: number;
  headers: {
    get(name: string): string | null | undefined;
    getAll(name: string): string[];
  };
  text(): Promise<string>;
  json(): Promise<any>;
};

export type SimpleFetchFunction = (url: string, options?: SimpleRequestOptions) => Promise<SimpleResponse>;

let fetchFunction: SimpleFetchFunction = defaultFetchFunction;

export default function simpleFetch(url: string, options?: SimpleRequestOptions): Promise<SimpleResponse> {
  return fetchFunction(url, options);
}

// This allows the standard fetch-based implementation to be overriden by e.g. a native implementation.
export function setFetchFunction(f: SimpleFetchFunction) {
  fetchFunction = f;
}

// Default fetch function based on the fetch polyfill + timeout handling.
function defaultFetchFunction(url: string, options: SimpleRequestOptions = {}): Promise<SimpleResponse> {
  const timeoutValue = options.timeout || defaultTimeout;
  // @ts-ignore SimpleResponse != Response ...
  return timeout(timeoutValue, fetch(url, options));
}
