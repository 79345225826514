import { Dispatch as ReduxDispatch } from 'redux';
import { useDispatch as useDispatchGeneric } from 'react-redux';

import { Action } from '../../common/actions';
import { AdminAction } from './admin';
import { CallOutsAction } from './callOuts';
import { CrowdTaskUiAction } from './crowdTaskUi';
import { EditCallOutAction } from './editCallOut';
import { OperationsAction } from './operations';
import { ParticipationsAction } from './participations';
import { FormsAction } from './reduxForms';
import { WebNavAction } from './webNav';

// eslint-disable-next-line import/export
export * from '../../common/actions';
export * from './admin';
export * from './callOuts';
export * from './crowdTaskUi';
export * from './editCallOut';
export * from './operations';
export * from './participations';
export * from './reduxForms';
export * from './webNav';

export type WebAction =
  | Action
  | WebNavAction
  | AdminAction
  | CrowdTaskUiAction
  | CallOutsAction
  | EditCallOutAction
  | FormsAction
  | OperationsAction
  | ParticipationsAction;

export type WebDispatch = ReduxDispatch<WebAction>;
export type WebDispatchProps = { dispatch: WebDispatch };

// eslint-disable-next-line import/export
export const useDispatch: () => WebDispatch = useDispatchGeneric;
