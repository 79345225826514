import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history';
import { Redirect } from 'react-router-dom';

import EditOperation from './EditOperation';

type Props = {
  match: match<{ operationId?: string }>;
  history: History;
};

// eslint-disable-next-line no-shadow
export default function EditOperationScreen({ match, history }: Props) {
  const operationId = match.params.operationId;

  return operationId ? <EditOperation operationId={operationId} history={history} /> : <Redirect to="/operations" />;
}
