import HttpStatus from 'http-status-codes';
import { SimpleResponse } from './simpleFetch';

export type ApiErrorObject = {
  status: number;
  message: string;
};

export default function handleError(response: SimpleResponse): Promise<any> {
  if (response.ok) {
    return Promise.resolve(response);
  }

  return response.text().then(text => {
    try {
      const errorObject: ApiErrorObject = JSON.parse(text);
      if (!errorObject || typeof errorObject.status !== 'number' || typeof errorObject.message !== 'string') {
        throw new Error('Invalid error object');
      }

      return Promise.reject(errorObject);
    } catch (e) {
      // Unable to parse error text as JSON or invalid parse result.
      let message = text || HttpStatus.getStatusText(response.status);

      if (process.env.NODE_ENV === 'development') {
        const failureHeader = response.headers.get('failure');
        if (failureHeader) {
          message = `${message}; dev-only failure info: ${failureHeader}`;
        }
      }

      const errorObject: ApiErrorObject = {
        status: response.status,
        message,
      };
      return Promise.reject(errorObject);
    }
  });
}
