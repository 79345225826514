import { isEmpty, every } from 'lodash-es';
import isEqual from 'date-fns/isEqual';

import { IncidentData } from '../../api/incidentsTypes';

export type FieldInfo = {
  changed: boolean;
  changedToEmpty: boolean;
};

export type IncidentDataCompareResult = {
  status: FieldInfo;
  lines: FieldInfo;
  locationFrom: FieldInfo;
  locationTo: FieldInfo;
  regions: FieldInfo;
  incidentType: FieldInfo;
  cause: FieldInfo;
  endTime: FieldInfo;
  measure: FieldInfo;
  place: FieldInfo;
  information: FieldInfo;
  internals: FieldInfo;
  finalInformation: FieldInfo;
  replacedById: FieldInfo;
};

function compareArrays(a: string[], b: string[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const setA = new Set(a);
  return b.every(s => setA.has(s));
}

function compareStrings(a: string | null | undefined, b: string | null | undefined): boolean {
  return a === b;
}

function compareDateStrings(a: string | null | undefined, b: string | null | undefined): boolean {
  return a && b ? isEqual(new Date(a), new Date(b)) : a === b;
}

export function compareIncidentData(a: IncidentData, b: IncidentData): IncidentDataCompareResult {
  function getFieldInfo<T>(key: string, compareFunction: (x: T, y: T) => boolean) {
    const valueA: T = a[key];
    const valueB: T = b[key];

    return {
      changed: !compareFunction(valueA, valueB),
      changedToEmpty: isEmpty(valueA) && !isEmpty(valueB),
    };
  }

  return {
    status: getFieldInfo('status', compareStrings),
    lines: getFieldInfo('lines', compareArrays),
    locationFrom: getFieldInfo('locationFrom', compareStrings),
    locationTo: getFieldInfo('locationTo', compareStrings),
    regions: getFieldInfo('regions', compareArrays),
    incidentType: getFieldInfo('incidentType', compareStrings),
    cause: getFieldInfo('cause', compareStrings),
    place: getFieldInfo('place', compareStrings),
    endTime: getFieldInfo('endTime', compareDateStrings),
    measure: getFieldInfo('measure', compareStrings),
    information: getFieldInfo('information', compareStrings),
    internals: getFieldInfo('internals', compareStrings),
    finalInformation: getFieldInfo('finalInformation', compareStrings),
    replacedById: getFieldInfo('replacedById', compareStrings),
  };
}

export function incidentDataEqual(a: IncidentData, b: IncidentData) {
  const result = compareIncidentData(a, b);
  return every(result, (fieldInfo: FieldInfo) => !fieldInfo.changed);
}
