import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { DataFilterType } from '../../api/reducers/config';
import { Incident, IncidentData } from '../../api/incidentsTypes';
import tableStyles from '../css/tables.module.css';
import Button from '../../api/components/Button';
import CockpitApiErrors from '../navigation/CockpitApiStatus';
import CrowdTaskIncidentRow from './CrowdTaskIncidentRow';
import EditOperationForm from './EditOperationForm';
import styles from './NewOperationRightPanel.module.css';

type Props = {
  incidents: Incident[];
  selectedIncidentId: string | null | undefined;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  saveDisabled: boolean;
  getIconName: (data: IncidentData) => string;
  onIncidentChange: (incident: Incident) => void;
  onSaveClicked: () => void;
  onCancelClicked: () => void;
  onApiCallSuccess: () => void;
};

const apiCallActions = ['POST_OPERATION'];

export default function NewOperationRightPanel({
  incidents,
  selectedIncidentId,
  dataFilterTypes,
  saveDisabled,
  getIconName,
  onIncidentChange,
  onSaveClicked,
  onCancelClicked,
  onApiCallSuccess,
}: Props) {
  return (
    <div className={styles.rightPanel}>
      <div className={styles.upperContainer}>
        <IncidentSelectionList
          incidents={incidents}
          selectedIncidentId={selectedIncidentId}
          dataFilterTypes={dataFilterTypes}
          getIconName={getIconName}
          onIncidentChange={onIncidentChange}
        />
        <div className={styles.form}>
          <EditOperationForm operationId={null} />
        </div>
      </div>
      <CockpitApiErrors actions={apiCallActions} onSuccess={onApiCallSuccess} />
      <ButtonsRow onSaveClicked={onSaveClicked} onCancelClicked={onCancelClicked} saveDisabled={saveDisabled} />
    </div>
  );
}

function ButtonsRow({ onSaveClicked, onCancelClicked, saveDisabled }) {
  return (
    <div className={styles.buttonRow}>
      <Button onClick={onCancelClicked}>
        <FormattedMessage id="NewOperation.cancel" defaultMessage="Cancel" />
      </Button>
      <Button disabled={saveDisabled} onClick={onSaveClicked}>
        <FormattedMessage id="NewOperation.create" defaultMessage="Create" />
      </Button>
    </div>
  );
}

function IncidentSelectionList({ incidents, selectedIncidentId, dataFilterTypes, getIconName, onIncidentChange }) {
  return (
    <div className={styles.incidentList}>
      <table className={tableStyles.table}>
        <tbody>
          {incidents.map(incident => (
            <CrowdTaskIncidentRow
              key={incident.incidentId}
              incident={incident}
              showRadioButton={true}
              isChecked={incident.incidentId === selectedIncidentId}
              iconName={getIconName(incident.currentData)}
              dataFilterTypes={dataFilterTypes}
              onRowClicked={onIncidentChange}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
