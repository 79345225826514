import { ChatState } from '../reducers/chat';
import { ChatPendingState } from '../reducers/chatPending';

type PartialReduxState = {
  chat: ChatState;
  chatPending: ChatPendingState;
};

// Don't really need a caching selector for this. Still, let's provide the same API as for hasRoleSelector.
const hasChatSelector = (state: PartialReduxState) => (chatThreadId: string) => {
  const thread = state.chat[chatThreadId];
  const threadInfo = state.chatPending[chatThreadId];

  return Boolean(
    (thread && thread.messages.ids.length > 0) || (threadInfo && threadInfo.pendingMessages.ids.length > 0)
  );
};

export default hasChatSelector;
