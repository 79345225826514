import * as React from 'react';

import Button from '../../api/components/Button';
import styles from './LoadingScreen.module.css';

type Props = {
  errorMessage: React.ReactNode;
  buttonTitle: React.ReactNode;
  onButtonClicked: () => void;
};

export default class SimpleErrorScreen extends React.Component<Props> {
  render() {
    const { errorMessage, buttonTitle, onButtonClicked } = this.props;
    return (
      <div className={styles.loadingScreen}>
        <div className={styles.loadingText}>{errorMessage}</div>
        <Button onClick={onButtonClicked}>{buttonTitle}</Button>
      </div>
    );
  }
}
