import { createSelector } from 'reselect';

import { Incident } from '../../api/incidentsTypes';
import sortIncidents from '../../common/utils/sortIncidents';
import { WebReduxState } from '../reducers';
import { isIncidentClosed } from '../../common/utils/incidents';

const sortedCrowdTaskIncidentsSelector: (state: WebReduxState) => Incident[] = createSelector(
  state => state.crowdTaskIncidents.byId,
  incidentsById => sortIncidents(incidentsById).filter(i => !isIncidentClosed(i.currentData))
);

export default sortedCrowdTaskIncidentsSelector;
