import * as React from 'react';

import FilterText from './FilterText';
import styles from './FilterHeader.module.css';

type Props = {
  iconName: string;
  title: React.ReactNode;
  data: Set<string>;
};

export default class FilterHeader extends React.PureComponent<Props> {
  render() {
    const { iconName, title, data } = this.props;
    const iconColor = data.size > 0 ? 'tint' : 'greyText';

    return (
      <div className={styles.filterHeader}>
        <div>
          <i className={`icon-${iconName} ${styles.iconFilter} ${iconColor}`} />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.filterText}>
          <FilterText items={data} />
        </div>
      </div>
    );
  }
}
