import * as React from 'react';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';

import versionInfo from '../../shared/version/versionInfo';
import styles from './Menu.module.css';

export type MenuComponentRenderFunction = (requestClose: () => void) => React.ReactNode;

type Props = {
  anchorElement: null | HTMLElement;
  open: boolean;
  onRequestClose: () => void;
  renderMenuHeader?: MenuComponentRenderFunction | null | undefined;
  renderMenuBody?: MenuComponentRenderFunction | null | undefined;
  renderMenuFooter?: MenuComponentRenderFunction | null | undefined;
};

const anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' };

export default class Menu extends React.PureComponent<Props> {
  render() {
    const { anchorElement, open, onRequestClose, renderMenuHeader, renderMenuBody, renderMenuFooter } = this.props;

    return (
      <Popover open={open} anchorEl={anchorElement} anchorOrigin={anchorOrigin} onClose={onRequestClose}>
        <div className={styles.menu}>
          {renderMenuHeader && <div className={styles.header}>{renderMenuHeader(onRequestClose)}</div>}
          {renderMenuBody && (
            <div className={styles.content}>
              {renderMenuBody(onRequestClose)}
              <div className={styles.version}>
                {versionInfo.version} ({versionInfo.build})
              </div>
            </div>
          )}
          {renderMenuFooter && <div className={styles.footer}>{renderMenuFooter(onRequestClose)}</div>}
        </div>
      </Popover>
    );
  }
}
