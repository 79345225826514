import * as React from 'react';
import Radio from '@material-ui/core/Radio';

import { Location } from '../../api/types';
import { LocationsMap } from '../reducers/locations';
import { NewOperationState } from '../reducers/newOperation';
import styles from './NewOperationLeftPanel.module.css';

type Props = {
  newOperationState: NewOperationState;
  sortedLocationCodes: string[];
  usedLocationCodes: Set<string>;
  locationsByCode: LocationsMap;
  onLocationChange: (locationCode: string) => void;
};

export default function NewOperationLeftPanel({
  newOperationState,
  sortedLocationCodes,
  usedLocationCodes,
  locationsByCode,
  onLocationChange,
}: Props) {
  const { stateFilter, selectedLocationCode } = newOperationState;
  return (
    <div className={styles.leftPanel}>
      <LocationList
        usedLocationCodes={usedLocationCodes}
        stateFilter={stateFilter}
        sortedLocationCodes={sortedLocationCodes}
        selectedLocationCode={selectedLocationCode}
        locationsByCode={locationsByCode}
        onLocationChange={onLocationChange}
      />
    </div>
  );
}

class LocationList extends React.PureComponent<any> {
  render() {
    const {
      stateFilter,
      sortedLocationCodes,
      usedLocationCodes,
      selectedLocationCode,
      locationsByCode,
      onLocationChange,
    } = this.props;

    const filteredLocationCodes = sortedLocationCodes.filter(
      code => stateFilter === '__all__' || locationsByCode[code].state === stateFilter
    );

    return (
      <div className={styles.locationList}>
        <table className={styles.locationTable}>
          <tbody>
            {filteredLocationCodes.map(id => (
              <LocationRow
                key={id}
                location={locationsByCode[id]}
                disabled={usedLocationCodes.has(id)}
                selected={id === selectedLocationCode}
                onLocationChange={onLocationChange}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

type LocationRowProps = {
  location: Location;
  disabled: boolean;
  selected: boolean;
  onLocationChange: (locationCode: string) => void;
};

class LocationRow extends React.PureComponent<LocationRowProps> {
  render() {
    const { location, disabled, selected } = this.props;
    const { locationCode, name } = location;

    return (
      <tr className={disabled ? styles.disabledRow : 'pointerCursor'} onClick={this.handleRowClicked}>
        <td className={styles.radioButtonCol}>
          <Radio value={locationCode} disabled={disabled} checked={selected} />
        </td>
        <td>
          <span className={styles.locationName}>{name}</span>
        </td>
      </tr>
    );
  }

  handleRowClicked = () => {
    const { disabled, location, onLocationChange } = this.props;
    if (!disabled) {
      onLocationChange(location.locationCode);
    }
  };
}
