import { Action } from '../actions';
import { GetFAQResult } from '../../api/actions/faq';

export type FaqState = {
  readonly document: string | null;
  readonly lastModified: string | null;
};

const initialState: FaqState = {
  document: null,
  lastModified: null,
};

function handleFetchFaqSuccess(state: FaqState, response: GetFAQResult): FaqState {
  switch (response.type) {
    case 'NOT_FOUND':
      return {
        document: null,
        lastModified: null,
      };
    case 'NOT_UPDATED':
      return state;
    case 'UPDATED':
      return {
        document: response.document,
        lastModified: response.lastModified,
      };
    default:
      return state;
  }
}

type PartialReduxState = {
  faq: FaqState;
};

export function lastModifiedSelector(state: PartialReduxState): string | null {
  return state.faq.lastModified;
}

export function faqDocumentSelector(state: PartialReduxState): string | null {
  return state.faq.document;
}

export default function faq(state: FaqState = initialState, action: Action): FaqState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'FETCH_FAQ':
          return handleFetchFaqSuccess(state, action.result);
        default:
          return state;
      }

    default:
      return state;
  }
}
