import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import ModalDialog from '../../shared/modalDialog/ModalDialog';
import { CallOutKey } from '../../api/crowdTask';
import { CallOutsAction, ParticipationsAction, WebDispatch } from '../actions';

type Props = {
  onChangeDialogVisibility: (show: boolean) => void;
  open: boolean;
  title: React.ReactNode;
  message: JSX.Element;
  apiAction?: (callOutKey: CallOutKey) => CallOutsAction | ParticipationsAction;
  apiActionWithVersion?: (callOutKey: CallOutKey, version: number) => CallOutsAction | ParticipationsAction;
  callOutKey: CallOutKey;
  version: number;
  dispatch: WebDispatch;
};

export default class CallOutsKeyApiDialog extends React.PureComponent<Props> {
  render() {
    const { open, title, message } = this.props;

    const actions = [
      <Button variant="text" key="cancel" onClick={this.handleCancelClicked}>
        <FormattedMessage id="CallOutsDialog.cancel" defaultMessage="Cancel" />
      </Button>,
      <Button variant="text" key="ok" color="primary" onClick={this.handleOkClicked}>
        <FormattedMessage id="CallOutsDialog.ok" defaultMessage="OK" />
      </Button>,
    ];

    return (
      <ModalDialog open={open} title={title} actions={actions}>
        {message}
      </ModalDialog>
    );
  }

  handleOkClicked = () => {
    const { onChangeDialogVisibility, apiAction, apiActionWithVersion, callOutKey, dispatch, version } = this.props;

    if (apiActionWithVersion) {
      dispatch(apiActionWithVersion(callOutKey, version));
    } else if (apiAction) {
      dispatch(apiAction(callOutKey));
    }

    onChangeDialogVisibility(false);
  };

  handleCancelClicked = () => {
    const { onChangeDialogVisibility } = this.props;

    onChangeDialogVisibility(false);
  };
}
