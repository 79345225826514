import * as React from 'react';
import { connect } from 'react-redux';
import { History, Location } from 'history';

import { WebReduxState } from '../reducers';
import getViewModeByPath from '../utils/getViewModeByPath';
import FilterSidebar from '../filter/FilterSidebar';
import IncidentList from './IncidentList';
import styles from './Incidents.module.css';

type OwnProps = {
  history: History;
  location: Location;
};

type StoreProps = {
  filterBarOpen: boolean;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    filterBarOpen: state.webNav.filterBarOpen,
  };
}

function Incidents({ history, location, filterBarOpen }: Props) {
  const path = location.pathname;
  const viewMode = getViewModeByPath(path) || 'all';
  const filterBarVisible = filterBarOpen && path === '/incidents/filter';

  return (
    <div className={styles.incidents}>
      <div className={styles.incidentList}>
        <IncidentList viewMode={viewMode} location={location} history={history} />
      </div>
      {filterBarVisible && (
        <div className={styles.filterBar}>
          <FilterSidebar />
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(Incidents);
