import dateFormat from 'dateformat';

const LogLevel = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
};

export type LogFunction = (level: number, message: string, object?: any) => void;

let logFunction: LogFunction | null | undefined = null;

function log(level: number, message: string, object?: any) {
  // if (process.env.NODE_ENV === 'development') {
  // TODO: disable again for release builds, at least for React Native.
  logToConsole(level, message, object);
  // }

  if (logFunction) {
    logFunction(level, message, object);
  }
}

const logger = {
  log,

  debug(message: string, object?: any) {
    log(LogLevel.DEBUG, message, object);
  },

  info(message: string, object?: any) {
    log(LogLevel.INFO, message, object);
  },

  warn(message: string, object?: any) {
    log(LogLevel.WARN, message, object);
  },

  error(message: string, error?: any) {
    // Special handling in case error is an Error object.
    if (error && error.message) {
      log(LogLevel.ERROR, `${message}: ${error.message}`);

      if (error.stack) {
        log(LogLevel.ERROR, `Stack trace: ${error.stack}`);
      }
    } else {
      log(LogLevel.ERROR, message, error);
    }
  },
};

export function setLogFunction(f: LogFunction) {
  logFunction = f;
}

export default logger;

function logToConsole(level: number, message: string, object?: any) {
  const now = new Date();
  const logString = `${dateFormat(now, 'HH:MM:ss')} ${message}`;
  const input: any[] = [logString];
  if (object) {
    input.push(object);
  }

  switch (level) {
    case LogLevel.INFO:
      console.info(...input);
      break;
    case LogLevel.WARN:
    case LogLevel.ERROR:
      // Don't use console.error, as it will open a red box in React Native.
      console.warn(...input);
      break;
    case LogLevel.DEBUG:
    default:
      console.log(...input);
      break;
  }
}
