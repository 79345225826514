import { IncidentId } from '../../api/incidentsTypes';

export type CrowdTaskUiAction =
  | { type: 'SET_NEW_OPERATION_LOCATION_FILTER'; stateFilter: string }
  | { type: 'SELECT_NEW_OPERATION_LOCATION'; locationCode: string }
  | { type: 'SELECT_NEW_OPERATION_INCIDENT'; incidentId: IncidentId };

export function setNewOperationLocationFilter(stateFilter: string): CrowdTaskUiAction {
  return { type: 'SET_NEW_OPERATION_LOCATION_FILTER', stateFilter };
}

export function selectNewOperationLocation(locationCode: string): CrowdTaskUiAction {
  return { type: 'SELECT_NEW_OPERATION_LOCATION', locationCode };
}

export function selectNewOperationIncident(incidentId: IncidentId): CrowdTaskUiAction {
  return { type: 'SELECT_NEW_OPERATION_INCIDENT', incidentId };
}
