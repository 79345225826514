import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import { getApiCallStatus } from '../../api/reducers/api';
import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import Button from '../../api/components/Button';
import { submitInitialLoad, WebDispatchProps } from '../actions';
import LoadingIcon from '../navigation/LoadingIcon';
import { WebReduxState } from '../reducers';
import textStyles from '../css/text.module.css';
import styles from './InitialLoad.module.css';

type OwnProps = {};

type StoreProps = {
  initialLoadState: ApiCallStatus;
  initialLoadTriggeredAt: string;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    initialLoadState: getApiCallStatus(state, 'SUBMIT_INITIAL_LOAD'),
    initialLoadTriggeredAt: state.admin.persisted.initialLoadTriggeredAt,
  };
}

class InitialLoad extends React.Component<Props> {
  render() {
    const { initialLoadState, initialLoadTriggeredAt } = this.props;
    const lastTimeTriggered = (
      <span className={textStyles.bold}>
        <FormattedTimestamp value={initialLoadTriggeredAt} hourAndMinutesOnPastDays={true} />
      </span>
    );
    return (
      <div className={styles.centerText}>
        <h4>
          <FormattedMessage id="InitialLoad.description" defaultMessage="You can trigger the initial load here." />
        </h4>
        <Button onClick={this.handleTriggerInitialLoad}>
          <FormattedMessage id="InitialLoad.trigger" defaultMessage="Trigger Initial Load" />
        </Button>
        <div>
          <br />
          <div>
            {initialLoadState.error && (
              <FormattedMessage
                id="InitialLoad.error"
                defaultMessage="Error: {message}"
                values={{ message: initialLoadState.error.message }}
              />
            )}
          </div>
          {initialLoadState.inProgress ? (
            <LoadingIcon />
          ) : (
            initialLoadTriggeredAt && (
              <FormattedMessage
                id="InitialLoad.lastTriggered"
                defaultMessage="Initial Load was successfully triggered at {lastTimeTriggered} for the last time"
                values={{ lastTimeTriggered }}
              />
            )
          )}
        </div>
      </div>
    );
  }

  handleTriggerInitialLoad = () => {
    this.props.dispatch(submitInitialLoad());
  };
}

export default connect(mapStateToProps)(InitialLoad);
