import { select, takeLatest } from 'redux-saga/effects';

import { updateDeviceDataFilter } from '../../api';
import { AvailableFilterCriteria } from '../../api/types';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { deviceRegistrationSelector, DeviceRegistration } from '../../common/reducers/device';
import { ReduxState } from '../reducers';

export default function* postDataFilterCriteriaSaga(): Generator<any, any, any> {
  yield takeLatest('POST_DATAFILTER_CRITERIA', performPostDataFilterCriteria);
}

function* performPostDataFilterCriteria() {
  const device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  if (!device) {
    return;
  }

  yield* wrapApiCallActions('POST_DATAFILTER_CRITERIA', doPostDataFilterCriteria(device));
}

function* doPostDataFilterCriteria(device) {
  const dataFilterCriteria: AvailableFilterCriteria = yield select(
    (state: ReduxState) => state.inform.dataFilter.criteria
  );

  yield* updateDeviceDataFilter(device.deviceId, dataFilterCriteria);
  return dataFilterCriteria;
}
