import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Location, History } from 'history';
import { FormattedMessage } from 'react-intl';

import { Tabs, Tab } from '../../shared/tabs/Tabs';
import { toggleFilterBar, WebDispatchProps } from '../actions';
import incidentsByViewModeSelector, { IncidentsByViewMode } from '../../common/selectors/incidentsByViewMode';
import { WebReduxState } from '../reducers';
import TabBarButton from '../navigation/TabBarButton';

type OwnProps = {
  location: Location;
  history: History;
};

type StoreProps = {
  filterBarOpen: boolean;
  incidentsByViewMode: IncidentsByViewMode;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    filterBarOpen: state.webNav.filterBarOpen,
    incidentsByViewMode: incidentsByViewModeSelector(state),
  };
}

class TabBarIncidents extends React.Component<Props> {
  render() {
    const { incidentsByViewMode, location, history } = this.props;
    const path = location.pathname;

    const allIncidentsCount = incidentsByViewMode.all.length;
    const favoriteIncidentsCount = incidentsByViewMode.favorites.length;
    const filteredIncidentsCount = incidentsByViewMode.filter.length;

    return (
      <Tabs buttons={<Route path="/incidents/filter" render={this.renderFilterButton} />}>
        <Tab
          path="/incidents/all"
          currentPath={path}
          history={history}
          label={
            <FormattedMessage
              id="TabsUser.all"
              defaultMessage="All Messages ({allIncidentsCount, number})"
              values={{ allIncidentsCount }}
            />
          }
        />
        <Tab
          path="/incidents/favorites"
          currentPath={path}
          history={history}
          label={
            <FormattedMessage
              id="TabsUser.favorites"
              defaultMessage="Favorites ({favoriteIncidentsCount, number})"
              values={{ favoriteIncidentsCount }}
            />
          }
        />
        <Tab
          path="/incidents/filter"
          currentPath={path}
          history={history}
          label={
            <FormattedMessage
              id="TabsUser.filter"
              defaultMessage="Filter ({filteredIncidentsCount, number})"
              values={{ filteredIncidentsCount }}
            />
          }
        />
      </Tabs>
    );
  }

  renderFilterButton = _router => {
    const { filterBarOpen } = this.props;
    return <TabBarButton onClick={this.handleFilterButtonPressed} toggle={filterBarOpen} icon="filter" />;
  };

  handleFilterButtonPressed = () => {
    this.props.dispatch(toggleFilterBar());
  };
}

export default connect(mapStateToProps)(TabBarIncidents);
