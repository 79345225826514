import * as React from 'react';
import { Redirect, Route, Switch, match } from 'react-router';
import { connect } from 'react-redux';

import hasRoleSelector from '../../api/selectors/hasRole';
import { InformRoles } from '../../api/roles';
import { ReduxState } from '../../common/reducers';
import Analysis from './Analysis';
import Tenants from './Tenants';
import InitialLoad from './InitialLoad';
import Statistics from './Statistics';

type OwnProps = {
  match: match;
};

type StoreProps = {
  hasRole: (role: string) => boolean;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    hasRole: hasRoleSelector(state),
  };
}

function AdminRoutes({ match: { url }, hasRole }: Props) {
  const isTenantAdmin = hasRole(InformRoles.ADMIN_TENANTS);
  const initialLoadAllowed = hasRole(InformRoles.ADMIN_INITIAL_LOAD);
  const statisticsAllowed = hasRole(InformRoles.STATISTICS);
  const analysisAllowed = hasRole(InformRoles.ANALYSIS);

  return (
    <Switch>
      {/* Sub-routes dependent on role */}
      {isTenantAdmin && <Route path={`${url}/tenants`} component={Tenants} />}
      {statisticsAllowed && <Route path={`${url}/statistics`} component={Statistics} />}
      {initialLoadAllowed && <Route path={`${url}/initial-load`} component={InitialLoad} />}
      {analysisAllowed && <Route path={`${url}/analysis`} component={Analysis} />}

      {/* Redirects dependent on role */}
      {isTenantAdmin && <Redirect to="/admin/tenants" />}
      {statisticsAllowed && <Redirect to="/admin/statistics" />}
      {initialLoadAllowed && <Redirect to="/admin/initial-load" />}
      {analysisAllowed && <Redirect to="/admin/analysis" />}
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(mapStateToProps)(AdminRoutes);
