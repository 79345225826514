import * as React from 'react';
import { History } from 'history';
import { Redirect, match } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { times } from 'lodash-es';

import format from 'date-fns/format';
import addHours from 'date-fns/addHours';
import startOfHour from 'date-fns/startOfHour';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import FilterableTableColumn from '../../shared/filterableTableColumn/FilterableTableColumn';
import getHorizontalScrollbarHeight from '../../shared/utils/getHorizontalScrollbarHeight';
import { CrowdTaskCallOut, CrowdTaskCallOutStatus } from '../../api/types';
import { WebReduxState } from '../reducers';
import { CallOutStatsState } from '../reducers/callOutStats';
import sortedGroupedCallOutsSelector from '../selectors/sortedGroupedCallOuts';
import scrollTo from '../utils/scroll';
import colorStyles from '../css/colors.module.css';
import styles from './Timeline.module.css';
import stylesTableBase from './TimelineTableBase.module.css';
import stylesTable1 from './TimelineTable1.module.css';
import stylesTable2 from './TimelineTable2.module.css';
import CallOutStatusText from './CallOutStatusText';

const NUMBER_OF_ROWS = 4;
const OFFSET_TO_MIDDLE = false;
const RESOLUTION = 5;
const TIMELINE_MIN_SIZE = 20; // hours
const TIMELINE_SIZE = 48; // hours

type OwnProps = {
  match: match<{ operationId?: string; callOutId?: string }>;
  history: History;
};

type StoreProps = {
  selectedCallOutId: string | null | undefined;
  operationId: string;
  callOutStats: CallOutStatsState;
  groupedCallOuts: {
    [status in CrowdTaskCallOutStatus | 'all']: CrowdTaskCallOut[];
  };
};

type Props = OwnProps & StoreProps;

type State = {
  filter: CrowdTaskCallOutStatus | null;
  selectedRef: HTMLElement | null;
  now: Date; // Store "now" in state! We don't want to re-render the page just because "now" changes, thus not using nowSelector.
  time: string[];
};

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const {
    match: {
      params: { callOutId, operationId },
    },
  } = ownProps;

  if (!operationId) {
    throw new Error('Timeline: operationId is not set. This should not happen.');
  }

  return {
    selectedCallOutId: callOutId,
    operationId,
    callOutStats: state.callOutStats,
    groupedCallOuts: sortedGroupedCallOutsSelector(state),
  };
}

class Timeline extends React.Component<Props, State> {
  masterScrollRef: HTMLElement | null = null;
  timeHeaderScrollRef: HTMLElement | null = null;
  table1ScrollRef: HTMLElement | null = null;

  constructor(props) {
    super(props);
    const {
      groupedCallOuts: { all },
    } = props;
    const now = new Date();

    this.state = {
      filter: null,
      selectedRef: null,
      now,
      time: this.createTimeArray(now, all),
    };
  }

  UNSAFE_componentWillUpdate(_nextProps: Props, nextState: State) {
    const { selectedRef } = this.state;
    const nextSelectedRef = nextState.selectedRef;

    if (selectedRef !== nextSelectedRef && nextSelectedRef && this.masterScrollRef) {
      scrollTo(this.masterScrollRef, nextSelectedRef, true);
    }
  }

  render() {
    const { history, selectedCallOutId, operationId, callOutStats, groupedCallOuts } = this.props;
    const { filter, now, time } = this.state;

    const filteredCallOuts = filter ? groupedCallOuts[filter] || [] : groupedCallOuts.all;

    if (
      (!selectedCallOutId || !filteredCallOuts.some(c => c.callOutId === selectedCallOutId)) &&
      filteredCallOuts.length > 0
    ) {
      return <Redirect to={`/operations/${operationId}/callOuts/${filteredCallOuts[0].callOutId}`} />;
    }

    return (
      <div className={stylesTableBase.container}>
        <div className={stylesTableBase.headerContainer}>
          <div className={stylesTable1.headerRow}>
            <div className={stylesTable1.headerCell}>
              <p className={stylesTableBase.headerText}>
                <FormattedMessage id="Timeline.team" defaultMessage="Team" />
              </p>
            </div>
            <div className={stylesTable1.headerCellIcon} />
            <div className={stylesTable1.headerCellTask}>
              <p className={stylesTableBase.headerText}>
                <FormattedMessage id="Timeline.activity" defaultMessage="Activity" />
              </p>
            </div>
            <div className={stylesTable1.headerCellStatus}>
              <div className={stylesTableBase.headerText}>
                <FilterableTableColumn
                  activeClassName={colorStyles.active}
                  message={<FormattedMessage id="Timeline.status" defaultMessage="Status" />}
                  items={[null, 'DRAFT', 'RELEASED', 'CLOSED']}
                  selectedItem={filter}
                  onChange={this.handleStatusFilterChanged}
                  renderItem={this.renderItem}
                />
              </div>
            </div>
          </div>
          <div className={stylesTable2.headerRow} ref={this.setTimeHeaderScrollRef}>
            {time.map((t, i) => (
              <div className={i === time.length - 1 ? stylesTable2.lastHeaderCell : stylesTable2.headerCell} key={i}>
                <p className={stylesTableBase.headerText}>{t}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={stylesTableBase.tableWrapper}>
          <div className={stylesTable1.table} ref={this.setTable1ScrollRef}>
            {filteredCallOuts.map((c, i) => {
              const { callOutId } = c;
              return (
                <DataRow
                  history={history}
                  callOut={c}
                  key={callOutId}
                  isLastRow={i === Math.max(filteredCallOuts.length, NUMBER_OF_ROWS) - 1}
                  selected={callOutId === selectedCallOutId}
                  hasOfferedParticipations={
                    callOutStats[callOutId] && callOutStats[callOutId].numberOfOfferedParticipations > 0
                  }
                  acceptedParticipationCount={
                    callOutStats[callOutId] ? callOutStats[callOutId].numberOfActiveParticipations : 0
                  }
                />
              );
            })}
            {times(Math.max(NUMBER_OF_ROWS - filteredCallOuts.length, 0)).map(i => (
              <EmptyDataRow key={i} isLastRow={i === NUMBER_OF_ROWS - filteredCallOuts.length - 1} />
            ))}
          </div>
          <div className={this.getTable2Style()} ref={this.setMasterScrollRef}>
            {filteredCallOuts.map(c => {
              const { callOutId } = c;
              const isSelected = callOutId === selectedCallOutId;
              return (
                <TimelineRow
                  time={time}
                  operationId={operationId}
                  callOut={c}
                  history={history}
                  key={callOutId}
                  selected={isSelected}
                  setRef={isSelected ? this.setSelectedRef : undefined}
                  now={now}
                />
              );
            })}
            {times(Math.max(NUMBER_OF_ROWS - filteredCallOuts.length, 0)).map(i => (
              <EmptyTimelineRow key={i} time={time} history={history} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  getTable2Style = () => {
    if (this.masterScrollRef) {
      const scrollbarHeight = getHorizontalScrollbarHeight(this.masterScrollRef);
      return stylesTable2[`hsh${scrollbarHeight}px`];
    }
    return stylesTable2.hsh18px;
  };

  setSelectedRef = (ref: HTMLElement | null) => {
    this.setState({ selectedRef: ref });
  };

  setTable1ScrollRef = (ref: HTMLElement | null) => {
    if (ref) {
      this.table1ScrollRef = ref;
    }
  };

  setTimeHeaderScrollRef = (ref: HTMLElement | null) => {
    if (ref) {
      this.timeHeaderScrollRef = ref;
    }
  };

  setMasterScrollRef = (ref: HTMLElement | null) => {
    if (ref) {
      this.masterScrollRef = ref;
      ref.addEventListener('scroll', this.syncScroll);
    }
  };

  syncScroll = () => {
    if (this.timeHeaderScrollRef && this.masterScrollRef && this.table1ScrollRef) {
      this.timeHeaderScrollRef.scrollLeft = this.masterScrollRef.scrollLeft;
      this.table1ScrollRef.scrollTop = this.masterScrollRef.scrollTop;
    }
  };

  handleStatusFilterChanged = (value: CrowdTaskCallOutStatus | null) => {
    this.setState({ filter: value });
  };

  renderItem = (value: CrowdTaskCallOutStatus | null) => {
    const { groupedCallOuts } = this.props;
    if (value) {
      return (
        <div>
          <CallOutStatusText status={value} />
          {` (${groupedCallOuts[value] ? groupedCallOuts[value].length : '0'})`}
        </div>
      );
    } else {
      return (
        <div>
          <FormattedMessage id="Timeline.all" defaultMessage="All" />
          {` (${groupedCallOuts.all.length})`}
        </div>
      );
    }
  };

  handleChange = (event, index, value) => {
    this.setState({ filter: value });
  };

  createTimeArray(now: Date, callOuts: CrowdTaskCallOut[]): Array<string> {
    let diff = 0;
    callOuts.forEach(c => {
      const d = differenceInHours(new Date(c.endTime), startOfHour(now)) + 1;

      if (d < TIMELINE_SIZE) {
        diff = Math.max(diff, d);
      }
    });
    diff = Math.min(Math.max(TIMELINE_MIN_SIZE, diff), TIMELINE_SIZE);

    const timeArray: string[] = [];
    for (let i = 0; i < diff; i++) {
      const nextTime = format(startOfHour(addHours(now, i)), 'HH:mm');
      timeArray.push(nextTime);
    }

    return timeArray;
  }
}

export default connect(mapStateToProps)(Timeline);

type DataRowProps = {
  history: History;
  callOut: CrowdTaskCallOut;
  isLastRow: boolean;
  selected: boolean;
  hasOfferedParticipations: boolean;
  acceptedParticipationCount: number;
};

class DataRow extends React.PureComponent<DataRowProps> {
  render() {
    const {
      acceptedParticipationCount,
      hasOfferedParticipations,
      callOut,
      isLastRow,
      selected,
      callOut: { status },
    } = this.props;
    let rowStyle = stylesTable1.row;
    if (isLastRow) {
      if (selected) {
        rowStyle = stylesTable1.lastRowSelected;
      } else {
        rowStyle = stylesTable1.lastRow;
      }
    } else if (selected) {
      rowStyle = stylesTable1.rowSelected;
    }

    return (
      <div className={rowStyle} onClick={this.handleClicked}>
        <div className={stylesTable1.contentCell}>
          <p className={stylesTableBase.text}>
            {acceptedParticipationCount}/{callOut.requiredTeamSize}
          </p>
        </div>
        <div className={stylesTable1.contentCellIcon}>
          <p className={stylesTableBase.cellContent}>
            {hasOfferedParticipations ? (
              <span className={styles.icon}>
                <i className="icon-accept" />
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </p>
        </div>
        <div className={stylesTable1.contentCellTask}>
          <p className={stylesTableBase.text}>{callOut.task}</p>
        </div>
        <div className={stylesTable1.contentCellStatus}>
          <p className={stylesTableBase.text}>
            <CallOutStatusText status={status} />
          </p>
        </div>
      </div>
    );
  }

  handleClicked = () => {
    const {
      callOut,
      history,
      callOut: { operationId },
    } = this.props;
    history.push(`/operations/${operationId}/callOuts/${callOut.callOutId}`);
  };
}

type EmptyDataRowProps = {
  isLastRow: boolean;
};

class EmptyDataRow extends React.PureComponent<EmptyDataRowProps> {
  render() {
    const { isLastRow } = this.props;
    return (
      <div className={isLastRow ? stylesTable1.lastRow : stylesTable1.row}>
        <div className={stylesTable1.emptyCell}>
          <p className={stylesTableBase.text}>
            <FormattedMessage id="Timeline.noCallOut" defaultMessage="-" />
          </p>
        </div>
      </div>
    );
  }
}

type TimelineRowProps = {
  operationId: string;
  callOut: CrowdTaskCallOut;
  history: History;
  selected: boolean;
  time: Array<string>;
  setRef?: (ref: HTMLElement | null) => void;
  now: Date;
};

class TimelineRow extends React.PureComponent<TimelineRowProps> {
  render() {
    const { callOut, selected, time, history, setRef, now } = this.props;
    const { startTime, status, endTime } = callOut;

    const timeDiffUntilStart = differenceInMinutes(new Date(startTime), startOfHour(now));
    const timeDiffUntilEnd = differenceInMinutes(new Date(endTime), startOfHour(now));
    const duration = differenceInMinutes(new Date(endTime), new Date(startTime));
    const timeDiffUntilChartEnd =
      timeDiffUntilStart >= 0 ? time.length * 60 - timeDiffUntilStart - duration : time.length * 60 - timeDiffUntilEnd;

    if (timeDiffUntilStart <= time.length * 60 && timeDiffUntilStart + duration >= 0) {
      // Not too far in the future or already over
      return (
        <div className={stylesTable2.row} onClick={this.handleClicked} ref={setRef}>
          {timeDiffUntilStart >= 0 && (
            <TimelineBeforeSpacer
              span={minutesToSpan(timeDiffUntilStart) + (OFFSET_TO_MIDDLE ? 60 / RESOLUTION / 2 : 0)}
              selected={selected}
            />
          )}
          {timeDiffUntilStart >= 0 && <TimelineStart status={status} selected={selected} />}
          <TimelineMiddle
            selected={selected}
            status={status}
            span={
              timeDiffUntilStart >= 0 ? minutesToSpan(duration) - 2 : minutesToSpan(duration + timeDiffUntilStart) - 1
            }
          />
          <TimelineEnd selected={selected} status={status} />
          <TimelineAfterSpacer selected={selected} span={minutesToSpan(timeDiffUntilChartEnd)} />
        </div>
      );
    } else {
      // Already over or too far in the future
      return <EmptyTimelineRow time={time} callOut={callOut} selected={selected} history={history} setRef={setRef} />;
    }
  }

  handleClicked = () => {
    const { callOut, history, operationId } = this.props;
    history.push(`/operations/${operationId}/callOuts/${callOut.callOutId}`);
  };
}

type EmptyTimelineRowProps = {
  time: Array<string>;
  callOut?: CrowdTaskCallOut;
  selected?: boolean;
  history: History;
  setRef?: (ref: HTMLElement | null) => void;
};

class EmptyTimelineRow extends React.PureComponent<EmptyTimelineRowProps> {
  render() {
    const { time, callOut, selected, setRef } = this.props;

    return (
      <div className={callOut ? stylesTable2.row : stylesTable2.emptyRow} onClick={this.handleClicked} ref={setRef}>
        {time.map((t, j) => (
          <div className={selected ? stylesTable2.emptyCellSelected : stylesTable2.emptyCell} key={j}>
            &nbsp;
          </div>
        ))}
      </div>
    );
  }

  handleClicked = () => {
    const { callOut, history } = this.props;
    if (callOut) {
      history.push(`/operations/${callOut.operationId}/callOuts/${callOut.callOutId}`);
    }
  };
}

function minutesToSpan(minutes: number): number {
  return minutes / RESOLUTION;
}

function TimelineMiddle({ status, span, selected }) {
  return (
    <div className={selected ? stylesTable2.timelinePartWrapperSelected : stylesTable2.timelinePartWrapper}>
      {times(span).map(t => (
        <div key={t} className={stylesTable2.timelinePart}>
          <div key={t} className={status === 'RELEASED' ? styles.durationCell : styles.durationCellGrey}>
            &nbsp;
          </div>
        </div>
      ))}
    </div>
  );
}

function TimelineStart({ status, selected }) {
  return (
    <div className={selected ? stylesTable2.timelinePartWrapperSelected : stylesTable2.timelinePartWrapper}>
      <div className={stylesTable2.timelinePart}>
        <div className={status === 'RELEASED' ? styles.startCell : styles.startCellGrey}>&nbsp;</div>
      </div>
    </div>
  );
}

function TimelineEnd({ status, selected }) {
  return (
    <div className={selected ? stylesTable2.timelinePartWrapperSelected : stylesTable2.timelinePartWrapper}>
      <div className={stylesTable2.timelinePart}>
        <div className={status === 'RELEASED' ? styles.endCell : styles.endCellGrey}>&nbsp;</div>
      </div>
    </div>
  );
}

function TimelineBeforeSpacer({ span, selected }) {
  return (
    <div className={selected ? stylesTable2.timelinePartWrapperSelected : stylesTable2.timelinePartWrapper}>
      {times(span).map(t => (
        <div key={t} className={stylesTable2.timelinePart} />
      ))}
    </div>
  );
}

function TimelineAfterSpacer({ span, selected }) {
  return (
    <div className={selected ? stylesTable2.timelinePartWrapperSelected : stylesTable2.timelinePartWrapper}>
      {times(span).map(t => (
        <div key={t} className={stylesTable2.timelinePart} />
      ))}
    </div>
  );
}
