import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import { getStatistics, WebDispatchProps } from '../actions';
import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import { getApiCallStatus } from '../../api/reducers/api';
import { WebReduxState } from '../reducers';
import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { StatisticsState } from '../reducers/statistics';
import LoadingIcon from '../navigation/LoadingIcon';
import textStyles from '../css/text.module.css';
import styles from './Statistics.module.css';

type StatType = 'region' | 'line' | 'incidentType' | 'day';

type OwnProps = {};

type StoreProps = {
  appGetStatistics: ApiCallStatus;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  statisticsFetchedAt: string;
  statistics: StatisticsState;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    appGetStatistics: getApiCallStatus(state, 'GET_STATISTICS'),
    dataFilterTypes: dataFilterTypesSelector(state),
    statisticsFetchedAt: state.admin.persisted.statisticsFetchedAt,
    statistics: state.admin.statistics,
  };
}

class Statistics extends React.Component<Props> {
  componentDidMount() {
    const { statistics, dispatch } = this.props;
    const { dailyStatistic, regionStatistic, lineStatistic, incidentTypeStatistic } = statistics;
    if (
      isEmpty(dailyStatistic) &&
      isEmpty(regionStatistic) &&
      isEmpty(lineStatistic) &&
      isEmpty(incidentTypeStatistic)
    ) {
      dispatch(getStatistics());
    }
  }

  render() {
    const { appGetStatistics, statistics, dataFilterTypes, statisticsFetchedAt } = this.props;
    const { dailyStatistic, regionStatistic, lineStatistic, incidentTypeStatistic } = statistics;

    if (appGetStatistics.inProgress) {
      return (
        <tr>
          <td>
            <LoadingIcon />
          </td>
        </tr>
      );
    }

    if (
      isEmpty(dailyStatistic) &&
      isEmpty(regionStatistic) &&
      isEmpty(lineStatistic) &&
      isEmpty(incidentTypeStatistic)
    ) {
      return (
        <h2 className={styles.centerText}>
          <NoStats />
        </h2>
      );
    }

    const lastTimeFetched = (
      <span className={textStyles.bold}>
        <FormattedTimestamp value={statisticsFetchedAt} hourAndMinutesOnPastDays={true} />
      </span>
    );

    const statisticsColDescription = <FormattedMessage id="Statistics.setCount" defaultMessage="#Set" />;

    return (
      <div className={styles.container}>
        <div className={styles.statistics}>
          <StatisticsCol
            header={<FormattedMessage id="Statistics.date" defaultMessage="Date" />}
            desc={<FormattedMessage id="Statistics.appStarts" defaultMessage="App Starts/Day" />}
            body={<StatisticsBody statistics={dailyStatistic} type="day" />}
          />
          {dataFilterTypes.includes('incidentTypes') ? (
            <StatisticsCol
              header={<FormattedMessage id="Statistics.incidentTypes" defaultMessage="Categories" />}
              desc={statisticsColDescription}
              body={<StatisticsBody statistics={incidentTypeStatistic} type="incidentType" />}
            />
          ) : (
            <StatisticsCol
              header={<FormattedMessage id="Statistics.regions" defaultMessage="Regions" />}
              desc={statisticsColDescription}
              body={<StatisticsBody statistics={regionStatistic} type="region" />}
            />
          )}
          <StatisticsCol
            header={<FormattedMessage id="Statistics.places" defaultMessage="Lines" />}
            desc={statisticsColDescription}
            body={<StatisticsBody statistics={lineStatistic} type="line" />}
          />
        </div>
        {statisticsFetchedAt && (
          <div className={styles.fetchedAt}>
            <FormattedMessage
              id="Statistics.lastTimeFetched"
              defaultMessage="Statistics were successfully fetched at {lastTimeFetched} for the last time"
              values={{ lastTimeFetched }}
            />
          </div>
        )}
      </div>
    );
  }
}

function StatisticsBody(props: { statistics: Object[]; type: StatType }) {
  const { statistics, type } = props;

  return (
    <tbody>
      {isEmpty(statistics) ? (
        <NoStatsRow />
      ) : (
        statistics.map((stat, i) => <StatisticsRow key={i} stat={stat} type={type} />)
      )}
    </tbody>
  );
}

function StatisticsRow(props: { stat: Object; type: StatType }) {
  const { stat, type } = props;
  return (
    <tr>
      <td>
        {type === 'day' ? (
          <FormattedDate value={stat['day']} day="2-digit" month="2-digit" year="numeric" />
        ) : (
          stat[type]
        )}
      </td>
      <td className={styles.textAlignRight}>{type === 'day' ? stat['appStarts'] : stat['count']}</td>
    </tr>
  );
}

function StatisticsCol(props: { header: React.ReactNode; desc: React.ReactNode; body: Object }) {
  const { header, desc, body } = props;
  return (
    <div className={styles.statisticsScrollBox}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{header}</th>
            <th className={styles.textAlignRight}>{desc}</th>
          </tr>
        </thead>
        {body}
      </table>
    </div>
  );
}

function NoStats() {
  return (
    <span className="tint">
      <FormattedMessage id="Statistics.noStatistics" defaultMessage="No statistics available!" />
    </span>
  );
}

function NoStatsRow() {
  return (
    <tr>
      <NoStats />
    </tr>
  );
}

function isEmpty(array: Object[] | null | undefined) {
  return !array || array.length < 1;
}

export default connect(mapStateToProps)(Statistics);
