import { createSelector } from 'reselect';

import { ReduxState } from '../../common/reducers';
import { App, appSelector } from '../../api/reducers/appConfig';
import { dataFilterTypesSelector } from '../../api/reducers/config';
import { hasManagedServerFiltersSelector } from '../../api/reducers/userInfo';

const wizardRouteNamesSelector: (state: ReduxState) => string[] = createSelector(
  state => state.settings.feedbackPhoneNumber,
  state => hasManagedServerFiltersSelector(state),
  state => dataFilterTypesSelector(state),
  state => appSelector(state),
  (feedbackPhoneNumber, hasManagedDataFilter, filterTypes, app) => {
    const routeNames: string[] = [];

    if (app === ('staffMember' as App)) {
      return ['states', 'callOutAreas'];
    }

    if (!feedbackPhoneNumber.length) {
      routeNames.push('feedbackPhoneNumber');
    }

    if (!hasManagedDataFilter) {
      routeNames.push(...filterTypes);
    }

    return routeNames;
  }
);

export default wizardRouteNamesSelector;
