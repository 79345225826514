import { call, select, delay } from 'redux-saga/effects';

import log from '../logging';
import simpleFetch, { SimpleRequestOptions } from './simpleFetch';
import handleError from './handleError';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultTimeout = 10000; // milliseconds

const retryOptions = {
  maxAttempts: 3,
  interval: 1000, // milliseconds
};

export type ApiCallConfig<S> = {
  authenticationEnabledSelector: (state: S) => boolean;
  serverUrlSelector: (state: S) => string;
  refreshToken: (force: boolean) => Generator<any, any, any>;
  logout: () => Generator<any, any, any>;
};

export default function* apiCall(
  config: ApiCallConfig<any>,
  path: string,
  options: SimpleRequestOptions = {}
): Generator<any, any, any> {
  const { serverUrlSelector, logout } = config;

  const serverUrl: string = yield select(serverUrlSelector);
  const url = `${serverUrl}/api/v1/${path}`;

  const { maxAttempts, interval } = retryOptions;
  let headers = options.headers || defaultHeaders;

  let needsForceRefresh = false;
  let num401Errors = 0;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    if (attempt > 1) {
      yield delay(interval);
      log.info(`Fetch attempt number ${attempt} for url ${url}`);
    }

    if (!options.noauth) {
      try {
        // We need to do this on every try, as the token may have expired in the meantime.
        headers = yield* setAuthorization(config, headers, needsForceRefresh);
      } catch (error) {
        if (error instanceof Error && error['code'] === 'EAUTH') {
          // EAUTH comes from the client-oauth2 framework or our refreshToken code.
          // It means that the error is an actual OAuth2 error / unrecoverable token refresh error.
          log.error(`Unrecoverable auth error on fetch attempt ${attempt} for url ${url}, logging out`, error);

          yield* logout();
          throw error;
        } else {
          // This might be due to network unavailable, timeout etc.
          log.error(`Token refresh error on fetch attempt ${attempt} for url ${url}`, error);
        }

        if (attempt === maxAttempts) {
          log.error('Max fetch attempts reached, giving up.');
          throw error;
        } else {
          // Don't try to do the API call, instead retry on next loop iteration.
          continue;
        }
      }
    }

    try {
      return yield call(performFetch, url, { ...options, timeout: options.timeout || defaultTimeout, headers });
    } catch (error) {
      const status: number = error.status;

      if (status === 401) {
        // Backend says 401 Unauthorized.
        num401Errors++;
        if (num401Errors > 1) {
          log.error(`401 error number ${num401Errors}. Unrecoverable authentication issue => logout`);
          yield* logout();

          throw error;
        } else {
          log.error('401 error. Forcing token refresh on next retry.');
          needsForceRefresh = true;
        }
      } else if (status && Math.trunc(status / 100) === 4 && status !== 408) {
        // Do not retry 4xx errors, except for 401 special handling above and 408 Request Timeout.
        log.error(`${status} error on fetch attempt ${attempt} for url ${url}, giving up immediately`, error);
        throw error;
      } else {
        // Other error.
        log.error(`Error on fetch attempt ${attempt} for url ${url}`, error);
      }

      if (attempt === maxAttempts) {
        log.error('Max fetch attempts reached, giving up.');
        throw error;
      }
    }
  }

  // Never happens.
  throw new Error('apiCall: internal error');
}

function* setAuthorization(config: ApiCallConfig<any>, headers: HeadersInit, needsForceRefresh: boolean) {
  const { authenticationEnabledSelector, refreshToken } = config;
  const authenticationEnabled: boolean = yield select(authenticationEnabledSelector);
  if (!authenticationEnabled) {
    return headers;
  }

  const token: string = yield* refreshToken(needsForceRefresh);

  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
}

export function performFetch(url: string, options: Object) {
  return simpleFetch(url, options)
    .then(handleError)
    .then(response => {
      const contentType: string | null | undefined = response.headers.get('content-type');
      return contentType && contentType.startsWith('application/json') ? response.json() : response.text();
    });
}
