import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { IncidentsViewMode } from '../../common/selectors/incidentsByViewMode';
import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { hasManagedServerFiltersSelector } from '../../api/reducers/userInfo';
import { WebDispatchProps, fetchIncidents, setWizardModal } from '../actions';
import { ReduxState } from '../../common/reducers';
import Button from '../../api/components/Button';
import FullScreenMessage from '../navigation/FullScreenMessage';

type OwnProps = {
  viewMode: IncidentsViewMode;
};

type StoreProps = {
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  hasManagedServerFilters: boolean;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    dataFilterTypes: dataFilterTypesSelector(state),
    hasManagedServerFilters: hasManagedServerFiltersSelector(state),
  };
}

class NoIncidents extends React.Component<Props> {
  render() {
    const { viewMode, dataFilterTypes, hasManagedServerFilters } = this.props;

    switch (viewMode) {
      case 'favorites' as IncidentsViewMode:
        return <NoFavoritesWarning />;
      case 'filter' as IncidentsViewMode:
        return <NoFilterWarning dataFilterTypes={dataFilterTypes} />;
      case 'all' as IncidentsViewMode:
      default:
        return (
          <NoIncidentsWarning
            onFetchClick={this.handleFetchClick}
            onDataFilterClick={this.handleDataFilterClick}
            hasManagedServerFilters={hasManagedServerFilters}
          />
        );
    }
  }

  handleFetchClick = () => {
    this.props.dispatch(fetchIncidents());
  };

  handleDataFilterClick = () => {
    this.props.dispatch(setWizardModal('user'));
  };
}

function NoIncidentsWarning({ onFetchClick, onDataFilterClick, hasManagedServerFilters }) {
  return (
    <FullScreenMessage
      icon={<i className="icon-message" />}
      title={<FormattedMessage id="NoDataScreen.noIncidentsFound" defaultMessage="No Messages" />}
      message={<FormattedMessage id="NoDataScreen.tryToReload" defaultMessage="Check your data filter settings." />}>
      <Button onClick={onFetchClick}>
        <FormattedMessage id="NoDataScreen.reload" defaultMessage="Reload" />
      </Button>
      <br />
      {hasManagedServerFilters || (
        <Button onClick={onDataFilterClick}>
          <FormattedMessage id="NoDataScreen.dataFilter" defaultMessage="Data Filter" />
        </Button>
      )}
    </FullScreenMessage>
  );
}

function NoFavoritesWarning() {
  return (
    <FullScreenMessage
      icon={<i className="icon-star" />}
      title={<FormattedMessage id="NoDataScreen.noFavorites" defaultMessage="No Favorites" />}
      message={
        <FormattedMessage
          id="NoDataScreen.addIncidents"
          defaultMessage={
            'Add messages to your Favorites list\nby tagging them with the Favorites icon\nin the message list.'
          }
        />
      }
    />
  );
}

function NoFilterWarning({ dataFilterTypes }) {
  return (
    <FullScreenMessage
      icon={<i className="icon-filter" />}
      title={<FormattedMessage id="NoDataScreen.noFilter" defaultMessage={'No Filters used or\nno Messages found'} />}
      message={
        dataFilterTypes.includes('incidentTypes') ? (
          <FormattedMessage
            id="NoDataScreen.addSettingsWithIncidentTypes"
            defaultMessage={'Add lines, locations or\nincident types in the filter settings.'}
          />
        ) : (
          <FormattedMessage
            id="NoDataScreen.addSettingsWithRegions"
            defaultMessage={'Add lines, locations or\nregions in the filter settings.'}
          />
        )
      }
    />
  );
}

export default connect(mapStateToProps)(NoIncidents);
