export type OperationsAction =
  | { type: 'GET_OPERATIONS' }
  | { type: 'POST_OPERATION' }
  | { type: 'UPDATE_OPERATION'; operationId: string; version: number }
  | { type: 'TERMINATE_OPERATION'; operationId: string; version: number }
  | { type: 'DELETE_OPERATION'; operationId: string; version: number }
  | { type: 'SEND_CODE'; operationId: string };

export function getOperations(): OperationsAction {
  return { type: 'GET_OPERATIONS' };
}

export function postOperation(): OperationsAction {
  return { type: 'POST_OPERATION' };
}

export function updateOperation(operationId: string, version: number): OperationsAction {
  return { type: 'UPDATE_OPERATION', operationId, version };
}

export function terminateOperation(operationId: string, version: number): OperationsAction {
  return { type: 'TERMINATE_OPERATION', operationId, version };
}

export function deleteOperation(operationId: string, version: number): OperationsAction {
  return { type: 'DELETE_OPERATION', operationId, version };
}

export function sendCode(operationId: string): OperationsAction {
  return { type: 'SEND_CODE', operationId };
}
