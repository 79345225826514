import * as React from 'react';
import Panel from '@material-ui/core/Accordion';
import PanelBody from '@material-ui/core/AccordionDetails';
import PanelHeading from '@material-ui/core/AccordionSummary';

import styles from './FilterPanel.module.css';

type Props = {
  activeKey: string;
  eventKey: string;
  onChange: (string) => void;
  header: React.ReactNode;
  children: React.ReactNode;
};

export default function FilterPanel({ activeKey, eventKey, onChange, header, children }: Props) {
  return (
    <Panel className={styles.panel} expanded={activeKey === eventKey} onChange={() => onChange(eventKey)}>
      <PanelHeading
        className={styles.panelHeading}
        expandIcon={<i className={`icon-caret_down ${styles.caretFilter}`} />}>
        {header}
      </PanelHeading>
      <PanelBody className={styles.panelBody}>{children}</PanelBody>
    </Panel>
  );
}
