import * as React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';

import isNumeric from 'validator/lib/isNumeric';
import subMinutes from 'date-fns/subMinutes';
import isSameDay from 'date-fns/isSameDay';
import addMinutes from 'date-fns/addMinutes';
import addHours from 'date-fns/addHours';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import { createSelector } from 'reselect';
import { values as lodashValues, mapValues, groupBy } from 'lodash-es';

import { TextField } from '../../shared/formComponents';
import { CrowdTaskCallOut, CrowdTaskCallOutStatus } from '../../api/types';
import { Select, MultiSelect } from '../../api/components/Select';
import { intlSelector } from '../../api/selectors/i18n';
import validatorsSelector, { Validators } from '../../api/selectors/validators';
import { clientConfigSelector } from '../../api/reducers/config';
import { nowSelector } from '../../common/reducers/refreshTimer';
import { setEditCallOutError, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import { matchTime, getDateFromTimeString, getOverlapInMinutes } from '../utils/dateTime';
import styles from './EditCallOutForm.module.css';
import { EditCallOutComponentMode, getCallOutIdOrNull } from './EditCallOutComponent';

const msg = defineMessages({
  notLongerThanValidator: {
    id: 'EditCallOutForm.validation.notLongerThanValidator',
    defaultMessage: 'Call out cannot be longer than {maxLengthInMinutes} minutes.',
  },
  notShorterThanValidator: {
    id: 'EditCallOutForm.validation.notShorterThanValidator',
    defaultMessage: 'Call out cannot be shorter than {minLengthInMinutes} minutes.',
  },
  notTooFarInFutureValidator: {
    id: 'EditCallOutForm.validation.notTooFarInFutureValidator',
    defaultMessage: 'Call out cannot be more than {maxStartTimeInFutureInHours}h in the future.',
  },
  notPastValidator: {
    id: 'EditCallOutForm.validation.notPastStartValidator',
    defaultMessage: 'Call out cannot start in the past',
  },
  notMatchesResolutionValidator: {
    id: 'EditCallOutForm.validation.notMatchesResolution',
    defaultMessage: 'Minutes must be a multiple of {resolution}.',
  },
  today: { id: 'EditCallOut.today', defaultMessage: '{date} (today)' },
  tomorrow: { id: 'EditCallOut.tomorrow', defaultMessage: '{date} (tomorrow)' },
  exceedsMaxTeamSize: {
    id: 'EditCallOutForm.validation.exceedsMaxTeamSize',
    defaultMessage: 'Required team size exceeds maximum of {max}',
  },
  overlapping: {
    id: 'EditCallOutForm.validation.overlapping',
    defaultMessage:
      'Overlap of more than {maxOverlap}min is not allowed. The original call out ends at {dateTime}. Please also check whether a follow-up call already exists for the planned period.',
  },
});

type FormValues = {
  baseDate: string;
  startTime: any;
  endTime: any;
  taskId?: string;
  task?: string;
  requiredTeamSize?: string;
  specialSkills?: Array<string>;
  languages?: Array<string>;
  callOutStatus?: CrowdTaskCallOutStatus;
};

type FormErrors = {
  startTime?: string;
  endTime?: string;
  requiredTeamSize?: string;
};

type CallOutsByTaskId = {
  [taskId: string]: { callOuts: CrowdTaskCallOut[]; containsNonDrafts: boolean };
};

type OwnProps = {
  mode: EditCallOutComponentMode;
};

type StoreProps = {
  isReleased: boolean;
  languages: string[];
  validate: (values: FormValues) => FormErrors;
  skills: string[];
  startTime: string | null | undefined;
  endTime: string | null | undefined;
  intl: IntlShape;
  baseDate: Date;
  validators: Validators;
  initialValues: FormValues;
  defaultLengthInMinutes: number;
  callOutsByTaskId: CallOutsByTaskId;
  followUpOverlapInMinutes: number;
  now: Date;
  editCallOutError: string | null | undefined;
};

type Props = OwnProps & WebDispatchProps & StoreProps;

type State = {
  startDate?: Date;
  endDate?: Date;
  days: Array<any>;
};

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { mode } = ownProps;

  const callOutId = getCallOutIdOrNull(mode);
  let callOut: CrowdTaskCallOut | null | undefined = callOutId ? state.callOuts.callOuts.byId[callOutId] : null;

  const callOutsByTaskId = callOutsByTaskIdSelector(state);
  if (mode.type === 'newFollowup' && callOut) {
    const callOutsByTask = callOutsByTaskId[callOut.taskId].callOuts;
    callOut = callOutsByTask.reduce((acc, curr) => (acc.endTime < curr.endTime ? curr : acc));
  }

  const initialValues = callOut ? getInitialValuesFromCallOut(callOut, mode, state) : getInitialValuesFromState(state);

  // new Date() might seem scary, but it is only used initially as a default initial value for the day selection box
  // in the case of getting there by pressing creating a new call out
  return {
    isReleased: callOut ? callOut.status === 'RELEASED' && mode.type === 'edit' : false,
    // These are "magically" passed to redux-form.
    initialValues,
    validate: validateSelector(state),
    skills: state.masterData.specialSkills,
    languages: state.masterData.languages,
    intl: intlSelector(state),
    // Get current form values additionally to calculate startDate and endDate in componentWillReceiveProps()

    // TODO: What about these three? Aren't they already in initialValues?
    baseDate:
      state.form.editCallOut && state.form.editCallOut.values ? state.form.editCallOut.values.baseDate : new Date(),
    startTime: state.form.editCallOut && state.form.editCallOut.values ? state.form.editCallOut.values.startTime : null,
    endTime: state.form.editCallOut && state.form.editCallOut.values ? state.form.editCallOut.values.endTime : null,
    validators: validatorsSelector(state),
    callOutsByTaskId,
    defaultLengthInMinutes: clientConfigSelector(state).crowdTask.callOuts.defaultLengthInMinutes,
    followUpOverlapInMinutes: state.config.loaded.crowdTask.callOuts.followUpOverlapInMinutes,
    now: nowSelector(state),
    editCallOutError: state.ui.editCallOut.editCallOutError,
  };
}

function getInitialValuesFromCallOut(
  callOut: CrowdTaskCallOut,
  mode: EditCallOutComponentMode,
  state: WebReduxState
): FormValues {
  const config = clientConfigSelector(state);
  const { defaultFollowupOverlapInMinutes, defaultLengthInMinutes } = config.crowdTask.callOuts;
  const { startTime, endTime, task, requiredTeamSize, specialSkills, languages, taskId } = callOut;

  const initialStartTime =
    mode.type === 'newFollowup' ? subMinutes(new Date(endTime), defaultFollowupOverlapInMinutes) : new Date(startTime);
  const initialEndTime =
    mode.type === 'newFollowup' ? addMinutes(initialStartTime, defaultLengthInMinutes) : new Date(endTime);

  return {
    baseDate: getDay(startTime).toISOString(),
    startTime: toTimeString(initialStartTime),
    endTime: toTimeString(initialEndTime),
    task,
    taskId,
    requiredTeamSize: requiredTeamSize.toString(),
    specialSkills,
    languages,
    callOutStatus: callOut.status,
  };
}

function getInitialValuesFromState(state): FormValues {
  const {
    crowdTask: {
      callOuts: { resolutionInMinutes },
    },
    config: {
      crowdTask: {
        callOuts: { defaultLengthInMinutes },
      },
    },
  } = state.config.loaded;
  const roundMinutes = resolutionInMinutes * 60 * 1000;

  // Round up accordingly
  const defaultTimeBase = addHours(new Date(Math.ceil(Number(new Date()) / roundMinutes) * roundMinutes), 1);

  const values = state.form.editCallOut ? state.form.editCallOut.values : null;
  const startTime = values && values.startTime ? values.startTime : toTimeString(defaultTimeBase);
  const endTime =
    values && values.endTime ? values.endTime : toTimeString(addMinutes(defaultTimeBase, defaultLengthInMinutes));

  const today = getDay(new Date().toISOString());
  const baseDate = today.toISOString();

  return { baseDate, startTime, endTime };
}

const callOutsByTaskIdSelector: (state: WebReduxState) => CallOutsByTaskId = createSelector(
  state => state.callOuts.callOuts.byId,
  callOutsById => {
    const callOuts = lodashValues(callOutsById);
    return mapValues(
      groupBy(callOuts, c => c.taskId),
      callOutsArray => ({
        callOuts: callOutsArray,
        containsNonDrafts: callOutsArray.some(c => c.status !== ('DRAFT' as CrowdTaskCallOutStatus)),
      })
    );
  }
);

const validateSelector: (state: WebReduxState) => (values: FormValues) => FormErrors = createSelector(
  state => intlSelector(state),
  state => state.config.loaded.crowdTask.callOuts,
  state => state.form.editCallOut && state.form.editCallOut.initial && state.form.editCallOut.initial.callOutStatus,
  (intl, config, editCallOutStatus) => {
    return values => {
      const {
        resolutionInMinutes,
        maxStartTimeInFutureInHours,
        minLengthInMinutes,
        maxLengthInMinutes,
        maxRequiredTeamSize,
      } = config;
      const errors: FormErrors = {};

      const { baseDate, startTime, endTime, requiredTeamSize } = values;
      const dates = getStartEndDates(baseDate, startTime, endTime);

      const { startDate, endDate } = dates;
      if (startDate && endDate) {
        const startDateTime = getDateFromTimeString(startDate, startTime);
        const endDateTime = getDateFromTimeString(endDate, endTime);

        // Min duration
        if (endDateTime && startDateTime && differenceInMinutes(endDateTime, startDateTime) < minLengthInMinutes) {
          errors.startTime = errors.endTime = intl.formatMessage(msg.notShorterThanValidator, {
            minLengthInMinutes,
          });
        }

        // Max duration
        if (endDateTime && startDateTime && differenceInMinutes(endDateTime, startDateTime) > maxLengthInMinutes) {
          errors.startTime = errors.endTime = intl.formatMessage(msg.notLongerThanValidator, {
            maxLengthInMinutes,
          });
        }

        // Not past
        if (editCallOutStatus !== 'RELEASED' && startDateTime && isBefore(startDateTime, new Date())) {
          errors.startTime = intl.formatMessage(msg.notPastValidator);
        }

        // Not more than x in the future
        if (startDateTime && differenceInHours(startDateTime, new Date()) > maxStartTimeInFutureInHours) {
          errors.startTime = intl.formatMessage(msg.notTooFarInFutureValidator, { maxStartTimeInFutureInHours });
        }
      }

      if (startDate && startDate.getMinutes() % resolutionInMinutes !== 0) {
        errors.startTime = intl.formatMessage(msg.notMatchesResolutionValidator, { resolution: resolutionInMinutes });
      }

      if (endDate && endDate.getMinutes() % resolutionInMinutes !== 0) {
        errors.endTime = intl.formatMessage(msg.notMatchesResolutionValidator, {
          resolution: resolutionInMinutes,
        });
      }

      if (requiredTeamSize && parseInt(requiredTeamSize, 10) > maxRequiredTeamSize) {
        errors.requiredTeamSize = intl.formatMessage(msg.exceedsMaxTeamSize, {
          max: maxRequiredTeamSize,
        });
      }

      return errors;
    };
  }
);

const isTeamSize = (value: string | null | undefined) =>
  !value || (isNumeric(value) && parseInt(value, 10) > 0) ? null : (
    <FormattedMessage id="EditCallOutForm.validation.teamSize" defaultMessage="Invalid team size." />
  );

const timeValidator = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }

  return matchTime(value) ? null : (
    <FormattedMessage
      id="EditCallOutForm.validation.time"
      defaultMessage="Invalid time format (must be of the form HHMM or HH:MM)"
    />
  );
};

const normalizeTime = value => {
  if (!value || value.length <= 2) {
    return value;
  }

  let newValue = value;
  if (value.includes(':')) {
    newValue = newValue.replace(':', '');
  }

  if (matchTime(newValue)) {
    const index = newValue.length === 3 ? 1 : 2;
    return `${newValue.slice(0, index)}:${newValue.slice(index)}`;
  }

  return value;
};

class EditCallOutForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const today = getDay(new Date());
    const tomorrow = addDays(today, 1);

    this.state = {
      days: [
        {
          id: 'TODAY',
          message: msg.today,
          date: today.toISOString(),
        },
        {
          id: 'TOMORROW',
          message: msg.tomorrow,
          date: tomorrow.toISOString(),
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { startTime, endTime, baseDate, defaultLengthInMinutes } = nextProps;
    const { startDate, endDate } = this.state;
    const baseDay = getDay(baseDate);

    // Initialize the start and end date
    const startDateTime = getDateFromTimeString(baseDay, startTime);
    if ((!startDate || !endDate) && startDateTime && startTime && endTime) {
      this.setState({
        startDate: getDay(startDateTime),
        endDate: addMinutes(startDateTime, defaultLengthInMinutes),
      });
      return;
    }

    if (startTime && baseDate) {
      this.validateStartTimeInFuture();
    }

    if (
      !startTime ||
      !endTime ||
      (startTime === this.props.startTime && endTime === this.props.endTime && baseDate === this.props.baseDate)
    ) {
      // Nothing changed
      return;
    }

    // Times and/or base date changed. Calculate new start and end dates!
    const newStartDate = getDateFromTimeString(baseDay, startTime);
    let newEndDate = getDateFromTimeString(baseDay, endTime);

    if (!newStartDate || !newEndDate) {
      return;
    }

    // if end is before start -> end is on the next day!
    newEndDate = addDays(newEndDate, isBefore(newEndDate, newStartDate) ? 1 : 0);

    // Set date values for the displays in front of the time text boxes
    this.setState({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }

  validateStartTimeInFuture() {
    const { startTime, baseDate, intl, dispatch, editCallOutError, isReleased } = this.props;

    const baseDay = getDay(baseDate);
    const startDate = getDateFromTimeString(baseDay, startTime);

    if (!startDate) {
      return;
    }

    const startDateTime = getDateFromTimeString(startDate, startTime);
    const hasError = !isReleased && startDateTime && isBefore(startDateTime, new Date());

    if (!editCallOutError && hasError) {
      dispatch(setEditCallOutError(intl.formatMessage(msg.notPastValidator)));
    } else if (editCallOutError && !hasError) {
      // Reset error
      dispatch(setEditCallOutError(null));
    }
  }

  render() {
    const {
      languages,
      skills,
      intl,
      mode: { type },
      initialValues,
      validators: { required },
      callOutsByTaskId,
      isReleased,
    } = this.props;
    const { endDate, days } = this.state;
    const requiredLabel = label => <span>{label}*</span>;

    return (
      <div>
        <form className={styles.form}>
          <div className={styles.fieldsWrapper}>
            <div className={styles.firstRow}>
              <Select
                disabled={isReleased}
                name="baseDate"
                fullWidth={false}
                className={styles.fieldMarginRight}
                validate={[]}
                label={<FormattedMessage id="EditCallOutForm.day" defaultMessage="Day" />}>
                {days.map(day => (
                  <MenuItem key={day.id} value={day.date}>
                    {intl.formatMessage(day.message, { date: intl.formatDate(day.date) })}
                  </MenuItem>
                ))}
              </Select>
              <Field
                className={styles.fieldMarginRight}
                disabled={isReleased}
                name="startTime"
                component={TextField}
                label={requiredLabel(<FormattedMessage id="EditCallOutForm.startDate" defaultMessage="Start Time" />)}
                validate={[required, timeValidator, this.followupOverlapValidatorStart]}
                onFocus={this.handleFocus}
                normalize={normalizeTime}
              />
              <div className={styles.toDateWrapper}>
                <div className={isReleased ? styles.toDateDisabled : styles.toDate}>
                  <p>
                    <FormattedMessage id="EditCallOutForm.to" defaultMessage="to" />
                  </p>
                </div>
                <div className={isReleased ? styles.toDateDisabled : styles.toDate}>
                  <p>
                    {endDate && isSameDay(new Date(endDate), new Date(this.state.days[0].date))
                      ? intl.formatMessage(msg.today, { date: intl.formatDate(endDate) })
                      : intl.formatMessage(msg.tomorrow, { date: intl.formatDate(endDate) })}
                  </p>
                </div>
              </div>
              <Field
                disabled={isReleased}
                name="endTime"
                component={TextField}
                label={requiredLabel(<FormattedMessage id="EditCallOutForm.endDate" defaultMessage="End Time" />)}
                validate={[required, timeValidator, this.followupOverlapValidatorEnd]}
                onFocus={this.handleFocus}
                normalize={normalizeTime}
              />
            </div>
            <Field
              name="task"
              component={TextField}
              label={requiredLabel(<FormattedMessage id="EditCallOutForm.task" defaultMessage="Task" />)}
              validate={[required]}
              fullWidth={true}
              disabled={
                isReleased ||
                type === 'newFollowup' ||
                (type === 'edit' &&
                  initialValues.taskId &&
                  callOutsByTaskId[initialValues.taskId].callOuts.length > 1 &&
                  callOutsByTaskId[initialValues.taskId].containsNonDrafts)
              }
            />
            <Field
              name="requiredTeamSize"
              component={TextField}
              fullWidth={true}
              label={requiredLabel(
                <FormattedMessage id="EditCallOutForm.teamSize" defaultMessage="Required Team Size" />
              )}
              validate={[required, isTeamSize]}
            />
            <MultiSelect
              disabled={isReleased}
              name="specialSkills"
              className={styles.selectField}
              validate={[]}
              label={<FormattedMessage id="EditCallOutForm.specialSkills" defaultMessage="Special Skills" />}>
              {skills.map(skill => (
                <MenuItem key={skill} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </MultiSelect>
            <MultiSelect
              disabled={isReleased}
              name="languages"
              className={styles.selectField}
              validate={[]}
              label={<FormattedMessage id="EditCallOutForm.languages" defaultMessage="Languages" />}>
              {languages.map(language => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </MultiSelect>
          </div>
        </form>
      </div>
    );
  }

  handleFocus = (e: any) => {
    e.target.select();
  };

  followupOverlapValidatorStart = (value: string | null | undefined) => {
    return this.followupValidation(value, true);
  };

  followupOverlapValidatorEnd = (value: string | null | undefined) => {
    return this.followupValidation(value, false);
  };

  followupValidation(value: string | null | undefined, start: boolean) {
    // It's not beautiful having this type of field validation function that actually validates 2 fields.
    // Otoh, doing it in the form validation adds a few more hurdles which are not easily solvable in the form validation.
    // E.g. we need the taskId of the callOut edit/followUpCreation to check for followups which are in props but not readily available in ReduxState.
    const { callOutsByTaskId, initialValues, baseDate, followUpOverlapInMinutes, intl, mode } = this.props;

    const startTime = start ? value : this.props.startTime;
    const endTime = start ? this.props.endTime : value;

    if (!startTime || !endTime) {
      return null;
    }

    if (!initialValues.taskId) {
      return null;
    }

    const followupCallOuts = callOutsByTaskId[initialValues.taskId].callOuts;
    if (!followupCallOuts || !followupCallOuts.length) {
      return null;
    }

    const followupStartDate = getDateFromTimeString(baseDate, startTime);
    const followupEndDate = getDateFromTimeString(baseDate, endTime);
    if (!followupStartDate || !followupEndDate) {
      return null;
    }

    const overlappingWith = followupCallOuts.find(callOut => {
      if (mode.type === 'edit' && mode.callOutId && mode.callOutId === callOut.callOutId) {
        return null;
      }

      const startDate = new Date(callOut.startTime);
      const endDate = new Date(callOut.endTime);
      const overlap = getOverlapInMinutes(
        { startDate, endDate },
        { startDate: followupStartDate, endDate: followupEndDate }
      );

      // Check if overlap is longer than allowed
      if (overlap > followUpOverlapInMinutes) {
        return callOut;
      }

      return null;
    });

    if (overlappingWith) {
      return intl.formatMessage(msg.overlapping, {
        maxOverlap: followUpOverlapInMinutes,
        dateTime: format(new Date(overlappingWith.endTime), 'DD.MM HH:mm'),
      });
    }

    return null;
  }
}

function toTimeString(date: Date) {
  return format(date, 'HH:mm');
}

function getDay(baseDate: string | Date): Date {
  const date = new Date(baseDate);
  date.setHours(0, 0, 0, 0);
  return date;
}

type StartEndDate = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
};

function getStartEndDates(baseDate: string, startTime: string, endTime: string): StartEndDate {
  const baseDay = getDay(baseDate);

  // Times and/or base date changed. Calculate new start and end dates!
  const newStartDate = getDateFromTimeString(baseDay, startTime);
  let newEndDate = getDateFromTimeString(baseDay, endTime);

  // if end is before start -> end is on the next day!
  if (newEndDate && newStartDate) {
    newEndDate = addDays(newEndDate, isBefore(newEndDate, newStartDate) ? 1 : 0);
  }

  return { endDate: newEndDate, startDate: newStartDate };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editCallOut',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: true,
  })(EditCallOutForm)
);
