export const InformRoles = {
  USER: 'ROLE_INFORM_USER',
  USER_INTERNAL: 'ROLE_INFORM_USER_INTERNAL',
  FEEDBACK_READ: 'ROLE_INFORM_FEEDBACK_READ',
  FEEDBACK_WRITE: 'ROLE_INFORM_FEEDBACK_WRITE',
  ADMIN_TENANTS: 'ROLE_INFORM_ADMIN_TENANTS',
  ADMIN_INITIAL_LOAD: 'ROLE_INFORM_ADMIN_INITIAL_LOAD',
  STATISTICS: 'ROLE_INFORM_STATISTICS',
  ANALYSIS: 'ROLE_INFORM_ANALYSIS',
};

export const CrowdTaskRoles = {
  COCKPIT: 'ROLE_CROWD_TASK_COCKPIT',
  PORTAL_FORUM_ALL: 'ROLE_CROWD_TASK_PORTAL_FORUM_ALL',
  PORTAL_ADMIN: 'ROLE_CROWD_TASK_PORTAL_ADMIN',
  STAFF_MEMBER: 'ROLE_CROWD_TASK_STAFF_MEMBER',
  COORDINATOR: 'ROLE_CROWD_TASK_COORDINATOR',
};
