import * as React from 'react';
import { History } from 'history';

import NewOperation from './NewOperation';

type Props = {
  history: History;
};

export default class NewOperationScreen extends React.PureComponent<Props> {
  render() {
    const { history } = this.props;
    return <NewOperation history={history} />;
  }
}
