import { buffers } from 'redux-saga';
import { actionChannel, take, select } from 'redux-saga/effects';

import log from '../../shared/logging';
import { updateDevice, updateCoordinatorDevice, updateStaffMemberDevice } from '../../api';
import { UpdateDeviceRequest, UpdateCrowdTaskDeviceRequest } from '../../api/types';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { App, appSelector } from '../../api/reducers/appConfig';
import { DeviceInfoState } from '../../common/reducers/deviceInfo';
import { UpdateDeviceDataResult } from '../actions';
import { ReduxState } from '../reducers';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';

export default function* updateDeviceSaga(): Generator<any, any, any> {
  // Queue device update requests.
  // Last one wins, but always let the current processing run to completion.
  const channel = yield actionChannel(
    ['SET_PUSH_TOKEN', 'SET_NOTIFICATIONS_ENABLED', 'SET_PUSH_ALLOWED'],
    buffers.sliding(1)
  );

  try {
    while (true) {
      yield take(channel);
      yield* performUpdateDeviceData();
    }
  } finally {
    channel.close();
  }
}

function* performUpdateDeviceData() {
  const deviceRegistration: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);
  if (!deviceRegistration) {
    return;
  }

  yield* wrapApiCallActions('UPDATE_DEVICEDATA', doUpdateDeviceData(deviceRegistration));
}

function* doUpdateDeviceData(deviceRegistration) {
  const app: App = yield select(appSelector);
  const { deviceId, pushToken } = deviceRegistration;
  const currentPushToken: string | null | undefined = yield select((state: ReduxState) => state.device.pushToken);
  const pushAllowed: boolean = yield select((state: ReduxState) => state.device.pushAllowed);

  const deviceInfo: DeviceInfoState = yield select((state: ReduxState) => state.deviceInfo);
  const notificationsEnabled: boolean = yield select((state: ReduxState) => state.settings.push.notificationsEnabled);
  const bundleId: string | null | undefined = yield select((state: ReduxState) => state.app.bundleId);
  const { deviceName, deviceType } = deviceInfo;

  log.info('doUpdateDeviceData', { notificationsEnabled, pushAllowed });

  switch (app) {
    case 'coordinator': {
      const request: UpdateCrowdTaskDeviceRequest = {
        deviceName,
        bundleId: bundleId || 'none',
        notificationsEnabled: notificationsEnabled && pushAllowed,
      };

      if (currentPushToken && pushAllowed) {
        request.pushToken = currentPushToken;
      }

      yield* updateCoordinatorDevice(deviceId, request);
      break;
    }
    case 'staffMember': {
      const request: UpdateCrowdTaskDeviceRequest = {
        deviceName,
        bundleId: bundleId || 'none',
        notificationsEnabled: notificationsEnabled && pushAllowed,
      };

      if (currentPushToken && pushAllowed) {
        request.pushToken = currentPushToken;
      }

      yield* updateStaffMemberDevice(deviceId, request);
      break;
    }
    case 'inform':
    default: {
      const request: UpdateDeviceRequest = {
        deviceName,
        deviceType,
        bundleId: bundleId || 'none',
        notificationsEnabled: notificationsEnabled && pushAllowed,
      };

      if (currentPushToken && pushAllowed) {
        request.pushToken = currentPushToken;
      }

      yield* updateDevice(deviceId, request);
      break;
    }
  }

  const result: UpdateDeviceDataResult = {
    deviceId,
    pushToken: currentPushToken && pushAllowed ? currentPushToken : pushToken,
  };

  return result;
}
