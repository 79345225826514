import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage, defineMessages } from 'react-intl';

import { TextField } from '../../shared/formComponents';
import { intlSelector } from '../../api/selectors/i18n';
import validatorsSelector, { Validators } from '../../api/selectors/validators';
import { WebReduxState } from '../reducers';

const msg = defineMessages({
  defaultReason: {
    id: 'RejectParticipationForm.defaultReason',
    defaultMessage: 'The team size is already sufficient.',
  },
});

type OwnProps = {};

type StoreProps = {
  initialValues: { reason: string };
  validators: Validators;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  const intl = intlSelector(state);

  return {
    initialValues: {
      reason: intl.formatMessage(msg.defaultReason),
    },
    validators: validatorsSelector(state),
  };
}

class RejectParticipationReasonForm extends React.Component<Props> {
  render() {
    const { required } = this.props.validators;
    return (
      <Field
        name="reason"
        component={TextField}
        margin="normal"
        label={<FormattedMessage id="RejectParticipationForm.value" defaultMessage="Rejection reason" />}
        validate={[required]}
        fullWidth={true}
        multiline={true}
      />
    );
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'rejectParticipationReason',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })(RejectParticipationReasonForm)
);
