import * as React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import MuiTextField from '@material-ui/core/TextField';

export function TextField({
  className,
  label,
  fullWidth,
  id,
  children,
  defaultValue,
  onChange,
  value,
  disabled,
  input,
  margin,
  meta: { touched, error },
}) {
  const isError = Boolean(touched && error);
  return (
    <MuiTextField
      helperText={error}
      className={className}
      fullWidth={fullWidth}
      label={label}
      error={isError}
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue}
      margin={margin}
      inputProps={input}>
      {children}
    </MuiTextField>
  );
}

export function RadioGroup({ className, id, children, defaultValue, input: { value, onChange } }) {
  return (
    <MuiRadioGroup className={className} id={id} value={value} onChange={onChange} defaultValue={defaultValue}>
      {children}
    </MuiRadioGroup>
  );
}

export function Checkbox({ className, disabled, id, defaultValue, input: { value, onChange } }) {
  return (
    <MuiCheckbox
      className={className}
      id={id}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
}
