import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './MenuButton.module.css';

type Props = {
  userDisplayName: string | null | undefined;
  tenantDisplayName: string | null | undefined;
  menuOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default function MenuButton({ userDisplayName, onClick, tenantDisplayName, menuOpen }: Props) {
  return (
    <div className={menuOpen ? styles.menuButtonOpen : styles.menuButton} onClick={onClick}>
      {userDisplayName ? (
        <UserInfo userDisplayName={userDisplayName} tenantDisplayName={tenantDisplayName} />
      ) : (
        <HamburgerIcon />
      )}
    </div>
  );
}

function HamburgerIcon() {
  return (
    <div className={styles.burgerIcon}>
      <i className="icon-drawer" />
    </div>
  );
}

function UserInfo({ userDisplayName, tenantDisplayName }) {
  return (
    <div className={styles.userInfo}>
      <div className={styles.tenantAndUserName}>
        <span className={styles.tenantName}>
          {tenantDisplayName || <FormattedMessage id="AppNavbar.loggedInAs" defaultMessage="Logged in as" />}
        </span>
        <span className={styles.userName}>{userDisplayName}</span>
      </div>
      <span className={styles.userIcon}>
        <i className="icon-user" />
      </span>
      <i className="icon-caret_down" />
    </div>
  );
}
