import { createSelector } from 'reselect';

import { authenticationEnabledSelector, ConfigState } from '../reducers/config';
import { UserInfoState } from '../reducers/userInfo';
import { AppConfig } from '../reducers/appConfig';
import { InformRoles } from '../roles';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
  userInfo: UserInfoState;
};

// If authentication (Keycloak) is disabled, we have no roles,
// but chat still needs to be allowed.
const rolesAssumedWithoutAuth = new Set([InformRoles.USER, InformRoles.FEEDBACK_WRITE]);

function userRolesAsSet(user) {
  return user ? new Set(user.roles) : new Set();
}

const hasRoleSelector: (state: PartialReduxState) => (...roles: string[]) => boolean = createSelector(
  state => state.userInfo.user,
  state => authenticationEnabledSelector(state),
  (user, authenticationEnabled) => {
    const userRoles = authenticationEnabled ? userRolesAsSet(user) : rolesAssumedWithoutAuth;
    return (...roles) => roles.some(role => userRoles.has(role));
  }
);

export default hasRoleSelector;
