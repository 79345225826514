import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { FormattedMessage } from 'react-intl';
import Button from '../../api/components/Button';
import { IncidentStatus } from '../../api';

import styles from './FilterSidebar.module.css';
import inputStyles from './input.module.css';
import FilterRadio from './FilterRadio';

type Props = {
  incidentStatus: IncidentStatus | null | undefined;
  remIdText: string;
  onRemIdEndEditing: () => void;
  onRemIdChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIncidentStatusClicked: (status: IncidentStatus | null | undefined) => void;
  onResetRowPressed: () => void;
};

export default class FilterPanelForm extends React.Component<Props> {
  render() {
    const { incidentStatus, remIdText, onResetRowPressed, onRemIdChangeText, onRemIdEndEditing } = this.props;
    const all = !incidentStatus;
    const open = incidentStatus === 'PUBLISHED';
    const closed = incidentStatus === 'CLOSED';

    return (
      <div className={`${styles.filterBottom} ${styles.noOverflowX} ${inputStyles.input} whiteText `}>
        <h4 className={`${styles.indentedFilterItem} ${styles.filterHeading}`}>
          <FormattedMessage id="FilterSidebar.incidentStatus" defaultMessage="Message Status" />
        </h4>
        <FormControl component="fieldset">
          <FilterRadio
            checked={all}
            onChange={this.handleIncidentStatusAllClicked}
            label={<FormattedMessage id="FilterSidebar.all" defaultMessage="All" />}
          />
          <FilterRadio
            checked={open}
            onChange={this.handleIncidentStatusOpenClicked}
            label={<FormattedMessage id="FilterSidebar.open" defaultMessage="Only open" />}
          />
          <FilterRadio
            checked={closed}
            onChange={this.handleIncidentStatusClosedClicked}
            label={<FormattedMessage id="FilterSidebar.closed" defaultMessage="Only closed" />}
          />
        </FormControl>
        <br />
        <h4 className={`${styles.indentedFilterItem} ${styles.filterHeadingSmall}`}>
          <FormattedMessage id="FilterSidebar.incidentId" defaultMessage="REM ID" />
        </h4>
        <form className={styles.indentedFilterItem}>
          <FormGroup row>
            <TextField
              className={styles.searchBarButton}
              value={remIdText}
              type="text"
              onChange={onRemIdChangeText}
              onKeyPress={this.handleEnterKeyPress}
              InputProps={{
                endAdornment: (
                  <IconButton className={styles.iconButton} onClick={onRemIdEndEditing}>
                    <i className="icon-search" />
                  </IconButton>
                ),
              }}
            />
          </FormGroup>
          <br />
          <Button onClick={onResetRowPressed}>
            <FormattedMessage id="FilterSidebar.resetFilter" defaultMessage="Reset Filter" />
          </Button>
        </form>
        <br /> {/* fixes Button moving off-screen. */}
      </div>
    );
  }

  handleIncidentStatusAllClicked = () => {
    this.props.onIncidentStatusClicked(null);
  };

  handleIncidentStatusOpenClicked = () => {
    this.props.onIncidentStatusClicked('PUBLISHED');
  };

  handleIncidentStatusClosedClicked = () => {
    this.props.onIncidentStatusClicked('CLOSED');
  };

  handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.onRemIdEndEditing();
    }
  };
}
