import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import MuiTab from '@material-ui/core/Tab';
import { History } from 'history';

import styles from './Tabs.module.css';

type Props = {
  buttons?: React.ReactNode;
  children: React.ReactNode;
};

export function Tabs({ buttons, children }: Props) {
  return (
    <Paper elevation={1}>
      <nav className={styles.tabBar}>
        <>
          <div className={styles.tabs}>{children}</div>
          {buttons ? <div>{buttons}</div> : null}
        </>
      </nav>
    </Paper>
  );
}

type TabProps = {
  history: History;
  path: string;
  currentPath: string;
  label: React.ReactNode;
};

export class Tab extends React.Component<TabProps> {
  render() {
    const { label, currentPath, path } = this.props;
    const labelClass = currentPath.startsWith(path) ? styles.tabLabelSelected : styles.tabLabel;

    return (
      <span className={labelClass}>
        <MuiTab label={label} onClick={this.handleClick} />
      </span>
    );
  }

  handleClick = () => {
    const { path, history } = this.props;
    history.push(path);
  };
}
