import { keyBy, union, difference, omit, orderBy } from 'lodash-es';

export type NormalizedCollection<V> = {
  readonly ids: string[];
  readonly byId: {
    [id: string]: V;
  };
};

export const emptyCollection: NormalizedCollection<any> = {
  ids: [],
  byId: {},
};

export function createCollection<V>(values: V[], idSelector: (arg0: V) => string): NormalizedCollection<V> {
  return {
    ids: values.map(idSelector),
    byId: keyBy(values, idSelector),
  };
}

export function addValues<V>(
  collection: NormalizedCollection<V>,
  newValues: V[],
  idSelector: (arg0: V) => string
): NormalizedCollection<V> {
  if (newValues.length === 0) {
    return collection;
  }

  const { ids, byId } = collection;

  const newIds = newValues.map(idSelector);
  const newById = keyBy(newValues, idSelector);

  return {
    ids: union(ids, newIds),
    byId: { ...byId, ...newById },
  };
}

export function removeIds<V>(collection: NormalizedCollection<V>, idsToRemove: string[]): NormalizedCollection<V> {
  if (idsToRemove.length === 0) {
    return collection;
  }

  const { ids, byId } = collection;

  return {
    ids: difference(ids, idsToRemove),
    byId: omit(byId, idsToRemove),
  };
}

export function addValue<V>(
  collection: NormalizedCollection<V>,
  newValue: V,
  idSelector: (arg0: V) => string
): NormalizedCollection<V> {
  return addValues(collection, [newValue], idSelector);
}

export function removeId<V>(collection: NormalizedCollection<V>, idToRemove: string): NormalizedCollection<V> {
  return removeIds(collection, [idToRemove]);
}

export function orderCollection<V extends Object>(
  collection: NormalizedCollection<V>,
  sortSelector: (arg0: V) => unknown,
  order: 'asc' | 'desc'
) {
  const { ids, byId } = collection;

  return {
    ids: orderBy(ids, [id => sortSelector(byId[id])], [order]),
    byId,
  };
}
