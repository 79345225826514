import * as React from 'react';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { History, Location, Action as HistoryAction } from 'history';
import { Redirect, Prompt } from 'react-router-dom';
import { isInvalid, isDirty } from 'redux-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import ApiCallStatusHandler from '../../api/components/ApiCallStatusHandler';
import { CrowdTaskCallOut } from '../../api/types';
import { intlSelector } from '../../api/selectors/i18n';
import { WebDispatchProps, postCallOut, updateCallOut, postFollowupCallOut, getCallOuts } from '../actions';
import CockpitApiErrors from '../navigation/CockpitApiStatus';
import { WebReduxState } from '../reducers';
import Button from '../../api/components/Button';
import EditCallOutForm from './EditCallOutForm';
import styles from './EditCallOutComponent.module.css';

export type EditCallOutComponentMode =
  | { type: 'new' }
  | { type: 'edit'; callOutId: string }
  | { type: 'newFollowup'; callOutId: string };

type StoreProps = {
  operationId: string;
  formInvalid: boolean;
  editCallOut: CrowdTaskCallOut | null | undefined;
  day: string | null | undefined;
  lastCreatedCallOutId: string | null | undefined;
  didGetCallOutsOnce: boolean;
  formDirty: boolean;
  intl: IntlShape;
  editCallOutError: string | null | undefined;
};

type OwnProps = {
  operationId: string | null | undefined;
  history: History;
  mode: EditCallOutComponentMode;
};

type Props = OwnProps & WebDispatchProps & StoreProps;

export function getCallOutIdOrNull(mode: EditCallOutComponentMode) {
  switch (mode.type) {
    case 'edit':
    case 'newFollowup':
      return mode.callOutId;
    case 'new':
    default:
      return null;
  }
}

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { operationId, mode } = ownProps;

  if (!operationId) {
    throw new Error('EditCallOut: operationId is not set. This should not happen.');
  }

  const callOutId = getCallOutIdOrNull(mode);

  return {
    operationId,
    editCallOut: callOutId ? state.callOuts.callOuts.byId[callOutId] : null,
    formInvalid: isInvalid('editCallOut')(state),
    day: state.form.editCallOut ? state.form.editCallOut.values : null,
    lastCreatedCallOutId: state.ui.editCallOut.lastCreatedCallOutId,
    didGetCallOutsOnce: state.api.apiCalls.GET_CALL_OUTS ? !state.api.apiCalls.GET_CALL_OUTS.inProgress : false,
    formDirty: isDirty('editCallOut')(state),
    intl: intlSelector(state),
    editCallOutError: state.ui.editCallOut.editCallOutError,
  };
}

const apiCallActions = ['POST_CALL_OUT', 'UPDATE_CALL_OUT', 'POST_FOLLOWUP_CALL_OUT', 'UPDATE_TASK'];

class EditCallOutComponent extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, operationId } = this.props;

    if (this.missingData(this.props) && operationId) {
      dispatch(getCallOuts(operationId));
    }
  }

  render() {
    const { formInvalid, mode, operationId, didGetCallOutsOnce, formDirty, editCallOutError } = this.props;

    if (this.missingData(this.props) && (didGetCallOutsOnce || !operationId)) {
      if (operationId) {
        return <Redirect to={`/operations/${operationId}/callOuts`} />;
      } else {
        return <Redirect to="/operations" />;
      }
    }

    const saveDisabled =
      editCallOutError !== null || mode.type === 'newFollowup' ? formInvalid : formInvalid || !formDirty;
    return (
      <>
        <Prompt when={formDirty} message={this.getCancelPrompt} />
        <ApiCallStatusHandler apiCallNames={['GET_CALL_OUTS']} renderProgress={this.renderFullScreenProgress}>
          <div className={styles.container}>
            <div className={styles.form}>
              <EditCallOutForm mode={mode} />
            </div>
            <CockpitApiErrors actions={apiCallActions} onSuccess={this.handleSuccess} />
          </div>
          {editCallOutError && (
            <p className={styles.errorText}>
              <FormattedMessage
                id="EditCallOut.error"
                defaultMessage="Error: {errorMessage}"
                values={{ errorMessage: editCallOutError }}
              />
            </p>
          )}
          <ButtonsRow onSaveClicked={this.handleSave} onCancelClicked={this.handleCancel} saveDisabled={saveDisabled} />
        </ApiCallStatusHandler>
      </>
    );
  }

  getCancelPrompt = (_location: Location, _action: HistoryAction) => {
    const { intl, mode } = this.props;
    const msg = defineMessages({
      cancelPromptCreate: {
        id: 'EditOperation.cancelPromptEdit',
        defaultMessage: 'You have unsaved changes. Are you sure you want to cancel editing the call out?',
      },
      cancelPromptEdit: {
        id: 'EditOperation.cancelPromptCreate',
        defaultMessage: 'You have unsaved changes. Are you sure you want to cancel creating the call out?',
      },
    });

    return intl.formatMessage(mode.type === 'edit' ? msg.cancelPromptEdit : msg.cancelPromptCreate);
  };

  renderFullScreenProgress = () => {
    return (
      <div className={styles.centeredFullscreenSpinnerWrapper}>
        <div className={styles.centeredFullscreenSpinner}>
          <CircularProgress className={styles.spinner} size={32} thickness={3} />
          <FormattedMessage id="CallOutsLoading.edit.title" defaultMessage="Fetching Call Outs" />
        </div>
      </div>
    );
  };

  handleSuccess = () => {
    const { operationId, history, mode, lastCreatedCallOutId } = this.props;

    // TODO: Investigate if there is a way to avoid having to specify the full URL ("go back to .." or something like this)
    if (operationId) {
      if (mode.type === 'edit') {
        history.replace(`/operations/${operationId}/callOuts/${mode.callOutId}`);
      } else if ((mode.type === 'new' || mode.type === 'newFollowup') && lastCreatedCallOutId) {
        history.replace(`/operations/${operationId}/callOuts/${lastCreatedCallOutId}`);
      }
    } else {
      // Should never happen
      history.replace('/operations');
    }
  };

  handleSave = () => {
    const { editCallOut, dispatch, operationId, mode } = this.props;

    if (operationId) {
      switch (mode.type) {
        case 'new':
          dispatch(postCallOut(operationId));
          break;
        case 'newFollowup': {
          if (!editCallOut) {
            return;
          }
          const { callOutId, taskId } = editCallOut;
          dispatch(postFollowupCallOut({ callOutId, operationId, taskId }));
          break;
        }
        case 'edit': {
          if (!editCallOut) {
            return;
          }
          const { callOutId, taskId, version, taskVersion } = editCallOut;
          dispatch(updateCallOut({ callOutId, operationId, taskId }, version, taskVersion));
          break;
        }
        default:
      }
    }
  };

  handleCancel = () => {
    const { history, operationId, editCallOut } = this.props;
    if (operationId) {
      history.replace(`/operations/${operationId}/callOuts/${editCallOut ? editCallOut.callOutId : ''}`);
    }
  };

  missingData(props: Props) {
    const { editCallOut, mode } = props;
    return (mode.type === 'edit' || mode.type === 'newFollowup') && !editCallOut;
  }
}

function ButtonsRow({ onSaveClicked, onCancelClicked, saveDisabled }) {
  return (
    <div className={styles.buttonRow}>
      <Button onClick={onCancelClicked}>
        <FormattedMessage id="EditCallOut.cancel" defaultMessage="Cancel" />
      </Button>
      <Button className={styles.okButton} disabled={saveDisabled} onClick={onSaveClicked}>
        <FormattedMessage id="EditCallOut.create" defaultMessage="Save" />
      </Button>
    </div>
  );
}

export default connect(mapStateToProps)(EditCallOutComponent);
