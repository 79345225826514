import { createSelector } from 'reselect';
import { values } from 'lodash-es';

import { ChatState } from '../../api/reducers/chat';
import { ChatPendingState } from '../../api/reducers/chatPending';

type PartialReduxState = {
  chat: ChatState;
  chatPending: ChatPendingState;
};

const unreadThreadIdsSelector: (state: PartialReduxState) => Set<string> = createSelector(
  state => state.chat,
  state => state.chatPending,
  (chat, chatPending) => {
    const threadIds = Object.keys(chat);

    const unreadIds = threadIds.filter(
      threadId =>
        threadId !== '_persist' &&
        chat[threadId].messages &&
        values(chat[threadId].messages.byId).some(m => !m.you) &&
        (!chatPending[threadId] || chat[threadId].lastMessageId !== chatPending[threadId].lastReadMessageId)
    );

    return new Set(unreadIds);
  }
);

export default unreadThreadIdsSelector;
