import * as React from 'react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import Button from '../../api/components/Button';
import { Incident } from '../../api/types';
import { getDateOrNow, isValidDate } from '../../api/utils/dateUtils';
import iconNameSelector from '../../common/selectors/iconName';
import { incidentToIncidentData } from '../../common/utils/incidents';
import { createCsvFilename, createCsvUri, triggerDownload } from '../../shared/fileUtils';
import {
  getIncidentsSearch,
  getIncidentsSearchCsv,
  selectIncidentsSearchId,
  setIncidentsSearch,
  setIncidentsSearchHistory,
  useDispatch,
} from '../actions';
import { useSelector, WebReduxState } from '../reducers';
import IncidentListContent from '../incidents/IncidentListContent';
import { IncidentRowStructure } from '../incidents/IncidentRow';
import { IncidentHeaderStructure } from '../incidents/IncidentHeader';
import { InvisibleRow } from '../incidents/IncidentListTable';
import AnalysisIncidentDetail from '../incidents/AnalysisIncidentDetail';
import AnalysisPagination from './AnalysisPagination';

import styles from './Analysis.module.css';

const handleDownload = (csv: string) => triggerDownload(createCsvFilename(), true, createCsvUri(csv));

function dataSelector(state: WebReduxState) {
  return {
    analysis: state.admin.analysis,
    incidents: state.admin.analysis.history,
    getIconName: iconNameSelector(state),
  };
}

export default function Analysis() {
  const { analysis, incidents, getIconName } = useSelector(dataSelector, shallowEqual);
  const { search, csv, selectedIncidentId, selectedHistory } = analysis;
  const dispatch = useDispatch();

  const [dates, setDates] = React.useState<{
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    fromDate: getDateOrNow(search.from),
    toDate: getDateOrNow(search.to),
  });

  const previousSearch = React.useRef(search);
  const previousContent = React.useRef(csv);
  const scrollRef = React.useRef<HTMLElement | null>(null);
  const incidentRef = React.useRef<HTMLElement | null>(null);

  // Trigger download after csv successfully changes.
  React.useEffect(() => {
    if (csv && csv !== previousContent.current) {
      previousContent.current = csv;
      previousSearch.current = search;

      handleDownload(csv);
    }
  }, [csv, search]);

  const onCsvExportClick = React.useCallback(
    _evt => {
      // If nothing changed, download export immediately from redux store.
      if (csv && shallowEqual(search, previousSearch.current)) {
        handleDownload(csv);
      } else {
        dispatch(getIncidentsSearchCsv());
      }
    },
    [dispatch, csv, search]
  );

  const onSearchClick = React.useCallback(
    _evt => {
      dispatch(getIncidentsSearch());
    },
    [dispatch]
  );

  const onSearchTermChange = React.useCallback(
    evt => {
      let term = evt.target.value;
      dispatch(setIncidentsSearch({ ...search, term }));
    },
    [dispatch, search]
  );

  const onDateFromChange = React.useCallback(
    (date: Date | null) => {
      setDates(dates => {
        return { ...dates, fromDate: date };
      });

      // Only update redux state with valid dates.
      if (isValidDate(date)) {
        let from = date ? date.toISOString() : undefined;
        dispatch(setIncidentsSearch({ ...search, from }));
      }
    },
    [dispatch, search]
  );

  const onDateToChange = React.useCallback(
    (date: Date | null) => {
      setDates(dates => {
        return { ...dates, toDate: date };
      });

      // Only update redux state with valid dates.
      if (isValidDate(date)) {
        let to = date ? date.toISOString() : undefined;
        dispatch(setIncidentsSearch({ ...search, to }));
      }
    },
    [dispatch, search]
  );

  const onSearchInChatChange = React.useCallback(
    _evt => {
      dispatch(setIncidentsSearch({ ...search, searchInChat: !search.searchInChat }));
    },
    [dispatch, search]
  );

  const onEnterKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        dispatch(getIncidentsSearch());
      }
    },
    [dispatch]
  );

  const handleRowClick = (incidentId: string | null) => {
    dispatch(selectIncidentsSearchId(incidentId));
  };

  // The history array starts with the most current entry, so we need to go up in the index when clicking left,
  // and down when clicking right.
  const handleLeftClick = (incidentId: string, position: number) => {
    dispatch(setIncidentsSearchHistory(incidentId, position + 1));
  };

  const handleRightClick = (incidentId: string, position: number) => {
    dispatch(setIncidentsSearchHistory(incidentId, position - 1));
  };

  const searchDisabled = !isValidDate(dates.fromDate) || !isValidDate(dates.toDate);

  const setScrollRef = (ref: HTMLElement | null) => (scrollRef.current = ref);
  const setIncidentRef = (ref: HTMLElement | null) => (incidentRef.current = ref);

  const canSeeIncidentType = true;
  const longDates = true;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className={styles.searchFields}>
          <div className={styles.searchBox}>
            <TextField
              id="analysis-search"
              type="search"
              autoFocus
              fullWidth
              onChange={onSearchTermChange}
              onKeyPress={onEnterKeyPress}
              value={search.term}
              label={<FormattedMessage id="Analysis.enterSearchTerm" defaultMessage="Search term" />}
            />
            <div className={styles.checkbox}>
              <FormControlLabel
                control={<Checkbox value={search.searchInChat} onChange={onSearchInChatChange} />}
                label={<FormattedMessage id="Analysis.searchInChat" defaultMessage="Search in chat?" />}
              />
            </div>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={styles.pickers}>
              <KeyboardDatePicker
                fullWidth
                label={<FormattedMessage id="Analysis.from" defaultMessage="From" />}
                value={dates.fromDate}
                onChange={onDateFromChange}
                onKeyPress={onEnterKeyPress}
                format="dd.MM.yyyy"
              />
              <KeyboardDatePicker
                fullWidth
                label={<FormattedMessage id="Analysis.to" defaultMessage="To" />}
                value={dates.toDate}
                onChange={onDateToChange}
                onKeyPress={onEnterKeyPress}
                format="dd.MM.yyyy"
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onSearchClick} disabled={searchDisabled}>
            <FormattedMessage id="Analysis.search" defaultMessage="Search" />
          </Button>
          <Button onClick={onCsvExportClick} disabled={searchDisabled}>
            <FormattedMessage id="Analysis.exportAsCsv" defaultMessage="Export as CSV" />
          </Button>
        </div>
      </div>
      <div className={styles.incidents}>
        <IncidentListContent
          showTableHeader={true}
          canSeeIncidentType={canSeeIncidentType}
          dataFilterTypes={[]}
          longDates={longDates}
          setScrollRef={setScrollRef}>
          <InvisibleRow key="invisibleAnalysisRow" canSeeIncidentType={canSeeIncidentType} longDates={longDates} />
          {Object.keys(incidents).map(key => {
            const history = incidents[key];

            const currentIncident = selectedHistory[key] ? history[selectedHistory[key]] : history[0];
            const lastIncidentIndex = history.length - 1;

            let previousIncident: Incident | null = null;

            if (history.length > 1 && selectedHistory[key] <= history.length) {
              previousIncident = history[selectedHistory[key] + 1];
            }

            const incidentData = incidentToIncidentData(currentIncident);
            const previousIncidentData = previousIncident ? incidentToIncidentData(previousIncident) : null;
            const iconName = getIconName(incidentData);

            if (key === selectedIncidentId) {
              return (
                <Fragment key={key}>
                  <IncidentHeaderStructure
                    iconName={iconName}
                    incidentData={incidentData}
                    lastUpdated={currentIncident.lastUpdated}
                    threadId={currentIncident.threadId}
                    onRowClick={_evt => handleRowClick(null)}
                    setRef={setIncidentRef}
                    canSeeIncidentType={canSeeIncidentType}
                    longDates={longDates}
                    renderPagination={() => (
                      <AnalysisPagination
                        incidentId={key}
                        position={selectedHistory[key]}
                        maximum={lastIncidentIndex}
                        handleLeftClick={handleLeftClick}
                        handleRightClick={handleRightClick}
                      />
                    )}
                  />
                  <AnalysisIncidentDetail
                    incidentId={key}
                    created={currentIncident.created}
                    incidentDate={currentIncident.incidentDate}
                    canSeeIncidentType={canSeeIncidentType}
                    hasChat={true}
                    lastUpdated={currentIncident.lastUpdated}
                    incidentData={incidentData}
                    previousIncidentData={previousIncidentData}
                    longDates={longDates}
                  />
                </Fragment>
              );
            }

            return (
              <IncidentRowStructure
                key={key}
                iconName={iconName}
                incidentData={incidentData}
                lastUpdated={currentIncident.lastUpdated}
                threadId={currentIncident.threadId}
                onRowClick={_evt => handleRowClick(key)}
                setRef={setIncidentRef}
                canSeeIncidentType={canSeeIncidentType}
                longDates={longDates}
              />
            );
          })}
        </IncidentListContent>
      </div>
    </div>
  );
}
