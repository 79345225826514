import * as React from 'react';

import { DisplayFilterType } from '../../common/actions/filter';
import { FilterCriterion } from '../../common/selectors/filterCriteria';
import FilterCheckbox from './FilterCheckbox';

type Props = {
  type: DisplayFilterType;
  criteria: FilterCriterion[];
  onChange: (criterionName: string, type: DisplayFilterType) => void;
};

export default class FilterCheckboxes extends React.PureComponent<Props> {
  render() {
    const { type, criteria, onChange } = this.props;

    return (
      <form className="noOverflowX">
        {criteria.map(criterion => (
          <FilterCheckbox
            key={criterion.name}
            criterion={criterion}
            filterPrefix="display"
            filterType={type}
            onChange={onChange}
          />
        ))}
      </form>
    );
  }
}
