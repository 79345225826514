import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import Button from '../../api/components/Button';
import LoadingIcon from './LoadingIcon';
import styles from './LoadingScreen.module.css';

type Props = {
  text?: React.ReactNode;
  status?: ApiCallStatus;
  onButtonClicked?: () => void;
};

export default class LoadingScreen extends React.Component<Props> {
  render() {
    const { text, status, onButtonClicked } = this.props;

    return status && status.error ? (
      <div className={styles.loadingScreen}>
        <div className={styles.loadingText}>
          <FormattedMessage id="LoadingScreen.failed" defaultMessage="{action} failed!" values={{ action: text }} />
        </div>
        {onButtonClicked && (
          <Button onClick={this.handleRetryButtonClick}>
            <FormattedMessage id="LoadingScreen.retry" defaultMessage="Retry" />
          </Button>
        )}
      </div>
    ) : (
      <div className={styles.loadingScreen}>
        <div className={styles.loadingText}>
          {text ? <span>{text}...</span> : <FormattedMessage id="LoadingScreen.load" defaultMessage="Loading..." />}
        </div>
        <LoadingIcon />
      </div>
    );
  }

  handleRetryButtonClick: () => void = () => {
    if (this.props.onButtonClicked) {
      this.props.onButtonClicked();
    }
  };
}
