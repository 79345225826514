import { PostTenantRequest, PutTenantRequest } from '../../api/types';
import { DataFilterType } from '../../api/reducers/config';

export type CreateTenantAction = { type: 'CREATE_TENANT'; tenant: PostTenantRequest };
export type UpdateTenantAction = { type: 'UPDATE_TENANT'; tenant: PutTenantRequest; tenantId: string };

export type AdminAction =
  | { type: 'GET_TENANTS' }
  | CreateTenantAction
  | UpdateTenantAction
  | { type: 'TRIGGER_INITIAL_LOAD' }
  | { type: 'GET_TENANT_FILTER' }
  | { type: 'UPDATE_TENANT_FILTER' }
  | { type: 'SET_TENANT_ID'; tenantId: string }
  | { type: 'SET_TENANT_DATA_FILTER_CRITERIA'; dataFilterType: DataFilterType; criteria: string[] }
  | { type: 'SUBMIT_INITIAL_LOAD' };

export function getTenants(): AdminAction {
  return { type: 'GET_TENANTS' };
}

export function createTenant(tenant: PostTenantRequest): AdminAction {
  return { type: 'CREATE_TENANT', tenant };
}

export function updateTenant(tenant: PutTenantRequest, tenantId: string): AdminAction {
  return { type: 'UPDATE_TENANT', tenant, tenantId };
}

export function triggerInitialLoad(): AdminAction {
  return { type: 'TRIGGER_INITIAL_LOAD' };
}

export function getTenantFilter(): AdminAction {
  return { type: 'GET_TENANT_FILTER' };
}

export function updateTenantFilter(): AdminAction {
  return { type: 'UPDATE_TENANT_FILTER' };
}

export function setTenantId(tenantId: string): AdminAction {
  return { type: 'SET_TENANT_ID', tenantId };
}

export function setTenantDataFilterCriteria(dataFilterType: DataFilterType, criteria: string[]): AdminAction {
  return { type: 'SET_TENANT_DATA_FILTER_CRITERIA', dataFilterType, criteria };
}

export function submitInitialLoad(): AdminAction {
  return { type: 'SUBMIT_INITIAL_LOAD' };
}
