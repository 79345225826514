import * as React from 'react';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import Button from './Button';
import styles from './ChatTextField.module.css';

type Props = {
  value: string;
  onSubmitClick: () => void;
  onChange: (value: string) => void;
  maxChatMessageSize: number;
  closed?: boolean;
  intl: IntlShape;
};

export const DEFAULT_MAX_CHAT_MESSAGE_SIZE = 1000;

const msg = defineMessages({
  enter: { id: 'ChatTextField.enter', defaultMessage: 'Enter a message...' },
});

export default class ChatTextField extends React.Component<Props> {
  render() {
    const { value, onSubmitClick, closed, intl } = this.props;
    return (
      <div className={styles.chatTextField}>
        {closed ? (
          <div className={styles.closed}>
            <FormattedMessage id="ChatTextField.closed" defaultMessage="The forum is already closed." />
          </div> // This div wrapper is necessary for the chat layout to work across all instances and browsers.
        ) : (
          <div className={styles.textFieldWrapper}>
            <TextField
              className={styles.chatInput}
              placeholder={intl.formatMessage(msg.enter)}
              fullWidth={true}
              multiline={true}
              rows={1}
              rowsMax={4}
              value={value}
              margin="normal"
              onChange={this.handleValueChange}
              onKeyPress={this.handleEnterKeyPress}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className={styles.buttonWrapper}>
              <Button onClick={onSubmitClick} disabled={value.trim().length < 1} size="large">
                <FormattedMessage id="ChatTextField.send" defaultMessage="Send" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  handleEnterKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { value, onSubmitClick } = this.props;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (value.trim().length > 0) {
        onSubmitClick();
      }
    }
  };

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxChatMessageSize, onChange } = this.props;
    const value = e.target.value;
    const maxSize = maxChatMessageSize ? maxChatMessageSize : DEFAULT_MAX_CHAT_MESSAGE_SIZE;
    onChange(value.length <= maxSize ? value : value.substring(0, maxSize));
  };
}
