import * as React from 'react';
import Button from '@material-ui/core/Button';

import styles from './ErrorScreen.module.css';

type Props = {
  title: React.ReactNode;
  errorMessage: React.ReactNode;
  reloadButtonTitle?: React.ReactNode;
  reloadText?: React.ReactNode;
  resetButtonTitle?: React.ReactNode;
  resetText?: React.ReactNode;
  logoutButtonTitle?: React.ReactNode;
  logoutText?: React.ReactNode;
  onLogout?: () => void;
};

export default class ErrorScreen extends React.Component<Props> {
  render() {
    const {
      title,
      errorMessage,
      reloadButtonTitle,
      reloadText,
      resetButtonTitle,
      resetText,
      logoutButtonTitle,
      logoutText,
      onLogout,
    } = this.props;

    return (
      <div className={styles.errorScreen}>
        <div className={styles.errorScreenText}>
          <div className={styles.title}>{title}</div>
          {errorMessage}
        </div>
        <table>
          <tbody>
            {reloadButtonTitle && (
              <tr>
                <td>
                  <Button variant="contained" onClick={this.handleReloadApp} fullWidth={true} color="primary">
                    {reloadButtonTitle}
                  </Button>
                </td>
                <td>{reloadText}</td>
              </tr>
            )}
            {resetButtonTitle && (
              <tr>
                <td>
                  <Button variant="contained" onClick={this.handleResetApp} fullWidth={true} color="primary">
                    {resetButtonTitle}
                  </Button>
                </td>
                <td>{resetText}</td>
              </tr>
            )}
            {onLogout && (
              <tr>
                <td>
                  <Button variant="contained" onClick={onLogout} fullWidth={true} color="primary">
                    {logoutButtonTitle}
                  </Button>
                </td>
                <td>{logoutText}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  handleResetApp = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  handleReloadApp = () => {
    window.location.href = '/';
  };
}
