import log from '../../shared/logging';

type ErrorInfo = {
  sagaStack: string;
};

export function onSagaError(error: Error, { sagaStack }: ErrorInfo) {
  const { message, stack } = error;

  log.error('Saga error occurred: ' + message);
  if (stack) {
    log.error('Stack trace: ' + stack);
  }
  log.error(sagaStack);
}
