import { createSelector } from 'reselect';
import { defineMessages } from 'react-intl';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';

import { I18NState } from '../../shared/i18n/reducers/i18n';
import { AppConfig } from '../reducers/appConfig';
import { ConfigState } from '../reducers/config';
import { intlSelector } from './i18n';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
  i18n: I18NState;
};

export type ValidatorFunction = (value: string | null | undefined, values?: Object) => string | null;

export type Validators = {
  required: ValidatorFunction;
  email: ValidatorFunction;
  confirmEmail: ValidatorFunction;
  phone: ValidatorFunction;
  identificationNumber: ValidatorFunction;
};

const msg = defineMessages({
  required: { id: 'validation.required', defaultMessage: 'This field is required.' },
  email: { id: 'validation.email', defaultMessage: 'Invalid email address.' },
  confirmEmail: { id: 'validation.confirmEmail', defaultMessage: 'Email addresses do not match.' },
  phone: { id: 'validation.phone', defaultMessage: 'Invalid phone number.' },
  identificationNumber: {
    id: 'validation.identificationNumber',
    defaultMessage: 'Identification number must be numeric.',
  },
});

const validatorsSelector: (state: PartialReduxState) => Validators = createSelector(
  state => intlSelector(state),
  intl => ({
    required: (value: string | null | undefined) => (value ? null : intl.formatMessage(msg.required)),
    email: (value: string | null | undefined) =>
      value && isEmail(value, { allow_utf8_local_part: false }) ? null : intl.formatMessage(msg.email),
    confirmEmail: (value: string | null | undefined, values?: Object) =>
      value === (values && values['email']) ? null : intl.formatMessage(msg.confirmEmail),
    phone: (value: string | null | undefined) => (!value || isNumeric(value) ? null : intl.formatMessage(msg.phone)),
    identificationNumber: (value: string | null | undefined) =>
      value && isNumeric(value) ? null : intl.formatMessage(msg.identificationNumber),
  })
);

export default validatorsSelector;
