export default function getHorizontalScrollbarHeight(containerRef: HTMLElement | null | undefined) {
  if (!containerRef) {
    return 18;
  }
  const possibleHorizontalScrollbarHeight = containerRef.offsetHeight - containerRef.clientHeight;
  if (possibleHorizontalScrollbarHeight > 13 && possibleHorizontalScrollbarHeight < 19) {
    return possibleHorizontalScrollbarHeight;
  }

  return 18;
}
