import { Dispatch as ReduxDispatch } from 'redux';
import { useDispatch as useDispatchGeneric } from 'react-redux';

import { I18NAction } from '../../shared/i18n/actions';
import { ApiAction } from '../../api/actions';
import { AnalysisAction } from './analysis';
import { AppAction } from './app';
import { DataFilterAction } from './dataFilter';
import { DataFilterCallOutAreasAction } from './dataFilterCallOutAreas';
import { DeviceAction } from './device';
import { FilterAction } from './filter';
import { IncidentsAction } from './incidents';
import { SettingsAction } from './settings';
import { StartupAction } from './startup';
import { StatisticsAction } from './statistics';

export type Action =
  | ApiAction
  | AnalysisAction
  | AppAction
  | DataFilterAction
  | DataFilterCallOutAreasAction
  | DeviceAction
  | FilterAction
  | I18NAction
  | IncidentsAction
  | SettingsAction
  | StartupAction
  | StatisticsAction;

export type Dispatch = ReduxDispatch<Action>;
export type DispatchProps = { dispatch: ReduxDispatch<Action> };

export const useDispatch: () => Dispatch = useDispatchGeneric;

export * from '../../shared/i18n/actions';
export * from '../../api/actions';
export * from './app';
export * from './analysis';
export * from './dataFilter';
export * from './dataFilterCallOutAreas';
export * from './device';
export * from './filter';
export * from './incidents';
export * from './settings';
export * from './startup';
export * from './statistics';
