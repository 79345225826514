import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, IntlShape } from 'react-intl';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import Button from '../../api/components/Button';
import {
  changeFeedbackPhoneNumber,
  fetchDataFilterCriteria,
  postDataFilterCriteria,
  setWizardModal,
  getTenantFilter,
  updateTenantFilter,
  setFeedbackPhoneNumber,
  wizardFinished,
  setWizardPage,
  WebDispatchProps,
} from '../actions';

import { WebReduxState } from '../reducers';
import { Wizard } from '../reducers/webNav';
import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { intlSelector } from '../../api/selectors/i18n';
import { getApiCallStatus } from '../../api/reducers/api';
import wizardRouteNamesSelector from '../../common/selectors/wizardRouteNames';
import validatePhoneNumber from '../../common/utils/validatePhoneNumber';
import LoadingIcon from '../navigation/LoadingIcon';
import WizardRegionFilterPage from './WizardRegionFilterPage';
import WizardLinesFilterPage from './WizardLinesFilterPage';
import WizardIncidentTypesFilterPage from './WizardIncidentTypesFilterPage';
import WizardFeedbackPhoneNumberPage from './WizardFeedbackPhoneNumberPage';
import styles from './wizard.module.css';

type OwnProps = {
  tenantMode?: boolean;
};

type StoreProps = {
  intl: IntlShape;
  tenantWizardPages: ReadonlyArray<DataFilterType>;
  wizard: Wizard;
  wizardPages: string[];
  fetchDataFilter: ApiCallStatus;
  postDataFilter: ApiCallStatus;
  getTenantFilterStatus: ApiCallStatus;
  updateTenantFilterStatus: ApiCallStatus;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    intl: intlSelector(state),
    tenantWizardPages: dataFilterTypesSelector(state),
    wizard: state.webNav.wizard,
    wizardPages: wizardRouteNamesSelector(state),
    fetchDataFilter: getApiCallStatus(state, 'FETCH_DATAFILTER_CRITERIA'),
    postDataFilter: getApiCallStatus(state, 'POST_DATAFILTER_CRITERIA'),
    getTenantFilterStatus: getApiCallStatus(state, 'GET_TENANT_FILTER'),
    updateTenantFilterStatus: getApiCallStatus(state, 'UPDATE_TENANT_FILTER'),
  };
}

class DataFilter extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, tenantMode } = this.props;
    if (tenantMode) {
      dispatch(getTenantFilter());
    } else {
      dispatch(fetchDataFilterCriteria());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.postDataFilter.inProgress && !nextProps.postDataFilter.inProgress) {
      // Saving finished, check for error.
      if (!nextProps.postDataFilter.error) {
        this.props.dispatch(setWizardModal('closed'));
      }
    }
  }

  render() {
    const {
      wizard,
      wizardPages,
      fetchDataFilter,
      postDataFilter,
      tenantMode,
      tenantWizardPages,
      getTenantFilterStatus,
      updateTenantFilterStatus,
      intl,
    } = this.props;

    if (
      fetchDataFilter.inProgress ||
      postDataFilter.inProgress ||
      getTenantFilterStatus.inProgress ||
      updateTenantFilterStatus.inProgress
    ) {
      return (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      );
    }
    const retryButtonText = <FormattedMessage id="DataFilter.retry" defaultMessage="Retry" />;

    if (fetchDataFilter.error || getTenantFilterStatus.error) {
      return (
        <div className={styles.filterAlertBox}>
          <p>
            <FormattedMessage
              id="DataFilter.loadingError"
              defaultMessage="Failed to load the data filter. Try again?"
            />
          </p>
          <Button onClick={this.handleRefetchClick}>{retryButtonText}</Button>
        </div>
      );
    }

    if (postDataFilter.error || updateTenantFilterStatus.error) {
      return (
        <div className={styles.filterAlertBox}>
          <div>
            <FormattedMessage id="DataFilter.savingError" defaultMessage="Failed to save the data filter. Try again?" />
          </div>
          <Button onClick={this.handleRepostClick}>{retryButtonText}</Button>
        </div>
      );
    }

    const page = tenantMode ? tenantWizardPages[wizard.page] : wizardPages[wizard.page];

    switch (page) {
      case 'regions':
        return <WizardRegionFilterPage tenantMode={tenantMode} />;
      case 'lines':
        return <WizardLinesFilterPage tenantMode={tenantMode} />;
      case 'incidentTypes':
        return <WizardIncidentTypesFilterPage tenantMode={tenantMode} />;
      case 'feedbackPhoneNumber':
        return (
          <WizardFeedbackPhoneNumberPage
            intl={intl}
            onChange={this.handleSetCallbackNumber}
            onEnterKeyPress={this.handleWizardEnterKeyPress}
            feedbackPhoneNumber={wizard.phoneNumber}
            dark={true}
          />
        );
      default:
        return null;
    }
  }

  handleRefetchClick = () => {
    const { dispatch, tenantMode } = this.props;
    if (tenantMode) {
      dispatch(getTenantFilter());
    } else {
      dispatch(fetchDataFilterCriteria());
    }
  };

  handleRepostClick = () => {
    const { dispatch, tenantMode } = this.props;
    if (tenantMode) {
      dispatch(updateTenantFilter());
    } else {
      dispatch(postDataFilterCriteria());
    }
  };

  handleSetCallbackNumber = (phoneNumber: string) => {
    this.props.dispatch(changeFeedbackPhoneNumber(phoneNumber));
  };

  handleWizardEnterKeyPress = () => {
    const { wizard, dispatch, tenantWizardPages, wizardPages } = this.props;
    const pageLength = wizard.modal === 'tenant' ? tenantWizardPages.length : wizardPages.length;

    if (validatePhoneNumber(wizard.phoneNumber)) {
      if (pageLength === 1) {
        dispatch(setFeedbackPhoneNumber(wizard.phoneNumber.trim()));
        dispatch(wizardFinished());
        dispatch(setWizardModal('closed'));
      } else {
        dispatch(setWizardPage(wizard.page + 1));
      }
    }
  };
}

export default connect(mapStateToProps)(DataFilter);
