import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Copyright from '../../shared/copyright/Copyright';
import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { getApiCallStatus } from '../../api/reducers/api';
import { hasManagedServerFiltersSelector } from '../../api/reducers/userInfo';
import Support from '../../api/components/Support';
import {
  postDataFilterCriteria,
  setCopyrightModalOpen,
  setFeedbackPhoneNumber,
  setSettingsModalOpen,
  setSupportModalOpen,
  setWizardModal,
  setWizardPage,
  updateTenantFilter,
  wizardFinished,
  WebDispatchProps,
} from '../actions';
import { isDataFilterSetSelector } from '../../common/reducers/device';
import dataFilterLinesAndRegionsEmptySelector from '../../common/selectors/dataFilterLinesAndRegionsEmpty';
import dataFilterIncidentTypeEmptySelector from '../../common/selectors/dataFilterIncidentTypeEmpty';
import tenantDataFilterLinesAndRegionsEmptySelector from '../selectors/tenantDataFilterLinesAndRegionsEmpty';
import tenantDataFilterIncidentTypeEmptySelector from '../selectors/tenantDataFilterIncidentTypeEmpty';
import validatePhoneNumber from '../../common/utils/validatePhoneNumber';

import { WebReduxState } from '../reducers';
import { tenantAdminSelector, Tenant } from '../reducers/adminTenants';
import { Wizard } from '../reducers/webNav';
import wizardRouteNamesSelector from '../../common/selectors/wizardRouteNames';
import DataFilter from '../wizard/DataFilter';
import colorStyles from '../css/colors.module.css';
import PopupModal from './PopupModal';
import Settings from './Settings';

type OwnProps = {};

type StoreProps = {
  dataFilterLinesAndRegionsEmpty: boolean;
  fetchDataFilterError: any;
  postDataFilterError: any;
  hasManagedDataFilter: boolean;
  incidentTypeEmpty: boolean;
  isDataFilterSet: boolean;
  selectedTenant: Tenant | null | undefined;
  showCopyright: boolean;
  showSettingsPage: boolean;
  showSupportPage: boolean;
  tenantDataFilterIncidentTypeEmpty: boolean;
  tenantDataFilterLinesAndRegionsEmpty: boolean;
  tenantWizardPages: ReadonlyArray<DataFilterType>;
  wizard: Wizard;
  wizardPages: string[];
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    dataFilterLinesAndRegionsEmpty: dataFilterLinesAndRegionsEmptySelector(state),
    fetchDataFilterError: getApiCallStatus(state, 'FETCH_DATAFILTER_CRITERIA').error,
    postDataFilterError: getApiCallStatus(state, 'POST_DATAFILTER_CRITERIA').error,
    hasManagedDataFilter: hasManagedServerFiltersSelector(state),
    incidentTypeEmpty: dataFilterIncidentTypeEmptySelector(state),
    isDataFilterSet: isDataFilterSetSelector(state),
    selectedTenant: tenantAdminSelector(state),
    showCopyright: state.webNav.copyrightModal,
    showSettingsPage: state.webNav.settingsModal,
    showSupportPage: state.webNav.supportModal,
    tenantDataFilterLinesAndRegionsEmpty: tenantDataFilterLinesAndRegionsEmptySelector(state),
    tenantDataFilterIncidentTypeEmpty: tenantDataFilterIncidentTypeEmptySelector(state),
    tenantWizardPages: dataFilterTypesSelector(state),
    wizard: state.webNav.wizard,
    wizardPages: wizardRouteNamesSelector(state),
  };
}

class Modals extends React.Component<Props> {
  render() {
    const {
      dataFilterLinesAndRegionsEmpty,
      fetchDataFilterError,
      hasManagedDataFilter,
      incidentTypeEmpty,
      isDataFilterSet,
      postDataFilterError,
      selectedTenant,
      showCopyright,
      showSettingsPage,
      showSupportPage,
      tenantDataFilterIncidentTypeEmpty,
      tenantDataFilterLinesAndRegionsEmpty,
      tenantWizardPages,
      wizard,
      wizardPages,
    } = this.props;

    const page = wizard.modal === 'tenant' ? tenantWizardPages[wizard.page] : wizardPages[wizard.page];
    const pageLength = wizard.modal === 'tenant' ? tenantWizardPages.length : wizardPages.length;
    const noError = !fetchDataFilterError && !postDataFilterError;

    const submitText = <FormattedMessage id="Modals.submitText" defaultMessage="Save" />;

    let wizardTitle = <FormattedMessage id="Modals.wizardTitle.init" defaultMessage="Setup" />;
    switch (page) {
      case 'regions':
        wizardTitle = <FormattedMessage id="Modals.wizardTitle.regions" defaultMessage="Regions" />;
        break;
      case 'lines':
        wizardTitle = <FormattedMessage id="Modals.wizardTitle.lines" defaultMessage="Lines" />;
        break;
      case 'incidentTypes':
        wizardTitle = <FormattedMessage id="Modals.wizardTitle.incidentTypes" defaultMessage="Category" />;
        break;
      case 'feedbackPhoneNumber':
      default:
        break;
    }

    return (
      <div>
        <PopupModal
          title={<FormattedMessage id="Modals.copyright" defaultMessage="Copyright" />}
          body={<Copyright linkClassName={colorStyles.tintLink} />}
          show={showCopyright}
          closeButton={true}
          onHide={this.handleCopyrightModalClose}
        />
        <PopupModal
          title={
            pageLength > 1 ? (
              <FormattedMessage
                id="Modals.wizardMultiPage"
                values={{ page: wizard.page + 1, wizardTitle, pageLength }}
                defaultMessage="Wizard {page, number}/{pageLength} - {wizardTitle}"
              />
            ) : (
              <FormattedMessage id="Modals.wizardSinglePage" defaultMessage="{wizardTitle}" values={{ wizardTitle }} />
            )
          }
          body={<DataFilter />}
          submitText={submitText}
          show={wizard.modal !== 'closed' && wizard.modal !== 'tenant'}
          dark={true}
          closeButton={isDataFilterSet}
          nextDisabled={
            (page === 'feedbackPhoneNumber' && !validatePhoneNumber(wizard.phoneNumber)) ||
            (page === 'incidentTypes' && incidentTypeEmpty)
          }
          onSubmit={
            (!dataFilterLinesAndRegionsEmpty || hasManagedDataFilter) && noError
              ? this.handleWizardSubmitClick
              : undefined
          }
          onNext={wizard.page < pageLength - 1 && noError ? this.handleWizardNextClick : undefined}
          onBack={wizard.page > 0 && noError ? this.handleWizardBackClick : undefined}
          onHide={isDataFilterSet ? this.handleWizardModalClose : undefined}
        />
        {selectedTenant && (
          <PopupModal
            title={
              <FormattedMessage
                id="Modals.tenant"
                values={{ tenant: selectedTenant.displayName }}
                defaultMessage="Set data filter for tenant {tenant}"
              />
            }
            body={<DataFilter tenantMode={true} />}
            submitText={submitText}
            show={wizard.modal === 'tenant'}
            dark={true}
            closeButton={true}
            onSubmit={tenantDataFilterLinesAndRegionsEmpty ? undefined : this.handleTenantFilterSubmitClick}
            nextDisabled={page === 'incidentTypes' && tenantDataFilterIncidentTypeEmpty}
            onNext={wizard.page < pageLength - 1 && noError ? this.handleWizardNextClick : undefined}
            onBack={wizard.page > 0 && noError ? this.handleWizardBackClick : undefined}
            onHide={this.handleWizardModalClose}
          />
        )}
        <PopupModal
          title={<FormattedMessage id="Modals.support" defaultMessage="Support" />}
          body={<Support linkClassName={colorStyles.tintLink} />}
          show={showSupportPage}
          closeButton={true}
          onHide={this.handleSupportModalClose}
        />
        <PopupModal
          title={<FormattedMessage id="Modals.settings" defaultMessage="Settings" />}
          body={<Settings />}
          show={showSettingsPage}
          closeButton={true}
          onHide={this.handleSettingsModalClose}
        />
      </div>
    );
  }

  handleCopyrightModalClose = () => {
    this.props.dispatch(setCopyrightModalOpen(false));
  };

  handleSettingsModalClose = () => {
    const {
      wizard: { phoneNumber },
      dispatch,
    } = this.props;
    if (validatePhoneNumber(phoneNumber)) {
      dispatch(setFeedbackPhoneNumber(phoneNumber.trim()));
    }
    dispatch(setSettingsModalOpen(false));
  };

  handleSupportModalClose = () => {
    this.props.dispatch(setSupportModalOpen(false));
  };

  handleWizardModalClose = () => {
    this.props.dispatch(setWizardModal('closed'));
  };

  handleWizardSubmitClick = () => {
    const {
      wizard: { phoneNumber },
      dispatch,
      hasManagedDataFilter,
    } = this.props;
    if (validatePhoneNumber(phoneNumber)) {
      dispatch(setFeedbackPhoneNumber(phoneNumber.trim()));
    }

    if (hasManagedDataFilter) {
      dispatch(wizardFinished());
    } else {
      dispatch(postDataFilterCriteria());
    }

    dispatch(setWizardModal('closed'));
  };

  handleWizardNextClick = () => {
    const { wizard, dispatch } = this.props;
    dispatch(setWizardPage(wizard.page + 1));
  };

  handleWizardBackClick = () => {
    const { wizard, dispatch } = this.props;
    dispatch(setWizardPage(wizard.page - 1));
  };

  handleTenantFilterSubmitClick = () => {
    const { dispatch } = this.props;
    dispatch(updateTenantFilter());
    dispatch(setWizardModal('closed'));
  };
}

export default connect(mapStateToProps)(Modals);
