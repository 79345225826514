import { createSelector } from 'reselect';
import { IntlShape, createIntlCache, createIntl } from 'react-intl';

import { I18NState, MessageDictionary } from '../../shared/i18n/reducers/i18n';
import { AppConfig } from '../reducers/appConfig';
import { ConfigState, localizationOverridesSelector } from '../reducers/config';

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
  i18n: I18NState;
};

export const messagesSelector: (state: PartialReduxState) => MessageDictionary = createSelector(
  state => state.i18n,
  state => localizationOverridesSelector(state),
  ({ currentLocale, messages }, overrides) => {
    const lang = currentLocale.substring(0, 2);
    if (!overrides) {
      return messages[lang];
    }

    return { ...messages[lang], ...overrides[lang] };
  }
);

export const intlSelector: (state: PartialReduxState) => IntlShape = createSelector(
  state => state.i18n.currentLocale,
  state => messagesSelector(state),
  (locale, messages) => {
    const intlCache = createIntlCache();
    return createIntl({ locale, messages }, intlCache);
  }
);
