import * as React from 'react';

import { DataFilterType } from '../../api/reducers/config';
import { Header } from './IncidentListTable';

import styles from './IncidentList.module.css';
import tableStyles from '../css/tables.module.css';

type Props = {
  showTableHeader: boolean;
  showChatColumn?: boolean;
  showFavoriteColumn?: boolean;
  longDates?: boolean;
  canSeeIncidentType: boolean;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  setScrollRef: (ref: HTMLElement | null) => void;
  children: React.ReactNode;
};

export default function IncidentListContent({
  showTableHeader,
  showChatColumn,
  showFavoriteColumn,
  longDates,
  canSeeIncidentType,
  dataFilterTypes,
  setScrollRef,
  children,
}: Props) {
  return (
    <div className={styles.mainContent}>
      <table className={`${tableStyles.table} ${styles.tableMinWidth}`}>
        {showTableHeader && (
          <Header
            showChatColumn={showChatColumn}
            showFavoriteColumn={showFavoriteColumn}
            canSeeIncidentType={canSeeIncidentType}
            dataFilterTypes={dataFilterTypes}
            longDates={longDates}
          />
        )}
      </table>
      <div className={`${tableStyles.tableBodyWrapper} ${styles.tableMinWidth}`} ref={setScrollRef}>
        <table className={tableStyles.table}>
          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  );
}
