import * as React from 'react';
import { Route, Switch, match } from 'react-router';

import { setSelectedCallOutId, useDispatch } from '../actions';
import { useSelector, WebReduxState } from '../reducers';
import EditCallOutScreen from '../callOuts/EditCallOutScreen';
import OperationDetailsCallOuts from '../callOuts/OperationDetailsCallOuts';
import NewFollowupCallOutScreen from '../callOuts/NewFollowupCallOutScreen';

type Props = {
  match: match<{ callOutId: string }>;
};

// eslint-disable-next-line no-shadow
export default function CallOutsRoutes({ match }: Props) {
  const callOutId = match.params.callOutId;
  const path = match.path;

  const storedSelectedCallOutId = useSelector((state: WebReduxState) => state.participations.selectedCallOutId);
  const dispatch = useDispatch();

  // Sync selection to Redux store.
  React.useEffect(() => {
    if (callOutId !== storedSelectedCallOutId) {
      dispatch(setSelectedCallOutId(callOutId));
    }
  });

  return (
    <Switch>
      <Route path={`${path}/newFollowup`} component={NewFollowupCallOutScreen} />
      <Route path={`${path}/edit`} component={EditCallOutScreen} />
      <Route component={OperationDetailsCallOuts} />
    </Switch>
  );
}
