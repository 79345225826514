import { PostIncidentsQueryRequest, PostIncidentsQueryResponse } from '../types';
import apiCall from '../apiCall';

export function* fetchIncidents(deviceId: string, request: PostIncidentsQueryRequest): Generator<any, any, any> {
  const response: PostIncidentsQueryResponse = yield* apiCall(`devices/${deviceId}/incidents`, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  return response;
}
