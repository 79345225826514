import * as React from 'react';
import { orderBy, groupBy } from 'lodash-es';
import { FormattedDate } from 'react-intl';

import { ChatMessage } from '../../api/types';
import { ChatThread } from '../../api/reducers/chat';
import { ChatThreadInfo } from '../../api/reducers/chatPending';
import ChatMessageRow from './ChatMessageRow';
import styles from './ChatList.module.css';

type ChatMap = {
  [date: string]: ChatMessage[];
};

type Props = {
  thread: ChatThread | null | undefined;
  threadInfo?: ChatThreadInfo | null | undefined;
  isAdmin?: boolean;
  height?: number;
  onBlock?: (messageId: string) => void;
  onSetRead?: (messageId: string) => void;
};

type MessageMap = {
  [messageId: string]: ChatMessage;
};

function chatMap(messages: MessageMap, pendingMessages: MessageMap | null | undefined): ChatMap {
  const allMessages = pendingMessages ? { ...messages, ...pendingMessages } : messages;
  const orderedMessages = orderBy(allMessages, [m => m.submitted], ['asc']);
  return groupBy(orderedMessages, m => m.submitted.substring(0, m.submitted.indexOf('T')));
}

export default class ChatList extends React.PureComponent<Props> {
  chatWindowRef: HTMLElement | null | undefined;

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: Props) {
    const getMessages = props => props.thread && props.thread.messages;
    const getPendingMessages = props => props.threadInfo && props.threadInfo.pendingMessages;

    if (
      getMessages(this.props) !== getMessages(prevProps) ||
      getPendingMessages(this.props) !== getPendingMessages(prevProps)
    ) {
      this.scrollToBottom();
    }
  }

  render() {
    const { thread, threadInfo, isAdmin, onBlock, height } = this.props;

    if (!thread) {
      return <div className={styles.chatWindow} />;
    }

    const messagesById = thread.messages.byId;
    const pendingMessagesById = threadInfo ? threadInfo.pendingMessages.byId : null;
    const byDay = chatMap(messagesById, pendingMessagesById);
    const chatDays = Object.keys(byDay);
    const heightStyle = height ? { height } : {};

    return (
      <div className={styles.chatWindow} style={heightStyle} ref={this.setChatWindowRef}>
        {chatDays.map(day => (
          <ChatAggregation key={day} chatMessages={byDay[day]} day={day} isAdmin={isAdmin} onBlock={onBlock} />
        ))}
      </div>
    );
  }

  setChatWindowRef = (ref: HTMLElement | null | undefined) => {
    this.chatWindowRef = ref;
  };

  scrollToBottom() {
    const { chatWindowRef } = this;
    if (!chatWindowRef) {
      return;
    }

    chatWindowRef.scrollTop = chatWindowRef.scrollHeight;

    const { onSetRead, thread } = this.props;
    if (onSetRead && thread && thread.lastMessageId) {
      onSetRead(thread.lastMessageId);
    }
  }
}

type ChatAggregationProps = {
  chatMessages: ChatMessage[];
  day: string;
  isAdmin?: boolean;
  onBlock?: (messageId: string) => void;
};

function ChatAggregation({ chatMessages, day, isAdmin, onBlock }: ChatAggregationProps) {
  return (
    <div className={styles.chatDay}>
      <span className={styles.chatDayTitle}>
        <FormattedDate value={day} weekday="short" day="numeric" month="numeric" year="numeric" />
      </span>
      {chatMessages.map(cm => (
        <ChatMessageRow key={cm.messageId} chatMessage={cm} isAdmin={isAdmin} onBlock={onBlock} />
      ))}
    </div>
  );
}
