import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { CallOutKey } from '../../api/crowdTask/cockpit/callOuts';
import { closeCallOut, releaseCallOut, deleteCallOut, terminateOpenParticipations, WebDispatch } from '../actions';
import CallOutsKeyApiDialog from './CallOutsKeyApiDialog';

type Props = {
  showDeleteDialog: boolean;
  showReleaseDialog: boolean;
  showCloseDialog: boolean;
  showTerminateDialog: boolean;
  onChangeDeleteDialogVisibility: (show: boolean) => void;
  onChangeReleaseDialogVisibility: (show: boolean) => void;
  onChangeCloseDialogVisibility: (show: boolean) => void;
  onChangeTerminateDialogVisibility: (show: boolean) => void;
  dispatch: WebDispatch;
  callOutKey: CallOutKey;
  version: number;
};

export default function SelectedCallOutDialogs({
  showDeleteDialog,
  showReleaseDialog,
  showCloseDialog,
  showTerminateDialog,
  onChangeDeleteDialogVisibility,
  onChangeReleaseDialogVisibility,
  onChangeCloseDialogVisibility,
  onChangeTerminateDialogVisibility,
  dispatch,
  callOutKey,
  version,
}: Props) {
  return (
    <div>
      <CallOutsKeyApiDialog
        open={showDeleteDialog}
        title={<FormattedMessage id="DeleteDialog.title" defaultMessage="Delete call out?" />}
        message={
          <FormattedMessage id="DeleteDialog.message" defaultMessage="Do you really want to delete the call out?" />
        }
        onChangeDialogVisibility={onChangeDeleteDialogVisibility}
        apiActionWithVersion={deleteCallOut}
        dispatch={dispatch}
        callOutKey={callOutKey}
        version={version}
      />
      <CallOutsKeyApiDialog
        open={showReleaseDialog}
        title={<FormattedMessage id="ReleaseDialog.title" defaultMessage="Release call out?" />}
        message={
          <FormattedMessage id="ReleaseDialog.message" defaultMessage="Do you really want to release the call out?" />
        }
        onChangeDialogVisibility={onChangeReleaseDialogVisibility}
        apiAction={releaseCallOut}
        dispatch={dispatch}
        callOutKey={callOutKey}
        version={version}
      />
      <CallOutsKeyApiDialog
        open={showCloseDialog}
        title={<FormattedMessage id="CloseDialog.title" defaultMessage="End call out?" />}
        message={<FormattedMessage id="CloseDialog.message" defaultMessage="Do you really want to end the call out?" />}
        onChangeDialogVisibility={onChangeCloseDialogVisibility}
        apiActionWithVersion={closeCallOut}
        dispatch={dispatch}
        callOutKey={callOutKey}
        version={version}
      />
      <CallOutsKeyApiDialog
        open={showTerminateDialog}
        title={<FormattedMessage id="TerminateParticipationsDialog.title" defaultMessage="Terminate participations?" />}
        message={
          <FormattedMessage
            id="TerminateParticipationsDialog.message"
            defaultMessage="Do you really want to terminate the participations?"
          />
        }
        onChangeDialogVisibility={onChangeTerminateDialogVisibility}
        apiAction={terminateOpenParticipations}
        dispatch={dispatch}
        callOutKey={callOutKey}
        version={version}
      />
    </div>
  );
}
