export default function generateUuid(removeDividers: boolean = false): string {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
    return v.toString(16);
  });

  if (removeDividers) {
    return uuid.replace(/-/g, '');
  } else {
    return uuid;
  }
}
