import { GetIncidentsSearchResponse } from '../types';
import apiCall from '../apiCall';
import { setTimeToEndOfDay, setTimeToStartOfDay } from '../utils/dateUtils';

export type GetIncidentsSearchRequest = {
  term: string;
  from?: string;
  to?: string;
  searchInChat: boolean;
};

const incidentsSearchPath = 'search/incidents';

const createQueryParams = params =>
  Object.keys(params)
    .filter(k => params[k])
    .map(k => `${k}=${encodeURI(params[k])}`)
    .join('&');

const makeUriParams = (request: GetIncidentsSearchRequest) => {
  const { term, from, to, searchInChat } = request;

  return createQueryParams({
    term: term.length > 0 ? term : null,
    from: from ? setTimeToStartOfDay(from) : null,
    to: to ? setTimeToEndOfDay(to) : null,
    searchInChat,
  });
};

export function* getIncidentsSearch(request: GetIncidentsSearchRequest): Generator<any, any, any> {
  let uriParameters = makeUriParams(request);

  const response: GetIncidentsSearchResponse = yield* apiCall(`${incidentsSearchPath}?${uriParameters}`);

  return response;
}

export function* getIncidentsSearchChat(incidentId: string): Generator<any, any, any> {
  return yield* apiCall(`${incidentsSearchPath}/${incidentId}/chat`);
}

export function* getIncidentsSearchCsv(request: GetIncidentsSearchRequest): Generator<any, any, any> {
  let uriParameters = makeUriParams(request);

  return yield* apiCall(`${incidentsSearchPath}/export.csv?${uriParameters}`);
}
