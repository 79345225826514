import { PostTenantRequest, PutTenantRequest } from '../types';
import apiCall from '../apiCall';

// Returns GetTenantsResponse.
export function* getTenants(): Generator<any, any, any> {
  // Upper line fetches all tenants, lower line only restricted tenants
  // return yield* apiCall('admin/tenant');
  return yield* apiCall('admin/tenant/managed');
}

// Returns PostTenantResponse.
export function* createTenant(request: PostTenantRequest): Generator<any, any, any> {
  return yield* apiCall('admin/tenant', {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

// Returns PutTenantResponse.
export function* updateTenant(tenantId: string, request: PutTenantRequest): Generator<any, any, any> {
  return yield* apiCall(`admin/tenant/${tenantId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}
