import * as React from 'react';
import Radio from '@material-ui/core/Radio';

import { Incident } from '../../api/incidentsTypes';
import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import { isIncidentClosed, isIncidentUnread } from '../../common/utils/incidents';
import { DataFilterType } from '../../api/reducers/config';
import rowStyles from '../incidents/IncidentRow.module.css';

type Props = {
  incident: Incident;
  iconName: string;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  showRadioButton?: boolean;
  isChecked?: boolean;
  onRowClicked: (incident: Incident) => void;
};

export default class CrowdTaskIncidentRow extends React.PureComponent<Props> {
  render() {
    const { incident, showRadioButton, isChecked, iconName, dataFilterTypes } = this.props;
    const data = incident.currentData;
    const { cause } = data;

    const closed = isIncidentClosed(incident.currentData);
    const iconBackgroundColor = closed ? 'greyIcon' : 'tintIcon';
    const rowColor = closed ? 'greyText' : 'blackText';
    const readStyle = isIncidentUnread(incident) ? rowStyles.unread : '';

    return (
      <tr
        className={`${rowStyles.crowdTaskIncidentRow} pointerCursor noSelect ${rowColor} ${readStyle}`}
        onClick={this.handleRowClicked}>
        {showRadioButton && (
          <td className={rowStyles.radioButtonCol}>
            <Radio value={incident.incidentId} checked={isChecked} />
          </td>
        )}
        <td className={`${iconBackgroundColor} ${rowStyles.causeIconBodyCol}`}>
          <span className={`icon-${iconName} whiteText ${rowStyles.causeIcon}`} />
        </td>
        <td>
          <div>{cause}</div>
        </td>
        <td>
          <div>{data.lines.join(', ')}</div>
        </td>
        <td>
          <div>{data.locationFrom}</div>
        </td>
        <td>
          <div>{data.locationTo}</div>
        </td>
        <td>
          <div>{dataFilterTypes.includes('incidentTypes') ? data.incidentType : data.regions.join(', ')}</div>
        </td>
        <td className={rowStyles.dateCol}>
          <div>{<FormattedTimestamp value={incident.lastUpdated} />}</div>
        </td>
      </tr>
    );
  }

  handleRowClicked = () => {
    const { onRowClicked, incident } = this.props;
    onRowClicked(incident);
  };
}
