import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, IntlShape } from 'react-intl';

import { I18NState } from '../../shared/i18n/reducers/i18n';
import { intlSelector } from '../selectors/i18n';
import { ApiState, ApiCallDict } from '../reducers/api';
import { AppConfig } from '../reducers/appConfig';
import { ConfigState } from '../reducers/config';

type OwnProps = {
  apiCallNames: string[];
  children?: React.ReactNode;
  renderProgress?: () => React.ReactNode;
  renderErrorMessage?: (errorMessage: string) => React.ReactNode;
  onSuccess?: (apiCallName: string) => void;
  onError?: (apiCallName: string, errorMessage: string) => void;
};

type StoreProps = {
  statuses: ApiCallDict;
  intl: IntlShape;
};

type Props = OwnProps & StoreProps;

type State = {
  inProgress: boolean;
  errorMessage: string | null | undefined;
};

type PartialReduxState = {
  api: ApiState;
  appConfig: AppConfig;
  config: ConfigState;
  i18n: I18NState;
};

function mapStateToProps(state: PartialReduxState): StoreProps {
  return {
    statuses: state.api.apiCalls,
    intl: intlSelector(state),
  };
}

class ApiCallStatusHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inProgress: computeInProgress(props),
      errorMessage: null,
    };
  }

  // Will need to be reworked for React 17.
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.statuses === this.props.statuses) {
      // Avoid unnecessary recomputations.
      // (Making this a pure component won't help, as children will still change.)
      return;
    }

    const { statuses, apiCallNames, onSuccess, onError, intl } = this.props;

    const completedApiCall = apiCallNames.find(name => {
      const thisStatus = statuses[name];
      const nextStatus = nextProps.statuses[name];

      return thisStatus && nextStatus && thisStatus.inProgress && !nextStatus.inProgress;
    });

    if (completedApiCall) {
      const status = nextProps.statuses[completedApiCall];

      if (status.error) {
        const errorMessage = getErrorMessage(intl, status.error);
        this.setState({ errorMessage });

        if (onError) {
          onError(completedApiCall, errorMessage);
        }
      } else {
        this.setState({ errorMessage: null });

        if (onSuccess) {
          onSuccess(completedApiCall);
        }
      }
    }

    this.setState({ inProgress: computeInProgress(nextProps) });
  }

  render() {
    const { children, renderProgress, renderErrorMessage } = this.props;
    const { inProgress, errorMessage } = this.state;

    if (renderProgress && inProgress) {
      return renderProgress();
    }

    if (renderErrorMessage && errorMessage) {
      return (
        <>
          {children}
          {renderErrorMessage(errorMessage)}
        </>
      );
    }

    // Must return null in case children is undefined.
    return children || null;
  }
}

export function getErrorMessage(intl: IntlShape, _error: any) {
  const msg = defineMessages({
    anErrorOccurred: { id: 'ApiCallStatusHandler.anErrorOccurred', defaultMessage: 'An error occurred' },
  });

  // 2804: Do not show any error code or text at all, just a generic error message.
  return intl.formatMessage(msg.anErrorOccurred);

  // const { status, message } = error;
  // if (status) {
  //   return `${status} ${HttpStatus.getStatusText(status)}`;
  // }
  // const errorMessage = message || error.toString();
  // const MAX_LEN = 80;
  // return errorMessage.length > MAX_LEN ? `${errorMessage.substring(0, MAX_LEN)}...` : errorMessage;
}

function computeInProgress(props: Props) {
  const { apiCallNames, statuses } = props;

  return apiCallNames.some(name => {
    const status = statuses[name];
    return status && status.inProgress;
  });
}

export default connect(mapStateToProps)(ApiCallStatusHandler);
