import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  radio: {
    color: '#505050',
    height: '24px',
    width: '40px',
    paddingLeft: '8px',
  },
  label: {
    color: '#959595',
  },
};

type Props = {
  checked: boolean;
  onChange: () => void;
  label: React.ReactNode;
  classes: Object;
};

class FilterRadio extends React.PureComponent<Props> {
  render() {
    const { checked, onChange, label, classes } = this.props;
    return (
      <FormControlLabel
        classes={{ label: classes['label'] }}
        control={<Radio classes={{ root: classes['radio'] }} checked={checked} onChange={onChange} />}
        label={label}
      />
    );
  }
}

export default withStyles(styles)(FilterRadio);
