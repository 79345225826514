import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { CrowdTaskCallOutStatus } from '../../api/types';

type Props = {
  status: CrowdTaskCallOutStatus;
};

export default function CallOutStatusText({ status }: Props) {
  switch (status) {
    case 'DRAFT':
      return <FormattedMessage id="Timeline.draft" defaultMessage="Draft" />;
    case 'RELEASED':
      return <FormattedMessage id="Timeline.released" defaultMessage="Released" />;
    case 'CLOSED':
      return <FormattedMessage id="Timeline.closed" defaultMessage="Closed" />;
    case 'DELETED':
      return <FormattedMessage id="Timeline.deleted" defaultMessage="Deleted" />;
    default:
      return <FormattedMessage id="Timeline.unknown" defaultMessage="Unknown" />;
  }
}
