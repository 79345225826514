import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setFilterCriteria, setFilterIncidentStatus, resetFilter, setRemId } from '../actions';
import { ReduxState } from '../../common/reducers';
import { IncidentStatus } from '../../api';
import { DispatchProps } from '../../common/actions';
import { DisplayFilterType } from '../../common/actions/filter';
import {
  dataFilterTypesSelector,
  DataFilterType,
  clientConfigSelector,
  RegionInfo,
  canSeeIncidentTypeSelector,
} from '../../api/reducers/config';
import filterCriteriaSelector, { FilterCriteriaResult } from '../../common/selectors/filterCriteria';
import filterWithSetsSelector, { FilterWithSets } from '../../common/selectors/filterWithSets';
import FilterHeader from './FilterHeader';
import FilterPanel from './FilterPanel';
import FilterPanelForm from './FilterPanelForm';
import FilterCheckboxList from './FilterCheckboxList';
import SVGRegionMap from './SVGRegionMap';

type OwnProps = {};

type StoreProps = {
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  regionInfos?: ReadonlyArray<RegionInfo>;
  filterCriteria: FilterCriteriaResult;
  filter: FilterWithSets;
  canSeeIncidentType: boolean;
};

type Props = OwnProps & StoreProps & DispatchProps;

type State = {
  remIdText: string;
  activeKey: string;
};

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    dataFilterTypes: dataFilterTypesSelector(state),
    regionInfos: clientConfigSelector(state).features.regionInfos,
    filterCriteria: filterCriteriaSelector(state),
    filter: filterWithSetsSelector(state),
    canSeeIncidentType: canSeeIncidentTypeSelector(state),
  };
}

class FilterSidebar extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      remIdText: props.filter.remID,
      activeKey: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      remIdText: nextProps.filter.remID,
    });
  }

  render() {
    const { dataFilterTypes, regionInfos, filter, filterCriteria, canSeeIncidentType } = this.props;
    const { activeKey } = this.state;

    return (
      <div>
        <div>
          <>
            {canSeeIncidentType && dataFilterTypes.includes('incidentTypes') && (
              <FilterPanel
                activeKey={activeKey}
                eventKey="incidentTypes"
                onChange={this.handlePanelToggle}
                header={
                  <FilterHeader
                    iconName="tag"
                    title={<FormattedMessage id="FilterSidebar.incidentType" defaultMessage="Category" />}
                    data={filter.incidentTypes}
                  />
                }>
                <FilterCheckboxList
                  type="incidentTypes"
                  criteria={filterCriteria.incidentTypes}
                  onChange={this.handleCriterionChanged}
                />
              </FilterPanel>
            )}

            {dataFilterTypes.includes('lines') && (
              <FilterPanel
                activeKey={activeKey}
                eventKey="lines"
                onChange={this.handlePanelToggle}
                header={
                  <FilterHeader
                    iconName="line"
                    title={<FormattedMessage id="FilterSidebar.place" defaultMessage="Line" />}
                    data={filter.lines}
                  />
                }>
                <FilterCheckboxList
                  type="lines"
                  criteria={filterCriteria.lines}
                  onChange={this.handleCriterionChanged}
                />
              </FilterPanel>
            )}

            <FilterPanel
              activeKey={activeKey}
              eventKey="locations"
              onChange={this.handlePanelToggle}
              header={
                <FilterHeader
                  iconName="location_ft"
                  title={<FormattedMessage id="FilterSidebar.location" defaultMessage="Location" />}
                  data={filter.locations}
                />
              }>
              <FilterCheckboxList
                type="locations"
                criteria={filterCriteria.locations}
                onChange={this.handleCriterionChanged}
              />
            </FilterPanel>

            {dataFilterTypes.includes('regions') && (
              <FilterPanel
                activeKey={activeKey}
                eventKey="regions"
                onChange={this.handlePanelToggle}
                header={
                  <FilterHeader
                    iconName="region"
                    title={<FormattedMessage id="FilterSidebar.region" defaultMessage="Region" />}
                    data={filter.regions}
                  />
                }>
                {regionInfos ? (
                  <SVGRegionMap
                    regionInfos={regionInfos}
                    regions={filterCriteria.regions}
                    onChange={this.handleCriterionChanged}
                  />
                ) : (
                  <FilterCheckboxList
                    type="regions"
                    criteria={filterCriteria.regions}
                    onChange={this.handleCriterionChanged}
                  />
                )}
              </FilterPanel>
            )}
          </>
        </div>

        <FilterPanelForm
          incidentStatus={filter.incidentStatus}
          onIncidentStatusClicked={this.handleIncidentStatusClicked}
          remIdText={this.state.remIdText}
          onResetRowPressed={this.handleResetRowPressed}
          onRemIdChangeText={this.handleRemIdChangeText}
          onRemIdEndEditing={this.handleRemIdEndEditing}
        />
      </div>
    );
  }

  handlePanelToggle = (eventKey: string) => {
    if (this.state.activeKey === eventKey) {
      this.setState({ activeKey: '' });
    } else {
      this.setState({ activeKey: eventKey });
    }
  };

  handleRemIdChangeText = e => {
    this.setState({ remIdText: e.target.value });
  };

  handleRemIdEndEditing = () => {
    this.props.dispatch(setRemId(this.state.remIdText));
  };

  handleIncidentStatusClicked = (status: IncidentStatus | null | undefined) => {
    this.props.dispatch(setFilterIncidentStatus(status));
  };

  handleCriterionChanged = (criterion: string, type: DisplayFilterType) => {
    const { filter, dispatch } = this.props;
    const newCriteria = new Set(filter[type]);

    if (newCriteria.has(criterion)) {
      newCriteria.delete(criterion);
    } else {
      newCriteria.add(criterion);
    }

    dispatch(setFilterCriteria(type, Array.from(newCriteria)));
  };

  handleResetRowPressed = () => {
    this.props.dispatch(resetFilter());
  };
}

export default connect(mapStateToProps)(FilterSidebar);
