import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { dataFilterTypesSelector, DataFilterType } from '../../api/reducers/config';
import { Incident, IncidentData, IncidentId } from '../../api/incidentsTypes';
import iconNameSelector from '../../common/selectors/iconName';
import { WebReduxState } from '../reducers';
import IncidentDetailsLeft from '../incidents/IncidentDetailsLeft';
import IncidentDetailsRight from '../incidents/IncidentDetailsRight';
import incidentDetailStyles from '../incidents/IncidentDetail.module.css';
import styles from './OperationDetailsIncidentInfo.module.css';
import CrowdTaskIncidentRow from './CrowdTaskIncidentRow';

type OwnProps = {
  incidentId: IncidentId;
};

type StoreProps = {
  incident: Incident | null | undefined;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  getIconName: (data: IncidentData) => string;
};

type Props = OwnProps & StoreProps;

type State = {
  showDetails: boolean;
};

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { incidentId } = ownProps;

  return {
    incident: state.crowdTaskIncidents.byId[incidentId],
    dataFilterTypes: dataFilterTypesSelector(state),
    getIconName: iconNameSelector(state),
  };
}

class OperationDetailsIncidentInfo extends React.Component<Props, State> {
  state = {
    showDetails: true,
  };

  render() {
    const { incidentId, incident } = this.props;
    if (!incident) {
      return (
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>
                <FormattedMessage
                  id="OperationDetailsIncidentInfo.incidentNotFound"
                  defaultMessage="Underlying incident {incidentId} not found."
                  values={{ incidentId }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    const { dataFilterTypes, getIconName } = this.props;
    const { showDetails } = this.state;

    return (
      <table className={styles.table}>
        <tbody>
          <CrowdTaskIncidentRow
            key={incidentId}
            incident={incident}
            iconName={getIconName(incident.currentData)}
            dataFilterTypes={dataFilterTypes}
            onRowClicked={this.handleRowClicked}
          />
          {showDetails && (
            <tr className={incidentDetailStyles.incidentDetailRow}>
              <td colSpan={7}>
                <div className={styles.incidentDetails}>
                  <div className={styles.incidentDetailLeft}>
                    <IncidentDetailsLeft
                      incidentId={incident.incidentId}
                      created={incident.created}
                      lastUpdated={incident.lastUpdated}
                      incidentDate={incident.incidentDate}
                      replacedById={incident.currentData.replacedById}
                      incidentData={incident.currentData}
                      canSeeIncidentType={true}
                    />
                  </div>
                  <div className={styles.incidentDetailRight}>
                    <IncidentDetailsRight incidentData={incident.currentData} />
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  handleRowClicked = () => {
    this.setState(state => ({
      showDetails: !state.showDetails,
    }));
  };
}

export default connect(mapStateToProps)(OperationDetailsIncidentInfo);
