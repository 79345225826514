import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { CrowdTaskOperation, CrowdTaskOperationStats } from '../../api/types';
import scrollTo from '../utils/scroll';
import OperationCard from './OperationCard';
import styles from './OperationList.module.css';

type Props = {
  operationIds: string[];
  operationsById: {
    [id: string]: CrowdTaskOperation;
  };
  operationStats: {
    [id: string]: CrowdTaskOperationStats;
  };
  selectedOperationId: string;
  unreadThreadIds: Set<string>;
  onOperationSelected: (operationId: string, subPath?: 'chat' | 'callOuts') => void;
};

type State = {
  selectedRef: HTMLElement | null | undefined;
  containerRef: HTMLElement | null | undefined;
};

function getEmptyOperationStats(operationId: string): CrowdTaskOperationStats {
  return {
    operationId,
    numberOfOfferedParticipations: 0,
    numberOfDraftCallOuts: 0,
    numberOfReleasedCallOuts: 0,
    runningSince: undefined,
  };
}

export default class OperationList extends React.PureComponent<Props, State> {
  state = { selectedRef: null, containerRef: null };

  componentWillUpdate(nextProps: Props, nextState: State) {
    const { selectedRef } = this.state;
    const nextSelectedRef = nextState.selectedRef;
    const containerRef = nextState.containerRef;

    if (selectedRef !== nextSelectedRef && nextSelectedRef && containerRef) {
      scrollTo(containerRef, nextSelectedRef);
    }
  }

  render() {
    const {
      operationIds,
      operationsById,
      operationStats,
      selectedOperationId,
      onOperationSelected,
      unreadThreadIds,
    } = this.props;

    return (
      <div className={styles.list} ref={this.setContainerRef}>
        <div className={styles.listContent}>
          <div className={styles.listHeader}>
            <FormattedMessage id="Operations.activeOperations" defaultMessage="Active Operations" />
          </div>
          {operationIds.map(id => {
            const operation = operationsById[id];
            // Stats may not be present in the state yet.
            const stats = operationStats[id] || getEmptyOperationStats(id);
            const isSelected = selectedOperationId === operation.operationId;

            return (
              <OperationCard
                setRef={isSelected ? this.setSelectedRef : undefined}
                key={id}
                operation={operation}
                operationStats={stats}
                isSelected={isSelected}
                onClick={onOperationSelected}
                unreadThreadIds={unreadThreadIds}
              />
            );
          })}
        </div>
      </div>
    );
  }

  setContainerRef = (ref: HTMLElement | null | undefined) => {
    this.setState({ containerRef: ref });
  };

  setSelectedRef = (ref: HTMLElement | null | undefined) => {
    this.setState({ selectedRef: ref });
  };
}
