import { createSelector } from 'reselect';
import { defineMessages } from 'react-intl';

import { I18NState } from '../../shared/i18n/reducers/i18n';
import { intlSelector } from '../../api/selectors/i18n';
import { ApiState } from '../reducers/api';
import { AppConfig } from '../reducers/appConfig';
import { ConfigState } from '../reducers/config';
import { RECONNECT_DELAY } from '../sagas/sync';

const msg = defineMessages({
  connected: { id: 'connectionStatus.connected', defaultMessage: 'Connected' },
  syncing: { id: 'connectionStatus.syncing', defaultMessage: 'Syncing...' },
  disconnected: { id: 'connectionStatus.disconnected', defaultMessage: 'Disconnected' },
  disconnectedWithRetryInfo: {
    id: 'connectionStatus.disconnectedWithRetryInfo',
    defaultMessage: 'Disconnected (will retry every {reconnectDelay} seconds)',
  },
});

type PartialReduxState = {
  api: ApiState;
  appConfig: AppConfig;
  config: ConfigState;
  i18n: I18NState;
};

// Note: Do not make a React component out of this,
// otherwise concatenating it with other strings is troublesome.
const connectionStatusTextSelector: (state: PartialReduxState) => string = createSelector(
  state => intlSelector(state),
  state => state.api.sync,
  (intl, syncState) => {
    const { status, error } = syncState;

    switch (status) {
      case 'connected':
        return intl.formatMessage(msg.connected);
      case 'syncing':
        return intl.formatMessage(msg.syncing);
      case 'disconnected':
      default:
        return error
          ? intl.formatMessage(msg.disconnectedWithRetryInfo, { reconnectDelay: RECONNECT_DELAY })
          : intl.formatMessage(msg.disconnected);
    }
  }
);

export default connectionStatusTextSelector;
