import { keyBy, omit } from 'lodash-es';

import { Action } from '../actions';
import { CrowdTaskCallOutStats, CrowdTaskCallOutWithStats } from '../../api/types';

export type CallOutStatsState = {
  [callOutId: string]: CrowdTaskCallOutStats;
};

export default function callOutStats(state: CallOutStatsState = {}, action: Action): CallOutStatsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_CALL_OUTS': {
          const results: CrowdTaskCallOutWithStats[] = action.result;
          const statsArray = results.map(r => r.stats);

          return keyBy(statsArray, stats => stats.callOutId);
        }
        default:
          return state;
      }

    case 'LONG_POLLING_MESSAGE':
      return handleLongPollingMessage(state, action.message);
    case 'CLEANUP':
      return {};
    default:
      return state;
  }
}

function handleLongPollingMessage(state, message) {
  if (message.type !== 'CrowdTaskCallOutStats') {
    return state;
  }

  const { action, payload } = message;

  switch (action) {
    case 'UPSERT': {
      const stats: CrowdTaskCallOutStats = payload;
      return { ...state, [stats.callOutId]: stats };
    }
    case 'DELETE': {
      const id: string = payload;
      return omit(state, [id]);
    }
    default:
      return state;
  }
}
