import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { hasManagedServerFiltersSelector } from '../../api/reducers/userInfo';
import MenuItem from '../../api/components/MenuItem';
import {
  resetIncidentsState,
  setCopyrightModalOpen,
  setSettingsModalOpen,
  setSupportModalOpen,
  setWizardModal,
  WebDispatchProps,
} from '../actions';
import { WebReduxState } from '../reducers';
import colorStyles from '../css/colors.module.css';

type OwnProps = {
  requestClose: () => void;
};

type StoreProps = {
  hasManagedDataFilter: boolean;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    hasManagedDataFilter: hasManagedServerFiltersSelector(state),
  };
}

class InformMenuBody extends React.Component<Props> {
  render() {
    const { hasManagedDataFilter } = this.props;

    return (
      <>
        {!hasManagedDataFilter && (
          <MenuItem
            onClick={this.handleDataFilterModalOpen}
            title={<FormattedMessage id="Menu.datafilter" defaultMessage="Data Filter" />}
            subtitle={
              <FormattedMessage id="Menu.dataFilterText" defaultMessage="Change server-side filter settings." />
            }
            linkClass={colorStyles.tintLink}
          />
        )}
        <MenuItem
          onClick={this.handleSettingsModalOpen}
          title={<FormattedMessage id="Menu.settings" defaultMessage="Settings" />}
          subtitle={<FormattedMessage id="Menu.settingsText" defaultMessage="Change app settings." />}
          linkClass={colorStyles.tintLink}
        />
        <MenuItem
          onClick={this.handleSupportModalOpen}
          title={<FormattedMessage id="Menu.support" defaultMessage="Support" />}
          subtitle={<FormattedMessage id="Menu.supportText" defaultMessage="Help and support." />}
          linkClass={colorStyles.tintLink}
        />
        <MenuItem
          onClick={this.handleResetIncidentsStateClick}
          title={<FormattedMessage id="Menu.resetIncidentsState" defaultMessage="Reset Message Status" />}
          subtitle={
            <FormattedMessage
              id="Menu.resetIncidentsStateText"
              defaultMessage="Mark all messages as unread and remove favorites."
            />
          }
          linkClass={colorStyles.tintLink}
        />
        <MenuItem
          onClick={this.handleCopyrightModalOpen}
          title={<FormattedMessage id="Menu.copyright" defaultMessage="Copyright" />}
          linkClass={colorStyles.tintLink}
        />
      </>
    );
  }

  handleResetIncidentsStateClick = () => {
    const { requestClose, dispatch } = this.props;

    requestClose();
    dispatch(resetIncidentsState());
  };

  handleCopyrightModalOpen = () => {
    const { requestClose, dispatch } = this.props;

    requestClose();
    dispatch(setCopyrightModalOpen(true));
  };

  handleDataFilterModalOpen = () => {
    const { requestClose, dispatch } = this.props;

    requestClose();
    dispatch(setWizardModal('user'));
  };

  handleSettingsModalOpen = () => {
    const { requestClose, dispatch } = this.props;

    requestClose();
    dispatch(setSettingsModalOpen(true));
  };

  handleSupportModalOpen = () => {
    const { requestClose, dispatch } = this.props;

    requestClose();
    dispatch(setSupportModalOpen(true));
  };
}

export default connect(mapStateToProps)(InformMenuBody);
