import { TenantInfo } from '../../api/ApplicationOptions';
import { ApiAction } from '../actions';

export type User = {
  readonly username: string;
  readonly email: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly roles: string[];
};

// The userInfo reducer stores the information about the current user.
// The information is not cleared on logout. It remains set until the next login.
// This is because the startup saga needs it to find out if the user has changed.
export type UserInfoState = {
  readonly tenantInfo: TenantInfo | null | undefined;
  readonly user: User | null | undefined;
};

const initialState: UserInfoState = {
  tenantInfo: null,
  user: null,
};

type PartialReduxState = {
  userInfo: UserInfoState;
};

export default function userInfo(state: UserInfoState = initialState, action: ApiAction): UserInfoState {
  switch (action.type) {
    case 'SET_TENANT':
      return {
        ...state,
        tenantInfo: action.tenantInfo,
      };
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'LOAD_TENANT':
          return {
            ...state,
            tenantInfo: action.result,
          };
        default:
          return state;
      }

    case 'SET_USER_INFO': {
      const { user } = action;
      return {
        ...state,
        user,
      };
    }
    default:
      return state;
  }
}

export function userSelector(state: PartialReduxState): User | null | undefined {
  return state.userInfo.user;
}

export function usernameSelector(state: PartialReduxState): string {
  const { user } = state.userInfo;
  return user ? user.username : 'anonymous';
}

export function userRolesSelector(state: PartialReduxState): Set<string> {
  const { user } = state.userInfo;
  return user ? new Set(user.roles) : new Set();
}

export function userDisplayNameSelector(state: PartialReduxState): string | null | undefined {
  const { user } = state.userInfo;
  if (!user) {
    return null;
  }

  const components = [user.firstName, user.lastName];
  const notNilComponents = components.filter(x => x);

  if (notNilComponents.length) {
    return notNilComponents.join(' ');
  } else {
    return user.username;
  }
}

export function reporterNameSelector(state: PartialReduxState): string {
  return userDisplayNameSelector(state) || 'Me';
}

export function tenantDisplayNameSelector(state: PartialReduxState): string | null | undefined {
  const { tenantInfo } = state.userInfo;
  return tenantInfo ? tenantInfo.displayName : null;
}

export function hasManagedServerFiltersSelector(state: PartialReduxState) {
  const { tenantInfo } = state.userInfo;
  return tenantInfo ? tenantInfo.hasManagedServerFilters : false;
}

export function tenantKeySelector(state: PartialReduxState): string | null | undefined {
  const { tenantInfo } = state.userInfo;
  return tenantInfo ? tenantInfo.tenantKey : null;
}
