import { select, takeLatest } from 'redux-saga/effects';
import { values } from 'lodash-es';

import { Incident } from '../../api/incidentsTypes';
import { Action } from '../actions';
import { ReduxState } from '../reducers';
import { filterHashSelector, IncidentsMap } from '../reducers/incidents';
import { performSyncChatMessages } from '../sagas/chat';

let filterHash: string = '';

export default function* refetchChatMessagesIfFilterHashChangedSaga(): Generator<any, any, any> {
  filterHash = yield select(filterHashSelector);
  yield takeLatest(mightHaveChangedFilterHash, checkFilterHashChanged);
}

function mightHaveChangedFilterHash(action: Action) {
  return action.type === 'API_CALL_SUCCESS' && action.name === 'FETCH_INCIDENTS';
}

function* checkFilterHashChanged() {
  const newFilterHash: string | null | undefined = yield select(filterHashSelector);

  if (newFilterHash !== filterHash) {
    const incidentsById: IncidentsMap = yield select((state: ReduxState) => state.inform.incidents.byId);
    const incidentChatThreadIds = values(incidentsById).map((incident: Incident) => incident.threadId);

    yield* performSyncChatMessages(incidentChatThreadIds);
  }
}
