import { ChatState } from '../reducers/chat';
import { ChatPendingState } from '../reducers/chatPending';

type PartialReduxState = {
  chat: ChatState;
  chatPending: ChatPendingState;
};

// Don't really need a caching selector for this. Still, let's provide the same API as for hasRoleSelector.
const hasNewMessages = (state: PartialReduxState) => (chatThreadId: string) => {
  const thread = state.chat[chatThreadId];
  const threadInfo = state.chatPending[chatThreadId];

  return Boolean(
    thread &&
      thread.lastMessageId &&
      (!threadInfo || (threadInfo && thread.lastMessageId !== threadInfo.lastReadMessageId))
  );
};

export default hasNewMessages;
