import { GetConfigResult } from '../config';

let CONFIG_KEY = 'inform.config';
let TENANT_KEY = 'inform.tenantKey';
let LOGIN_REQUIRED_KEY = '';

if (process.env.REACT_APP_WEB === 'portal') {
  CONFIG_KEY = 'crowdTask.portal.config';
  TENANT_KEY = 'crowdTask.portal.tenantKey';
  LOGIN_REQUIRED_KEY = 'crowdTask.portal.loginRequired';
}

export function getCachedConfig(): GetConfigResult | null | undefined {
  const configString = localStorage.getItem(CONFIG_KEY);
  return configString ? JSON.parse(configString) : null;
}

export function setCachedConfig(config: GetConfigResult) {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(config));
}

export function getTenantKey(): string | null | undefined {
  return localStorage.getItem(TENANT_KEY);
}

export function setTenantKey(tenantKey: string | null | undefined) {
  if (tenantKey) {
    localStorage.setItem(TENANT_KEY, tenantKey);
  } else {
    localStorage.removeItem(TENANT_KEY);
  }
}

export function isLoginRequired() {
  return localStorage.getItem(LOGIN_REQUIRED_KEY) === 'true';
}

export function setLoginRequired() {
  localStorage.setItem(LOGIN_REQUIRED_KEY, 'true');
}

export function clearLoginRequired() {
  localStorage.removeItem(LOGIN_REQUIRED_KEY);
}
