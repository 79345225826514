import { Action } from '../actions';

import { GetStatisticsResult } from '../../api/admin';

export type StatisticsState = GetStatisticsResult;

const initialState = {
  dailyStatistic: [],
  incidentTypeStatistic: [],
  lineStatistic: [],
  regionStatistic: [],
};

export default function statistics(state: StatisticsState = initialState, action: Action): StatisticsState {
  if (action.type === 'API_CALL_SUCCESS' && action.name === 'GET_STATISTICS') {
    return action.result;
  } else {
    return state;
  }
}
