import * as React from 'react';
import { connect } from 'react-redux';
import { Location, History } from 'history';
import { FormattedMessage } from 'react-intl';
import MuiButton from '@material-ui/core/Button';
import { destroy } from 'redux-form';
import { createSelector } from 'reselect';
import { size, values } from 'lodash-es';

import ModalDialog from '../../shared/modalDialog/ModalDialog';
import CockpitApiErrors from '../navigation/CockpitApiStatus';
import { terminateOperation, WebDispatch, deleteOperation, sendCode } from '../actions';
import { CrowdTaskOperation, CrowdTaskCallOutStatus } from '../../api/types';
import { WebReduxState } from '../reducers';
import Button from '../../api/components/Button';
import Buttons from '../navigation/Buttons';
import styles from './OperationDetailsInfo.module.css';
import OperationDetailsIncidentInfo from './OperationDetailsIncidentInfo';

type State = { showTerminateDialog: boolean };

type OwnProps = {
  history: History;
  location: Location;
  dispatch: WebDispatch;
};

type StoreProps = {
  operation: CrowdTaskOperation;
  hasCallOuts: boolean;
  callOutsSynced: boolean;
  hasOpenCallOuts: boolean;
  numberOfReleasedCallOuts: number;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const pathname: string = ownProps.location.pathname;
  const operationId = pathname.split('/')[2];

  if (!operationId) {
    throw new Error('OperationDetailsInfo: operationId is not set. This should not happen.');
  }

  return {
    operation: state.operations.byId[operationId],
    hasCallOuts: hasCallOutsSelector(state),
    hasOpenCallOuts: hasOpenCallOutsSelector(state),
    callOutsSynced: state.callOuts.synced,
    numberOfReleasedCallOuts: state.operationStats[operationId]
      ? state.operationStats[operationId].numberOfReleasedCallOuts
      : 0,
  };
}

const hasCallOutsSelector: (state: WebReduxState) => boolean = createSelector(
  state => state.callOuts.callOuts.byId,
  callOutsById => {
    return size(callOutsById) > 0;
  }
);

const closedStates: CrowdTaskCallOutStatus[] = ['CLOSED', 'DELETED'];
const hasOpenCallOutsSelector: (state: WebReduxState) => boolean = createSelector(
  state => state.callOuts.callOuts.byId,
  callOutsById => {
    return values(callOutsById).some(c => !closedStates.includes(c.status));
  }
);

const apiCallActions = ['TERMINATE_OPERATION', 'SEND_CODE'];

class OperationDetailsInfo extends React.Component<Props, State> {
  dialogActions: React.ReactNode[];

  constructor(props) {
    super(props);

    this.state = {
      showTerminateDialog: false,
    };

    this.dialogActions = [
      <MuiButton variant="text" key="cancel" onClick={this.handleTerminateCancelClicked}>
        <FormattedMessage id="TerminateOperationDialog.cancel" defaultMessage="Cancel" />
      </MuiButton>,
      <MuiButton variant="text" key="ok" color="primary" onClick={this.handleTerminateOkClicked}>
        <FormattedMessage id="TerminateOperationDialog.ok" defaultMessage="OK" />
      </MuiButton>,
    ];
  }

  render() {
    const { showTerminateDialog } = this.state;
    const { operation, callOutsSynced, hasOpenCallOuts, numberOfReleasedCallOuts } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.horizontalContainer}>
          <OperationInfo operation={operation} />
          <OperationContactDetails operation={operation} />
          <OperationButtons
            onEditClicked={this.handleEditClicked}
            onTerminateClicked={this.handleTerminateClicked}
            terminateDisabled={!callOutsSynced || hasOpenCallOuts}
            onSendCodeClicked={this.handleSendCodeClicked}
            sendCodeDisabled={
              numberOfReleasedCallOuts === 0 ||
              !(operation.contactName && operation.contactPhoneNumber) ||
              operation.status === 'CLOSED'
            }
          />
        </div>
        <OperationDetailsIncidentInfo incidentId={operation.incidentId} />
        <TerminateOperationDialog open={showTerminateDialog} actions={this.dialogActions} />
        <CockpitApiErrors actions={apiCallActions} />
      </div>
    );
  }

  handleSendCodeClicked = () => {
    const {
      operation: { operationId },
      dispatch,
    } = this.props;
    dispatch(sendCode(operationId));
  };

  handleEditClicked = () => {
    const {
      history,
      operation: { operationId },
      dispatch,
    } = this.props;
    dispatch(destroy('newOperation') as any);
    history.push(`/operations/${operationId}/edit`);
  };

  handleTerminateClicked = () => {
    this.setState({ showTerminateDialog: true });
  };

  handleTerminateOkClicked = () => {
    const {
      operation: { operationId, version },
      dispatch,
      hasCallOuts,
    } = this.props;
    if (hasCallOuts) {
      dispatch(terminateOperation(operationId, version));
    } else {
      dispatch(deleteOperation(operationId, version));
    }
    this.setState({ showTerminateDialog: false });
  };

  handleTerminateCancelClicked = () => {
    this.setState({ showTerminateDialog: false });
  };
}

export default connect(mapStateToProps)(OperationDetailsInfo);

function OperationInfo({ operation }) {
  const { meetingPoint, additionalInformation } = operation;

  return (
    <div className={styles.info}>
      <Field
        title={<FormattedMessage id="OperationDetailsInfo.meetingPoint" defaultMessage="Meeting Point" />}
        value={meetingPoint}
      />
      <Field
        title={
          <FormattedMessage id="OperationDetailsInfo.additionalInformation" defaultMessage="Additional Information" />
        }
        value={additionalInformation}
      />
    </div>
  );
}

function OperationContactDetails({ operation }) {
  const { contactName, contactPhoneNumber } = operation;

  return (
    <div className={styles.contact}>
      <Field
        title={<FormattedMessage id="OperationDetailsInfo.contactPerson" defaultMessage="Contact Person" />}
        value={contactName}
      />
      <Field
        title={<FormattedMessage id="OperationDetailsInfo.phoneNumber" defaultMessage="Phone Number" />}
        value={contactPhoneNumber}
      />
    </div>
  );
}

function Field({ title, value }) {
  return (
    <div className={styles.field}>
      <div className={styles.title}>{title}</div>
      <p>{value}</p>
    </div>
  );
}

function OperationButtons({
  onEditClicked,
  onTerminateClicked,
  terminateDisabled,
  onSendCodeClicked,
  sendCodeDisabled,
}) {
  return (
    <Buttons>
      <Button onClick={onEditClicked}>
        <FormattedMessage id="OperationDetailsInfo.edit" defaultMessage="Edit" />
      </Button>
      <Button onClick={onTerminateClicked} disabled={terminateDisabled}>
        <FormattedMessage id="OperationDetailsInfo.terminate" defaultMessage="Terminate" />
      </Button>
      <Button onClick={onSendCodeClicked} disabled={sendCodeDisabled}>
        <FormattedMessage id="OperationDetailsInfo.resend" defaultMessage="Resend SMS" />
      </Button>
    </Buttons>
  );
}

function TerminateOperationDialog({ open, actions }) {
  return (
    <ModalDialog
      open={open}
      actions={actions}
      title={<FormattedMessage id="TerminateOperationDialog.title" defaultMessage="Terminate" />}>
      <FormattedMessage
        id="TerminateOperationDialog.message"
        defaultMessage="Do you really want to terminate the operation?"
      />
    </ModalDialog>
  );
}
