import { uniq } from 'lodash-es';

import { FetchDataFilterCriteriaResult } from '../actions/dataFilter';
import { Action } from '../actions';

// Removed covariance annotation from lines, locations, regions and incidentTypes to prevent flow
// from assuming that we change the filterType in SET_DATAFILTER_CRITERIA
export type DataFilterState = {
  readonly availableOptions: {
    regions: string[];
    lines: string[];
    incidentTypes: string[];
  };
  readonly criteria: {
    regions: string[];
    lines: string[];
    incidentTypes: string[];
  };
};

const initialState: DataFilterState = {
  availableOptions: {
    regions: [],
    lines: [],
    incidentTypes: [],
  },
  criteria: {
    regions: [],
    lines: [],
    incidentTypes: [],
  },
};

export default function dataFilter(state: DataFilterState = initialState, action: Action): DataFilterState {
  switch (action.type) {
    case 'SET_DATAFILTER_CRITERIA': {
      return { ...state, criteria: { ...state.criteria, [action.dataFilterType]: action.criteria } };
    }
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'FETCH_DATAFILTER_CRITERIA': {
          const result: FetchDataFilterCriteriaResult = action.result;

          return {
            ...state,
            availableOptions: {
              lines: uniq([...state.availableOptions.lines, ...result.availableOptions.lines]),
              regions: uniq([...state.availableOptions.regions, ...result.availableOptions.regions]),
              incidentTypes: uniq([...state.availableOptions.incidentTypes, ...result.availableOptions.incidentTypes]),
            },
            criteria: {
              lines: uniq([...state.criteria.lines, ...result.criteria.lines]),
              regions: uniq([...state.criteria.regions, ...result.criteria.regions]),
              incidentTypes: uniq([...state.criteria.incidentTypes, ...result.criteria.incidentTypes]),
            },
          };
        }
        default:
          return state;
      }

    // DataFilter for Device
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}
