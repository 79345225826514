import { DeviceType } from '../../api/types';
import { appSelector } from '../../api/reducers/appConfig';
import hasRoleSelector from '../../api/selectors/hasRole';
import { InformRoles } from '../../api/roles';
import { ReduxState } from '../reducers';

export default function isWebAppWithoutInformRights(state: ReduxState): boolean {
  const app = appSelector(state);
  const hasRole = hasRoleSelector(state);
  const { deviceType } = state.deviceInfo;

  return app === 'inform' && deviceType === ('WEB' as DeviceType) && !hasRole(InformRoles.USER);
}
