import { getKeycloak } from '../keycloakWeb';
import { setTenantKey } from './localStorage';

export default function handleLogout() {
  const keycloak = getKeycloak();
  if (keycloak) {
    setTenantKey(null);
    keycloak.logout();
  }
}
