import { createSelector } from 'reselect';

import { ReduxState } from '../reducers';
import sortedIncidentsSelector from './sortedIncidents';
import filterWithSetsSelector from './filterWithSets';

export type FilterCriterion = {
  name: string;
  isSelected: boolean;
};

export type FilterCriteriaResult = {
  lines: FilterCriterion[];
  locations: FilterCriterion[];
  regions: FilterCriterion[];
  incidentTypes: FilterCriterion[];
};

export type FilterCriteriaType = keyof FilterCriteriaResult;

const filterCriteriaSelector: (state: ReduxState) => FilterCriteriaResult = createSelector(
  state => sortedIncidentsSelector(state),
  state => filterWithSetsSelector(state),
  (incidents, filter) => {
    const lines: Set<string> = new Set();
    const locations: Set<string> = new Set();
    const regions: Set<string> = new Set();
    const incidentTypes: Set<string> = new Set();

    incidents.forEach(incident => {
      const d = incident.currentData;

      d.lines.forEach(l => lines.add(l));
      d.regions.forEach(r => regions.add(r));

      locations.add(d.locationFrom);

      // locationTo is an optional field.
      if (d.locationTo) {
        locations.add(d.locationTo);
      }

      // incidentType is an optional field.
      if (d.incidentType) {
        incidentTypes.add(d.incidentType);
      }
    });

    return {
      lines: getSortedCriteriaArray(lines, filter.lines),
      locations: getSortedCriteriaArray(locations, filter.locations),
      regions: getSortedCriteriaArray(regions, filter.regions),
      incidentTypes: getSortedCriteriaArray(incidentTypes, filter.incidentTypes),
    };
  }
);

export default filterCriteriaSelector;

function getSortedCriteriaArray(criteriaSet: Set<string>, filterSet: Set<string>): FilterCriterion[] {
  // Add existing filter data to the set
  filterSet.forEach(c => criteriaSet.add(c));

  return Array.from(criteriaSet)
    .sort()
    .map(c => ({ name: c, isSelected: filterSet.has(c) }));
}
