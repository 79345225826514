import { WebNavAction } from '../actions';

export type WizardModal = 'user' | 'closed' | 'tenant';
export type Wizard = {
  readonly modal: WizardModal;
  readonly page: number;
  readonly phoneNumber: string;
};

export type WebNavState = {
  readonly copyrightModal: boolean;
  readonly filterBarOpen: boolean;
  readonly supportModal: boolean;
  readonly settingsModal: boolean;
  readonly wizard: Wizard;
};

const initialState: WebNavState = {
  copyrightModal: false,
  filterBarOpen: false,
  supportModal: false,
  settingsModal: false,
  wizard: {
    modal: 'closed',
    page: 0,
    phoneNumber: '',
  },
};

export default function webNav(state: WebNavState = initialState, action: WebNavAction): WebNavState {
  switch (action.type) {
    case 'SET_COPYRIGHT_MODAL_OPEN':
      return { ...state, copyrightModal: action.open };
    case 'SET_SETTINGS_MODAL_OPEN':
      return { ...state, settingsModal: action.open };
    case 'SET_SUPPORT_MODAL_OPEN':
      return { ...state, supportModal: action.open };
    case 'SET_WIZARD_MODAL':
      return { ...state, wizard: { ...state.wizard, modal: action.modal, page: 0 } };
    case 'SET_WIZARD_PAGE':
      return { ...state, wizard: { ...state.wizard, page: action.page } };
    case 'TOGGLE_FILTER_BAR':
      return { ...state, filterBarOpen: !state.filterBarOpen };
    case 'CHANGE_FEEDBACK_PHONE_NUMBER':
      return { ...state, wizard: { ...state.wizard, phoneNumber: action.phoneNumber } };
    default:
      return state;
  }
}
