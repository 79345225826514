import { select } from 'redux-saga/effects';
import { values } from 'lodash-es';

import log from '../../shared/logging';
import wrapApiCallActions, { wrapApiCallPromise } from '../../api/wrapApiCallActions';
import { getCrowdTaskIncidents, getLocations, getOperations } from '../../api/crowdTask';
import { InformRoles, CrowdTaskRoles } from '../../api/roles';
import { getMasterData } from '../../api/crowdTask/masterData';
import hasRoleSelector from '../../api/selectors/hasRole';
import { Incident } from '../../api/incidentsTypes';
import fetchIncidentsSaga, { buildIncidentsQueryRequest } from '../../common/sagas/fetchIncidents';
import { IncidentsState, IncidentsMap } from '../../common/reducers/incidents';
import { performSyncChatMessages } from '../../common/sagas/chat';
import { OperationChatThread, getOperationChatThreads } from '../../common/sagas/platformSagas';
import { WebReduxState } from '../reducers';

export default function* performInitialFetch(): Generator<any, any, any> {
  const hasRole: (role: string) => boolean = yield select(hasRoleSelector);

  let chatThreadIds: string[] = [];

  if (hasRole(InformRoles.USER)) {
    log.info('Initial fetch for InformRoles.USER');
    yield* fetchIncidentsSaga();

    const incidentsById: IncidentsMap = yield select((state: WebReduxState) => state.inform.incidents.byId);
    const incidentChatThreadIds = values(incidentsById).map((incident: Incident) => incident.threadId);

    chatThreadIds = chatThreadIds.concat(incidentChatThreadIds);
  }

  if (hasRole(CrowdTaskRoles.COCKPIT)) {
    log.info('Initial fetch for CrowdTaskRoles.COCKPIT');
    yield* wrapApiCallPromise('GET_MASTER_DATA', getMasterData, true);
    yield* wrapApiCallActions('GET_LOCATIONS', getLocations(), true);
    yield* wrapApiCallActions('GET_OPERATIONS', getOperations(), true);

    const incidentsState: IncidentsState = yield select((state: WebReduxState) => state.crowdTaskIncidents);
    const request = buildIncidentsQueryRequest(incidentsState);

    yield* wrapApiCallActions('GET_CROWD_TASK_INCIDENTS', getCrowdTaskIncidents(request), true);

    const operationChatThreads: OperationChatThread[] = yield* getOperationChatThreads();
    const operationChatThreadIds = operationChatThreads.map(t => t.chatThreadId);

    chatThreadIds = chatThreadIds.concat(operationChatThreadIds);
  }

  if (chatThreadIds.length > 0) {
    yield* performSyncChatMessages(chatThreadIds);
  }
}
