import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, match } from 'react-router';
import { FormattedMessage } from 'react-intl';

import ApiCallStatusHandler from '../../api/components/ApiCallStatusHandler';
import { WebDispatchProps, getCallOuts, getOperationChatMessages } from '../actions';
import { CrowdTaskOperation } from '../../api/types';
import TabBar from '../navigation/TabBarNew';
import { renderErrorMessage } from '../navigation/CockpitApiStatus';
import Tab from '../navigation/Tab';
import { WebReduxState } from '../reducers';
import NewCallOutScreen from '../callOuts/NewCallOutScreen';
import CallOutRoutes from '../callOuts/CallOutRoutes';
import OperationDetailsCallOuts from '../callOuts/OperationDetailsCallOuts';
import styles from './OperationDetails.module.css';
import OperationDetailsInfo from './OperationDetailsInfo';
import OperationDetailsChat from './OperationDetailsChat';

type OwnProps = {
  match: match<{ operationId?: string }>;
};

type StoreProps = {
  operation: CrowdTaskOperation | null | undefined;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { operationId } = ownProps.match.params;
  const operation = operationId ? state.operations.byId[operationId] : null;

  return { operation };
}

class OperationDetails extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, operation } = this.props;
    if (operation) {
      dispatch(getCallOuts(operation.operationId));
      dispatch(getOperationChatMessages(operation.operationId));
    }
  }

  componentWillUpdate(nextProps: Props) {
    const { dispatch, operation } = nextProps;
    if (operation && (!this.props.operation || operation.operationId !== this.props.operation.operationId)) {
      dispatch(getCallOuts(operation.operationId));
      dispatch(getOperationChatMessages(operation.operationId));
    }
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { operation, match } = this.props;
    const { path, url } = match;

    if (!operation) {
      return <Redirect to="/operations" />;
    }

    return (
      <div className={styles.container}>
        <ApiCallStatusHandler apiCallNames={['GET_CALL_OUTS']} renderErrorMessage={renderErrorMessage}>
          <Header operation={operation} />
          <TabBar>
            <Tab label={<FormattedMessage id="OperationDetails.info" defaultMessage="Info" />} path={`${url}/info`} />
            <Tab
              label={<FormattedMessage id="OperationDetails.callOuts" defaultMessage="Call Outs" />}
              path={`${url}/callOuts`}
            />
            <Tab label={<FormattedMessage id="OperationDetails.chat" defaultMessage="Chat" />} path={`${url}/chat`} />
          </TabBar>
          <Switch>
            <Route path={`${path}/info`} component={OperationDetailsInfo} />
            <Route path={`${path}/callOuts/new`} component={NewCallOutScreen} />
            <Route path={`${path}/callOuts/:callOutId`} component={CallOutRoutes} />
            <Route path={`${path}/callOuts`} component={OperationDetailsCallOuts} />
            <Route path={`${path}/chat`} component={OperationDetailsChat} />
            <Redirect to={`${url}/info`} />
          </Switch>
        </ApiCallStatusHandler>
      </div>
    );
  }
}

export default connect(mapStateToProps)(OperationDetails);

function Header({ operation }) {
  const { location, contactName, contactPhoneNumber } = operation;

  return (
    <div className={styles.header}>
      <div className={styles.title}>{location}</div>
      {contactName && (
        <div className={styles.contactPerson}>
          {contactName}
          <br />
          {contactPhoneNumber && <a href={`tel:${contactPhoneNumber}`}>{contactPhoneNumber}</a>}
        </div>
      )}
    </div>
  );
}
