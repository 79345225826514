import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export function WizardDescription() {
  return (
    <FormattedMessage
      id="DataFilter.explanation"
      defaultMessage={
        'The server-side filters can be set on this page.\nYou will only receive data applicable to the data filter set on this page.'
      }
    />
  );
}

export function TenantWizardDescription() {
  return (
    <FormattedMessage
      id="DataFilter.tenantExplanation"
      defaultMessage={
        'You can define the server-side filters for a tenant here.\nUsers will only receive data applicable to these filters.'
      }
    />
  );
}
