import { takeEvery, select, put } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { isEqual } from 'lodash-es';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';

import { PostCrowdTaskCreateOperationRequest, PostCrowdTaskUpdateOperationRequest } from '../../api/types';
import { apiCallError, WebAction } from '../actions';
import {
  closeCallOut,
  deleteCallOut,
  confirmParticipations,
  rejectParticipations,
  terminateOpenParticipations,
  createOperation,
  updateOperation,
  createFollowupCallOut,
  createTaskAndCallOut,
  getCallOuts,
  getParticipations,
  releaseCallOut,
  releaseAllCallOuts,
  updateCallOut,
  updateTask,
  terminateOperation,
  deleteOperation,
  sendCode,
  CallOutKey,
} from '../../api/crowdTask';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { getDateFromTimeString } from '../utils/dateTime';
import { WebReduxState } from '../reducers';

export default function* crowdTaskApiCallsSaga(): Generator<any, any, any> {
  yield takeEvery(
    [
      'CLOSE_CALL_OUT',
      'DELETE_CALL_OUT',
      'POST_OPERATION',
      'UPDATE_OPERATION',
      'TERMINATE_OPERATION',
      'DELETE_OPERATION',
      'POST_CALL_OUT',
      'POST_FOLLOWUP_CALL_OUT',
      'RELEASE_CALL_OUT',
      'RELEASE_ALL_CALL_OUTS',
      'GET_CALL_OUTS',
      'GET_PARTICIPATIONS',
      'CONFIRM_PARTICIPATIONS',
      'REJECT_PARTICIPATIONS',
      'TERMINATE_PARTICIPATIONS',
      'UPDATE_CALL_OUT',
      'SEND_CODE',
    ],
    performCrowdTaskApiCall
  );
}

function* performCrowdTaskApiCall(action: WebAction): Generator<any, any, any> {
  switch (action.type) {
    case 'POST_OPERATION':
      yield* performPostOperation();
      break;
    case 'UPDATE_OPERATION':
      yield* performUpdateOperation(action.operationId, action.version);
      break;
    case 'TERMINATE_OPERATION':
      yield* wrapApiCallActions('TERMINATE_OPERATION', terminateOperation(action.operationId, action.version));
      break;
    case 'DELETE_OPERATION':
      yield* wrapApiCallActions('DELETE_OPERATION', deleteOperation(action.operationId, action.version));
      break;
    case 'POST_CALL_OUT':
      yield* performPostCallOut(action.operationId);
      break;
    case 'POST_FOLLOWUP_CALL_OUT':
      yield* performPostFollowupCallOut(action.callOutKey);
      break;
    case 'UPDATE_CALL_OUT':
      yield* performUpdateCallOut(action.callOutKey, action.version, action.taskVersion);
      break;
    case 'RELEASE_CALL_OUT':
      yield* wrapApiCallActions('RELEASE_CALL_OUT', releaseCallOut(action.callOutKey));
      break;
    case 'RELEASE_ALL_CALL_OUTS':
      yield* wrapApiCallActions('RELEASE_ALL_CALL_OUTS', releaseAllCallOuts());
      break;
    case 'GET_CALL_OUTS':
      yield* wrapApiCallActions('GET_CALL_OUTS', getCallOuts(action.operationId));
      break;
    case 'GET_PARTICIPATIONS':
      yield* wrapApiCallActions('GET_PARTICIPATIONS', getParticipations(action.callOutKey));
      break;
    case 'CONFIRM_PARTICIPATIONS':
      yield* wrapApiCallActions('CONFIRM_PARTICIPATIONS', confirmParticipations(action.callOutKey, action.request));
      break;
    case 'REJECT_PARTICIPATIONS':
      yield* wrapApiCallActions('REJECT_PARTICIPATIONS', rejectParticipations(action.callOutKey, action.request));
      break;
    case 'CLOSE_CALL_OUT':
      yield* wrapApiCallActions('CLOSE_CALL_OUT', closeCallOut(action.callOutKey, action.version));
      break;
    case 'DELETE_CALL_OUT':
      yield* wrapApiCallActions('DELETE_CALL_OUT', deleteCallOut(action.callOutKey, action.version));
      break;
    case 'TERMINATE_PARTICIPATIONS':
      yield* wrapApiCallActions('TERMINATE_PARTICIPATIONS', terminateOpenParticipations(action.callOutKey));
      break;
    case 'SEND_CODE':
      yield* wrapApiCallActions('SEND_CODE', sendCode(action.operationId));
      break;
    default:
      break;
  }
}

function* performPostOperation() {
  const formValues = yield select(getFormValues('newOperation'));
  const { additionalInformation, contactName, contactPhoneNumber, meetingPoint } = formValues;

  const newOperationData = yield select((state: WebReduxState) => state.ui.newOperation);
  const { selectedLocationCode, selectedIncidentId } = newOperationData;

  const request: PostCrowdTaskCreateOperationRequest = {
    additionalInformation: additionalInformation || '',
    contactName,
    contactPhoneNumber,
    incidentId: selectedIncidentId,
    locationCode: selectedLocationCode,
    meetingPoint,
  };

  yield* wrapApiCallActions('POST_OPERATION', createOperation(request));
}

function* performUpdateOperation(operationId: string, version: number) {
  const formValues = yield select(getFormValues('newOperation'));
  const { additionalInformation, contactName, contactPhoneNumber, meetingPoint } = formValues;

  const request: PostCrowdTaskUpdateOperationRequest = {
    additionalInformation: additionalInformation || '',
    contactName,
    contactPhoneNumber,
    meetingPoint,
  };

  yield* wrapApiCallActions('UPDATE_OPERATION', updateOperation(operationId, version, request));
}

function* performPostCallOut(operationId: string) {
  const formValues = yield select(getFormValues('editCallOut'));
  const { task, requiredTeamSize, languages, specialSkills } = formValues;

  try {
    const dates = yield* prepareCallOutDateTime();
    const { startTime, endTime } = dates;

    yield* wrapApiCallActions(
      'POST_CALL_OUT',
      createTaskAndCallOut(operationId, {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        task,
        requiredTeamSize,
        specialSkills,
        languages,
      })
    );
  } catch (error) {
    yield put(apiCallError('POST_CALL_OUT', { status: '400', message: 'Unable to parse date.' }));
  }
}

function* performPostFollowupCallOut(callOutKey: CallOutKey) {
  const formValues = yield select(getFormValues('editCallOut'));
  const { requiredTeamSize, languages, specialSkills } = formValues;

  try {
    const dates = yield* prepareCallOutDateTime();
    const { startTime, endTime } = dates;
    const { operationId, taskId } = callOutKey;

    yield* wrapApiCallActions(
      'POST_FOLLOWUP_CALL_OUT',
      createFollowupCallOut(operationId, taskId, {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        requiredTeamSize,
        specialSkills,
        languages,
      })
    );
  } catch (error) {
    yield put(apiCallError('POST_FOLLOWUP_CALL_OUT', { status: '400', message: 'Unable to parse date.' }));
  }
}

function* prepareCallOutDateTime() {
  const formValues = yield select(getFormValues('editCallOut'));
  const { baseDate, startTime, endTime } = formValues;

  const newStartTime = getDateFromTimeString(baseDate, startTime);
  let newEndTime = getDateFromTimeString(baseDate, endTime);

  if (!newStartTime || !newEndTime) {
    throw Error('Could not create dateTime.');
  }

  if (isBefore(newEndTime, newStartTime)) {
    newEndTime = addDays(newEndTime, 1);
  }

  return { endTime: newEndTime, startTime: newStartTime };
}

function* performUpdateCallOut(callOutKey: CallOutKey, version: number, taskVersion: number) {
  const formValues = yield select(getFormValues('editCallOut'));
  const { task, requiredTeamSize, languages, specialSkills } = formValues;
  const original = yield select((state: WebReduxState) => state.callOuts.callOuts.byId[callOutKey.callOutId]);
  const { operationId, taskId } = callOutKey;

  let startTime: Date | null = null;
  let endTime: Date | null = null;

  try {
    const dates = yield* prepareCallOutDateTime();
    startTime = dates.startTime;
    endTime = dates.endTime;
  } catch (error) {
    yield put(apiCallError('POST_CALL_OUT', { status: '400', message: 'Unable to parse date.' }));
    return;
  }

  try {
    if (
      original.startTime !== startTime ||
      original.endTime !== endTime ||
      original.requiredTeamSize !== requiredTeamSize ||
      isEqual(original.languages, languages) ||
      isEqual(original.specialSkills, specialSkills)
    ) {
      yield* wrapApiCallActions(
        'UPDATE_CALL_OUT',
        updateCallOut(callOutKey, version, {
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          requiredTeamSize,
          specialSkills,
          languages,
        }),
        true
      );
    }

    if (original.task !== task) {
      yield* wrapApiCallActions(
        'UPDATE_TASK',
        updateTask({ operationId, taskId }, taskVersion, {
          task,
        })
      );
    }
  } catch (error) {
    // PASS, catches errors thrown by call out update so update task is not executed
  }
}
