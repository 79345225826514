import * as React from 'react';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { History, Location, Action as HistoryAction } from 'history';
import { Redirect, Prompt } from 'react-router-dom';
import { isInvalid, isDirty } from 'redux-form';

import { CrowdTaskOperation } from '../../api/types';
import { intlSelector } from '../../api/selectors/i18n';
import { NewOperationState } from '../reducers/newOperation';
import { postOperation, updateOperation, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import CockpitApiErrors from '../navigation/CockpitApiStatus';
import Button from '../../api/components/Button';
import EditOperationForm from './EditOperationForm';
import styles from './EditOperation.module.css';

type OwnProps = {
  operationId: string;
  history: History;
};

type StoreProps = {
  newOperationState: NewOperationState;
  operation: CrowdTaskOperation | null | undefined;
  formInvalid: boolean;
  formDirty: boolean;
  intl: IntlShape;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState, ownProps: OwnProps): StoreProps {
  const { operationId } = ownProps;

  return {
    newOperationState: state.ui.newOperation,
    operation: state.operations.byId[operationId],
    formInvalid: isInvalid('newOperation')(state),
    formDirty: isDirty('newOperation')(state),
    intl: intlSelector(state),
  };
}

const apiCallActions = ['UPDATE_OPERATION'];

class EditOperation extends React.Component<Props> {
  render() {
    const { operation, formInvalid, formDirty } = this.props;

    if (!operation) {
      return <Redirect to="/operations" />;
    }

    return (
      <div className={styles.container}>
        <Prompt when={formDirty} message={this.getCancelPrompt} />
        <div className={styles.inner}>
          <Header />
          <div className={styles.form}>
            <EditOperationForm operationId={operation.operationId} />
          </div>
          <ButtonsRow
            onSaveClicked={this.handleSave}
            onCancelClicked={this.handleCancel}
            saveDisabled={formInvalid || !formDirty}
          />
          <CockpitApiErrors actions={apiCallActions} onSuccess={this.handleSuccess} />
        </div>
      </div>
    );
  }

  getCancelPrompt = (_location: Location, _action: HistoryAction) => {
    const { intl } = this.props;
    const msg = defineMessages({
      cancelPrompt: {
        id: 'EditOperation.cancelPrompt',
        defaultMessage: 'You have unsaved changes. Are you sure you want to cancel editing the operation?',
      },
    });

    return intl.formatMessage(msg.cancelPrompt);
  };

  handleSave = () => {
    const { operation, dispatch } = this.props;

    if (operation) {
      const { operationId, version } = operation;
      dispatch(updateOperation(operationId, version));
    } else {
      dispatch(postOperation());
    }
  };

  handleSuccess = () => {
    const { history, operationId } = this.props;

    history.replace(`/operations/${operationId}`);
  };

  handleCancel = () => {
    this.props.history.replace('/operations');
  };
}

function Header() {
  return (
    <div>
      <h3>
        <FormattedMessage id="EditOperation.editOperation" defaultMessage="Edit Operation" />
      </h3>
    </div>
  );
}

function ButtonsRow({ onSaveClicked, onCancelClicked, saveDisabled }) {
  return (
    <div className={styles.buttonRow}>
      <Button onClick={onCancelClicked}>
        <FormattedMessage id="EditOperation.cancel" defaultMessage="Cancel" />
      </Button>
      <Button disabled={saveDisabled} onClick={onSaveClicked}>
        <FormattedMessage id="EditOperation.save" defaultMessage="Save" />
      </Button>
    </div>
  );
}

export default connect(mapStateToProps)(EditOperation);
