import { ApiAction, PostChatMessageResult } from '../actions';
import { ChatMessage } from '../../api/types';

import { NormalizedCollection, emptyCollection, addValues, removeIds } from '../../shared/normalizedCollection';

export type ChatThreadInfo = {
  currentMessage: string;
  lastReadMessageId: string | null | undefined;
  pendingMessages: NormalizedCollection<ChatMessage>;
};

export type ChatPendingState = {
  [threadId: string]: ChatThreadInfo;
};

const initialState = {};

const idSelector = (m: ChatMessage) => m.messageId;

export default function chatPending(state: ChatPendingState = initialState, action: ApiAction): ChatPendingState {
  switch (action.type) {
    case 'EDIT_CHAT_TEXT': {
      const threadInfo = getThreadInfo(state, action.threadId);
      return {
        ...state,
        [action.threadId]: { ...threadInfo, currentMessage: action.value },
      };
    }
    case 'SET_CHAT_THREAD_READ': {
      const threadInfo = getThreadInfo(state, action.threadId);
      return {
        ...state,
        [action.threadId]: { ...threadInfo, lastReadMessageId: action.lastReadMessageId },
      };
    }
    case 'SUBMIT_INCIDENT_CHAT_MESSAGES':
    case 'SUBMIT_OPERATION_CHAT_MESSAGES':
    case 'SUBMIT_FORUM_CHAT_MESSAGES': {
      const threadInfo = getThreadInfo(state, action.threadId);
      return {
        ...state,
        [action.threadId]: {
          ...threadInfo,
          currentMessage: '',
          pendingMessages: addValues(threadInfo.pendingMessages, action.messages, idSelector),
        },
      };
    }
    case 'API_CALL_SUCCESS': {
      switch (action.name) {
        case 'POST_INCIDENT_CHAT_MESSAGE':
        case 'POST_OPERATION_CHAT_MESSAGE':
        case 'POST_FORUM_CHAT_MESSAGE': {
          const result: PostChatMessageResult = action.result;
          const { threadId } = result.chatMessage;
          const threadInfo = getThreadInfo(state, threadId);
          return {
            ...state,
            [threadId]: {
              ...threadInfo,
              pendingMessages: removeIds(threadInfo.pendingMessages, [result.pendingMessageId]),
            },
          };
        }
        default:
          return state;
      }
    }
    case 'CLEANUP':
      return initialState;
    default:
      return state;
  }
}

function getThreadInfo(state: ChatPendingState, threadId: string) {
  const threadInfo = state[threadId];
  if (threadInfo) {
    return threadInfo;
  }
  return { currentMessage: '', lastReadMessageId: null, pendingMessages: emptyCollection };
}
