import { CrowdTaskStaffMemberFilter, CallOutArea } from '../../api/types';
import { DataFilterCallOutAreasType } from '../../api/reducers/config';

export type GetDataFilterCallOutAreasResult = {
  availableCallOutAreas: CallOutArea[];
  criteria: CrowdTaskStaffMemberFilter;
};

export type DataFilterCallOutAreasAction =
  | { type: 'SET_DATAFILTER_CRITERIA_CALLOUT_AREAS'; dataFilterType: DataFilterCallOutAreasType; criteria: string[] }
  | { type: 'FETCH_DATAFILTER_CALLOUT_AREAS' }
  | { type: 'POST_DATAFILTER_CALLOUT_AREAS' };

export function setDataFilterCriteriaCallOutAreas(
  dataFilterType: DataFilterCallOutAreasType,
  criteria: string[]
): DataFilterCallOutAreasAction {
  return { type: 'SET_DATAFILTER_CRITERIA_CALLOUT_AREAS', dataFilterType, criteria };
}

export function fetchDataFilterCallOutAreas(): DataFilterCallOutAreasAction {
  return { type: 'FETCH_DATAFILTER_CALLOUT_AREAS' };
}

export function postDataFilterCallOutAreas(): DataFilterCallOutAreasAction {
  return { type: 'POST_DATAFILTER_CALLOUT_AREAS' };
}
