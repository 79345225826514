import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { WebDispatch, setDataFilterCriteria, setTenantDataFilterCriteria } from '../actions';
import { WebReduxState } from '../reducers';
import WizardFilterPage from './WizardFilterPage';
import { WizardDescription, TenantWizardDescription } from './WizardDescription';

const Header = () => <FormattedMessage id="DataFilter.region" defaultMessage="Region" />;

const optionsSelector = (state: WebReduxState) => state.inform.dataFilter.availableOptions.regions;
const selectedOptionsSelector = (state: WebReduxState) => state.inform.dataFilter.criteria.regions;
const handleSelectionChange = (dispatch: WebDispatch, options: string[]) => {
  dispatch(setDataFilterCriteria('regions', options));
};

const optionsSelectorTenantMode = (state: WebReduxState) => state.admin.tenants.availableOptions.regions;
const selectedOptionsSelectorTenantMode = (state: WebReduxState) => state.admin.tenants.tenantFilter.regions;
const handleSelectionChangeTenantMode = (dispatch: WebDispatch, options: string[]) => {
  dispatch(setTenantDataFilterCriteria('regions', options));
};

type Props = {
  tenantMode?: boolean;
};

export default function WizardRegionFilterPage({ tenantMode }: Props) {
  return (
    <WizardFilterPage
      HeaderComponent={Header}
      DescriptionComponent={tenantMode ? TenantWizardDescription : WizardDescription}
      optionsSelector={tenantMode ? optionsSelectorTenantMode : optionsSelector}
      selectedOptionsSelector={tenantMode ? selectedOptionsSelectorTenantMode : selectedOptionsSelector}
      onSelectionChanged={tenantMode ? handleSelectionChangeTenantMode : handleSelectionChange}
    />
  );
}
