import { PutDeviceFilterRequest } from '../types';
import apiCall from '../apiCall';

// Returns a GetDeviceFilterResponse.
export function* getTenantDataFilter(tenantId: string): Generator<any, any, any> {
  return yield* apiCall(`admin/tenant/${tenantId}/serverFilter`);
}

export function* updateTenantDataFilter(tenantId: string, request: PutDeviceFilterRequest): Generator<any, any, any> {
  return yield* apiCall(`admin/tenant/${tenantId}/serverFilter`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}
