import * as React from 'react';
import { Redirect, Route, match } from 'react-router';
import { History, Location } from 'history';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { values, difference } from 'lodash-es';
import CircularProgress from '@material-ui/core/CircularProgress';
import { destroy } from 'redux-form';

import MuiButton from '@material-ui/core/Button';
import { createSelector } from 'reselect';
import ModalDialog from '../../shared/modalDialog/ModalDialog';
import { ApiCallStatus, apiCallFinishedSuccessfully } from '../../shared/apiCalls/status';
import { getApiCallStatus } from '../../api/reducers/api';
import ApiCallStatusHandler from '../../api/components/ApiCallStatusHandler';
import { CrowdTaskOperation, CrowdTaskOperationStats } from '../../api/types';
import { renderErrorMessage } from '../navigation/CockpitApiStatus';
import { WebDispatchProps, releaseAllCallOuts } from '../actions';
import { WebReduxState } from '../reducers';
import sortedOperationIdsSelector from '../selectors/sortedOperationIds';
import unreadThreadIdsSelector from '../selectors/unreadThreadIds';
import Button from '../../api/components/Button';
import Buttons from '../navigation/Buttons';
import NoOperations from './NoOperations';
import OperationList from './OperationList';
import OperationDetails from './OperationDetails';
import styles from './Operations.module.css';

type OwnProps = {
  history: History;
  location: Location;
  match: match<{ operationId?: string }>;
};

type StoreProps = {
  operationIds: string[];
  operationsById: {
    [id: string]: CrowdTaskOperation;
  };
  operationStats: {
    [id: string]: CrowdTaskOperationStats;
  };
  postOperation: ApiCallStatus | null | undefined;
  unreadThreadIds: Set<string>;
  numberOfDraftCallOuts: number;
};

type State = {
  showReleaseAllDialog: boolean;
  releaseAllErrorDialog: { show: boolean; errorMessage: string | null | undefined };
};

type Props = OwnProps & StoreProps & WebDispatchProps;

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    operationIds: sortedOperationIdsSelector(state),
    operationsById: state.operations.byId,
    operationStats: state.operationStats,
    postOperation: getApiCallStatus(state, 'POST_OPERATION'),
    unreadThreadIds: unreadThreadIdsSelector(state),
    numberOfDraftCallOuts: draftCallOutsCountSelector(state),
  };
}

const draftCallOutsCountSelector: (state: WebReduxState) => number = createSelector(
  state => state.operationStats,
  stats => {
    return values(stats).reduce((a, b) => {
      return a + b.numberOfDraftCallOuts;
    }, 0);
  }
);

const apiCallNames = ['GET_OPERATIONS'];

class Operations extends React.Component<Props, State> {
  state = {
    showReleaseAllDialog: false,
    releaseAllErrorDialog: { show: false, errorMessage: null },
  };

  componentWillReceiveProps(nextProps: Props) {
    // After creating a new operation, select it.
    if (apiCallFinishedSuccessfully(nextProps.postOperation, this.props.postOperation)) {
      const newOperationIds = difference(nextProps.operationIds, this.props.operationIds);
      if (newOperationIds.length > 0) {
        const newOperationId = newOperationIds[0];
        this.props.history.push(`/operations/${newOperationId}`);
      }
    }
  }

  render() {
    const { numberOfDraftCallOuts } = this.props;
    const { showReleaseAllDialog, releaseAllErrorDialog } = this.state;

    return (
      <ApiCallStatusHandler
        apiCallNames={apiCallNames}
        renderProgress={this.renderFullScreenProgress}
        renderErrorMessage={renderErrorMessage}>
        {this.renderContent()}
        <ReleaseAllDialog
          numberOfDraftCallOuts={numberOfDraftCallOuts}
          open={showReleaseAllDialog}
          onOkClicked={this.handleReleaseAllOkClicked}
          onCancelClicked={this.handleReleaseAllCancelClicked}
        />
        <ReleaseAllErrorDialog open={releaseAllErrorDialog.show} onOkClicked={this.handleErrorDialogOkClicked} />
        <ApiCallStatusHandler apiCallNames={['RELEASE_ALL_CALL_OUTS']} onError={this.handleError} />
      </ApiCallStatusHandler>
    );
  }

  renderContent() {
    // eslint-disable-next-line no-shadow
    const { operationIds, operationsById, operationStats, match, unreadThreadIds, numberOfDraftCallOuts } = this.props;

    if (operationIds.length === 0) {
      return <NoOperations onCreateClicked={this.handleCreateOperationClicked} />;
    }

    const { operationId } = match.params;
    if (!operationId) {
      return <Redirect to={`/operations/${operationIds[0]}`} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <OperationList
            operationIds={operationIds}
            operationsById={operationsById}
            operationStats={operationStats}
            selectedOperationId={operationId}
            onOperationSelected={this.handleOperationSelected}
            unreadThreadIds={unreadThreadIds}
          />
          <OperationButtons
            releaseAllDisabled={numberOfDraftCallOuts === 0}
            onReleaseAllClicked={this.handleReleaseAllClicked}
            onCreateOperationClicked={this.handleCreateOperationClicked}
          />
        </div>
        {/* Use Route so that router params (location, match) are passed through */}
        <Route component={OperationDetails} />
      </div>
    );
  }

  handleErrorDialogOkClicked = () => {
    this.setState({ releaseAllErrorDialog: { show: false, errorMessage: null } });
  };

  handleError = (apiCallName: string, errorMessage: string) => {
    this.setState({ releaseAllErrorDialog: { show: true, errorMessage } });
  };

  renderFullScreenProgress = () => {
    return (
      <div className={styles.centeredFullscreenSpinnerWrapper}>
        <div className={styles.centeredFullscreenSpinner}>
          <CircularProgress className={styles.spinner} size={32} thickness={3} />
          <FormattedMessage id="OperationsLoading.title" defaultMessage="Fetching Operations" />
        </div>
      </div>
    );
  };

  handleCreateOperationClicked = () => {
    const { history, dispatch } = this.props;
    dispatch(destroy('newOperation') as any);
    history.push('/operations/new');
  };

  handleReleaseAllClicked = () => {
    this.setState({ showReleaseAllDialog: true });
  };

  handleReleaseAllOkClicked = () => {
    const { dispatch } = this.props;
    dispatch(releaseAllCallOuts());
    this.setState({ showReleaseAllDialog: false });
  };

  handleReleaseAllCancelClicked = () => {
    this.setState({ showReleaseAllDialog: false });
  };

  handleOperationSelected = (operationId: string, subPath?: string) => {
    const { history } = this.props;
    let subPathToUse = subPath;

    if (!subPathToUse) {
      const {
        match: { url },
        location: { pathname },
      } = this.props;

      const rest = pathname.substring(url.length);
      subPathToUse = rest.split('/')[1];
    }

    history.push(`/operations/${operationId}/${subPathToUse}`);
  };
}

export default connect(mapStateToProps)(Operations);

function OperationButtons({ onReleaseAllClicked, onCreateOperationClicked, releaseAllDisabled }) {
  return (
    <Buttons className={styles.buttons}>
      <Button disabled={releaseAllDisabled} onClick={onReleaseAllClicked}>
        <FormattedMessage id="Operations.releaseAll" defaultMessage="Release all" />
      </Button>
      <Button onClick={onCreateOperationClicked}>
        <FormattedMessage id="Operations.newOperation" defaultMessage="New Operation" />
      </Button>
    </Buttons>
  );
}

function ReleaseAllDialog({ open, onOkClicked, onCancelClicked, numberOfDraftCallOuts }) {
  const dialogActions = [
    <MuiButton variant="text" key="cancel" onClick={onCancelClicked}>
      <FormattedMessage id="ReleaseAllDialog.cancel" defaultMessage="Cancel" />
    </MuiButton>,
    <MuiButton variant="text" key="ok" color="primary" onClick={onOkClicked}>
      <FormattedMessage id="ReleaseAllDialog.ok" defaultMessage="OK" />
    </MuiButton>,
  ];

  return (
    <ModalDialog
      title={<FormattedMessage id="ReleaseAllDialog.title" defaultMessage="Release all?" />}
      actions={dialogActions}
      open={open}>
      <FormattedMessage
        id="ReleaseAllDialog.message"
        defaultMessage="Do you really want to release {numberOfDraftCallOuts} call {numberOfDraftCallOuts, plural, one {out} other {outs}}?"
        values={{ numberOfDraftCallOuts }}
      />
    </ModalDialog>
  );
}

function ReleaseAllErrorDialog({ open, onOkClicked }) {
  return (
    <ModalDialog
      title={<FormattedMessage id="ReleaseAllErrorDialog.title" defaultMessage="Error" />}
      actions={[
        <MuiButton variant="text" key="OK" onClick={onOkClicked}>
          <FormattedMessage id="ReleaseAllErrorDialog.ok" defaultMessage="OK" />
        </MuiButton>,
      ]}
      open={open}>
      <FormattedMessage
        id="ReleaseAllErrorDialog.message"
        defaultMessage="An error occurred trying to release all call outs."
      />
    </ModalDialog>
  );
}
