import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FullScreenMessage from '../navigation/FullScreenMessage';
import Button from '../../api/components/Button';

type Props = {
  onCreateClicked: () => void;
};

export default function NoOperations({ onCreateClicked }: Props) {
  return (
    <FullScreenMessage
      icon={<i className="icon-operation" />}
      title={<FormattedMessage id="NoOperations.noOperations" defaultMessage="No Operations" />}
      message={
        <FormattedMessage id="NoOperations.hint" defaultMessage="Use the button below to create a new operation." />
      }>
      <Button onClick={onCreateClicked}>
        <FormattedMessage id="NoOperations.createOperation" defaultMessage="Create Operation" />
      </Button>
    </FullScreenMessage>
  );
}
