import { put, call } from 'redux-saga/effects';
// @ts-ignore can't get namespace import to work correctly with TS
import { Keycloak } from 'keycloak-js';

import log from '../../shared/logging';
import { setUserInfo, User } from '../actions/userInfo';
import { getKeycloak } from '.';

// Token should be valid for 30 more seconds to be on the safe side.
const MIN_TOKEN_VALIDITY = 30;

// Sets the user name from the keycloak token.
export function* authenticate(): Generator<any, any, any> {
  const keycloak: Keycloak | null | undefined = yield call(getKeycloak);
  if (!keycloak) {
    log.info('Authentication disabled');
    return;
  }

  const { preferred_username, email, given_name, family_name, realm_access } = keycloak.tokenParsed;
  const user: User = {
    username: preferred_username,
    email,
    firstName: given_name,
    lastName: family_name,
    roles: realm_access ? realm_access.roles : [],
  };

  yield put(setUserInfo(user));
}

export function* logout(): Generator<any, any, any> {
  const keycloak: Keycloak | null | undefined = yield call(getKeycloak);
  if (!keycloak) {
    log.info('Authentication disabled');
    return;
  }

  // Do *not* invoke keycloak.logout(), as this would affect
  // other browser tabs too because of single sign out.
  // Reloading the page will re-initialize Keycloak, leading to
  // the login screen only when necessary.
  window.location.reload();
}

export function* refreshToken(_force: boolean): Generator<any, any, any> {
  const keycloak: Keycloak | null | undefined = yield call(getKeycloak);
  if (!keycloak) {
    return null;
  }

  return yield call(keycloakRefreshToken, keycloak);
}

function keycloakRefreshToken(keycloak: Keycloak): Promise<string> {
  return keycloak.updateToken(MIN_TOKEN_VALIDITY).then(
    refreshed => {
      if (refreshed) {
        log.info('Token was refreshed');
      }

      return keycloak.token;
    },
    error => {
      log.error('Error refreshing token', error);

      const tokenRefreshError: any = new Error('Failed to refresh the token, or the session has expired');

      // Mark our unrecoverable auth errors with "EAUTH" like client-oauth2 does.
      tokenRefreshError.code = 'EAUTH';

      return Promise.reject(tokenRefreshError);
    }
  );
}
