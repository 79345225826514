import { createSelector } from 'reselect';

import { ReduxState } from '../reducers';
import { Incident } from '../../api/incidentsTypes';
import sortedIncidentsSelector from './sortedIncidents';
import filteredIncidentsSelector from './filteredIncidents';

export type IncidentsByViewMode = {
  all: Incident[];
  favorites: Incident[];
  filter: Incident[];
};

export type IncidentsViewMode = keyof IncidentsByViewMode;

const incidentsByViewModeSelector: (state: ReduxState) => IncidentsByViewMode = createSelector(
  state => sortedIncidentsSelector(state),
  state => filteredIncidentsSelector(state),
  (sortedIncidents, filteredIncidents) => {
    return {
      all: sortedIncidents,
      favorites: sortedIncidents.filter(incident => incident.isFavorite),
      filter: filteredIncidents,
    };
  }
);

export default incidentsByViewModeSelector;
