import { Action } from '../actions';

export type StartupState =
  | 'Rehydrate'
  | 'LoadConfiguration'
  | 'Authenticate'
  | 'DeviceRegistration'
  | 'OperationCode'
  | 'StartApiSagas'
  | 'LoadDataFilter'
  | 'DataFilter'
  | 'PushRegistration'
  | 'StartPeriodicSagas'
  | 'Complete';

// Needs to match RN AppStateStatus type.
export type ApplicationState = 'active' | 'background' | 'inactive' | 'unknown' | 'extension';

export type AppState = {
  readonly startupState: StartupState;
  readonly applicationState: ApplicationState;
  readonly appVersionIncompatible: boolean;
  readonly networkOnline: boolean; // native app only: true while Internet connection is available.
  readonly userIdle: boolean; // web app only: true when user is idle.
  readonly pushNotificationReceived: boolean; // set to true if pushNotification was received during startup
  readonly bundleId: string | null;
};

// This reducer contains transient app state.
const initialState: AppState = {
  startupState: 'Rehydrate',
  applicationState: 'inactive',
  appVersionIncompatible: false,
  networkOnline: true, // native app only: true while Internet connection is available.
  userIdle: false, // web app only: true when user is idle.
  pushNotificationReceived: false, // set to true if pushNotification was received during startup
  bundleId: null,
};

export default function app(state: AppState = initialState, action: Action) {
  switch (action.type) {
    case 'SET_STARTUP_STATE':
      return { ...state, startupState: action.startupState };
    case 'SET_APPLICATION_STATE':
      return { ...state, applicationState: action.applicationState };
    case 'SET_APP_VERSION_INCOMPATIBLE':
      return { ...state, appVersionIncompatible: true };
    case 'SET_USER_IDLE':
      return { ...state, userIdle: action.idle };
    case 'SET_NETWORK_ONLINE':
      return { ...state, networkOnline: action.online };
    case 'PUSH_NOTIFICATION_RECEIVED':
      return { ...state, pushNotificationReceived: true };
    case 'SET_APP_BUNDLE_ID':
      return { ...state, bundleId: action.bundleId };
    default:
      return state;
  }
}
