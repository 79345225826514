import { IncidentData } from '../../api/incidentsTypes';
import { ReduxState } from '../reducers';

import { clientConfigSelector } from '../../api/reducers/config';

const iconNameSelector = (state: ReduxState) => (data: IncidentData) => {
  if (data.replacedById) {
    return 'replace';
  }

  return clientConfigSelector(state).iconNames[data.cause] || 'unknown';
};

export default iconNameSelector;
