import './css/styles.css';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import log from '../shared/logging';
import { I18NState } from '../shared/i18n/reducers/i18n';
import getMuiThemeForTintColor from '../shared/utils/getMuiThemeForTintColor';
import { ApplicationOptions } from '../api/ApplicationOptions';
import { ClientConfig } from '../api/reducers/config';
import { GetConfigResponse } from '../api';
import { DeviceInfoState } from '../common/reducers/deviceInfo';
import TenantSelectionScreen from './navigation/TenantSelectionScreen';
// @ts-ignore
import configureStore from './store/configureStore';
import initAppStateHandler from './appStateHandler';
import applyTintColor from './utils/applyTintColor';

type InitialState = {
  deviceInfo: DeviceInfoState;
  i18n: I18NState;
};

export default function startApplication(options: ApplicationOptions) {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    return;
  }

  const response: GetConfigResponse = options.configResult.response;
  const config = response.config as ClientConfig;
  applyConfig(config);

  if (response.authenticationEnabled && !options.tenantInfo) {
    renderTenantSelectionScreen(rootElement, config);
    return;
  }

  const initialState: InitialState = {
    deviceInfo: {
      deviceName: navigator.userAgent.slice(0, 99),
      deviceType: 'WEB',
    },
    i18n: {
      currentLocale: getLocale(config.forcedLanguageCode),
      messages: {
        de: require('./i18n/de.json'),
        en: require('./i18n/en.json'),
        fr: require('./i18n/fr.json'),
      },
    },
  };

  if (response.internalClient) {
    log.info('Internal client detected => persistence will be initialized.');
  } else {
    log.info('External client detected => no persistence.');
  }

  const store = configureStore(initialState, response.internalClient, options);
  initAppStateHandler(store.dispatch);

  const RootWrapper = require('./navigation/RootWrapper').default;

  ReactDOM.render(
    <Provider store={store}>
      <RootWrapper />
    </Provider>,
    rootElement
  );
}

function renderTenantSelectionScreen(rootElement, config) {
  const muiTheme = getMuiThemeForTintColor(config.theme.tintColor);

  ReactDOM.render(
    <MuiThemeProvider theme={muiTheme}>
      <TenantSelectionScreen />
    </MuiThemeProvider>,
    rootElement
  );
}

function applyConfig(config: ClientConfig) {
  const {
    theme: { tintColor },
    siteName,
  } = config;
  applyTintColor(tintColor);

  document.title = siteName;
}

function getLocale(languageCode: string | null | undefined): string {
  let locale = 'en';
  if (languageCode) {
    locale = languageCode;
  } else if (navigator.languages) {
    // Chrome, Firefox, Safari
    locale = navigator.languages[0];
  } else if (navigator.language) {
    // IE11, Edge
    locale = navigator.language;
  }

  return locale.toLowerCase().replace('_', '-');
}
