import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import isAfter from 'date-fns/isAfter';
import { some } from 'lodash-es';
import { createSelector } from 'reselect';

import { nowSelector } from '../../common/reducers/refreshTimer';
import { CrowdTaskCallOut, CrowdTaskParticipation } from '../../api/types';
import { useSelector, WebReduxState } from '../reducers';
import Button from '../../api/components/Button';
import styles from './SelectedCallOutButtons.module.css';

type Props = {
  onEditClicked: () => void;
  selectedCallOut: CrowdTaskCallOut;
  onReleaseClicked: (show: boolean) => void;
  onDeleteClicked: (show: boolean) => void;
  onCloseClicked: (show: boolean) => void;
  onCloseParticipationsClicked: (show: boolean) => void;
};

const anyOpenParticipationsSelector: (state: WebReduxState) => boolean = createSelector(
  state => state.participations.participations.byId,
  participationsById => {
    return some(participationsById, (p: CrowdTaskParticipation) => p.status === 'OPEN');
  }
);

function dataSelector(state: WebReduxState) {
  return {
    now: nowSelector(state),
    anyOpenParticipations: anyOpenParticipationsSelector(state),
    participationsCallInProgress: Boolean(
      state.api.apiCalls.GET_PARTICIPATIONS && state.api.apiCalls.GET_PARTICIPATIONS.inProgress
    ),
  };
}

function SelectedCallOutButtons(props: Props) {
  const {
    selectedCallOut: { status, startTime },
    onEditClicked,
    onReleaseClicked,
    onDeleteClicked,
    onCloseClicked,
    onCloseParticipationsClicked,
  } = props;

  const { now, anyOpenParticipations, participationsCallInProgress } = useSelector(dataSelector, shallowEqual);

  const handleReleaseClicked = () => onReleaseClicked(true);
  const handleDeleteClicked = () => onDeleteClicked(true);
  const handleCloseClicked = () => onCloseClicked(true);
  const handleCloseParticipationsClicked = () => onCloseParticipationsClicked(true);

  return (
    <div className={styles.container}>
      {status === 'DRAFT' && (
        <Button
          disabled={isAfter(now, new Date(startTime))}
          className={styles.smallButton}
          onClick={handleReleaseClicked}>
          <FormattedMessage id="OperationDetailsCallOuts.release" defaultMessage="Release" />
        </Button>
      )}
      {(status === 'DRAFT' || status === 'RELEASED') && (
        <Button className={status === 'DRAFT' ? styles.smallButton : styles.bigButton} onClick={onEditClicked}>
          <FormattedMessage id="OperationDetailsCallOuts.edit" defaultMessage="Edit" />
        </Button>
      )}
      {status === 'DRAFT' && (
        <Button className={styles.smallButton} onClick={handleDeleteClicked}>
          <FormattedMessage id="OperationDetailsCallOuts.deleteCallOut" defaultMessage="Delete" />
        </Button>
      )}
      {status === 'RELEASED' && (
        <Button className={styles.bigButton} onClick={handleCloseClicked}>
          <FormattedMessage id="OperationDetailsCallOuts.endCallOut" defaultMessage="End Call Out" />
        </Button>
      )}
      {anyOpenParticipations && (
        <Button
          disabled={participationsCallInProgress}
          className={styles.bigButton}
          onClick={handleCloseParticipationsClicked}>
          <FormattedMessage id="OperationDetailsCallOuts.closeParticipations" defaultMessage="Close Participations" />
        </Button>
      )}
    </div>
  );
}

export default React.memo(SelectedCallOutButtons);
