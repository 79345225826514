import { take, put, fork, cancel, select, spawn } from 'redux-saga/effects';
import { isEqual } from 'lodash-es';

import isReactNative from '../../shared/utils/isReactNative';
import { ApplicationOptions } from '../../api/ApplicationOptions';
import { AvailableFilterCriteria } from '../../api/types';
import {
  tenantKeySelector,
  usernameSelector,
  hasManagedServerFiltersSelector,
  userRolesSelector,
} from '../../api/reducers/userInfo';
import { overrideUILanguageIfNeeded } from '../../api/sagas/config';
import syncSaga, { SyncConfig } from '../../api/sagas/sync';
import { App, appSelector } from '../../api/reducers/appConfig';
import {
  Action,
  applyDataFilter,
  fetchDataFilterCriteria,
  setStartupState,
  incrementAppStartsCounter,
  resetAppStartsCounter,
  setConfiguration,
  setTenant,
  cleanup,
  setPushSound,
  fetchDataFilterCallOutAreas,
  Dispatch,
} from '../actions';
import { ReduxState } from '../reducers';
import { deviceRegistrationSelector, isDataFilterSetSelector, DeviceRegistration } from '../reducers/device';
import isWebAppWithoutInformRightsSelector from '../selectors/isWebAppWithoutInformRights';
import logActionsSaga from './logActions';
import loadConfigurationSaga from './loadConfiguration';
import deviceRegistrationSaga from './deviceRegistration';
import fetchDataFilterCriteriaSaga from './fetchDataFilterCriteria';
import postDataFilterCriteriaSaga from './postDataFilterCriteria';
import postStatisticsSaga from './postStatistics';
import updateDeviceSaga from './updateDevice';
import refreshTimerSaga from './refreshTimer';
import { chatApiCallsSaga } from './chat';
import refetchChatMessagesIfFilterHashChangedSaga from './refetchChatMessagesIfFilterHashChanged';
import {
  startNavigationSagas,
  checkAppVersion,
  authenticate,
  startApiSagas,
  performInitialFetch,
  ensureWizardCompleted,
  startPeriodicSagas,
  checkPermissions,
} from './platformSagas';

export default function* startupSaga(dispatch: Dispatch, options?: ApplicationOptions): Generator<any, any, any> {
  // Log all actions using CCA logger.
  yield spawn(logActionsSaga);

  yield put(setStartupState('LoadConfiguration'));

  if (options) {
    // Web app: configuration was already loaded before and passed to the startup saga => set it.
    yield put(setConfiguration(options.configResult));
  } else {
    // Mobile app: load configuration from server.
    yield* loadConfigurationSaga();
  }

  // Override UI language if forced in config.
  yield* overrideUILanguageIfNeeded();

  // Initialize navigation.
  yield spawn(startNavigationSagas);

  // Mobile app: check app version compatibility.
  yield* checkAppVersion();

  // Login loop
  while (true) {
    if (options && options.tenantInfo) {
      // Web app: tenant was already loaded before and passed to the startup saga => set it.
      yield put(setTenant(options.tenantInfo));
    }

    const hasUserChangedData = yield* ensureUserAuthenticated();

    // The main flow performs the final (login/device-dependent) startup actions.
    const mainTask = yield fork(mainFlow, hasUserChangedData, dispatch);

    // When the user is logged out, cancel mainTask and thereby API sagas and periodic sagas, too.
    yield take(['AUTH_LOGOUT', 'COORDINATOR_UNAUTHORIZE', 'COORDINATOR_OPERATION_CLOSED']);
    yield cancel(mainTask);

    yield put(cleanup('CACHE'));
  }
}

function* loadDataFilter(): Generator<any, any, any> {
  const isDataFilterSet: boolean = yield select(isDataFilterSetSelector);

  if (isDataFilterSet) {
    return;
  }

  // Move this to common platformSagas
  const app: App = yield select(appSelector);
  if (app === ('staffMember' as App)) {
    yield put(fetchDataFilterCallOutAreas());
    yield take(a => a.type === 'API_CALL_SUCCESS' && a.name === 'FETCH_DATAFILTER_CALLOUT_AREAS');
  } else if (app === ('inform' as App)) {
    yield put(fetchDataFilterCriteria());
    yield take(a => a.type === 'API_CALL_SUCCESS' && a.name === 'FETCH_DATAFILTER_CRITERIA');
  }
}

function* ensureUserAuthenticated() {
  const oldTenantKey: string = yield select(tenantKeySelector);
  const oldUsername: string = yield select(usernameSelector);
  const oldUserRoles: Set<string> = yield select(userRolesSelector);

  // Platform-dependent
  yield* authenticate();

  const newTenantKey: string = yield select(tenantKeySelector);
  const newUsername: string = yield select(usernameSelector);
  const newUserRoles: Set<string> = yield select(userRolesSelector);

  return {
    hasUserChanged: newTenantKey !== oldTenantKey || newUsername !== oldUsername,
    hasUserRolesChanged: !isEqual(oldUserRoles, newUserRoles),
  };
}

function* mainFlow(hasUserChangedData: any, dispatch: Dispatch) {
  const { hasUserChanged, hasUserRolesChanged } = hasUserChangedData;

  // Ensure the device is registered.
  yield put(setStartupState('DeviceRegistration'));
  yield* deviceRegistrationSaga();

  if (hasUserChanged || hasUserRolesChanged) {
    // Do this after deviceRegistrationSaga as it depends on data received from the device registration response.
    yield put(cleanup(hasUserChanged ? 'FULL' : 'CACHE'));
  }

  // Check for Android permissions
  yield* checkPermissions();

  // Start sagas handling device-dependent API call requests.
  yield put(setStartupState('StartApiSagas'));
  yield fork(startCommonApiSagas);
  yield fork(startApiSagas);

  yield put(setStartupState('LoadDataFilter'));
  yield* loadDataFilter();

  yield put(setStartupState('DataFilter'));

  const app = yield select(appSelector);
  if (app === 'inform') {
    yield* applyChangedInformDataFilter(hasUserChanged);
  }

  yield* ensureWizardCompleted();

  // Start periodic sagas (sync, timer).
  yield put(setStartupState('StartPeriodicSagas'));
  yield fork(startPeriodicSagas, dispatch);

  yield put(setStartupState('Complete'));
}

function* startCommonApiSagas() {
  const app = yield select(appSelector);
  if (app === 'inform') {
    yield fork(fetchDataFilterCriteriaSaga);
    yield fork(postDataFilterCriteriaSaga);
  }
  // In CrowdTask apps only update.wav sound is used and sound selection is excluded from settings screen.
  // TODO: Make configurable per variant
  if (app === 'coordinator' || app === 'staffMember') {
    yield put(setPushSound('update.wav'));
  }

  yield fork(updateDeviceSaga);
}

function* applyChangedInformDataFilter(hasUserChanged) {
  const dataFilter = yield select(isDataFilterSetSelector);
  const hasManagedDataFilter = yield select(hasManagedServerFiltersSelector);
  // Check if user is different and handle the DataFilter change accordingly
  if ((hasUserChanged && dataFilter) || hasManagedDataFilter) {
    yield put(fetchDataFilterCriteria());
    yield take((action: Action) => action.type === 'API_CALL_SUCCESS' && action.name === 'FETCH_DATAFILTER_CRITERIA');

    const criteria: AvailableFilterCriteria = yield select((state: ReduxState) => state.inform.dataFilter.criteria);
    yield put(applyDataFilter(criteria));
  }
}

function* getDeviceIdForSyncConfig() {
  // Inform web app, but without authentication does not have a device id.
  // Portal does not have one either, but that's in a separate source.
  // In all other cases we want to include the deviceId in the sync config.
  const isWebAppWithoutInformRights: boolean = yield select(isWebAppWithoutInformRightsSelector);
  if (isWebAppWithoutInformRights) {
    return null;
  }

  const device: DeviceRegistration = yield select(deviceRegistrationSelector);
  return device.deviceId.toString();
}

function getAppTypeForSync(app: App) {
  switch (app) {
    case 'coordinator':
      return 'COORDINATOR_APP';
    case 'staffMember':
      return 'STAFF_MEMBER_APP';
    case 'inform':
    default:
      return isReactNative() ? 'INFORM_APP' : 'INFORM_WEB';
  }
}

export function* startCommonPeriodicSagas(): Generator<any, any, any> {
  yield fork(refreshTimerSaga);

  const app: App = yield select(appSelector);
  const deviceId = yield* getDeviceIdForSyncConfig();

  const syncConfig: SyncConfig = {
    appType: getAppTypeForSync(app),
    deviceId,
    performInitialFetch,
    performBackgroundWork: chatApiCallsSaga,
    checkResyncTrigger,
  };

  yield fork(syncSaga, syncConfig);

  const isWebAppWithoutInformRights: boolean = yield select(isWebAppWithoutInformRightsSelector);
  if (app === 'inform' && !isWebAppWithoutInformRights) {
    yield put(incrementAppStartsCounter());

    const ok = yield* postStatisticsSaga();
    if (ok) {
      yield put(resetAppStartsCounter());
    }

    yield fork(refetchChatMessagesIfFilterHashChangedSaga);
  }
}

function checkResyncTrigger(action: Action) {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      return action.name === 'POST_DATAFILTER_CRITERIA';
    case 'RESET_OFFLINE_CACHE':
      return true;
    default:
      return false;
  }
}
