import { select, take, put, fork } from 'redux-saga/effects';

import log from '../../shared/logging';
import { authenticate, refreshToken, logout } from '../../api/keycloakWeb/sagas';
import { hasManagedServerFiltersSelector } from '../../api/reducers/userInfo';
import { isDataFilterSetSelector } from '../../common/reducers/device';
import { PlatformSagas, OperationChatThread } from '../../common/sagas/platformSagas';
import { startCommonPeriodicSagas } from '../../common/sagas/startup';
import isWebAppWithoutInformRightsSelector from '../../common/selectors/isWebAppWithoutInformRights';
import { Action, setWizardModal, Dispatch } from '../actions';
import { WebReduxState } from '../reducers';
import { OperationsState } from '../reducers/operations';
import performInitialFetch from './performInitialFetch';
import crowdTaskApiCallsSaga from './crowdTaskApiCalls';
import adminApiCallsSaga from './adminApiCalls';
import signalChangedIncidentsSaga from './signalChangedIncidents';
// import timeModificationSaga from './timeModification';

function* getOperationChatThreads() {
  const operations: OperationsState = yield select((state: WebReduxState) => state.operations);
  const operationChatThreads: OperationChatThread[] = [];

  operations.ids.forEach(id => {
    const { operationId, chatThreadId } = operations.byId[id];
    if (chatThreadId) {
      operationChatThreads.push({ operationId, chatThreadId });
    }
  });

  return operationChatThreads;
}

const platformSagas: PlatformSagas = {
  *startNavigationSagas(): Generator<any, any, any> {
    // Not used for web
  },
  *checkAppVersion(): Generator<any, any, any> {
    // Not used for web
  },
  authenticate,
  logout,
  refreshToken,
  performInitialFetch,
  getOperationChatThreads,
  *ensureWizardCompleted(): Generator<any, any, any> {
    const isWebAppWithoutInformRights: boolean = yield select(isWebAppWithoutInformRightsSelector);

    if (isWebAppWithoutInformRights) {
      log.info('No inform user rights, skipping wizard.');
      return;
    }

    const hasManagedDataFilter: boolean = yield select(hasManagedServerFiltersSelector);
    const isDataFilterSet: boolean = yield select(isDataFilterSetSelector);
    const feedbackPhoneNumber: string = yield select((state: WebReduxState) => state.settings.feedbackPhoneNumber);

    const userNeedsFeedbackPhoneNumber = hasManagedDataFilter && !feedbackPhoneNumber;
    const userNeedsFilter = !isDataFilterSet && !hasManagedDataFilter;

    if (userNeedsFilter || userNeedsFeedbackPhoneNumber) {
      yield put(setWizardModal('user'));

      // Wait for the data filter to be saved successfully.
      yield take(
        (action: Action) =>
          action.type === 'WIZARD_FINISHED' ||
          (action.type === 'API_CALL_SUCCESS' && action.name === 'POST_DATAFILTER_CRITERIA')
      );
    }
  },
  *checkPermissions(): Generator<any, any, any> {
    // Not used for web
  },
  *startPeriodicSagas(_dispatch: Dispatch): Generator<any, any, any> {
    yield fork(signalChangedIncidentsSaga);
    yield fork(startCommonPeriodicSagas);
  },
  *startApiSagas(): Generator<any, any, any> {
    yield fork(crowdTaskApiCallsSaga);
    yield fork(adminApiCallsSaga);
  },
};

export default platformSagas;
