import { LongPollingMessage } from '../types';

export type ConnectionStatus = 'disconnected' | 'syncing' | 'connected';

export type ApiCallAction =
  | { type: 'API_CALL_STARTED'; name: string; pauseLongPollingMessages: boolean }
  | { type: 'API_CALL_SUCCESS'; name: string; result: any }
  | { type: 'API_CALL_ERROR'; name: string; error: Object }
  | { type: 'LONG_POLLING_MESSAGE'; message: LongPollingMessage }
  | { type: 'LONG_POLLING_PROCESSED' }
  | { type: 'SYNC_CONNECTED' }
  | { type: 'SYNC_DISCONNECTED' }
  | { type: 'SYNC_IN_PROGRESS' }
  | { type: 'SYNC_ERROR'; error: any };

export function apiCallStarted(name: string, pauseLongPollingMessages?: boolean): ApiCallAction {
  return { type: 'API_CALL_STARTED', name, pauseLongPollingMessages: Boolean(pauseLongPollingMessages) };
}

export function apiCallSuccess(name: string, result: any): ApiCallAction {
  return { type: 'API_CALL_SUCCESS', name, result };
}

export function apiCallError(name: string, error: Object): ApiCallAction {
  return { type: 'API_CALL_ERROR', name, error };
}

export function longPollingMessage(message: LongPollingMessage): ApiCallAction {
  return { type: 'LONG_POLLING_MESSAGE', message };
}

export function longPollingProcessed(): ApiCallAction {
  return {
    type: 'LONG_POLLING_PROCESSED',
  };
}

export function syncConnected(): ApiCallAction {
  return { type: 'SYNC_CONNECTED' };
}

export function syncInProgress(): ApiCallAction {
  return { type: 'SYNC_IN_PROGRESS' };
}

export function syncDisconnected(): ApiCallAction {
  return { type: 'SYNC_DISCONNECTED' };
}

export function syncError(error: any): ApiCallAction {
  return { type: 'SYNC_ERROR', error };
}
