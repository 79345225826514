import { put, select, take } from 'redux-saga/effects';

import log from '../../shared/logging';
import {
  getDevice,
  createDevice,
  getCoordinatorDevice,
  createCoordinatorDevice,
  getStaffMemberDevice,
  createStaffMemberDevice,
} from '../../api';
import {
  CreateDeviceRequest,
  CreateDeviceResponse,
  CreateCrowdTaskDeviceRequest,
  CreateCrowdTaskDeviceResponse,
} from '../../api/types';
import { App, appSelector } from '../../api/reducers/appConfig';
import { usernameSelector } from '../../api/reducers/userInfo';
import wrapApiCallActions from '../../api/wrapApiCallActions';
import { DeviceInfoState } from '../reducers/deviceInfo';
import isWebAppWithoutInformRightsSelector from '../selectors/isWebAppWithoutInformRights';
import { clearDeviceData } from '../actions';
import { deviceRegistrationSelector, DeviceRegistration } from '../reducers/device';
import { ReduxState } from '../reducers';

export default function* deviceRegistrationSaga(): Generator<any, any, any> {
  const isWebAppWithoutInformRights: boolean = yield select(isWebAppWithoutInformRightsSelector);

  if (isWebAppWithoutInformRights) {
    log.info('No inform user rights, skipping device registration.');
    return;
  }

  let device: DeviceRegistration | null | undefined = yield select(deviceRegistrationSelector);

  if (device) {
    const { deviceId } = device;

    const ok = yield* performGetDevice(deviceId);
    if (!ok) {
      log.warn(`Device ${deviceId} not found!`);

      // Device not found on server => force new device registration.
      yield put(clearDeviceData());

      device = null;
    }
  }

  while (!device) {
    yield* wrapApiCallActions('POST_DEVICEDATA', performCreateDevice());

    device = yield select(deviceRegistrationSelector);

    if (device) {
      log.info('New device', device);
    } else {
      yield take('POST_DEVICEDATA_RETRY');
    }
  }
}

function* performCreateDevice() {
  const app: App = yield select(appSelector);
  const bundleId: string | null | undefined = yield select((state: ReduxState) => state.app.bundleId);
  const username: string = yield select(usernameSelector);
  const deviceInfo: DeviceInfoState = yield select((state: ReduxState) => state.deviceInfo);
  const { deviceName, deviceType } = deviceInfo;

  if (app === ('inform' as App)) {
    // Use "old" device creation api
    const request: CreateDeviceRequest = {
      userName: username,
      deviceName,
      deviceType,
    };

    const response: CreateDeviceResponse = yield* createDevice(request);
    return response.deviceId.toString();
  }

  const request: CreateCrowdTaskDeviceRequest = {
    deviceName,
    deviceType,
    bundleId: bundleId || 'none',
    notificationsEnabled: false, // pushToken will be registered separately later.
  };

  switch (app) {
    case 'coordinator': {
      const response: CreateCrowdTaskDeviceResponse = yield* createCoordinatorDevice(request);
      return response.deviceId;
    }
    case 'staffMember':
    default: {
      const response: CreateCrowdTaskDeviceResponse = yield* createStaffMemberDevice(request);
      return response.deviceId;
    }
  }
}

// Returns false if device was not found (HTTP error 404) or access forbidden (HTTP error 403).
// Returns true in other cases (device found, network error, ...).
function* performGetDevice(deviceId) {
  const app: App = yield select(appSelector);

  try {
    switch (app) {
      case 'coordinator':
        yield* getCoordinatorDevice(deviceId);
        break;
      case 'staffMember':
        yield* getStaffMemberDevice(deviceId);
        break;
      case 'inform':
      default:
        yield* getDevice(deviceId);
        break;
    }

    return true;
  } catch (error) {
    const status = error.status;
    return status !== 403 && status !== 404;
  }
}
