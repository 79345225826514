import { createSelector } from 'reselect';
import { forIn } from 'lodash-es';

import { Incident } from '../../api/incidentsTypes';
import { ReduxState } from '../../common/reducers';
import { IncidentsMap } from '../reducers/incidents';

type ReplacesIncidentsMap = {
  [id: string]: Incident[];
};

const replacesIncidentsSelector: (incidents: IncidentsMap) => ReplacesIncidentsMap = createSelector(
  incidents => incidents,
  incidents => {
    const replacesMap: ReplacesIncidentsMap = {};

    forIn(incidents, (incident: Incident) => {
      const replacedById = incident.currentData.replacedById;

      if (!replacedById) {
        return;
      }

      const replacesArray = replacesMap[replacedById] || [];
      replacesArray.push(incident);

      if (!replacesMap[replacedById]) {
        replacesMap[replacedById] = replacesArray;
      }
    });

    return replacesMap;
  }
);

export const replacedByIncidentSelector: (state: ReduxState) => IncidentsMap = createSelector(
  state => state.inform.incidents.byId,
  incidents => {
    const replacedByMap = {};

    forIn(incidents, (incident: Incident) => {
      const id = incident.incidentId;
      const replacedById = incident.currentData.replacedById;

      if (!replacedById) {
        return;
      }

      const replacement = incidents[replacedById];

      if (replacement) {
        replacedByMap[id] = replacement;
      }
    });

    return replacedByMap;
  }
);

export default replacesIncidentsSelector;
