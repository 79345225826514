import { PersistBootStrappedAction } from '../../shared/utils/persist';
import { ApplicationState } from '../reducers/app';

// Transient application/device/network state
export type AppAction =
  | PersistBootStrappedAction
  | { type: 'SET_APPLICATION_STATE'; applicationState: ApplicationState }
  | { type: 'SET_APP_VERSION_INCOMPATIBLE' }
  | { type: 'SET_USER_IDLE'; idle: boolean }
  | { type: 'SET_NETWORK_ONLINE'; online: boolean }
  | { type: 'SET_APP_BUNDLE_ID'; bundleId: string } // Refresh
  | { type: 'REFRESH_TIMER' }
  | { type: 'NEW_OR_UPDATED_INCIDENTS_AVAILABLE'; numChanged: number } // Configuration
  | { type: 'LOAD_CONFIGURATION_RETRY' } // Other
  | { type: 'WIZARD_FINISHED' };

// Transient application/device/network state
export function setApplicationState(applicationState: ApplicationState): AppAction {
  return { type: 'SET_APPLICATION_STATE', applicationState };
}

export function setAppVersionIncompatible(): AppAction {
  return { type: 'SET_APP_VERSION_INCOMPATIBLE' };
}

export function setUserIdle(idle: boolean): AppAction {
  return { type: 'SET_USER_IDLE', idle };
}

export function setNetworkOnline(online: boolean): AppAction {
  return { type: 'SET_NETWORK_ONLINE', online };
}

export function setAppBundleId(bundleId: string): AppAction {
  return { type: 'SET_APP_BUNDLE_ID', bundleId };
}

// Refresh
export function refreshTimer(): AppAction {
  return { type: 'REFRESH_TIMER' };
}

export function newOrUpdatedIncidentsAvailable(numChanged: number): AppAction {
  return { type: 'NEW_OR_UPDATED_INCIDENTS_AVAILABLE', numChanged };
}

// Configuration
export function loadConfigurationRetry(): AppAction {
  return { type: 'LOAD_CONFIGURATION_RETRY' };
}

// Other
export function wizardFinished(): AppAction {
  return { type: 'WIZARD_FINISHED' };
}
