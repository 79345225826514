// @ts-ignore can't get namespace import to work correctly with TS
import newKeycloak, { Keycloak } from 'keycloak-js';

// Hold our global keycloak instance here.
let globalKeycloak: Keycloak | null | undefined = null;

export type KeycloakConfiguration = {
  realm: string;
  'auth-server-url': string;
  'ssl-required': string;
  resource: string;
  'public-client': boolean;
};

export function initKeycloak(config: KeycloakConfiguration, loginRequired: boolean): Promise<Keycloak> {
  const clientConfig = {
    url: config['auth-server-url'],
    realm: config.realm,
    clientId: config.resource,
    credentials: null,
  };

  const keycloak = newKeycloak(clientConfig);
  const onLoad = loginRequired ? 'login-required' : 'check-sso';

  // Keycloak adapter 3.x causes reload loops when used with Keycloak 2.x SSO.
  // Workaround: Set checkLoginIframe to false until we have upgraded to Keycloak 3.x everywhere.
  // (This disables SSO.)
  return keycloak
    .init({ onLoad, checkLoginIframe: false })
    .then(ok => (ok ? keycloak : Promise.reject(new Error('Keycloak init failed'))));
}

export function getKeycloak(): Keycloak | null | undefined {
  return globalKeycloak;
}

export function setKeycloak(keycloak: Keycloak) {
  globalKeycloak = keycloak;
}
