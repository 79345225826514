import { createMuiTheme } from '@material-ui/core/styles';

export default function getMuiThemeForTintColor(tintColor: string) {
  return createMuiTheme({
    palette: {
      primary: {
        main: tintColor,
        dark: tintColor,
      },
      secondary: {
        main: tintColor,
        dark: tintColor,
      },
    },
  });
}
