import { createSelector } from 'reselect';
import { keyBy, sortBy, map, countBy } from 'lodash-es';

import { Location } from '../../api/types';
import { Action } from '../actions';

export type LocationsMap = {
  [locationCode: string]: Location;
};

export type LocationsState = {
  ids: string[];
  byId: LocationsMap;
};

type PartialReduxState = {
  locations: LocationsState;
};

export type StateWithLocationCount = {
  state: string;
  locationCount: number;
};

const initialData: LocationsState = {
  ids: [],
  byId: {},
};

export default function locations(state: LocationsState = initialData, action: Action): LocationsState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_LOCATIONS': {
          const result: Location[] = action.result;

          return {
            ids: result.map(l => l.locationCode),
            byId: keyBy(result, l => l.locationCode),
          };
        }
        default:
          return state;
      }

    default:
      return state;
  }
}

export const statesWithLocationCountsSelector: (state: PartialReduxState) => StateWithLocationCount[] = createSelector(
  state => state.locations,
  ({ ids, byId }) =>
    sortBy(
      map(
        countBy(ids, id => byId[id].state),
        (value, key) => ({ state: key, locationCount: value })
      ),
      s => s.state
    )
);

export const sortedLocationCodesSelector: (state: PartialReduxState) => string[] = createSelector(
  state => state.locations,
  ({ ids, byId }) => sortBy(ids, id => byId[id].name)
);
