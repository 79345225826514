import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ReduxState } from '../reducers';
import { nowSelector } from '../reducers/refreshTimer';

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    now: nowSelector(state),
  };
}

type OwnProps = {
  value: Date | string;
};

type StoreProps = {
  now: Date;
};

type Props = OwnProps & StoreProps;

function FormattedShortRelativeTime({ value, now }: Props) {
  const milliseconds = Math.max(now.valueOf() - new Date(value).valueOf(), 0);

  // we calculate 7 days = 1 week / 30 days = 1 month / 365 days = 1 year

  let temp = Math.floor(milliseconds / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return (
      <FormattedMessage id="FormattedShortRelativeTime.years" defaultMessage="{years, number}Y" values={{ years }} />
    );
  }

  const months = Math.floor((temp %= 31536000) / 2592000);
  if (months) {
    return (
      <FormattedMessage id="FormattedShortRelativeTime.months" defaultMessage="{months, number}M" values={{ months }} />
    );
  }

  const weeks = Math.floor((temp %= 2592000) / 604800);
  if (weeks) {
    return (
      <FormattedMessage id="FormattedShortRelativeTime.weeks" defaultMessage="{weeks, number}W" values={{ weeks }} />
    );
  }

  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return <FormattedMessage id="FormattedShortRelativeTime.days" defaultMessage="{days, number}D" values={{ days }} />;
  }

  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return (
      <FormattedMessage id="FormattedShortRelativeTime.hours" defaultMessage="{hours, number}h" values={{ hours }} />
    );
  }

  const minutes = Math.floor((temp %= 3600) / 60);
  return (
    <FormattedMessage
      id="FormattedShortRelativeTime.minutes"
      defaultMessage="{minutes, number}m"
      values={{ minutes }}
    />
  );
}

export default connect(mapStateToProps)(FormattedShortRelativeTime);

export { FormattedShortRelativeTime as UndecoratedFormattedShortRelativeTime };
