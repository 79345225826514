export function triggerDownload(filename: string, blankTarget: boolean, url: string) {
  const tempLink = document.createElement('a');
  const documentBody = document.body;

  if (tempLink && documentBody) {
    tempLink.setAttribute('style', 'display: none');
    tempLink.setAttribute('href', url);
    tempLink.setAttribute('download', filename);

    if (blankTarget) {
      tempLink.setAttribute('target', '_blank');
    }

    documentBody.appendChild(tempLink);
    tempLink.click();
    documentBody.removeChild(tempLink);
  }
}

export function createCsvUri(content: string) {
  return `data:text/csv;charset=utf-8,\uFEFF${content}`;
}

export function createCsvFilename() {
  const localISOTime = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -5)
    .replace('T', ' ')
    .replace(/:/g, '_');

  return `export_${localISOTime}.csv`;
}
