import { NormalizedCollection, addValue, removeId } from '../../shared/normalizedCollection';
import log from '../../shared/logging';
import { LongPollingMessage } from '../types';

export default function applyLongPollingUpdate<V extends Object>(
  collection: NormalizedCollection<V>,
  message: LongPollingMessage,
  idSelector: (arg0: V) => string
): NormalizedCollection<V> {
  const { action, payload: unknownPayload } = message;
  const payload = unknownPayload as any;

  switch (action) {
    case 'UPSERT': {
      const id = idSelector(payload);
      const oldValue = collection.byId[id];

      if (!shouldUpdate(payload, oldValue)) {
        return collection;
      }

      return addValue(collection, payload, idSelector);
    }
    case 'DELETE':
      return removeId(collection, payload);
    default:
      log.warn(`Unknown long polling action ${action}`);
      return collection;
  }
}

function shouldUpdate<V extends Object>(payload: V, oldValue: V | undefined) {
  // If the entity is versioned, only update if the version is same or newer.
  if (oldValue && oldValue['version'] && payload['version']) {
    return oldValue['version'] <= payload['version'];
  } else {
    return true;
  }
}
