import { select, takeLatest } from 'redux-saga/effects';

import wrapApiCallActions from '../../api/wrapApiCallActions';
import { WebAction, CreateTenantAction, UpdateTenantAction, GetIncidentsSearchChatAction } from '../actions';
import {
  getTenants,
  createTenant,
  updateTenant,
  getTenantDataFilter,
  updateTenantDataFilter,
  getAllStatistics,
  submitInitialLoad,
  getIncidentsSearch,
  getIncidentsSearchChat,
  getIncidentsSearchCsv,
  GetIncidentsSearchRequest,
} from '../../api/admin';
import { fetchAvailableFilterCriteria } from '../../api/incidents';
import { PutDeviceFilterRequest } from '../../api/types';
import { tenantIdSelector } from '../reducers/adminTenants';
import { WebReduxState } from '../reducers';

export default function* adminApiCallsSaga(): Generator<any, any, any> {
  // Immediately fetch the tenant list again after creating a new tenant.
  yield takeLatest(
    (action: WebAction) =>
      action.type === 'GET_TENANTS' || (action.type === 'API_CALL_SUCCESS' && action.name === 'CREATE_TENANT'),
    performGetTenants
  );
  yield takeLatest('CREATE_TENANT', performCreateTenant);
  yield takeLatest('UPDATE_TENANT', performUpdateTenant);
  yield takeLatest('GET_TENANT_FILTER', performGetTenantFilter);
  yield takeLatest('UPDATE_TENANT_FILTER', performUpdateTenantFilter);
  yield takeLatest('GET_STATISTICS', performGetStatistics);
  yield takeLatest('SUBMIT_INITIAL_LOAD', performSubmitInitialLoad);
  yield takeLatest('GET_INCIDENTS_SEARCH', performGetIncidentsSearch);
  yield takeLatest('GET_INCIDENTS_SEARCH_CHAT', performGetIncidentsSearchChat);
  yield takeLatest('GET_INCIDENTS_SEARCH_CSV', performGetIncidentsSearchCsv);
}

function* performGetTenants() {
  yield* wrapApiCallActions('GET_TENANTS', getTenants());
}

function* performCreateTenant(action: CreateTenantAction) {
  yield* wrapApiCallActions('CREATE_TENANT', createTenant(action.tenant));
}

function* performUpdateTenant(action: UpdateTenantAction) {
  yield* wrapApiCallActions('UPDATE_TENANT', updateTenant(action.tenantId, action.tenant));
}

function* performGetTenantFilter() {
  const tenantId: string = yield select(tenantIdSelector);

  yield* wrapApiCallActions('FETCH_AVAILABLE_DATAFILTER_CRITERIA', fetchAvailableFilterCriteria());
  yield* wrapApiCallActions('GET_TENANT_FILTER', getTenantDataFilter(tenantId));
}

function* performUpdateTenantFilter() {
  const tenantId: string = yield select(tenantIdSelector);
  const request: PutDeviceFilterRequest = yield select((state: WebReduxState) => state.admin.tenants.tenantFilter);

  yield* wrapApiCallActions('UPDATE_TENANT_FILTER', updateTenantDataFilter(tenantId, request));
}

function* performGetStatistics(): Generator<any, any, any> {
  yield* wrapApiCallActions('GET_STATISTICS', getAllStatistics());
}

function* performSubmitInitialLoad() {
  yield* wrapApiCallActions('SUBMIT_INITIAL_LOAD', submitInitialLoad());
}

function* performGetIncidentsSearch() {
  const request: GetIncidentsSearchRequest = yield select((state: WebReduxState) => state.admin.analysis.search);

  yield* wrapApiCallActions('GET_INCIDENTS_SEARCH', getIncidentsSearch(request));
}

function* performGetIncidentsSearchChat(action: GetIncidentsSearchChatAction) {
  yield* wrapApiCallActions('GET_INCIDENTS_SEARCH_CHAT', getIncidentsSearchChat(action.incidentId));
}

function* performGetIncidentsSearchCsv() {
  const request: GetIncidentsSearchRequest = yield select((state: WebReduxState) => state.admin.analysis.search);

  yield* wrapApiCallActions('GET_INCIDENTS_SEARCH_CSV', getIncidentsSearchCsv(request));
}
