import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IncidentData } from '../../api/incidentsTypes';
import { IncidentDataCompareResult } from '../../common/utils/compareIncidentData';
import Field from './Field';
import styles from './IncidentDetailsRight.module.css';

type Props = {
  incidentData: IncidentData;
  fieldInfos?: IncidentDataCompareResult;
};

export default function IncidentDetailsRight({ incidentData, fieldInfos }: Props) {
  const { information, measure, place, internals, finalInformation } = incidentData;

  return (
    <div className={styles.incidentDetailRight}>
      <Field
        title={<FormattedMessage id="IncidentDetail.information" defaultMessage="Information" />}
        value={information}
        fieldInfo={fieldInfos && fieldInfos.information}
      />
      <Field
        title={<FormattedMessage id="IncidentDetail.measure" defaultMessage="Measures" />}
        value={measure}
        fieldInfo={fieldInfos && fieldInfos.measure}
      />
      <Field
        title={<FormattedMessage id="IncidentDetail.place" defaultMessage="Place" />}
        value={place}
        fieldInfo={fieldInfos && fieldInfos.place}
      />
      <Field
        title={<FormattedMessage id="IncidentDetail.internals" defaultMessage="Internals" />}
        value={internals}
        fieldInfo={fieldInfos && fieldInfos.internals}
      />
      <Field
        title={<FormattedMessage id="IncidentDetail.finalInformation" defaultMessage="End of the disorder" />}
        value={finalInformation}
        fieldInfo={fieldInfos && fieldInfos.finalInformation}
      />
    </div>
  );
}
