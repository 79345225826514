import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import Button from '../../api/components/Button';
import IconButton from '@material-ui/core/IconButton';

import { ApiCallStatus } from '../../shared/apiCalls/status';
import { intlSelector } from '../../api/selectors/i18n';
import { getApiCallStatus } from '../../api/reducers/api';
import { createTenant, WebDispatchProps } from '../actions';
import { WebReduxState } from '../reducers';
import LoadingIcon from '../navigation/LoadingIcon';
import InputField from '../navigation/InputField';
import styles from './CreateTenant.module.css';

type OwnProps = {};

type StoreProps = {
  intl: IntlShape;
  createTenantState: ApiCallStatus;
};

type Props = OwnProps & StoreProps & WebDispatchProps;

type State = {
  key: string;
  name: string;
  show: boolean;
};

function mapStateToProps(state: WebReduxState): StoreProps {
  return {
    intl: intlSelector(state),
    createTenantState: getApiCallStatus(state, 'CREATE_TENANT'),
  };
}

class CreateTenant extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: '',
      name: '',
      show: false,
    };
  }

  render() {
    const { createTenantState, intl } = this.props;
    const tenant = this.state;
    const enabled = tenant.key.length && tenant.name;
    const msg = defineMessages({
      tenantName: { id: 'CreateTenant.tenantName', defaultMessage: 'Enter tenant name' },
      tenantKey: { id: 'CreateTenant.tenantKey', defaultMessage: 'Enter tenant key' },
    });

    if (createTenantState.inProgress) {
      return (
        <tr>
          <td>
            <LoadingIcon />
          </td>
        </tr>
      );
    }

    return this.state.show ? (
      <tr className={styles.createTenantRow}>
        <td>
          <InputField
            placeholder={intl.formatMessage(msg.tenantName)}
            controlId="enterTenantName"
            formType="text"
            value={this.state.name}
            onChange={this.handleTenantNameChange}
            maxLength={128}
          />
        </td>
        <td>
          <InputField
            placeholder={intl.formatMessage(msg.tenantKey)}
            controlId="enterTenantKey"
            formType="text"
            value={this.state.key}
            onChange={this.handleTenantKeyChange}
            maxLength={36}
          />
        </td>
        <td>
          <div>
            <Button disabled={!enabled} onClick={this.handleCreateTenantButtonClick}>
              <FormattedMessage id="CreateTenant.submit" defaultMessage="Create Tenant" />
            </Button>
            <IconButton onClick={this.handleToggleAddTenant}>×</IconButton>
          </div>
          {createTenantState.error && (
            <div className={styles.tenantError}>
              <FormattedMessage
                id="CreateTenant.error"
                defaultMessage="Error {error}"
                values={{ error: createTenantState.error.message }}
              />
            </div>
          )}
        </td>
      </tr>
    ) : (
      <tr>
        <td colSpan={3} className={styles.addTenantRow} onClick={this.handleToggleAddTenant}>
          <IconButton>+</IconButton>
        </td>
      </tr>
    );
  }

  handleTenantKeyChange = (value: string) => {
    this.setState({ key: value });
  };

  handleTenantNameChange = (value: string) => {
    this.setState({ name: value });
  };

  handleCreateTenantButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    const tenant = {
      tenantKey: this.state.key,
      displayName: this.state.name,
    };

    if (tenant.tenantKey && tenant.displayName) {
      this.props.dispatch(createTenant(tenant));
      this.setState({ show: false, key: '', name: '' });
    }
  };

  handleToggleAddTenant = () => {
    this.setState({ show: !this.state.show });
  };
}

export default connect(mapStateToProps)(CreateTenant);
