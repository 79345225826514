import differenceInMinutes from 'date-fns/differenceInMinutes';

export function matchTime(time: string): any {
  return time.match(/^(2[0-3]|[01]?[0-9]):?(0[0-9]{1}|[1-5]{1}[0-9])$/);
}

export function getDateFromTimeString(date: Date, time: string | null | undefined): Date | null | undefined {
  if (!time) {
    return null;
  }

  const match = matchTime(time);
  if (!match || match.length < 3) {
    return null;
  }

  const hours = match[1];
  const minutes = match[2];

  const newDate = new Date(date);
  newDate.setHours(parseInt(hours, 10));
  newDate.setMinutes(parseInt(minutes, 10));
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
}

type Interval = { startDate: Date; endDate: Date };

export function getOverlapInMinutes(callOutInterval: Interval, followupInterval: Interval) {
  const { startDate, endDate } = callOutInterval;
  const followupStartDate = followupInterval.startDate;
  const followupEndDate = followupInterval.endDate;

  const startToStartDiff = differenceInMinutes(startDate, followupStartDate);
  const endToEndDiff = differenceInMinutes(followupEndDate, endDate);
  const startToEndDiff = differenceInMinutes(endDate, followupStartDate);
  const endToStartDiff = differenceInMinutes(followupEndDate, startDate);

  let overlap = 0;
  if (startToStartDiff >= 0 && endToEndDiff >= 0) {
    // Same interval or wider in start and end direction
    overlap = differenceInMinutes(endDate, startDate);
  } else if (startToStartDiff <= 0 && endToEndDiff <= 0) {
    // Contained in the interval
    overlap = differenceInMinutes(followupEndDate, followupStartDate);
  } else if (startToStartDiff >= 0 && endToEndDiff <= 0 && endToStartDiff >= 0) {
    // Partially contained but offset to the left
    overlap = differenceInMinutes(followupEndDate, startDate);
  } else if (startToStartDiff <= 0 && endToEndDiff >= 0 && startToEndDiff >= 0) {
    // Partially contained but offset to the right
    overlap = differenceInMinutes(endDate, followupStartDate);
  }

  return overlap;
}
