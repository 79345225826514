import * as React from 'react';
import { keyBy, sortBy } from 'lodash-es';

import { RegionInfo, DataFilterType } from '../../api/reducers/config';
import SVGRegionGroup, { Region } from './SVGRegionGroup';
import styles from './SVGRegionMap.module.css';

type Props = {
  regionInfos: ReadonlyArray<RegionInfo> | null | undefined;
  regions: Region[];
  onChange: (regionName: string, type: DataFilterType) => void;
};

type State = {
  sortedRegionInfos: ReadonlyArray<RegionInfo> | null | undefined;
  regionsMap: {
    [name: string]: Region;
  };
};

export default class SVGRegionMap extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      sortedRegionInfos: props.regionInfos,
      regionsMap: keyBy(props.regions, (r: Region) => r.name),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.regions !== this.props.regions) {
      this.setState({ regionsMap: keyBy(nextProps.regions, (r: Region) => r.name) });
    }
  }

  render() {
    const { sortedRegionInfos, regionsMap } = this.state;
    if (!sortedRegionInfos) {
      // This should not happen.
      return null;
    }

    return (
      <div className={styles.svgRegionMapContainer}>
        <svg className={styles.svgRegionMap} viewBox="0 0 151.4 77.5" preserveAspectRatio="xMidYMin">
          {sortedRegionInfos.map(regionInfo => {
            const region = regionsMap[regionInfo.name];
            return (
              <SVGRegionGroup
                key={regionInfo.name}
                region={region}
                regionInfo={regionInfo}
                onRegionClick={this.handleRegionClick}
                onMouseOver={this.handleMouseOver}
              />
            );
          })}
        </svg>
      </div>
    );
  }

  handleRegionClick = (regionName: string) => {
    this.props.onChange(regionName, 'regions');
  };

  handleMouseOver = (regionName: string) => {
    const { regionInfos } = this.props;
    const sortedRegionInfos = sortBy(regionInfos, regionInfo => (regionInfo.name === regionName ? 1 : 0));

    this.setState({ sortedRegionInfos });
  };
}
