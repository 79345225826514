import * as React from 'react';
import { Route, Switch, match } from 'react-router';
import { connect } from 'react-redux';

import hasRoleSelector from '../../api/selectors/hasRole';
import { ReduxState } from '../../common/reducers';
import ProgressSpinner from '../navigation/ProgressSpinner';
import { apiActionInProgressSelector } from '../selectors/apiActions';
import Operations from './Operations';
import EditOperationScreen from './EditOperationScreen';
import NewOperationScreen from './NewOperationScreen';

type OwnProps = {
  match: match;
};

type StoreProps = {
  apiActionInProgress: boolean;
  hasRole: (role: string) => boolean;
};

type Props = OwnProps & StoreProps;

function mapStateToProps(state: ReduxState): StoreProps {
  return {
    apiActionInProgress: apiActionInProgressSelector(state),
    hasRole: hasRoleSelector(state),
  };
}

const OperationRoutes = ({ match: { url }, apiActionInProgress }: Props) => (
  // Don't wrap in a div, that would break the layout.
  <>
    <Switch>
      <Route path={`${url}/new`} component={NewOperationScreen} />
      <Route path={`${url}/:operationId/edit`} component={EditOperationScreen} />
      <Route path={`${url}/:operationId`} component={Operations} />
      <Route component={Operations} />
    </Switch>
    {apiActionInProgress && <ProgressSpinner />}
  </>
);

export default connect(mapStateToProps)(OperationRoutes);
