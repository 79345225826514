import subDays from 'date-fns/subDays';

import { Incident } from '../../api/types';
import { GetIncidentsSearchRequest } from '../../api/admin';
import { createCollection } from '../../shared/normalizedCollection';
import { Action } from '../actions';
import { ChatThread, makeChatThread } from '../../api/utils/chat';

export type IncidentHistory = Incident[];

export type IncidentWithHistory = {
  incidentId: string;
  history: IncidentHistory;
};

export type IncidentsHistoryMap = {
  [id: string]: IncidentHistory;
};

export type SelectedIncidentMap = {
  [id: string]: number;
};

export type AnalysisState = {
  search: GetIncidentsSearchRequest;
  history: IncidentsHistoryMap;
  csv: string | null;
  selectedIncidentId: string | null;
  selectedHistory: SelectedIncidentMap;
  chat: ChatThread | null;
};

const fromDaysBeforeToday = 30;

const getFromTime = () => {
  const now = new Date();

  return subDays(now, fromDaysBeforeToday).toISOString();
};

const initialSearch = {
  term: '',
  from: getFromTime(),
  to: new Date().toISOString(),
  searchInChat: false,
};

const initialState = {
  search: initialSearch,
  history: {},
  csv: null,
  selectedIncidentId: null,
  selectedHistory: {},
  chat: null,
};

export default function analysis(state: AnalysisState = initialState, action: Action): AnalysisState {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'GET_INCIDENTS_SEARCH': {
          const history: IncidentsHistoryMap = {};
          const selectedHistory: SelectedIncidentMap = {};

          action.result.incidents.forEach((incident: IncidentWithHistory) => {
            history[incident.incidentId] = incident.history;
            selectedHistory[incident.incidentId] = 0;
          });

          return { ...state, history, selectedHistory };
        }
        case 'GET_INCIDENTS_SEARCH_CHAT': {
          const chat = makeChatThread(createCollection(action.result, message => message.messageId));
          return { ...state, chat };
        }
        case 'GET_INCIDENTS_SEARCH_CSV':
          return { ...state, csv: action.result };
        default:
          return state;
      }
    case 'SET_INCIDENTS_SEARCH':
      return { ...state, search: action.search };

    case 'SELECT_INCIDENTS_SEARCH_ID':
      return { ...state, selectedIncidentId: action.incidentId };

    case 'SET_INCIDENTS_SEARCH_HISTORY': {
      const selectedHistory = { ...state.selectedHistory, [action.incidentId]: action.historyNumber };
      return { ...state, selectedHistory };
    }
    default:
      return state;
  }
}
