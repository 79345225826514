import { MessageDictionary } from '../../shared/i18n/reducers/i18n';
import { ApiCallAction, ConfigAction } from '../actions';
import { GetConfigResult } from '../config';
import { GetConfigResponse } from '../types';
import { App, AppConfig, appSelector } from './appConfig';

export type IconNames = {
  [cause: string]: string | null | undefined;
};
export type DataFilterType = 'lines' | 'regions' | 'incidentTypes';
export type DataFilterCallOutAreasType = 'states' | 'callOutAreas';

export type RegionInfo = Readonly<{
  name: string;
  textTransform: string;
  path: string;
}>;

type ClientConfigFeatures = Readonly<{
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  regionInfos?: ReadonlyArray<RegionInfo>;
  faq?: boolean;
  hideCategoryField?: boolean;
  hideIncidentTypeField?: boolean;
}>;

export type LogoData = Readonly<{
  logoBase64: string | null | undefined;
  width: number;
  height: number;
}>;

export type NavbarItemSpec = Readonly<{
  title: Readonly<{
    [lang: string]: string;
  }>;
  rootUrl: string;
  path: string;
  roles: ReadonlyArray<string>;
}>;

export type LocalizationOverrides = Readonly<{
  [lang: string]: MessageDictionary;
}>;

export type AppVersionConfig = Readonly<{
  current: string;
  minimum: string;
  installLink: string;
}>;

export type KeyboardType = 'default' | 'number-pad' | 'decimal-pad' | 'numeric' | 'email-address' | 'phone-pad';

// The main tenant + staffMember tenant (for Unite Crowd Task projects) can be set in the config file
// for projects that do not use the tenant selection UI.
export type ClientConfig = Readonly<{
  siteName: string;
  tenant?: string;
  staffMemberTenant?: string;
  crowdTaskSiteName?: string;
  userNameKeyboardType?: Readonly<
    {
      [app in App]: KeyboardType;
    }
  >;
  forcedLanguageCode?: string;
  versions?: Readonly<{
    ios: AppVersionConfig;
    android: AppVersionConfig;
  }>;
  navbarItems: ReadonlyArray<NavbarItemSpec>;
  localizationOverrides?: Readonly<
    {
      [app in App]: LocalizationOverrides;
    }
  >;
  features: ClientConfigFeatures;
  iconNames: IconNames;
  crowdTask: Readonly<{
    callOuts: Readonly<{
      defaultFollowupOverlapInMinutes: number;
      defaultLengthInMinutes: number;
    }>;
  }>;
  support: Readonly<{
    phoneNumber: string | null | undefined;
    email: string | null | undefined;
  }>;
  theme: Readonly<{
    tintColor: string;
    logo: LogoData | null | undefined;
  }>;
}>;

export type ConfigState = Readonly<{
  loaded: GetConfigResponse;
  lastModified: string | null | undefined;
}>;

export const initialState: ConfigState = {
  loaded: {
    authenticationEnabled: false,
    internalClient: false,
    maxChatMessageSize: 1000,
    crowdTask: {
      callOuts: {
        minLengthInMinutes: 0,
        maxLengthInMinutes: 0,
        maxStartTimeInFutureInHours: 0,
        resolutionInMinutes: 0,
        setReadyBeforeStartTimeInMinutes: 0,
        followUpOverlapInMinutes: 0,
        maxRequiredTeamSize: 0,
      },
    },
    config: {
      siteName: 'Unite Inform',
      features: {
        dataFilterTypes: ['lines', 'regions', 'incidentTypes'] as DataFilterType[],
      },
      navbarItems: [],
      iconNames: {},
      crowdTask: {
        callOuts: {
          defaultFollowupOverlapInMinutes: 15,
          defaultLengthInMinutes: 180,
        },
      },
      support: {
        phoneNumber: null,
        email: null,
      },
      theme: {
        tintColor: '#e2002a',
        logo: {
          logoBase64: null,
          width: 0,
          height: 0,
        },
      },
    },
  },
  lastModified: null,
};

type PartialReduxState = {
  appConfig: AppConfig;
  config: ConfigState;
};

export default function configReducer(
  state: ConfigState = initialState,
  action: ApiCallAction | ConfigAction
): ConfigState {
  switch (action.type) {
    case 'SET_CONFIGURATION':
      return setConfiguration(state, action.result);
    case 'API_CALL_SUCCESS':
      switch (action.name) {
        case 'LOAD_CONFIGURATION':
          return setConfiguration(state, action.result);
        default:
          return state;
      }

    default:
      return state;
  }
}

function setConfiguration(state: ConfigState, result: GetConfigResult | null | undefined) {
  if (!result) {
    // Not modified
    return state;
  }

  const { response, lastModified } = result;

  return {
    ...state,
    loaded: response,
    lastModified,
  };
}

export function clientConfigSelector(state: PartialReduxState): ClientConfig {
  return state.config.loaded.config as ClientConfig;
}

export function authenticationEnabledSelector(state: PartialReduxState): boolean {
  return state.config.loaded.authenticationEnabled;
}

export function lastModifiedSelector(state: PartialReduxState): string | null | undefined {
  return state.config.lastModified;
}

export function supportPhoneNumberSelector(state: PartialReduxState): string | null | undefined {
  return clientConfigSelector(state).support.phoneNumber;
}

export function supportEmailSelector(state: PartialReduxState): string | null | undefined {
  return clientConfigSelector(state).support.email;
}

export function dataFilterTypesSelector(state: PartialReduxState): ReadonlyArray<DataFilterType> {
  return clientConfigSelector(state).features.dataFilterTypes;
}

export function siteNameSelector(state: PartialReduxState): string {
  const app: App = appSelector(state);
  const { siteName, crowdTaskSiteName } = clientConfigSelector(state);

  switch (app) {
    case 'staffMember':
    case 'coordinator':
      return crowdTaskSiteName || siteName;
    case 'inform':
    default:
      return siteName;
  }
}

export function crowdTaskSiteNameSelector(state: PartialReduxState): string {
  const { siteName, crowdTaskSiteName } = clientConfigSelector(state);
  return crowdTaskSiteName || siteName;
}

export function localizationOverridesSelector(state: PartialReduxState): LocalizationOverrides | null | undefined {
  const app: App = appSelector(state);
  const { localizationOverrides } = clientConfigSelector(state);

  return localizationOverrides ? localizationOverrides[app] : null;
}

export function defaultTenantKeySelector(state: PartialReduxState): string | null | undefined {
  const app: App = appSelector(state);
  switch (app) {
    case 'staffMember':
      return clientConfigSelector(state).staffMemberTenant;
    case 'coordinator':
    case 'inform':
    default:
      return clientConfigSelector(state).tenant;
  }
}

export function userNameKeyboardTypeSelector(state: PartialReduxState): KeyboardType {
  const app: App = appSelector(state);
  const clientConfig = clientConfigSelector(state);

  if (
    clientConfig.userNameKeyboardType &&
    clientConfig.userNameKeyboardType[app] &&
    clientConfig.userNameKeyboardType[app].length > 0
  ) {
    return clientConfig.userNameKeyboardType[app];
  }

  return 'default';
}

export function canSeeIncidentTypeSelector(state: PartialReduxState): boolean {
  const { features } = clientConfigSelector(state);

  if (features.hideIncidentTypeField) {
    return !features.hideIncidentTypeField;
  }
  return true;
}
