import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import log from '../../shared/logging';
import { getTenant } from '../../api/index';
import { setTenantKey } from '../../api/utils/localStorage';
import styles from './TenantSelectionScreen.module.css';

const backgroundLogo = require('../css/cca_logo_inform.png');

type State = {
  tenantKey: string;
  loading: boolean;
  error: any;
};

export default class TenantSelectionScreen extends React.Component<{}, State> {
  state = {
    tenantKey: '',
    loading: false,
    error: null,
  };

  render() {
    return (
      <div className={styles.loginPage}>
        <a href="https://www.cca.io" target="_blank" rel="noopener noreferrer">
          <img className={styles.ccaBackgroundLogo} src={backgroundLogo} alt="Background Logo" />
        </a>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const { tenantKey, loading, error } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <div className={styles.form}>
        <div className={styles.formHeader}>
          <h3 className={`${styles.formHeaderText} greyText`}>Unite Inform</h3>
        </div>
        <div className={styles.formBody}>
          {error && <div className={styles.errorBox}>{getErrorText(error)}</div>}
          <form className={styles.form} name="loginForm" onSubmit={this.handleSubmit}>
            <input
              className={styles.textInput}
              type="text"
              value={tenantKey}
              placeholder="Customer ID"
              autoFocus={true}
              autoCapitalize="off"
              onChange={this.handleTenantKeyChange}
            />
            <button type="submit" className={styles.loginButton}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }

  handleTenantKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tenantKey: e.target.value });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    this.handleSubmitAsync(e);
  };

  handleSubmitAsync = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({ loading: true, error: null });

    const { tenantKey } = this.state;
    try {
      // Load tenant and initialize Keycloak.
      await getTenant('', tenantKey);

      // Tenant loaded successfully => set tenant key and reload page
      // to get to Keycloak login for this tenant.
      setTenantKey(tenantKey);
      window.location.reload();
    } catch (error) {
      log.error('Error loading tenant', error);
      this.setState({ loading: false, error });
    }
  };
}

function getErrorText(error: any) {
  switch (error.status) {
    case 404:
      return 'Please enter a valid customer ID.';
    case 500:
      return 'Internal server error. Please try again later.';
    default:
      return 'Error loading tenant. Please retry.';
  }
}

function Loading() {
  return (
    <div className={styles.centered}>
      <CircularProgress size={32} thickness={3} />
    </div>
  );
}
