import * as React from 'react';

import ChatList from '../../api/components/ChatList';
import { ChatThread } from '../reducers/chat';

import styles from './Chat.module.css';

type Props = {
  height?: number;
  thread: ChatThread | null | undefined;
};

export default function ReadOnlyChat({ height, thread }: Props) {
  return (
    <div className={styles.chatView}>
      <ChatList thread={thread} height={height} />
    </div>
  );
}
