import { isNil } from 'lodash-es';

import { Incident, IncidentData } from '../../api/incidentsTypes';
import { Incident as ApiIncident, IncidentStatus } from '../../api/types';

export function isIncidentNew({ changeStatus }: Incident) {
  return changeStatus === 'new' || changeStatus === 'newOpened';
}

export function isIncidentStrictNew({ changeStatus }: Incident) {
  return changeStatus === 'new';
}

export function isIncidentUpdated({ changeStatus }: Incident) {
  return changeStatus === 'updated';
}

export function isIncidentRead({ changeStatus }: Incident) {
  return changeStatus === 'read';
}

export function isIncidentUnread({ changeStatus }: Incident) {
  return changeStatus !== 'read';
}

export function isIncidentClosed({ status }: IncidentData): boolean {
  return status === ('CLOSED' as IncidentStatus);
}

export function isIncidentClosedOrReplaced({ status, replacedById }: IncidentData): boolean {
  return status === ('CLOSED' as IncidentStatus) || !isNil(replacedById);
}

export function incidentToIncidentData(apiIncident: ApiIncident): IncidentData {
  return {
    status: apiIncident.status,
    lines: apiIncident.lines,
    locationFrom: apiIncident.locationFrom,
    locationTo: apiIncident.locationTo,
    regions: apiIncident.regions,
    incidentType: apiIncident.incidentType,
    cause: apiIncident.cause,
    place: apiIncident.place,
    endTime: apiIncident.endTime,
    measure: apiIncident.measure,
    information: apiIncident.information,
    internals: apiIncident.internals,
    finalInformation: apiIncident.finalInformation,
    replacedById: apiIncident.replacedById,
  };
}
