import { select, take, call, delay } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { some } from 'lodash-es';

import log from '../../shared/logging';
import { Incident as ApiIncident } from '../../api/types';
import { intlSelector } from '../../api/selectors/i18n';
import { Incident } from '../../api/incidentsTypes';
import { ReduxState } from '../../common/reducers';
import filterWithSetsSelector, { FilterWithSets } from '../../common/selectors/filterWithSets';
import { incidentMatchesFilter } from '../../common/selectors/filteredIncidents';
import { isIncidentNew } from '../../common/utils/incidents';
import { getGlobalAudioElement } from '../navigation/NotificationAudio';
import { Action } from '../actions';

const msg = defineMessages({
  alert: {
    id: 'newOrUpdatedIncidentsFound.alert',
    defaultMessage: 'New or updated messages found, which match your favorites or filter criteria!',
  },
});

function actionMayHaveChangedIncidents(action: Action) {
  switch (action.type) {
    case 'API_CALL_SUCCESS':
      return action.name === 'FETCH_INCIDENTS';
    case 'LONG_POLLING_MESSAGE': {
      const { message } = action;
      return message.type === 'Incident' && message.role === 'INFORM_USER' && message.action === 'UPSERT';
    }
    default:
      return false;
  }
}

export default function* signalChangedIncidentsSaga(): Generator<any, any, any> {
  while (true) {
    // Get and store the "old" lastTransactionId value.
    const oldTransactionId: number = yield select((state: ReduxState) => state.inform.incidents.transactionId);

    // Wait for incident changes.
    const action = yield take(actionMayHaveChangedIncidents);

    const filterWithSets: FilterWithSets = yield select(filterWithSetsSelector);
    const currentIncidents: {
      [id: string]: Incident;
    } = yield select((state: ReduxState) => state.inform.incidents.byId);

    switch (action.type) {
      case 'API_CALL_SUCCESS': {
        const newTransactionId: number = yield select((state: ReduxState) => state.inform.incidents.transactionId);
        if (oldTransactionId && newTransactionId !== oldTransactionId) {
          const shouldPlaySignal = some(
            currentIncidents,
            incident =>
              incident.transactionId === newTransactionId && shouldPlaySignalForIncident(incident, filterWithSets)
          );

          if (shouldPlaySignal) {
            yield* playChangedIncidentsSignal();
          }
        }
        break;
      }
      case 'LONG_POLLING_MESSAGE': {
        const apiIncident: ApiIncident = action.message.payload;
        const { incidentId } = apiIncident;
        const incident: Incident | null | undefined = currentIncidents[incidentId];

        if (incident && shouldPlaySignalForIncident(incident, filterWithSets)) {
          yield* playChangedIncidentsSignal();
        }
        break;
      }
      default:
        break;
    }
  }
}

function* playChangedIncidentsSignal() {
  const audioElement = getGlobalAudioElement();
  if (!audioElement) {
    return;
  }

  try {
    // Provide this context to call.
    yield call([audioElement, audioElement.play]);
  } catch (error) {
    log.error('Error playing notification audio', error);
  }

  const idle: boolean = yield select((state: ReduxState) => state.app.userIdle);
  if (idle) {
    yield delay(15000);

    const stillIdle: boolean = yield select((state: ReduxState) => state.app.userIdle);
    if (stillIdle) {
      const intl = yield select(intlSelector);
      // eslint-disable-next-line no-alert
      alert(intl.formatMessage(msg.alert));

      // TODO: Do we need this? LP saga should reconnect automatically.
      // yield put(fetchIncidents()); // Trigger fetch once after alert box confirmation
    }
  }
}

// For new/favorite/filtered incidents, play audio signal.
function shouldPlaySignalForIncident(incident, filterWithSets) {
  return incident.isFavorite || incidentMatchesFilter(incident, filterWithSets) || isIncidentNew(incident);
}
