import { Action } from '../actions';

export type RefreshTimerState = {
  readonly now: Date;
};

const initialState: RefreshTimerState = {
  now: new Date(),
};

type PartialReduxState = {
  refreshTimer: RefreshTimerState;
};

export default function refreshTimer(state: RefreshTimerState = initialState, action: Action) {
  switch (action.type) {
    case 'REFRESH_TIMER':
      return { now: new Date() };
    default:
      return state;
  }
}

export function nowSelector(state: PartialReduxState): Date {
  return state.refreshTimer.now;
}
