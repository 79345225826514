import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector as useSelectorGeneric } from 'react-redux';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

import commonReducers, { ReduxState } from '../../common/reducers';
import { crowdTaskIncidents, IncidentsState } from '../../common/reducers/incidents';
import adminTenants, { AdminTenantsState } from './adminTenants';
import adminPersisted, { AdminPersistedState } from './adminPersisted';
import analysis, { AnalysisState } from './analysis';
import callOuts, { CallOutsState } from './callOuts';
import callOutStats, { CallOutStatsState } from './callOutStats';
import locations, { LocationsState } from './locations';
import masterData, { MasterDataState } from './masterData';
import editCallOut, { EditCallOutState } from './editCallOut';
import newOperation, { NewOperationState } from './newOperation';
import operations, { OperationsState } from './operations';
import operationStats, { OperationStatsState } from './operationStats';
import participations, { StaffMemberParticipationsState } from './participations';
import statistics, { StatisticsState } from './statistics';
import webNav, { WebNavState } from './webNav';

function persist<R extends any>(key: string, reducer: R): R {
  const persistConfig = { key, storage: localForage };
  // @ts-ignore
  return persistReducer(persistConfig, reducer) as any;
}

const {
  api,
  app,
  appConfig,
  chat,
  chatPending,
  config,
  dataFilter,
  device,
  deviceInfo,
  filter,
  i18n,
  incidents,
  refreshTimer,
  settings,
  userInfo,
} = commonReducers;

export default combineReducers({
  // Common
  api,
  app,
  appConfig,
  chat: persist('chat', chat),
  chatPending: persist('chatPending', chatPending),
  config, // config is not persisted here, but in index.js outside of Redux.
  crowdTaskIncidents,
  device: persist('device', device),
  deviceInfo,
  i18n,
  refreshTimer,
  settings: persist('settings', settings),
  userInfo: persist('userInfo', userInfo),

  // Inform
  inform: combineReducers({
    incidents: persist('incidents', incidents),
    filter: persist('filter', filter),
    dataFilter,
  }),

  // Admin
  admin: combineReducers({
    analysis,
    tenants: adminTenants,
    persisted: persist('admin.persisted', adminPersisted),
    statistics,
  }),

  // Crowd Task
  callOuts,
  callOutStats,
  ui: combineReducers({
    editCallOut,
    newOperation,
  }),
  locations: persist('locations', locations),
  masterData,
  operations,
  operationStats,
  participations,

  // Web
  form: persist(
    'form',
    form.plugin({
      editCallOut: resetOnApiCallSuccessAndActions(['POST_CALL_OUT', 'UPDATE_CALL_OUT', 'POST_FOLLOWUP_CALL_OUT']),
      newOperation: resetOnApiCallSuccessAndActions(['POST_OPERATION', 'UPDATE_OPERATION']),
      rejectParticipationReason: resetOnApiCallSuccessAndActions(['REJECT_PARTICIPATIONS']),
    })
  ),
  webNav,
});

export type WebReduxState = ReduxState & {
  admin: {
    analysis: AnalysisState;
    tenants: AdminTenantsState;
    persisted: AdminPersistedState;
    statistics: StatisticsState;
  };
  callOuts: CallOutsState;
  callOutStats: CallOutStatsState;
  crowdTaskIncidents: IncidentsState;
  form: any;
  locations: LocationsState;
  masterData: MasterDataState;
  operations: OperationsState;
  operationStats: OperationStatsState;
  participations: StaffMemberParticipationsState;
  ui: {
    editCallOut: EditCallOutState;
    newOperation: NewOperationState;
  };
  webNav: WebNavState;
};

function resetOnApiCallSuccessAndActions(names: string[]) {
  return (state, action) => {
    return action.type === 'API_CALL_SUCCESS' && names.includes(action.name) ? undefined : state;
  };
}

export const useSelector: TypedUseSelectorHook<WebReduxState> = useSelectorGeneric;
