import { put, call } from 'redux-saga/effects';
import { apiCallStarted, apiCallSuccess, apiCallError } from './actions';

export default function* wrapApiCallActions(
  name: string,
  apiCall: Generator<any, any, any>,
  rethrowErrors?: boolean
): Generator<any, any, any> {
  yield put(apiCallStarted(name));

  try {
    const result = yield* apiCall;
    yield put(apiCallSuccess(name, result));

    return true;
  } catch (error) {
    yield put(apiCallError(name, error));

    if (rethrowErrors) {
      throw error;
    }

    return false;
  }
}

export function* wrapApiCallPromise(
  name: string,
  apiCall: () => Promise<any>,
  rethrowErrors?: boolean
): Generator<any, any, any> {
  yield put(apiCallStarted(name));

  try {
    const result = yield call(apiCall);
    yield put(apiCallSuccess(name, result));

    return true;
  } catch (error) {
    yield put(apiCallError(name, error));

    if (rethrowErrors) {
      throw error;
    }

    return false;
  }
}
