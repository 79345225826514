import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import { Incident, IncidentData } from '../../api/incidentsTypes';
import LinkButton from '../../api/components/LinkButton';
import { IncidentDataCompareResult } from '../../common/utils/compareIncidentData';
import { isIncidentClosed } from '../../common/utils/incidents';
import DetailRow from './DetailRow';
import styles from './IncidentDetailsLeft.module.css';
import { IncidentStatus } from '../../api';

type Props = {
  incidentId: string;
  created: string;
  lastUpdated: string;
  incidentDate: string;
  incidentData: IncidentData;
  fieldInfos?: IncidentDataCompareResult;
  replacedByIncident?: Incident | null | undefined;
  replacesIncidents?: Incident[] | null | undefined;
  replacedById?: string | null | undefined;
  onLinkedIncidentPressed?: (id: string) => void;
  longDates?: boolean;
  showStatus?: boolean;
  canSeeIncidentType: boolean;
};

const renderIncidentStatus = (status: IncidentStatus) => {
  switch (status) {
    case 'CLOSED':
      return <FormattedMessage id="IncidentDetail.status.closed" defaultMessage="Closed" />;
    case 'PUBLISHED':
    default:
      return <FormattedMessage id="IncidentDetail.status.published" defaultMessage="Published" />;
  }
};

export default class IncidentDetailsLeft extends React.PureComponent<Props> {
  render() {
    const {
      incidentId,
      created,
      lastUpdated,
      incidentDate,
      incidentData,
      fieldInfos,
      replacedByIncident,
      replacesIncidents,
      onLinkedIncidentPressed,
      longDates = false,
      showStatus = false,
      canSeeIncidentType,
    } = this.props;
    const { status, incidentType, lines, locationFrom, locationTo, regions, endTime, replacedById } = incidentData;

    const hasReplacedById = replacedById !== null && typeof replacedById !== 'undefined';
    const hasIncidentsToReplace =
      replacesIncidents !== null && typeof replacesIncidents !== 'undefined' && replacesIncidents.length > 0;

    return (
      <ul className={styles.list}>
        {showStatus ? (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.status" defaultMessage="Status" />}
            data={renderIncidentStatus(status)}
          />
        ) : null}
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.created" defaultMessage="Message from" />}
          data={<FormattedTimestamp value={created} fullDate={longDates} />}
        />
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.incidentDate" defaultMessage="Incident created at" />}
          data={<FormattedTimestamp value={incidentDate} fullDate={longDates} />}
        />
        {canSeeIncidentType && (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.incidentType" defaultMessage="Category" />}
            icon="tag"
            fieldInfo={fieldInfos && fieldInfos.incidentType}
            data={incidentType}
          />
        )}
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.lines" defaultMessage="Line" />}
          icon="line"
          fieldInfo={fieldInfos && fieldInfos.lines}
          data={<Lines lines={lines} />}
        />
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.locationFrom" defaultMessage="Location from" />}
          icon="location_from"
          fieldInfo={fieldInfos && fieldInfos.locationFrom}
          data={locationFrom}
        />
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.locationTo" defaultMessage="Location to" />}
          icon="location_to"
          fieldInfo={fieldInfos && fieldInfos.locationTo}
          data={locationTo}
        />
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.regions" defaultMessage="Region" />}
          icon="region"
          fieldInfo={fieldInfos && fieldInfos.regions}
          data={regions.join(', ')}
        />
        {isIncidentClosed(incidentData) ? (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.lastUpdated" defaultMessage="Resolution until" />}
            data={<FormattedTimestamp value={lastUpdated} fullDate={longDates} />}
          />
        ) : (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.endTime" defaultMessage="Estimated Resolution at" />}
            fieldInfo={fieldInfos && fieldInfos.endTime}
            data={
              endTime ? (
                <FormattedTimestamp value={endTime} fullDate={longDates} />
              ) : (
                <FormattedMessage id="IncidentDetail.unknown" defaultMessage="Unknown" />
              )
            }
          />
        )}
        <DetailRow
          name={<FormattedMessage id="IncidentDetail.incidentId" defaultMessage="REM ID" />}
          data={incidentId}
          showSeparator={hasReplacedById || hasIncidentsToReplace}
        />
        {hasReplacedById && (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.replacedById" defaultMessage="Replaced by" />}
            data={
              <ReplacedByLink
                replacedById={replacedById}
                replacedByIncident={replacedByIncident}
                onLinkedIncidentPressed={onLinkedIncidentPressed}
              />
            }
            showSeparator={hasIncidentsToReplace}
          />
        )}
        {hasIncidentsToReplace && (
          <DetailRow
            name={<FormattedMessage id="IncidentDetail.replacesId" defaultMessage="Replaces" />}
            data={
              <ReplacesIncidents
                replacesIncidents={replacesIncidents}
                onLinkedIncidentPressed={onLinkedIncidentPressed}
              />
            }
            showSeparator={false}
          />
        )}
      </ul>
    );
  }
}

function Lines({ lines }) {
  return lines.map(line => (
    <div key={line}>
      {line}
      <br />
    </div>
  ));
}

function ReplacedByLink({ replacedById, replacedByIncident, onLinkedIncidentPressed }) {
  if (replacedById && replacedByIncident) {
    return (
      <LinkButton className="redLink pointerCursor" onClick={onLinkedIncidentPressed}>
        {replacedById}
      </LinkButton>
    );
  }

  if (replacedById) {
    return (
      <FormattedMessage
        id="IncidentMissingRow"
        defaultMessage="Incident {incidentId} cannot be displayed. Please review your filter settings."
        values={{ incidentId: replacedById }}
      />
    );
  }

  return null;
}

function ReplacesIncidents({ replacesIncidents, onLinkedIncidentPressed }) {
  if (!replacesIncidents) {
    return null;
  }

  return replacesIncidents.map(replacesIncident => (
    <LinkButton key={replacesIncident.incidentId} className="redLink" onClick={onLinkedIncidentPressed}>
      {replacesIncident.incidentId}
      <br />
    </LinkButton>
  ));
}
