import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedShortRelativeTime from '../../common/components/FormattedShortRelativeTime';
import { CrowdTaskOperation, CrowdTaskOperationStats } from '../../api/types';
import styles from './OperationCard.module.css';

type Props = {
  setRef?: (ref: HTMLElement | null) => void;
  operation: CrowdTaskOperation;
  operationStats: CrowdTaskOperationStats;
  isSelected: boolean;
  unreadThreadIds: Set<string>;
  onClick: (operationId: string, subPath?: 'chat' | 'callOuts') => void;
};

export default class OperationCard extends React.PureComponent<Props> {
  render() {
    const { operation, operationStats, isSelected, unreadThreadIds, setRef } = this.props;
    const {
      numberOfOfferedParticipations,
      numberOfDraftCallOuts,
      numberOfReleasedCallOuts,
      runningSince,
    } = operationStats;
    const { location } = operation;

    const style = isSelected ? styles.cardSelected : styles.cardUnselected;
    const locationNameStyle = isSelected ? styles.locationNameSelected : styles.locationName;

    return (
      <div className={style} onClick={this.handleClick} ref={setRef}>
        <div className={styles.top}>
          <div className={locationNameStyle}>{location}</div>
          <div className={styles.icons}>
            {numberOfOfferedParticipations > 0 && (
              <div className={styles.iconSpacer}>
                <i className="icon-accept" onClick={this.handleParticipationsIconClick} />
              </div>
            )}
            {operation.chatThreadId && unreadThreadIds.has(operation.chatThreadId) && (
              <i className="icon-chat" onClick={this.handleChatIconClick} />
            )}
          </div>
        </div>
        <div className={styles.runningSince}>
          {runningSince && (
            <FormattedMessage
              id="OperationCard.runningSince"
              defaultMessage="running since {since}"
              values={{ since: <FormattedShortRelativeTime value={runningSince} /> }}
            />
          )}
        </div>
        <div className={styles.bottom}>
          <div className={numberOfReleasedCallOuts ? styles.tintPill : styles.greyPill}>
            <FormattedMessage
              id="OperationCard.activeCallOuts"
              defaultMessage="{numActive} active"
              values={{ numActive: numberOfReleasedCallOuts }}
            />
          </div>
          <div className={numberOfDraftCallOuts ? styles.tintPill : styles.greyPill}>
            <FormattedMessage
              id="OperationCard.plannedCallOuts"
              defaultMessage="{numPlanned} planned"
              values={{ numPlanned: numberOfDraftCallOuts }}
            />
          </div>
        </div>
      </div>
    );
  }

  handleClick = () => {
    const { operation, onClick } = this.props;
    onClick(operation.operationId);
  };

  handleChatIconClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    const { operation, onClick } = this.props;
    onClick(operation.operationId, 'chat');
  };

  handleParticipationsIconClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    const { operation, onClick } = this.props;
    onClick(operation.operationId, 'callOuts');
  };
}
